import { Pipe, PipeTransform } from '@angular/core';
import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js';

/**
 * Takes string of a phone number and optional country string to format the phone number
 */
@Pipe({
    name: 'phoneNumber',
    standalone: true,
})
export class PhoneNumberPipe implements PipeTransform {
  /**
   *
   * @param value string of phone number
   * @param countryString string of country of the phone number
   * @returns
   */
  transform(value: string, countryString?: string): any {
    if (!value) {
      return null;
    }
    const countryCode = <CountryCode>(countryString ? countryString : 'US');

    const phoneNum = parsePhoneNumberFromString(value, countryCode);
    return phoneNum?.formatNational();
  }
}
