<div class="flex max-h-[100vh] w-fit overflow-y-auto">
  <form-card
    (exitClick)="closeDialog()"
    [backButton]="false"
    [submitButton]="false"
    [titleText]="'Payment Link Created'"
    [secondaryText]="'Copy and share the one-time payment link with your customer.'"
    [testIds]="{ headerTestId: 'modal-header' }"
  >
    <div class="flex flex-col">
      <div class="mt-2 w-full pb-2.5">
        <tilled-label-l1 secondary="true">One-Time Payment Link</tilled-label-l1>
      </div>
      <div class="flex flex-row">
        <div
          class="-ml-0.5 flex h-12 items-center overflow-x-auto rounded-l-md border-y border-l border-r-0 border-tilled-neutral-400 pl-3"
        >
          <span class="w-[98%] overflow-clip text-ellipsis whitespace-nowrap">{{ displayUrl }}</span>
        </div>
        <div
          class="hoverable flex h-12 cursor-pointer items-center justify-center rounded-r-full border border-tilled-primary px-3 hover:bg-primary-100"
          data-test-id="copy-payment-link-Btn"
          (click)="copyPaymentLink()"
        >
          <mat-icon
            class="notranslate mat-icon-no-color mat-icon text-secondary mr-1 align-[-1px]"
            [svgIcon]="'heroicons_outline:square-2-stack'"
          ></mat-icon>
          <tilled-paragraph-p3>Copy</tilled-paragraph-p3>
        </div>
      </div>
    </div>
  </form-card>
</div>
