<div class="flex w-full flex-auto flex-col sm:w-120">
  <div class="relative left-1 top-[-5px] flex justify-end">
    <mat-icon style="cursor: pointer" (click)="closeDialog()" svgIcon="mat_outline:close"></mat-icon>
  </div>
  <ng-container>
    <div class="grid w-full justify-items-center pb-8 text-center">
      <!-- Title -->
      <tilled-heading-h2 class="pb-2">Cancel Outbound Transfer</tilled-heading-h2>
      <tilled-paragraph-p3
        >Confirm the cancellation of outbound transfer '{{ outboundTransfer.id }}' for
        {{ outboundTransfer.amount | minorUnitsToCurrency: outboundTransfer.currency }}</tilled-paragraph-p3
      >
    </div>

    <div *ngIf="displayAlert$ | async" class="flex w-full justify-start pb-6 sm:px-8">
      <fuse-alert
        [type]="alertType"
        [appearance]="'outline'"
        [dismissible]="alertDismissible"
        [dismissed]="false"
        [name]="'cancelDialogAlertBox'"
      >
        <span fuseAlertTitle>{{ alertTitle }}</span>
        {{ alertMessage }}
      </fuse-alert>
    </div>

    <div class="flex w-full justify-end px-8">
      <!-- Confirm Cancel Button -->
      <tilled-button
        data-test-id="confirm-cancel-Btn"
        bgColor="tilled-primary"
        [rounded]="true"
        buttonText="Confirm Cancellation"
        (buttonClick)="confirmClicked()"
        [whiteText]="true"
        [disabled]="activelyCancelling"
      ></tilled-button>
    </div>
  </ng-container>
</div>
