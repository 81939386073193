<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<empty-layout *ngIf="layout === 'empty'"></empty-layout>

<!-- Futuristic -->
<onboarding-layout *ngIf="layout === 'onboarding'"></onboarding-layout>

<!-- Futuristic -->
<futuristic-layout *ngIf="layout === 'futuristic'"></futuristic-layout>
