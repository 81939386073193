<div
  *ngIf="lineItems"
  data-test-id="line-items-table"
  class="flex flex-col"
  [ngClass]="{ 'max-w-fit': currentEditIndex !== -1, 'w-full': currentEditIndex === -1 }"
>
  <!-- List Column Headers-->
  <div class="grid w-full grid-cols-6 gap-x-4">
    <div class="col-span-1 flex flex-col">
      <tilled-label-l1 secondary="true">Product code</tilled-label-l1>
    </div>
    <div class="col-span-2 flex flex-col">
      <tilled-label-l1 secondary="true">Product description</tilled-label-l1>
    </div>
    <div class="col-span-1 flex flex-col">
      <tilled-label-l1 secondary="true">Quantity</tilled-label-l1>
    </div>
    <div class="col-span-1 flex flex-col">
      <tilled-label-l1 secondary="true">Unit amount</tilled-label-l1>
    </div>
    <div class="col-span-1 flex flex-col">
      <tilled-label-l1 secondary="true">Tax amount</tilled-label-l1>
    </div>
  </div>
  <mat-divider class="mb-3.5 mt-2 w-full border-tilled-neutral-400"></mat-divider>

  <!-- List Data Rows-->
  <ng-container *ngFor="let lineItem of lineItems; let i = index">
    <!-- Display Row -->
    <ng-container *ngIf="currentEditIndex !== i; else editRow">
      <div class="grid w-full grid-cols-6 gap-x-4 pt-4">
        <div class="col-span-1 -mt-3 mb-1 flex flex-col">
          <tilled-label-p3>{{ lineItem.product_code }}</tilled-label-p3>
        </div>
        <div class="col-span-2 -mt-3 mb-1 flex flex-col">
          <tilled-label-p3>{{ lineItem.product_description }}</tilled-label-p3>
        </div>
        <div class="col-span-1 -mt-3 mb-1 flex flex-col">
          <tilled-label-p3>{{ lineItem.quantity }}</tilled-label-p3>
        </div>
        <div class="col-span-1 -mt-3 mb-1 flex flex-col">
          <tilled-label-p3>{{ lineItem.unit_amount | currency }}</tilled-label-p3>
        </div>
        <div class="col-span-1 -mt-3 mb-1 flex flex-col">
          <div class="pr-6">
            <tilled-label-p3>{{ lineItem.tax_amount | currency }}</tilled-label-p3>
          </div>
          <button mat-icon-button [matMenuTriggerFor]="lineItemMenu" class="-mt-7 self-end" [disabled]="editDisabled">
            <mat-icon class="text-primary icon-size-5" [svgIcon]="'mat_solid:more_vert'"></mat-icon>
          </button>
          <mat-menu #lineItemMenu="matMenu">
            <button mat-menu-item (click)="editLineItemRow(i)">Edit</button>
            <button mat-menu-item (click)="deleteLineItemRow(i)">Delete</button>
          </mat-menu>
        </div>
        <mat-divider class="col-span-6 mb-3.5 flex flex-col border-tilled-neutral-300"></mat-divider>
      </div>
    </ng-container>
    <!-- Editing Row -->
    <ng-template #editRow>
      <form [formGroup]="lineItemForm">
        <div class="grid w-full grid-cols-6 gap-x-4 pt-2">
          <div class="col-span-1 -mt-3 mb-1 flex flex-col">
            <tilled-input
              name="productCode"
              [placeholder]="'Enter product code'"
              [errors]="lineItemForm.get('productCode').hasError('required') && lineItemForm.get('productCode').touched"
              errorMessage="Required"
            ></tilled-input>
          </div>
          <div class="col-span-2 -mt-3 mb-1 flex flex-col">
            <tilled-input
              name="productDescription"
              [placeholder]="'Enter product description'"
              [errors]="
                lineItemForm.get('productDescription').hasError('required') &&
                lineItemForm.get('productDescription').touched
              "
              errorMessage="Required"
            >
            </tilled-input>
          </div>
          <div class="col-span-1 -mt-3 mb-1 flex flex-col">
            <tilled-input
              name="quantity"
              [placeholder]="'Enter quantity'"
              [errors]="lineItemForm.get('quantity').hasError('required') && lineItemForm.get('quantity').touched"
              errorMessage="Required"
              mask="separator"
              appearance="fill"
            ></tilled-input>
          </div>
          <div class="col-span-1 -mt-3 mb-1 flex flex-col">
            <tilled-input
              name="unitAmount"
              [placeholder]="'Enter unit amount'"
              [errors]="lineItemForm.get('unitAmount').hasError('required') && lineItemForm.get('unitAmount').touched"
              errorMessage="Required"
              [mask]="lineItemForm.get('unitAmount').touched ? 'separator.2' : ''"
              appearance="fill"
              showCurrency="true"
            ></tilled-input>
          </div>
          <div class="col-span-1 -mt-3 mb-1 flex flex-col">
            <div class="pr-8">
              <tilled-input
                name="taxAmount"
                [placeholder]="'Enter tax amount'"
                [errors]="lineItemForm.get('taxAmount').hasError('required') && lineItemForm.get('taxAmount').touched"
                errorMessage="Required"
                [mask]="lineItemForm.get('taxAmount').touched ? 'separator.2' : ''"
                appearance="fill"
                showCurrency="true"
              ></tilled-input>
            </div>
            <button mat-icon-button (click)="saveLineItemRow(i)" class="-mr-2 -mt-7 self-end">
              <mat-icon [svgIcon]="'heroicons_solid:check-circle'" class="text-primary icon-size-6"></mat-icon>
            </button>
          </div>
          <mat-divider class="col-span-6 mb-3.5 flex flex-col border-tilled-neutral-300"></mat-divider>
        </div>
      </form>
    </ng-template>
  </ng-container>
  <div class="flex w-full flex-col">
    <button mat-icon-button (click)="addLineItemRow()" class="self-end" [disabled]="editDisabled">
      <mat-icon [svgIcon]="'heroicons_solid:plus'" class="text-primary icon-size-6"></mat-icon>
    </button>
  </div>
</div>

<div class="w-fulll flex h-fit flex-auto">
  <!-- No templates -->
  <div *ngIf="!lineItems" class="h-fit w-full">
    <!-- Admin no templates-->
    <dashed-line-container>
      <div class="my-[5%] text-center">
        <div class="flex flex-col items-center">
          <tilled-heading-h2 class="mb-2">No line items</tilled-heading-h2>
          <tilled-paragraph-p1 class="mb-10">Minimum 1 line item required</tilled-paragraph-p1>
        </div>
        <tilled-button
          bgColor="tilled-primary"
          [rounded]="true"
          [buttonText]="'Add Line Items'"
          (buttonClick)="addFirstLineItem()"
        ></tilled-button>
      </div>
    </dashed-line-container>
  </div>
</div>
