import 'reflect-metadata';
import { ColumnModel } from './column.model';
import { TableModel } from './table.model';

export const tableSymbol = Symbol('columns');

// https://betterprogramming.pub/angular-material-build-your-own-generic-mattable-a49ba68a375a
export function Column(options: Partial<ColumnModel> = {}) {
  return function (target: any, propertyKey: string) {
    if (!target[tableSymbol]) {
      target[tableSymbol] = new TableModel();
    }
    options.key = options.key || propertyKey;
    const propType = Reflect.getMetadata('design:type', target, propertyKey);
    options.propertyType = propType.name;
    const columnOptions = new ColumnModel(options);
    target[tableSymbol].addColumn(columnOptions);
  };
}
