<div class="flex max-h-[100vh] w-fit flex-col overflow-y-auto sm:min-w-120">
  <form-card
    [titleText]="'Create Payment Link'"
    [secondaryText]="'Enter payment details below to generate a one-time payment link.'"
    (exitClick)="closeDialog()"
    [backButton]="false"
    [submitButton]="true"
    (submitClick)="generatePaymentLink()"
    [submitDisabled]="paymentLinkForm.invalid || (creatingPaymentLink$ | async)"
    submitText="Generate Payment Link"
    [testIds]="{ headerTestId: 'modal-header' }"
  >
    <form [formGroup]="paymentLinkForm">
      <div class="-mt-6">
        <ng-container *ngIf="!isMerchant">
          <app-merchant-selector
            labelName="Merchant"
            inputAppearance="fill"
            additionalClasses="w-full"
            (setMerchantAccount)="parseAccountData($event)"
          >
          </app-merchant-selector>
        </ng-container>
        <div *ngIf="availablePaymentMethods" class="-mb-1 flex flex-col" formGroupName="paymentMethodCheckboxGroup">
          <tilled-label-l1 secondary="true" class="pb-3 pt-5">Payment options</tilled-label-l1>
          <mat-checkbox
            [ngClass]="disableCard ? 'hidden' : ''"
            data-test-id="card-checkbox"
            class="text-p3"
            [color]="'primary'"
            [formControlName]="'cardCheckbox'"
          >
            <tilled-paragraph-p3>Credit and debit card</tilled-paragraph-p3>
          </mat-checkbox>
          <mat-checkbox
            [ngClass]="disableAch ? 'hidden' : ''"
            data-test-id="ach-checkbox"
            class="-mt-1 text-p3"
            [color]="'primary'"
            [formControlName]="'achCheckbox'"
          >
            <tilled-paragraph-p3>ACH debit</tilled-paragraph-p3>
          </mat-checkbox>
          <mat-checkbox
            [ngClass]="disableEft ? 'hidden' : ''"
            data-test-id="eft-checkbox"
            class="-mt-1 text-p3"
            [color]="'primary'"
            [formControlName]="'eftCheckbox'"
          >
            <tilled-paragraph-p3>EFT debit</tilled-paragraph-p3>
          </mat-checkbox>
          <mat-error
            class="text-sm"
            color="warn"
            *ngIf="paymentLinkForm.get('paymentMethodCheckboxGroup').hasError('requiresCheckbox')"
          >
            At least one payment method is required</mat-error
          >
        </div>
        <tilled-input
          label="Payment Description"
          data-test-id="payment-description"
          placeholder="Enter the name of the product or service"
          name="description"
          [errors]="
            paymentLinkForm.get('description').hasError('required') && paymentLinkForm.get('description').touched
          "
          errorMessage="A payment description is required"
          tilledLabel="true"
          appearance="fill"
          styles="mr-18"
        >
        </tilled-input>
        <tilled-input
          label="Amount"
          placeholder="Enter the amount"
          name="amount"
          [errors]="paymentLinkForm.get('amount').hasError('required') && paymentLinkForm.get('amount').touched"
          errorMessage="A valid payment amount is required"
          tilledLabel="true"
          showCurrency="true"
          mask="separator.2"
          data-test-id="payment-amount"
          hint="Enter amount for the product or service"
          [suffixMultiple]="false"
          [suffixOptions]="availableCurrencies"
          [suffixControlName]="paymentLinkForm.get('currency')"
          [suffixPlaceholder]="selectedCurrency ? availableCurrencies[0]?.label : ''"
          [suffixReadonly]="disableCurrency"
        >
        </tilled-input>
      </div>
    </form>
  </form-card>
</div>
