import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { CommonModule, PercentPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ComponentBase } from 'app/core/componentBase';
import { SelectorTypes } from 'app/core/data/selector-types';
import { MerchantAppService } from 'app/core/services/merchant-app.service';
import { TilledSelectComponent } from 'app/shared/tilled-select/tilled-select.component';
import { _compareTwoStrings } from 'app/shared/utils/compare-two-strings';
import { isOlderThan, isValidBirthYear } from 'app/shared/validators/dob.validator';
import { notEqualToList } from 'app/shared/validators/notEqualToList.validator';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { Observable, Subscription, takeUntil } from 'rxjs';
import {
  BusinessLegalEntity,
  MerchantApplication,
  PartialAddressV2,
  Principal,
} from '../../../../../projects/tilled-api-client/src';
import { FuseAlertComponent } from '../../../../@fuse/components/alert/alert.component';
import { AutocompleteComponent } from '../../autocomplete/autocomplete.component';
import { TilledButtonComponent } from '../../buttons/tilled-button.component';
import { MerchantAppAlertComponent } from '../../cards/merchant-application/merchant-app-alert/merchant-app-alert.component';
import { MerchantAppCardComponent } from '../../cards/merchant-application/merchant-app-card/merchant-app-card.component';
import { TilledInputComponent } from '../../form-fields/tilled-input/tilled-input.component';
import { TilledHeadingH4Component } from '../../tilled-text/tilled-heading/tilled-heading-h4.component';
import { TilledHeadingH5Component } from '../../tilled-text/tilled-heading/tilled-heading-h5.component';
import { TilledHeadingH6Component } from '../../tilled-text/tilled-heading/tilled-heading-h6.component';
import { TilledLabelL1Component } from '../../tilled-text/tilled-label/tilled-label-l1.component';
import { TilledParagraphP3Component } from '../../tilled-text/tilled-paragraph/tilled-paragraph-p3.component';
import { RepresentativeRequirements } from './representative-requirements';
import RegionEnum = BusinessLegalEntity.RegionEnum;

const badSSNs = [
  '000000000',
  '111111111',
  '222222222',
  '333333333',
  '444444444',
  '555555555',
  '666666666',
  '777777777',
  '888888888',
  '999999999',
  '123456789',
  '987654321',
];

@Component({
  selector: 'representatives-step',
  templateUrl: './representatives-step.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MerchantAppCardComponent,
    FormsModule,
    ReactiveFormsModule,
    MerchantAppAlertComponent,
    FuseAlertComponent,
    MatIconModule,
    TilledHeadingH6Component,
    TilledParagraphP3Component,
    TilledHeadingH4Component,
    MatTooltipModule,
    MatRadioModule,
    TilledHeadingH5Component,
    TilledInputComponent,
    TilledLabelL1Component,
    AutocompleteComponent,
    TilledSelectComponent,
    TilledButtonComponent,
    CommonModule,
  ],
})
export class RepresentativesStepComponent extends ComponentBase implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @Input() forConsole: boolean = false;
  @Input() disabled$: Observable<boolean> = null;
  @Input() saveApp$: Observable<string> = null;
  @Input() checkUnsavedApp$: Observable<string> = null;
  @Input() resetApp$: Observable<boolean> = null;
  @Input() stepNumber: number;
  @Input() busDetailsStepNumber: number;
  @Output() markAppUnsaved: EventEmitter<boolean> = new EventEmitter<boolean>();
  public representativesFormArray: FormArray = new FormArray([]);
  public merchantApp: MerchantApplication;
  public businessEntityType: any;
  public phoneCodeMap: { label: string; value: string }[] = Array.from(SelectorTypes.CountryToPhoneCode).map(
    ([label, value]) => ({ label, value }),
  );
  public selectedPhoneCode: string;
  public supportPhone: string = '';
  public stateCodeMap: { label: string; value: string }[] = Array.from(SelectorTypes.stateAndProvinceMap).map(
    ([label, value]) => ({ label, value }),
  );
  private subscriptions: Subscription[] = [];
  public isCanadian: boolean;
  public requirements: string[];
  private selectorTypes: SelectorTypes = new SelectorTypes();
  private stateAndProvinceMap = SelectorTypes.stateAndProvinceMap;
  private principals: Principal[];
  private representativeRequirements: RepresentativeRequirements = new RepresentativeRequirements();

  constructor(
    private _formBuilder: FormBuilder,
    private _merchantAppService: MerchantAppService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _percentPipe: PercentPipe,
  ) {
    super();
  }

  ngOnInit(): void {
    this._merchantAppService.merchantApplicationResponse$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((application) => {
        this.merchantApp = cloneDeep(application);
        this.resetApplication();
      });

    if (this.disabled$) {
      this.subscriptions.push(
        this.disabled$.subscribe((isDisabled) => {
          if (isDisabled) {
            this.representativesFormArray.disable();
          } else {
            this.representativesFormArray.enable();
          }
        }),
      );
    }

    if (this.forConsole) {
      if (this.saveApp$) {
        this.subscriptions.push(
          this.saveApp$.subscribe((save) => {
            if (save) {
              this.onContinueClicked(save);
            }
          }),
        );
      }
      if (this.checkUnsavedApp$) {
        this.subscriptions.push(
          this.checkUnsavedApp$.subscribe((check) => {
            if (check) {
              this.markAppUnsaved.emit(this.isAppUnsaved());
            }
          }),
        );
      }
      if (this.resetApp$) {
        this.subscriptions.push(
          this.resetApp$.subscribe((reset) => {
            if (reset) {
              this.resetApplication();
            }
          }),
        );
      }
    }
    this.selectedPhoneCode = this.selectedPhoneCode ? this.selectedPhoneCode : this.phoneCodeMap[0].value;
  }

  ngAfterViewInit(): void {
    this.scrollToTop();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  getPrincipalFormGroup(data: Principal): FormGroup {
    data = data || ({} as Principal);

    return new FormGroup({
      id: new FormControl<string | null>(data.id || null),
      isApplicant: new FormControl<boolean>(data.is_applicant || false),
      isControlProng: new FormControl<boolean>(data.is_control_prong || false),
      firstName: new FormControl<string | null>(data.first_name || null, [Validators.required]),
      middleName: new FormControl<string | null>(data.middle_name || null),
      lastName: new FormControl<string | null>(data.last_name || null, [Validators.required]),
      dob: new FormControl<string | null>(data.date_of_birth ? moment(data.date_of_birth).format('MM/DD/YYYY') : null, [
        isOlderThan(18),
        isValidBirthYear(),
      ]),
      ssn: new FormControl<string | null>(data.ssn || null, [notEqualToList(badSSNs)]),
      jobTitle: new FormControl<string | null>(data.job_title || null),
      phone: new FormControl<string | null>(data.phone || null),
      phoneCode: new FormControl<string | null>(this.selectedPhoneCode || null),
      percentageShareholding: new FormControl<number | null>(data.percentage_shareholding || null, [
        Validators.min(0),
        Validators.max(100),
      ]),
      currentStreet: new FormControl<string | null>(data.address?.street || null),
      currentStreet2: new FormControl<string | null>(data.address?.street2 || null),
      currentCity: new FormControl<string | null>(data.address?.city || null),
      currentState: new FormControl<string | null>(SelectorTypes.stateAndProvinceMap.get(data.address?.state) || null),
      currentZip: new FormControl<string | null>(data.address?.zip || null),
      currentCountry: new FormControl<string | null>(data.address?.country || null),
      yearsAtCurrentAddress: new FormControl<number | null>(data.address?.years_at_address || null),
      previousStreet: new FormControl<string | null>(data.previous_address?.street || null),
      previousStreet2: new FormControl<string | null>(data.previous_address?.street2 || null),
      previousCity: new FormControl<string | null>(data.previous_address?.city || null),
      previousState: new FormControl<string | null>(
        SelectorTypes.stateAndProvinceMap.get(data.previous_address?.state) || null,
      ),
      previousZip: new FormControl<string | null>(data.previous_address?.zip || null),
      previousCountry: new FormControl<string | null>(data.previous_address?.country || null),
      yearsAtPreviousAddress: new FormControl<number | null>(data.previous_address?.years_at_address || null),
    });
  }

  removePrincipalFormGroup(index: number): void {
    this.representativesFormArray.removeAt(index);
  }

  public setPrincipalAddress(address: PartialAddressV2, index: number, addressType: string): void {
    if (addressType === 'current') {
      this.representativesFormArray.controls[index].patchValue({
        currentStreet: address.street,
        currentCity: address.city,
        currentState: SelectorTypes.stateAndProvinceMap.get(address.state),
        currentZip: address.postal_code,
      });
    } else {
      this.representativesFormArray.controls[index].patchValue({
        previousStreet: address.street,
        previousCity: address.city,
        previousState: SelectorTypes.stateAndProvinceMap.get(address.state),
        previousZip: address.postal_code,
      });
    }
    this.representativesFormArray.controls[index].markAsTouched();
  }

  onEditClicked(event: string): void {
    this._merchantAppService.updateCurrentStep(this.busDetailsStepNumber);
  }

  onBackClicked(event: string): void {
    this._merchantAppService.updateCurrentStep(this.stepNumber - 1);
  }

  onIsApplicantYes(index: number): void {
    for (let i = 0; i < this.representativesFormArray.value.length; i++) {
      if (i === index) {
        continue;
      }
      this.representativesFormArray.controls[i].patchValue({ isApplicant: false });
    }
  }

  onIsControlProngYes(index: number): void {
    for (let i = 0; i < this.representativesFormArray.value.length; i++) {
      if (i === index) {
        continue;
      }
      this.representativesFormArray.controls[i].patchValue({ isControlProng: false });
    }
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.mat-form-field-invalid');
    this.scrollTo(firstElementWithError);
  }

  onContinueClicked(accountId?: string): void {
    this.representativesFormArray.markAllAsTouched();
    if (this.representativesFormArray.invalid) {
      this.scrollToError();
      return;
    }

    // if the phone number is not prefixed with a country code, prefix it with the selected country code (US by default)
    this.representativesFormArray.controls.forEach((control) => {
      if (control.get('phone').value && !control.get('phone').value.startsWith('+')) {
        control.get('phone').patchValue(`${this.selectedPhoneCode}${control.get('phone').value}`);
      }
    });

    this.principals = [];
    for (let i = 0; i < this.representativesFormArray.value.length; i++) {
      const state = this.representativesFormArray.value[i].currentState
        ? [...this.stateAndProvinceMap].find(
            ([key, val]) => val === this.representativesFormArray.value[i].currentState,
          )[0]
        : null;
      this.principals[i] = {
        id: this.representativesFormArray.value[i].id,
        is_applicant: this.representativesFormArray.value[i].isApplicant,
        is_control_prong: this.representativesFormArray.value[i].isControlProng,
        first_name: this.representativesFormArray.value[i].firstName,
        middle_name: this.representativesFormArray.value[i].middleName,
        last_name: this.representativesFormArray.value[i].lastName,
        date_of_birth: moment(this.representativesFormArray.value[i].dob, 'MM-DD-YYYY').toISOString(),
        ssn: this.representativesFormArray.value[i].ssn !== '' ? this.representativesFormArray.value[i].ssn : null,
        job_title: this.representativesFormArray.value[i].jobTitle,
        percentage_shareholding: parseInt(this.representativesFormArray.value[i].percentageShareholding, 10),
        email: null,
        phone: this.representativesFormArray.value[i].phone,
        address: {
          street: this.representativesFormArray.value[i].currentStreet,
          street2: this.representativesFormArray.value[i].currentStreet2,
          city: this.representativesFormArray.value[i].currentCity,
          state: state,
          zip: this.representativesFormArray.value[i].currentZip,
          country: SelectorTypes.getCountryFromState(state),
        },
        previous_address: undefined,
      };

      if (this.isCanadian) {
        this.principals[i].address.years_at_address = parseInt(
          this.representativesFormArray.value[i].yearsAtCurrentAddress,
          10,
        );
      }

      if (this.representativesFormArray.value[i].yearsAtCurrentAddress <= 3 && this.isCanadian) {
        const previousState = this.representativesFormArray.value[i].previousState
          ? [...this.stateAndProvinceMap].find(
              ([key, val]) => val === this.representativesFormArray.value[i].previousState,
            )[0]
          : null;
        this.principals[i].previous_address = {
          street: this.representativesFormArray.value[i].previousStreet,
          street2: this.representativesFormArray.value[i].previousStreet2,
          city: this.representativesFormArray.value[i].previousCity,
          state: previousState,
          zip: this.representativesFormArray.value[i].previousZip,
          country: SelectorTypes.getCountryFromState(previousState),
          years_at_address: parseInt(this.representativesFormArray.value[i].yearsAtPreviousAddress, 10),
        };
      }
    }
    if (!this.merchantApp.business_legal_entity.principals) {
      this.merchantApp.business_legal_entity.principals = [];
    }
    this.merchantApp.business_legal_entity.principals = this.principals;
    this._merchantAppService.updateMerchantApplication(this.merchantApp, this.stepNumber + 1, accountId);
  }

  private isAppUnsaved(): boolean {
    let unchanged: number = 1;
    if (!this.principals) {
      for (const field in this.representativesFormArray.value[0]) {
        // check if there is any data for first bis rep in form
        const value = this.representativesFormArray.value[0][field];
        if (value) {
          return true;
        }
      }
      return false;
    }
    if (this.principals.length != this.representativesFormArray.length) {
      return true;
    }
    for (let i = 0; i < this.representativesFormArray.length; i++) {
      for (const field in this.representativesFormArray.value[i]) {
        if (field) {
          let value = this.representativesFormArray.controls[i].get(field).value;
          if (value === '') {
            this.representativesFormArray.controls[i].get(field).setValue(null);
          }
        }
      }
    }

    for (let i = 0; i < this.representativesFormArray.value.length; i++) {
      const state = this.representativesFormArray.value[i].currentState
        ? [...this.stateAndProvinceMap].find(
            ([key, val]) => val === this.representativesFormArray.value[i].currentState,
          )[0]
        : null;
      const previousState = this.representativesFormArray.value[i].previousState
        ? [...this.stateAndProvinceMap].find(
            ([key, val]) => val === this.representativesFormArray.value[i].previousState,
          )[0]
        : null;
      unchanged *= _compareTwoStrings(this.representativesFormArray.value[i].id, this.principals[i]?.id) ? 1 : 0;
      unchanged *= this.representativesFormArray.value[i].isApplicant === this.principals[i]?.is_applicant ? 1 : 0;
      unchanged *=
        this.representativesFormArray.value[i].isControlProng === this.principals[i]?.is_control_prong ? 1 : 0;
      unchanged *= _compareTwoStrings(this.representativesFormArray.value[i].firstName, this.principals[i]?.first_name)
        ? 1
        : 0;
      unchanged *= _compareTwoStrings(
        this.representativesFormArray.value[i].middleName,
        this.principals[i]?.middle_name,
      )
        ? 1
        : 0;
      unchanged *= _compareTwoStrings(this.representativesFormArray.value[i].lastName, this.principals[i]?.last_name)
        ? 1
        : 0;
      unchanged *= _compareTwoStrings(
        this.representativesFormArray.value[i].dob,
        this.principals[i]?.date_of_birth
          ? moment(this.principals[i]?.date_of_birth).format('MM/DD/YYYY').toString()
          : null,
      )
        ? 1
        : 0;
      unchanged *= _compareTwoStrings(this.representativesFormArray.value[i].ssn, this.principals[i]?.ssn) ? 1 : 0;
      unchanged *= _compareTwoStrings(this.representativesFormArray.value[i].jobTitle, this.principals[i]?.job_title)
        ? 1
        : 0;
      unchanged *=
        (this.representativesFormArray.value[i].percentageShareholding
          ? parseInt(this.representativesFormArray.value[i].percentageShareholding, 10)
          : null) == this.principals[i]?.percentage_shareholding
          ? 1
          : 0;
      unchanged *= _compareTwoStrings(this.representativesFormArray.value[i].email, this.principals[i]?.email) ? 1 : 0;
      unchanged *= _compareTwoStrings(this.representativesFormArray.value[i].phone, this.principals[i]?.phone) ? 1 : 0;
      unchanged *= _compareTwoStrings(
        this.representativesFormArray.value[i].currentStreet,
        this.principals[i]?.address?.street,
      )
        ? 1
        : 0;
      unchanged *= _compareTwoStrings(
        this.representativesFormArray.value[i].currentStreet2,
        this.principals[i]?.address?.street2,
      )
        ? 1
        : 0;
      unchanged *= _compareTwoStrings(
        this.representativesFormArray.value[i].currentCity,
        this.principals[i]?.address?.city,
      )
        ? 1
        : 0;
      unchanged *= _compareTwoStrings(state, this.principals[i]?.address?.state) ? 1 : 0;
      unchanged *= _compareTwoStrings(
        this.representativesFormArray.value[i].currentZip,
        this.principals[i]?.address?.zip,
      )
        ? 1
        : 0;
      unchanged *= _compareTwoStrings(SelectorTypes.getCountryFromState(state), this.principals[i]?.address?.country)
        ? 1
        : 0;
      unchanged *=
        (this.representativesFormArray.value[i].yearsAtCurrentAddress
          ? parseInt(this.representativesFormArray.value[i].yearsAtCurrentAddress, 10)
          : null) == this.principals[i]?.address?.years_at_address
          ? 1
          : 0;
      unchanged *= _compareTwoStrings(
        this.representativesFormArray.value[i].previousStreet,
        this.principals[i]?.previous_address?.street,
      )
        ? 1
        : 0;
      unchanged *= _compareTwoStrings(
        this.representativesFormArray.value[i].previousStreet2,
        this.principals[i]?.previous_address?.street2,
      )
        ? 1
        : 0;
      unchanged *= _compareTwoStrings(
        this.representativesFormArray.value[i].previousCity,
        this.principals[i]?.previous_address?.city,
      )
        ? 1
        : 0;
      unchanged *= _compareTwoStrings(previousState, this.principals[i]?.previous_address?.state) ? 1 : 0;
      unchanged *= _compareTwoStrings(
        this.representativesFormArray.value[i].previousZip,
        this.principals[i]?.previous_address?.zip,
      )
        ? 1
        : 0;
      unchanged *= _compareTwoStrings(
        SelectorTypes.getCountryFromState(previousState),
        this.principals[i]?.previous_address?.country,
      )
        ? 1
        : 0;
      unchanged *=
        (this.representativesFormArray.value[i].yearsAtPreviousAddress
          ? parseInt(this.representativesFormArray.value[i].yearsAtPreviousAddress, 10)
          : null) == this.principals[i]?.previous_address?.years_at_address
          ? 1
          : 0;

      if (!unchanged) {
        break;
      }
    }

    return !unchanged;
  }

  private resetApplication(): void {
    this.representativesFormArray = new FormArray([]);

    this.businessEntityType = this.representativeRequirements.requirements.find(
      (entityType) => entityType.type === this.merchantApp.business_legal_entity?.type,
    );

    if (this.businessEntityType) {
      this.requirements =
        this.merchantApp.product_codes[0].region === RegionEnum.US
          ? this.businessEntityType.usReq
          : this.businessEntityType.caReq;
    }

    this.principals = this.merchantApp.business_legal_entity?.principals;
    this.principals?.forEach((principal) => this.representativesFormArray.push(this.getPrincipalFormGroup(principal)));

    if (!this.principals || this.principals.length === 0) {
      this.representativesFormArray.push(this.getPrincipalFormGroup(null));
    }
    this.isCanadian = this.merchantApp.product_codes[0].region === RegionEnum.CA;

    this._changeDetectorRef.markForCheck();
  }

  scrollToTop(): void {
    const element = document.querySelector('.top-of-form');
    if (element) {
      element.scrollIntoView({ behavior: 'auto', block: 'end' });
    }
  }
}
