import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AUTH_LOGIN_ROUTE, HOMEPAGE_ROUTE } from 'app/core/constants';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class PartnerGuard {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.user) {
      if (!this.authService.isMerchantUser()) {
        return true;
      } else {
        // Logged in user but not a partner account. Redirect to dashboard
        return this.router.navigate(['/' + HOMEPAGE_ROUTE]);
      }
    } else {
      // No one logged in or token is expired
      // Redirect to login
      return this.router.navigate(['/' + AUTH_LOGIN_ROUTE], {
        queryParams: {
          redirectTo: state.url,
        },
      });
    }
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.user) {
      if (!this.authService.isMerchantUser()) {
        return true;
      } else {
        // Logged in user but not a partner account. Redirect to dashboard
        return this.router.navigate(['/' + HOMEPAGE_ROUTE]);
      }
    } else {
      // No one logged in or token is expired
      // Redirect to login
      return this.router.navigate(['/' + AUTH_LOGIN_ROUTE], {
        queryParams: {
          redirectTo: state.url,
        },
      });
    }
  }
}
