import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TilledTextGenericComponent } from '../tilled-text-generic.component';

@Component({
  selector: 'tilled-label-generic',
  templateUrl: '../tilled-text-generic.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class TilledLabelGenericComponent implements TilledTextGenericComponent {
  @Input() styles: string = '';
  @Input() secondary: boolean = false;
  @Input() white: boolean = false;
  @Input() primaryHighlight: boolean = false;

  constructor() {}

  ngOnInit() {
    this.styles += ' not-italic font-medium font-openSans tracking-normal leading-normal';
    if (this.secondary) {
      this.styles += ' text-tilled-primary';
    } else if (this.white) {
      this.styles += ' text-tilled-neutral-100';
    } else if (this.primaryHighlight) {
      this.styles += ' text-primary';
    } else {
      this.styles += ' text-tilled-primary';
    }
  }
}
