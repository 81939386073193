import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileDownload } from 'app/core/models/file-download';
import { TilledAlert } from 'app/core/models/tilled-alert';
import { AlertService } from 'app/core/services/alert.service';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  FilesService,
  GetContentsOfFileRequestParams,
} from '../../../../projects/tilled-api-client/src/api/files.service';

@Injectable({
  providedIn: 'root',
})
export class FileDownloadService {
  private _newFileDownload$ = new BehaviorSubject<FileDownload>(null);
  public newFileDownload$: Observable<FileDownload> = this._newFileDownload$.asObservable();

  constructor(
    private _httpClient: HttpClient,
    private _alertService: AlertService,
    private _filesService: FilesService,
  ) {}

  create(fileDownload: FileDownload) {
    if (fileDownload) {
      this._newFileDownload$.next(fileDownload);
    }
  }

  deleteAll(): void {
    this._newFileDownload$.next(null);
  }

  downloadCenterSubmitted(): void {
    const message: TilledAlert = {
      message:
        'We are processing your request and preparing it for download.\nReport will also be available under the Reports tab.',
      title: 'Download',
      type: 'warning',
      bottom: true,
    };
    this._alertService.showAlert(message);
  }

  downloadsCenterStarted(): void {
    const message: TilledAlert = {
      message: 'Download will begin shortly',
      title: 'Download',
      type: 'success',
      timer: 3000,
      bottom: true,
    };
    this._alertService.showAlert(message);
  }

  donwloadCenterFailed(errorMessage: string): void {
    // generic catch all for error responses
    const message: TilledAlert = {
      message: errorMessage,
      title: 'Download Failed',
      type: 'error',
      bottom: true,
    };
    this._alertService.showAlert(message);
  }

  async download(fileId: string, fileName: string, accountId: string): Promise<void> {
    let params: GetContentsOfFileRequestParams = {
      tilledAccount: accountId ?? '',
      id: fileId,
    };
    await this._filesService.getContentsOfFile(params).subscribe({
      next: (res) => {
        const blob = new Blob([res], { type: 'text/csv' });
        const objectUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a') as HTMLAnchorElement;
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(objectUrl);
      },
      error: (err) => {
        this.donwloadCenterFailed(`'${fileName}' failed to download`);
      },
    });
  }
}
