import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, RouterOutlet } from '@angular/router';
import { AuthService } from './core/services/auth.service';
import { BrandingService } from './core/services/branding.service';

const demo = require('../assets/js/asset-load.js').demo;

export const MESSAGE_SHARING_CHANNEL = 'message-sharing';
export type BroadcastMessageType = 'requesting-token' | 'supplying-token' | 'logout' | 'current-account-change';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, CommonModule],
})
export class AppComponent {
  public bc = new BroadcastChannel(MESSAGE_SHARING_CHANNEL);
  constructor(
    private _brandingService: BrandingService,
    private _router: Router,
    private _authService: AuthService,
    private _titleService: Title,
  ) {
    this.addBroadcastChannelListener();

    this._authService.loginFromSession();
  }

  ngOnInit(): void {
    // this.setFuseConfig();
    this._titleService.setTitle(demo.partnerName);
  }

  isImpersonated(): boolean {
    return this._authService.isImpersonated();
  }

  private addBroadcastChannelListener(): void {
    this.bc.addEventListener('message', (event) => {
      const data: IBroadcastMessageData = event.data;

      if (data.type === 'requesting-token') {
        const msg: IBroadcastMessageData = {
          type: 'supplying-token',
          accessToken: AuthService.getAccessToken(),
          refreshToken: AuthService.getRefreshToken(),
          currentAccountId: AuthService.getCurrentAccountId(),
        };
        // If someone is requesting data from us and we don't have an accessToken
        // no need to respond
        if (msg.accessToken) {
          this.bc.postMessage(msg);
        }
      } else if (data.type === 'supplying-token') {
        // If we already have an accessToken, that means we received it from one of the tabs
        // so no need to update again.
        const currentAccessToken = AuthService.getAccessToken();
        if (data.accessToken && data.accessToken !== currentAccessToken) {
          // Set refresh token first, so if you try and open a new tab
          // with an expired access token, it will refresh.
          if (data.refreshToken) {
            this._authService.setRefreshToken(data.refreshToken, false);
          }

          this._authService.setAccessToken(data.accessToken, false, data.currentAccountId);
        }
      } else if (data.type === 'logout') {
        // Only redirect if we have an actual user
        if (this._authService.user) {
          this._router.navigate(['/sign-out']);
        }
      } else if (data.type === 'current-account-change') {
        // Update?
      }
    });
  }
}

export type IBroadcastMessageData =
  | {
      type: 'requesting-token';
    }
  | {
      type: 'logout';
    }
  | {
      type: 'current-account-change';
      currentAccountId: string;
    }
  | {
      type: 'supplying-token';

      accessToken: string;
      refreshToken: string;
      currentAccountId: string;
    };
