<form-card
  [submitButton]="true"
  [submitDisabled]="scheduleForm.invalid"
  [titleText]="'Update Billing and Payout Schedule'"
  [styles]="'flex flex-col flex-auto'"
  [minWidth]="'min-w-[34rem]'"
  (exitClick)="closeDialog()"
  (submitClick)="submitClicked()"
  [submitText]="'Save Billing and Payout Schedule'"
  [testIds]="{ exitButton: 'cancel-btn', submitButton: 'submit-btn' }"
>
  <tilled-paragraph-p3 class="flex flex-wrap justify-start"
    >Select the desired billing and payout configurations. The most preferable option for a merchant may vary depending
    on their situation.
    <a
      class="whitespace-nowrap text-primary"
      target="_blank"
      href="https://tilledpartners.zendesk.com/hc/en-us/categories/14767565208468-Billing-Payouts"
      >Learn more ></a
    ></tilled-paragraph-p3
  >
  <form data-test-id="schedule-form" class="-mt-2 w-full" [formGroup]="scheduleForm">
    <div class="pt-5">
      <div class="w-full pb-2.5 pt-4">
        <tilled-label-l1 secondary="true"
          >Billing frequency
          <mat-icon
            class="relative z-10 align-sub icon-size-4"
            [svgIcon]="'mat_outline:help_outline'"
            matTooltip="The billing frequency refers to how often a merchant is billed for fees. While daily billing is standard, some merchants may prefer weekly or monthly."
          ></mat-icon>
        </tilled-label-l1>
      </div>
      <tilled-select
        data-test-id="schedule-billing-frequency"
        [multiple]="false"
        classOverride="w-full"
        [controlName]="scheduleForm.get('billingIntervalUnit')"
        [placeholder]="'Choose One'"
        [options]="[
          { label: 'Daily', value: 'day' },
          { label: 'Weekly', value: 'week' },
          { label: 'Monthly', value: 'month' }
        ]"
        [disabledOptions]="
          capability.processing_details.billing_interval_count !== 1
            ? [
                {
                  label: capability.processing_details.billing_frequency,
                  value: capability.processing_details.billing_interval_count
                }
              ]
            : []
        "
      ></tilled-select>
    </div>

    <div class="pt-5" *ngIf="scheduleForm.get('billingIntervalUnit').value === 'week'">
      <div class="w-full pb-2.5 pt-4">
        <tilled-label-l1 secondary="true"
          >Billing anchor day
          <mat-icon
            class="relative z-10 align-sub icon-size-4"
            [svgIcon]="'mat_outline:help_outline'"
            matTooltip="The billing anchor day refers to the day of the week or month on which fees are billed. If fee collection fails due to a low account balance, the billing job will retry until the collection succeeds. As a result, the actual billing day may be different from the scheduled billing day."
          ></mat-icon>
        </tilled-label-l1>
      </div>
      <tilled-select
        data-test-id="schedule-billing-anchor-day-of-week"
        [multiple]="false"
        classOverride="w-full"
        [controlName]="scheduleForm.get('billingAnchorDayOfWeek')"
        [placeholder]="'Choose One'"
        [options]="[
          { label: daysOfWeek[0], value: 0 },
          { label: daysOfWeek[1], value: 1 },
          { label: daysOfWeek[2], value: 2 },
          { label: daysOfWeek[3], value: 3 },
          { label: daysOfWeek[4], value: 4 },
          { label: daysOfWeek[5], value: 5 },
          { label: daysOfWeek[6], value: 6 }
        ]"
      ></tilled-select>
    </div>

    <div class="pt-5" *ngIf="scheduleForm.get('billingIntervalUnit').value === 'month'">
      <div class="w-full pb-2.5 pt-4">
        <tilled-label-l1 secondary="true"
          >Billing anchor day
          <mat-icon
            class="relative z-10 align-sub icon-size-4"
            [svgIcon]="'mat_outline:help_outline'"
            matTooltip="The billing anchor day refers to the day of the week or month on which fees are billed. If fee collection fails due to a low account balance, the billing job will retry until the collection succeeds. As a result, the actual billing day may be different from the scheduled billing day."
          ></mat-icon>
        </tilled-label-l1>
      </div>
      <tilled-select
        data-test-id="schedule-billing-anchor-day-of-month"
        [multiple]="false"
        classOverride="w-full"
        [controlName]="scheduleForm.get('billingMonthlyAnchorDateType')"
        [placeholder]="'Choose One'"
        [options]="monthlyAnchorTypes"
      ></tilled-select>
    </div>

    <div class="pt-5">
      <div class="w-full pb-2.5 pt-4">
        <tilled-label-l1 secondary="true"
          >Payout frequency
          <mat-icon
            class="relative z-10 align-sub icon-size-4"
            [svgIcon]="'mat_outline:help_outline'"
            matTooltip="The merchant's payout frequency refers to how often the merchant receives funds from their settled transactions."
          ></mat-icon>
        </tilled-label-l1>
      </div>
      <tilled-select
        data-test-id="schedule-payout-frequency"
        [multiple]="false"
        classOverride="w-full"
        [controlName]="scheduleForm.get('payoutIntervalUnit')"
        [placeholder]="'Choose One'"
        [options]="[
          { label: 'Daily', value: 'day' },
          { label: 'Weekly', value: 'week' },
          { label: 'Monthly', value: 'month' }
        ]"
        [disabledOptions]="
          capability.processing_details.payout_interval_count !== 1
            ? [
                {
                  label: capability.processing_details.payout_frequency,
                  value: capability.processing_details.payout_interval_count
                }
              ]
            : []
        "
      ></tilled-select>
    </div>

    <div class="pt-5" *ngIf="scheduleForm.get('payoutIntervalUnit').value === 'week'">
      <div class="w-full pb-2.5 pt-4">
        <tilled-label-l1 secondary="true"
          >Payout anchor day
          <mat-icon
            class="relative z-10 align-sub icon-size-4"
            [svgIcon]="'mat_outline:help_outline'"
            matTooltip="The payout anchor day refers to the day of the week or month on which funds are disbursed to the merchant's bank account."
          ></mat-icon>
        </tilled-label-l1>
      </div>
      <tilled-select
        data-test-id="schedule-payout-anchor-day-of-week"
        [multiple]="false"
        classOverride="w-full"
        [controlName]="scheduleForm.get('payoutAnchorDayOfWeek')"
        [placeholder]="'Choose One'"
        [options]="[
          { label: daysOfWeek[0], value: 0 },
          { label: daysOfWeek[1], value: 1 },
          { label: daysOfWeek[2], value: 2 },
          { label: daysOfWeek[3], value: 3 },
          { label: daysOfWeek[4], value: 4 },
          { label: daysOfWeek[5], value: 5 },
          { label: daysOfWeek[6], value: 6 }
        ]"
      ></tilled-select>
    </div>

    <div class="pt-5" *ngIf="scheduleForm.get('payoutIntervalUnit').value === 'month'">
      <div class="w-full pb-2.5 pt-4">
        <tilled-label-l1 secondary="true"
          >Payout anchor day
          <mat-icon
            class="relative z-10 align-sub icon-size-4"
            [svgIcon]="'mat_outline:help_outline'"
            matTooltip="The payout anchor day refers to the day of the week or month on which funds are disbursed to the merchant's bank account."
          ></mat-icon>
        </tilled-label-l1>
      </div>
      <tilled-select
        data-test-id="schedule-payout-anchor-day-of-month"
        [multiple]="false"
        classOverride="w-full"
        [controlName]="scheduleForm.get('payoutMonthlyAnchorDateType')"
        [placeholder]="'Choose One'"
        [options]="monthlyAnchorTypes"
      ></tilled-select>
    </div>
  </form>
</form-card>
