<tilled-table
  [data]="(documentsViewModel$ | async)!"
  [getPageCallback]="getDocuments"
  [dataLength]="documentsListCount$ | async"
  data-test-id="account-documents-table"
  [noDataMainText]="'No requested or uploaded documents'"
  [noDataSecondaryText]="'Nothing to see here.'"
  [startingPageIndex]="pageIndex"
  [startingPageLimit]="pageSize"
></tilled-table>
