/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TerminalReaderSettings } from './terminalReaderSettings';


export interface InternalTerminalReader { 
    /**
     * The Account that owns this terminal reader.
     */
    account_id: string;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * Time at which the object was deleted.
     */
    deleted_at?: string;
    /**
     * The merchant provided description of the terminal reader
     */
    description?: string;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * The name of the account associated with this Terminal reader.
     */
    merchant_name?: string;
    /**
     * Set of [key-value pairs](#section/Metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format.
     */
    metadata?: { [key: string]: string; };
    /**
     * The payment_provider_id associated with this terminal reader.
     */
    payment_provider_id: string;
    /**
     * The identifier the processor uses to retrieve a store.
     */
    processor_store_ref_id?: string;
    /**
     * The processor_terminal_id of the terminal reader. This should be a unique number from the processor AKA \'terminal id\' AKA \'tid\'
     */
    processor_terminal_id: string;
    /**
     * The identifier the processor uses to retrieve a device.
     */
    processor_terminal_ref_id?: string;
    /**
     * The provider of the terminal reader.
     */
    provider: InternalTerminalReader.ProviderEnum;
    /**
     * The serial number of the terminal reader.
     */
    serial_number?: string;
    settings?: TerminalReaderSettings;
    /**
     * The TerminalReaderStatus of the terminal reader.
     */
    status: InternalTerminalReader.StatusEnum;
    tsys_terminal?: object;
    /**
     * The tsys_terminal_d associated with this terminal reader.
     */
    tsys_terminal_id?: string;
    /**
     * The type of the terminal reader.
     */
    type: InternalTerminalReader.TypeEnum;
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
}
export namespace InternalTerminalReader {
    export type ProviderEnum = 'aevi' | 'handpoint' | 'valor';
    export const ProviderEnum = {
        AEVI: 'aevi' as ProviderEnum,
        HANDPOINT: 'handpoint' as ProviderEnum,
        VALOR: 'valor' as ProviderEnum
    };
    export type StatusEnum = 'created' | 'active' | 'enabled' | 'disabled';
    export const StatusEnum = {
        CREATED: 'created' as StatusEnum,
        ACTIVE: 'active' as StatusEnum,
        ENABLED: 'enabled' as StatusEnum,
        DISABLED: 'disabled' as StatusEnum
    };
    export type TypeEnum = 'vl_100' | 'vl_110' | 'vp_500';
    export const TypeEnum = {
        VL_100: 'vl_100' as TypeEnum,
        VL_110: 'vl_110' as TypeEnum,
        VP_500: 'vp_500' as TypeEnum
    };
}


