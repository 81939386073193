import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ComponentBase } from 'app/core/componentBase';
import { DEFAULT_PAGE_LIMIT, TERMINALS_ROUTE } from 'app/core/constants';
import { DateFormatPipe } from 'app/core/pipes/date-format.pipe';
import { TerminalReaderStatusPipe } from 'app/core/pipes/terminal-reader-status-pipe';
import { AuthService } from 'app/core/services/auth.service';
import { TerminalReadersAppService } from 'app/core/services/terminal-readers.app.service';
import { ActionListItem } from 'app/shared/action-list/action-list.model';
import { TerminalReaderUpdateDialogComponent } from 'app/shared/terminals/update-terminal-dialog/terminal-reader-update-dialog.component';
import { TilledChipConfig } from 'app/shared/tilled-chip/tilled-chip.component';
import { Column } from 'app/shared/tilled-table/decorators/column';
import { Observable, of } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ListTerminalReadersRequestParams } from '../../../../../projects/tilled-api-client/src/api/terminalReaders.service';
import {
  InternalTerminalReader,
  TerminalReaderUpdateParams,
} from '../../../../../projects/tilled-api-client/src/model/models';
import { TilledTableComponent } from '../../../shared/tilled-table/tilled-table.component';

const demo = require('../../../../assets/js/asset-load.js').demo;

@Component({
  selector: 'app-terminal-readers-list',
  templateUrl: './terminal-reader-list.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [TilledTableComponent, AsyncPipe],
})
export class TerminalReaderListComponent extends ComponentBase implements OnInit, OnChanges {
  @Input() accountId: string = null;
  @Input() initialQuery: string = null;
  @Input() query: string = null;
  @Input() pageIndex: number = 0;
  @Input() pageSize: number = DEFAULT_PAGE_LIMIT;
  @Input() updateQueryCallback: (q?: string, page?: number, size?: number) => void;
  @Input() getClickCallback: (data: any, event?: MouseEvent) => void;

  public secondaryReasonText = '';
  public terminalReadersViewModel$: Observable<TerminalReaderViewModel[]>;
  public terminalReadersCount$: Observable<number>;
  public isLoading = true;
  public sortInfo = null;

  constructor(
    private _terminalReadersAppService: TerminalReadersAppService,
    private _dateFormatPipe: DateFormatPipe,
    private _terminalReaderStatusPipe: TerminalReaderStatusPipe,
    private _router: Router,
    private _matDialog: MatDialog,
    private _authService: AuthService,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.getTerminalReaders(this.pageSize, this.pageIndex);

    this._terminalReadersAppService.terminalReaders$
      .pipe(
        takeUntil(this._unsubscribeAll),
        tap((result) => {
          this.terminalReadersViewModel$ = of(this.getViewModelsFromTerminalReaders(result));
          if (this.updateQueryCallback) {
            this.updateQueryCallback(this.query, this.pageIndex, this.pageSize);
          }
        }),
      )
      .subscribe();
    this.terminalReadersCount$ = this._terminalReadersAppService.terminalReadersCount$;
  }

  // onChange fires before onInit so we want to load he payment intents just once here on
  // page load.
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.query && !changes.query.firstChange) {
      this.getTerminalReaders(this.pageSize, 0);
    }
  }

  getTerminalReaders = (size: number, index: number): void => {
    this.pageSize = size;
    this.pageIndex = index;
    const params: ListTerminalReadersRequestParams = {
      tilledAccount: this.accountId,
      includeConnectedAccounts: true,
      offset: size * index,
      limit: size,
    };

    this._terminalReadersAppService.getAllTerminalReaders(params);

    if (this.query) {
      this.secondaryReasonText = 'Update the filters to see results.';
    } else {
      this.secondaryReasonText = '';
    }
  };

  convertTerminalReaderToViewModel(terminalReader: InternalTerminalReader): TerminalReaderViewModel {
    const temp: TerminalReaderViewModel = new TerminalReaderViewModel();
    temp.terminal_reader = terminalReader;
    temp.created_at = this._dateFormatPipe.transform(terminalReader.created_at);
    temp.id = terminalReader.id;
    temp.description = terminalReader.description;
    temp.merchant_id = terminalReader.account_id;
    temp.merchant_name = terminalReader.merchant_name;
    temp.serial_number = terminalReader.serial_number;
    temp.activation_code = terminalReader.processor_terminal_ref_id;
    temp.chipConfig = this._terminalReaderStatusPipe.transform(terminalReader);
    temp.terminal_type = terminalReader.type.replace(/[^a-zA-Z0-9 ]/g, '').toUpperCase();

    temp.action = [
      {
        name: 'Edit terminal',
        callback: (): void => {
          const dialogRef = this._matDialog.open(TerminalReaderUpdateDialogComponent, {
            data: {
              terminalReader: terminalReader,
            },
          });

          dialogRef
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: HawkeyeTerminalUpdateParams) => {
              if (result) {
                this._terminalReadersAppService.updateTerminalReader(
                  {
                    tilledAccount: result.accountId,
                    id: temp.id,
                    terminalReaderUpdateParams: result.params,
                  },
                  this.accountId,
                );
              } else {
                return;
              }
            });
        },
        disabled: false,
      },
      {
        name: 'View terminal details',
        callback: (): void => {
          this._router.navigate([`/${TERMINALS_ROUTE}/${terminalReader.account_id}/${terminalReader.id}`]);
        },
        disabled: false,
      },
    ];

    return temp;
  }

  getViewModelsFromTerminalReaders(terminalReaders: InternalTerminalReader[]): TerminalReaderViewModel[] {
    const viewModels: TerminalReaderViewModel[] = [];
    if (!terminalReaders || terminalReaders.length === 0) {
      const temp: TerminalReaderViewModel = new TerminalReaderViewModel();
      viewModels.push(temp);
      return viewModels;
    }
    for (const terminalReader of terminalReaders) {
      let viewModel = this.convertTerminalReaderToViewModel(terminalReader);

      viewModels.push(viewModel);
    }
    return viewModels;
  }

  rowClickedCallback = (data: TerminalReaderViewModel, event?: MouseEvent): void => {
    const url = `/${TERMINALS_ROUTE}/${data.merchant_id}/${data.id}`;

    // Open a new window (Shift) or tab (Ctrl/Option)
    if (event?.shiftKey || event?.ctrlKey || event?.metaKey) {
      window.open(url, event?.shiftKey ? '_blank' : undefined);
    } else {
      this._router.navigate([url]);
    }
  };
}

export class TerminalReaderViewModel {
  terminal_reader: InternalTerminalReader;
  id: string;
  merchant_id: string;
  // non display, needed if trying to display status chip
  chipConfig: TilledChipConfig;

  @Column({
    order: 0,
    name: 'Merchant',
    styling: 'padding-right: 2ch; width: 20ch; max-width: 20ch;',
  })
  merchant_name: string;

  @Column({ order: 1, name: 'Model', styling: 'max-width:120px' })
  terminal_type: string;

  @Column({
    order: 2,
    name: 'Serial Number',
  })
  serial_number: string;

  @Column({
    order: 3,
    name: 'Description',
  })
  description: string;

  @Column({ order: 4, name: 'Activation Code' })
  activation_code: string;

  @Column({
    order: 5,
    name: 'Created Date',
    styling: 'min-width:210px',
    dateTooltip: true,
  })
  created_at: string;

  @Column({
    order: 6,
    isActionList: true,
  })
  action: ActionListItem[];
}

export interface HawkeyeTerminalUpdateParams {
  accountId: string;
  params: TerminalReaderUpdateParams;
}
