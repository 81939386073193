<!-- Notifications toggle -->
<button mat-icon-button (click)="openPanel()" #notificationsOrigin>
  <ng-container *ngIf="unreadCount > 0">
    <span class="absolute left-0 right-0 top-0 flex h-3 items-center justify-center">
      <span
        class="ml-4 mt-2.5 flex h-4 min-w-4 shrink-0 items-center justify-center rounded-full bg-warn-500 px-1 text-xs font-medium text-indigo-50"
      >
        {{ unreadCount }}
      </span>
    </span>
  </ng-container>
  <mat-icon class="text-primary" [svgIcon]="'heroicons_outline:bell'"></mat-icon>
</button>

<!-- Notifications panel -->
<ng-template #notificationsPanel>
  <div
    class="fixed inset-0 flex flex-col overflow-hidden shadow-lg sm:static sm:inset-auto sm:w-90 sm:min-w-90 sm:rounded-2xl"
  >
    <!-- Header -->
    <div class="flex shrink-0 items-center justify-center bg-primary py-4 pl-6 pr-4">
      <div class="-ml-1 mr-3 sm:hidden">
        <button mat-icon-button (click)="closePanel()">
          <mat-icon class="text-current icon-size-5" [svgIcon]="'mat_outline:close'"></mat-icon>
        </button>
      </div>
      <tilled-heading-h4 [white]="whiteText" class="py-2">Notifications</tilled-heading-h4>
    </div>

    <!-- Content -->
    <div class="bg-card relative flex flex-auto flex-col divide-y overflow-y-auto sm:max-h-120">
      <!-- Notifications -->
      <ng-container *ngFor="let notification of notifications; trackBy: trackByFn">
        <div
          class="group flex hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5"
          [ngClass]="{ unread: !notification.read }"
        >
          <!-- Notification with a link -->
          <ng-container *ngIf="notification.link">
            <!-- Normal links -->
            <ng-container *ngIf="!notification.useRouter">
              <a class="flex flex-auto cursor-pointer py-5 pl-6" [href]="notification.link">
                <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
              </a>
            </ng-container>
            <!-- Router links -->
            <ng-container *ngIf="notification.useRouter">
              <a
                class="flex flex-auto cursor-pointer py-5 pl-6"
                [routerLink]="notification.link"
                [notificationRoute]="true"
              >
                <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
              </a>
            </ng-container>
          </ng-container>

          <!-- Notification without a link -->
          <ng-container *ngIf="!notification.link">
            <div class="flex flex-auto py-5 pl-6">
              <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
            </div>
          </ng-container>

          <!-- Actions -->
          <div class="relative my-5 ml-2 mr-6 flex flex-col">
            <!-- Indicator -->
            <button
              *ngIf="notification.dismissible"
              class="h-6 min-h-6 w-6"
              mat-icon-button
              (click)="toggleRead(notification)"
              [matTooltip]="notification.read ? 'Mark as unread' : 'Mark as read'"
            >
              <span
                class="h-2 w-2 rounded-full"
                [ngClass]="{
                  'bg-gray-400 dark:bg-gray-500 sm:opacity-0 sm:group-hover:opacity-100': notification.read,
                  'bg-primary': !notification.read
                }"
              ></span>
            </button>
            <!-- Remove -->
            <button
              *ngIf="notification.removable"
              class="h-6 min-h-6 w-6 sm:opacity-0 sm:group-hover:opacity-100"
              mat-icon-button
              (click)="delete(notification)"
              [matTooltip]="'Remove'"
            >
              <mat-icon class="icon-size-4" [svgIcon]="'mat_outline:close'"></mat-icon>
            </button>
          </div>
        </div>

        <!-- Notification content template -->
        <ng-template #notificationContent>
          <!-- Icon -->
          <ng-container *ngIf="notification.icon && !notification.image">
            <div
              class="mr-4 flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-gray-100 dark:bg-gray-700"
            >
              <mat-icon class="icon-size-5" [svgIcon]="notification.icon"> </mat-icon>
            </div>
          </ng-container>
          <!-- Image -->
          <ng-container *ngIf="notification.image">
            <img
              class="mr-4 h-8 w-8 shrink-0 overflow-hidden rounded-full object-cover object-center"
              [src]="notification.image"
              [alt]="'Notification image'"
            />
          </ng-container>
          <!-- Title, description & time -->
          <div class="flex flex-auto flex-col">
            <ng-container *ngIf="notification.title">
              <tilled-heading-h6 class="pb-2">{{ notification.title }}</tilled-heading-h6>
            </ng-container>
            <ng-container *ngIf="notification.description">
              <tilled-paragraph-p4>{{ notification.description }}</tilled-paragraph-p4>
            </ng-container>
            <div class="text-secondary mt-2 text-sm leading-none">
              {{ notification.time | date: 'MMM dd, h:mm a' }}
            </div>
          </div>
        </ng-template>
      </ng-container>

      <!-- No notifications -->
      <ng-container *ngIf="!notifications || !notifications.length">
        <div class="flex flex-auto flex-col items-center justify-center px-8 py-12 sm:justify-start">
          <div class="flex h-14 w-14 flex-0 items-center justify-center rounded-full bg-primary-100">
            <mat-icon class="text-primary-500-700" [svgIcon]="'heroicons_outline:bell'"></mat-icon>
          </div>
          <div class="mt-5 text-2xl font-semibold tracking-tight">No notifications</div>
          <div class="text-secondary mt-1 w-full max-w-60 text-center text-md">
            When you have notifications, they will appear here.
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
