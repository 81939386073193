import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { UserModule } from 'app/layout/common/user/user.module';
import { OnboardingLayoutComponent } from 'app/layout/layouts/onboarding/onboarding.component';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
    imports: [RouterModule, SharedModule, UserModule, OnboardingLayoutComponent],
    exports: [OnboardingLayoutComponent],
})
export class OnboardingLayoutModule {}
