/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CheckoutMethods { 
    checkout_method: CheckoutMethods.CheckoutMethodEnum;
    percentage: number;
}
export namespace CheckoutMethods {
    export type CheckoutMethodEnum = 'online_ecommerce' | 'virtual_terminal' | 'mobile_phone_app' | 'automated_billing' | 'card_present' | 'direct_debit';
    export const CheckoutMethodEnum = {
        ONLINE_ECOMMERCE: 'online_ecommerce' as CheckoutMethodEnum,
        VIRTUAL_TERMINAL: 'virtual_terminal' as CheckoutMethodEnum,
        MOBILE_PHONE_APP: 'mobile_phone_app' as CheckoutMethodEnum,
        AUTOMATED_BILLING: 'automated_billing' as CheckoutMethodEnum,
        CARD_PRESENT: 'card_present' as CheckoutMethodEnum,
        DIRECT_DEBIT: 'direct_debit' as CheckoutMethodEnum
    };
}


