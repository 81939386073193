import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, Output } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { UserRoles } from 'app/core/data/user-roles';
import { FormCardComponent } from 'app/shared/cards/form-cards/form-card.component';
import { TilledSelectComponent } from 'app/shared/tilled-select/tilled-select.component';
import { isEmail } from 'app/shared/validators/email.validator';
import { User } from '../../../../../projects/tilled-api-client/src';
import { TilledButtonComponent } from '../../buttons/tilled-button.component';
import { TilledInputComponent } from '../../form-fields/tilled-input/tilled-input.component';
import { TilledHeadingH2Component } from '../../tilled-text/tilled-heading/tilled-heading-h2.component';
import { TilledLabelL1Component } from '../../tilled-text/tilled-label/tilled-label-l1.component';
import { TilledParagraphP3Component } from '../../tilled-text/tilled-paragraph/tilled-paragraph-p3.component';
import { TilledParagraphP4Component } from '../../tilled-text/tilled-paragraph/tilled-paragraph-p4.component';
import { UserViewModel } from '../user-list.component';

@Component({
  selector: 'app-user-invite',
  templateUrl: './user-invite-dialog.component.html',
  styleUrls: ['./user-invite-dialog.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    TilledHeadingH2Component,
    TilledParagraphP3Component,
    FormsModule,
    FormCardComponent,
    ReactiveFormsModule,
    TilledInputComponent,
    TilledLabelL1Component,
    TilledSelectComponent,
    TilledParagraphP4Component,
    TilledButtonComponent,
    CommonModule,
  ],
})
export class UserInviteDialogComponent implements OnInit {
  @Output() userInviteData;
  public userInviteForm: FormGroup;
  public availableUserRoles: IUserRole[];
  public availableUserRoleOptions: { label: string; value: User.RoleEnum }[];
  public items: FormArray;
  public isEdit: boolean = false;
  public titleText: string;
  public secondaryText: string;
  public buttonText: string;
  public isMerchantUser: boolean = false;
  private user: UserViewModel;
  private isFirstUser: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<UserInviteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
  ) {
    this.user = this._data?.user ?? null;

    this.isMerchantUser = this._data?.isMerchantUser ?? false;
    this.isFirstUser = this._data?.isFirstUser ?? false;

    if (this._data.action === 'edit') {
      this.isEdit = true;
      this.titleText = 'Edit User';
      this.secondaryText = 'Select a new role for the user';
      this.buttonText = 'Save User';
    } else {
      this.isEdit = false;
      this.titleText = this.isMerchantUser ? 'Invite Merchant User' : 'Invite Partner User';
      this.secondaryText = this.isMerchantUser
        ? 'Enter the user’s email address.'
        : 'Enter the user’s email address and select their role. ';
      this.buttonText = 'Invite User';
    }
  }

  ngOnInit(): void {
    let email: string = null;
    if (this.user?.user_name) {
      email = this.user.user_name[1] ?? this.user.user_name[0];
    }

    this.availableUserRoles = this.generateUserRoles();
    this.availableUserRoleOptions = this.availableUserRoles.map((userRole) => ({
      label: userRole.name,
      value: userRole.value,
    }));
    this.userInviteForm = this._formBuilder.group({
      email: new FormControl<string | null>(email, [isEmail(), Validators.required]),
      role: new FormControl<User.RoleEnum | null>(this.user?.role ? UserRoles.KeysFromText.get(this.user.role) : null, [
        Validators.required,
      ]),
    });

    // If the user is a merchant user, they will automatically be assigned a role
    if (this.isMerchantUser) {
      if (this.isFirstUser) {
        this.userInviteForm.get('role').setValue(User.RoleEnum.MERCHANT_OWNER);
      } else {
        this.userInviteForm.get('role').setValue(User.RoleEnum.MERCHANT_ADMIN);
      }
    }
  }

  generateUserRoles(): IUserRole[] {
    let allRoleValues: User.RoleEnum[] = [];

    UserRoles.DisplayText.get(User.RoleEnum.ADMIN);
    if (this.isMerchantUser) {
      if (this.isFirstUser) {
        allRoleValues.push(User.RoleEnum.MERCHANT_OWNER);
      } else {
        allRoleValues.push(User.RoleEnum.MERCHANT_ADMIN);
      }
    } else {
      if (this.isFirstUser) {
        allRoleValues.push(User.RoleEnum.OWNER);
      } else {
        allRoleValues.push(User.RoleEnum.ADMIN);
        allRoleValues.push(User.RoleEnum.DEVELOPER);
        allRoleValues.push(User.RoleEnum.ANALYST);
        allRoleValues.push(User.RoleEnum.VIEW_ONLY);
      }
    }
    const userRoles: IUserRole[] = [];
    for (const roleValue of allRoleValues) {
      userRoles.push({
        value: roleValue,
        name: UserRoles.DisplayText.get(roleValue),
        description: UserRoles.Description.get(roleValue),
      });
    }
    return userRoles;
  }

  public inviteOrEditUser(): void {
    this.userInviteForm.markAllAsTouched();
    if (!this.userInviteForm.invalid) {
      this.dialogRef.close(this.userInviteForm);
    }
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}

interface IUserRole {
  value: User.RoleEnum;
  name: string;
  description: string;
}
