import { Route } from '@angular/router';
import { OUTBOUND_TRANSFERS_ROUTE, PAYMENTS_ROUTE, TERMINALS_ROUTE } from 'app/core/constants';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from './app.resolvers';
import { ApplicationStatusGuard } from './core/auth/guards/application-status.guard';
import { AuthorizeLinkGuard } from './core/auth/guards/authorize-link.guard';
import { AuthorizeGuard } from './core/auth/guards/authorize.guard';
import { OnboardingRouteGuard } from './core/auth/guards/onboarding-route.guard';
import { PartnerGuard } from './core/auth/guards/partner.guard';
import { MerchantRoleGuard } from './core/auth/guards/role.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  // Redirect empty path to '/example'
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

  // Redirect signed in user to the '/example'
  //
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  // { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'example' },

  // Auth routes for guests
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'forgot-password',
        loadChildren: () =>
          import('app/modules/auth/forgot-password/forgot-password.module').then((m) => m.AuthForgotPasswordModule),
      },
      {
        path: 'reset-password',
        loadChildren: () =>
          import('app/modules/auth/reset-password/reset-password.module').then((m) => m.AuthResetPasswordModule),
      },
      {
        path: 'sign-in',
        loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then((m) => m.AuthSignInModule),
      },
      {
        path: 'register', // for new users
        loadChildren: () =>
          import('app/modules/auth/user-registration/user-registration.module').then((m) => m.UserRegistrationModule),
      },
      {
        path: 'accept',
        canActivate: [AuthorizeGuard],
        canActivateChild: [AuthorizeGuard],
        loadChildren: () =>
          import('app/modules/auth/user-invitation-acceptance/user-invitation-acceptance.module').then(
            (m) => m.UserInvitationAcceptanceModule,
          ),
      },
      // Hosted Checked
      {
        path: 'pay/:id',
        loadChildren: () =>
          import('app/modules/hosted-checkout/hosted-checkout.module').then((m) => m.HostedCheckoutModule),
      },
    ],
  },

  // Auth routes for authenticated users with empty layout
  {
    path: '',
    canActivate: [AuthorizeGuard],
    canActivateChild: [AuthorizeGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then((m) => m.AuthSignOutModule),
      },
    ],
  },

  // Authenticated User Routes
  {
    path: '',
    canActivate: [AuthorizeGuard, ApplicationStatusGuard],
    canActivateChild: [AuthorizeGuard, ApplicationStatusGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      // Dashboard
      {
        path: 'dashboard',
        loadChildren: () => import('app/modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      // Payments
      {
        path: PAYMENTS_ROUTE,
        loadChildren: () => import('app/modules/payments/payments.module').then((m) => m.PaymentsModule),
      },
      // Pricing Templates
      {
        canActivate: [PartnerGuard],
        path: 'pricing-templates',
        loadChildren: () =>
          import('app/modules/pricing-templates/pricing-templates.module').then((m) => m.PricingTemplatesModule),
      },
      // Payouts Report
      {
        path: 'payouts',
        loadChildren: () => import('app/modules/payouts/payouts.module').then((m) => m.PayoutsModule),
      },
      // Payments
      {
        path: `:account_id/${PAYMENTS_ROUTE}`,
        loadChildren: () => import('app/modules/payments/payments.module').then((m) => m.PaymentsModule),
      },
      // Merchant Management
      {
        canActivate: [PartnerGuard],
        canActivateChild: [PartnerGuard],
        path: 'merchants',
        loadChildren: () =>
          import('app/modules/merchant-management/merchant-management.module').then((m) => m.MerchantManagementModule),
      },
      // Customers
      {
        canActivate: [MerchantRoleGuard],
        canActivateChild: [],
        path: 'customers',
        loadChildren: () => import('app/modules/customers/customers.module').then((m) => m.CustomersModule),
      },
      // Downloads (redirect to reports for a little while)
      { path: 'downloads', redirectTo: 'reports', pathMatch: 'full' },
      // Reports
      {
        path: 'reports',
        loadChildren: () => import('app/modules/downloads/downloads.module').then((m) => m.DownloadsModule),
      },
      // Settings
      {
        path: 'account',
        loadChildren: () => import('app/modules/settings/settings.module').then((m) => m.SettingsModule),
      },
      // FAQ
      {
        path: 'faq',
        loadChildren: () => import('app/modules/faq/faq.module').then((m) => m.FaqModule),
      },
      // Developers
      {
        canActivate: [PartnerGuard],
        path: 'developers',
        loadChildren: () => import('app/modules/developers/developers.module').then((m) => m.DevelopersModule),
      },
      // Terminals
      {
        path: TERMINALS_ROUTE,
        loadChildren: () => import('app/modules/terminals/terminals.module').then((m) => m.TerminalsModule),
      },
      // Terminal Details
      {
        path: TERMINALS_ROUTE + '/:account_id:/:id',
        loadChildren: () => import('app/modules/terminals/terminals.module').then((m) => m.TerminalsModule),
      },
      // Outbound Transfers
      {
        path: OUTBOUND_TRANSFERS_ROUTE,
        loadChildren: () =>
          import('app/modules/outbound-transfers/outbound-transfers.module').then((m) => m.OutboundTransfersModule),
      },
      // Outbound Transfer Details
      {
        path: `:account_id/${OUTBOUND_TRANSFERS_ROUTE}`,
        loadChildren: () =>
          import('app/modules/outbound-transfers/outbound-transfers.module').then((m) => m.OutboundTransfersModule),
      },
      // Disputes
      {
        path: 'disputes',
        loadChildren: () => import('app/modules/disputes/disputes.module').then((m) => m.DisputesModule),
      },
      // Documents
      {
        path: 'documents',
        loadChildren: () =>
          import('app/modules/documents-upload/documents-upload.module').then((m) => m.DocumentsUploadModule),
      },
    ],
  },

  // Partner application route
  {
    path: 'enroll',
    canActivate: [AuthorizeGuard, PartnerGuard],
    canActivateChild: [AuthorizeGuard, PartnerGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      //Summary
      {
        path: '',
        loadChildren: () =>
          import('app/modules/partner-application/partner-application.module').then((m) => m.PartnerApplicationModule),
      },
    ],
  },

  {
    path: 'onboarding',
    canActivate: [AuthorizeGuard, OnboardingRouteGuard],
    canActivateChild: [AuthorizeGuard, OnboardingRouteGuard],
    component: LayoutComponent,
    data: {
      layout: 'onboarding',
    },
    loadChildren: () =>
      import('app/modules/merchant-application/merchant-application.module').then((m) => m.MerchantApplicationModule),
  },

  {
    path: 'auth/magic-link',
    canActivate: [AuthorizeLinkGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
  },

  // TODO:Temporary hack. Once we turn off Iron we can eliminate this route
  {
    path: 'auth/register',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    loadChildren: () =>
      import('app/modules/auth/user-registration/user-registration.module').then((m) => m.UserRegistrationModule),
  },

  //404 Not Found
  {
    path: '',
    loadChildren: () => import('app/pages/pages.module').then((m) => m.PagesModule),
  },

  // TODO: figure out how to handle expired path (doesn't require auth)
  /*{
        path: 'enroll/expired',
        //canActivate: [AuthorizeGuard],
        //canActivateChild: [AuthorizeGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('app/modules/partner-application/partner-application.module').then(
                        (m) => m.PartnerApplicationModule
                    ),
            },
        ],
    },*/
];
