<div class="top-of-form"></div>
<merchant-app-card
  [stepNumber]="!forConsole ? this.stepNumber + 1 : null"
  [title]="!forConsole ? 'Payment Processing Volume' : null"
  [description]="!forConsole ? 'Enter your current or projected processing volumes' : null"
  [backButton]="!forConsole"
  (backClick)="onBackClicked($event)"
  (continueClick)="onContinueClicked($event)"
  [continueButton]="!forConsole && !(disabled$ | async)"
>
  <form data-test-id="processing-volumes-step" class="w-full space-y-4" [formGroup]="processingVolumesForm">
    <div class="items-left flex flex-col pt-2.5">
      <tilled-heading-h4>Processing Volumes</tilled-heading-h4>
      <tilled-paragraph-p3 [secondary]="true" class="pt-3 pb-4">
        Estimate the amount of money your business handles in credit card and bank to bank payments.
      </tilled-paragraph-p3>
    </div>
    <div class="flex w-full flex-col">
      <tilled-input
        data-test-id="average-monthly-transactions"
        name="averageMonthlyTransactions"
        mask="separator.0"
        label="Number of Transactions per month"
        tilledLabel="true"
        hint="Estimate the average number of credit card or bank to bank transactions you make in a month."
        [errors]="
          processingVolumesForm.get('averageMonthlyTransactions').hasError('min') &&
          processingVolumesForm.get('averageMonthlyTransactions').touched
        "
        errorMessage="Must be greater than 0"
      ></tilled-input>
    </div>
    <div class="flex w-full flex-col">
      <tilled-input
        data-test-id="average-monthly-volume"
        name="averageMonthlyVolume"
        showCurrency="'true'"
        mask="separator.0"
        label="Monthly Processing Volume"
        tilledLabel="true"
        hint="Estimate the average amount you make in a month from credit card and bank to bank sales."
        [errors]="
          processingVolumesForm.get('averageMonthlyVolume').hasError('min') &&
          processingVolumesForm.get('averageMonthlyVolume').touched
        "
        errorMessage="Must be greater than $0"
      ></tilled-input>
    </div>
    <div class="items-left flex flex-col pt-4">
      <tilled-heading-h4>Average Transaction Amounts</tilled-heading-h4>
      <tilled-paragraph-p3 [secondary]="true" class="pt-3 pb-4">
        Accurately estimating your average transaction amounts is crucial to prevent delays in fund disbursement. If you
        process a wide range of transaction amounts, consider estimating your average on the higher end to avoid delays
        in fund disbursement.
      </tilled-paragraph-p3>
    </div>
    <div class="flex w-full flex-col" *ngIf="hasCard">
      <tilled-input
        data-test-id="average-transaction-amount-card"
        name="averageTransactionAmountCard"
        showCurrency="'true'"
        mask="separator.0"
        label="Credit Card - Average Transaction Amount"
        tilledLabel="true"
        hint="Estimate the typical amount for a single credit card sale or transaction."
        [errors]="
          processingVolumesForm.get('averageTransactionAmountCard').hasError('min') &&
          processingVolumesForm.get('averageTransactionAmountCard').touched
        "
        errorMessage="Must be greater than $0"
      ></tilled-input>
    </div>
    <div class="flex w-full flex-col" *ngIf="hasDebit">
      <tilled-input
        data-test-id="average-transaction-amount-debit"
        name="averageTransactionAmountDebit"
        showCurrency="'true'"
        mask="separator.0"
        label="Bank to Bank - Average Transaction Amount"
        tilledLabel="true"
        hint="Estimate the typical amount for a single bank to bank sale or transaction, where the customers pay using their bank account details."
        [errors]="
          processingVolumesForm.get('averageTransactionAmountDebit').hasError('min') &&
          processingVolumesForm.get('averageTransactionAmountDebit').touched
        "
        errorMessage="Must be greater than $0"
      ></tilled-input>
    </div>
  </form>
</merchant-app-card>
