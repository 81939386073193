/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { ListTerminalReaders200Response } from '../model/listTerminalReaders200Response';
// @ts-ignore
import { TerminalReader } from '../model/terminalReader';
// @ts-ignore
import { TerminalReaderConnectionStatus } from '../model/terminalReaderConnectionStatus';
// @ts-ignore
import { TerminalReaderUpdateParams } from '../model/terminalReaderUpdateParams';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface GetTerminalRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    id: string;
}

export interface GetTerminalStatusRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    id: string;
}

export interface ListTerminalReadersRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    /** &#x60;metadata&#x60; key-value pairs to filter by. Only exact matches on the key-value pair(s) will be returned. Example: &#x60;?metadata[internal_customer_id]&#x3D;7cb1159d-875e-47ae-a309-319fa7ff395b&#x60;. */
    metadata?: { [key: string]: string; };
    /** The partial search of text fields. Supports searching by &#x60;terminal_reader.description&#x60;, &#x60;terminal_reader.serial_number&#x60;, &#x60;terminal_reader.type&#x60; */
    q?: string;
    /** Whether or not to include the results from any connected accounts. */
    includeConnectedAccounts?: boolean;
    /** Minimum &#x60;created_at&#x60; value to filter by (inclusive). */
    createdAtGte?: string;
    /** Maximum &#x60;created_at&#x60; value to filter by (inclusive). */
    createdAtLte?: string;
    /** The (zero-based) offset of the first item in the collection to return. */
    offset?: number;
    /** The maximum number of entries to return. If the value exceeds the maximum, then the maximum value will be used. */
    limit?: number;
}

export interface UpdateTerminalRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    id: string;
    terminalReaderUpdateParams: TerminalReaderUpdateParams;
}


@Injectable({
  providedIn: 'root'
})
export class TerminalReadersService {

    protected basePath = 'https://api.tilled.com';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Get a Terminal Reader
     * Retrieves the terminal reader with the given ID.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTerminal(requestParameters: GetTerminalRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<TerminalReader>;
    public getTerminal(requestParameters: GetTerminalRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<TerminalReader>>;
    public getTerminal(requestParameters: GetTerminalRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<TerminalReader>>;
    public getTerminal(requestParameters: GetTerminalRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling getTerminal.');
        }
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getTerminal.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/terminal-readers/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<TerminalReader>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a Terminal Reader status
     * Retrieves the network connection status of a terminal reader.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTerminalStatus(requestParameters: GetTerminalStatusRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<TerminalReaderConnectionStatus>;
    public getTerminalStatus(requestParameters: GetTerminalStatusRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<TerminalReaderConnectionStatus>>;
    public getTerminalStatus(requestParameters: GetTerminalStatusRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<TerminalReaderConnectionStatus>>;
    public getTerminalStatus(requestParameters: GetTerminalStatusRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling getTerminalStatus.');
        }
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getTerminalStatus.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/terminal-readers/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/status`;
        return this.httpClient.request<TerminalReaderConnectionStatus>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List all Terminal Readers
     * Returns a list of an account\&#39;s terminal readers.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listTerminalReaders(requestParameters: ListTerminalReadersRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<ListTerminalReaders200Response>;
    public listTerminalReaders(requestParameters: ListTerminalReadersRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<ListTerminalReaders200Response>>;
    public listTerminalReaders(requestParameters: ListTerminalReadersRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<ListTerminalReaders200Response>>;
    public listTerminalReaders(requestParameters: ListTerminalReadersRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling listTerminalReaders.');
        }
        const metadata = requestParameters.metadata;
        const q = requestParameters.q;
        const includeConnectedAccounts = requestParameters.includeConnectedAccounts;
        const createdAtGte = requestParameters.createdAtGte;
        const createdAtLte = requestParameters.createdAtLte;
        const offset = requestParameters.offset;
        const limit = requestParameters.limit;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (metadata !== undefined && metadata !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>metadata, 'metadata');
        }
        if (q !== undefined && q !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>q, 'q');
        }
        if (includeConnectedAccounts !== undefined && includeConnectedAccounts !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>includeConnectedAccounts, 'include_connected_accounts');
        }
        if (createdAtGte !== undefined && createdAtGte !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAtGte, 'created_at_gte');
        }
        if (createdAtLte !== undefined && createdAtLte !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAtLte, 'created_at_lte');
        }
        if (offset !== undefined && offset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/terminal-readers`;
        return this.httpClient.request<ListTerminalReaders200Response>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a Terminal Reader
     * Updates the terminal reader. Any parameters not provided will be left unchanged.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTerminal(requestParameters: UpdateTerminalRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<TerminalReader>;
    public updateTerminal(requestParameters: UpdateTerminalRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<TerminalReader>>;
    public updateTerminal(requestParameters: UpdateTerminalRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<TerminalReader>>;
    public updateTerminal(requestParameters: UpdateTerminalRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling updateTerminal.');
        }
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateTerminal.');
        }
        const terminalReaderUpdateParams = requestParameters.terminalReaderUpdateParams;
        if (terminalReaderUpdateParams === null || terminalReaderUpdateParams === undefined) {
            throw new Error('Required parameter terminalReaderUpdateParams was null or undefined when calling updateTerminal.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/terminal-readers/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<TerminalReader>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: terminalReaderUpdateParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
