import { Pipe, PipeTransform } from '@angular/core';
import { CapabilityStatusPipe } from 'app/core/pipes/capability-status.pipe';
import { ChipColorClass, TilledChipConfig } from 'app/shared/tilled-chip/tilled-chip.component';
import { mapSubtypeToTitle } from 'app/shared/utils/onboarding-utils';
import {
  AccountDocument,
  InternalAccount,
  InternalAccountCapability,
} from '../../../../projects/tilled-api-client/src';

/**
 * Takes a InternalAccountCapability StatusEnum
 */
@Pipe({
  name: 'accountStatus',
  standalone: true,
})
export class AccountStatusPipe implements PipeTransform {
  constructor(private capabilityStatusPipe: CapabilityStatusPipe) {}

  /**
   *
   * @param account InternalAccount object
   * @param solid Whether or not the chip should be solid in color or not
   * @returns
   */
  transform(account: InternalAccount, solid: boolean = true): TilledChipConfig {
    let chipConfig: TilledChipConfig = {
      color: this.getColorClass('orange', solid),
      text: '',
      toolTip: '',
    };

    const cardCapability = this.capabilityStatusPipe.getCapability(account, 'card');
    const debitCapability = this.capabilityStatusPipe.getCapability(account, 'debit');
    const cpCapability = this.capabilityStatusPipe.getCapability(account, 'card_present');
    const requestedDocs = this.getDocumentsStringArray(
      account.document_requests?.filter((doc) => doc.status === AccountDocument.StatusEnum.REQUESTED),
    );
    const submittedDocs = this.getDocumentsStringArray(
      account.document_requests?.filter((doc) => doc.status === AccountDocument.StatusEnum.SUBMITTED),
    );

    let allActive = false;
    let partiallyActive = false;
    let noneActive = false;
    if (
      (cardCapability ? cardCapability.status === InternalAccountCapability.StatusEnum.ACTIVE : true) &&
      (debitCapability ? debitCapability.status === InternalAccountCapability.StatusEnum.ACTIVE : true) &&
      (cpCapability ? cpCapability.status === InternalAccountCapability.StatusEnum.ACTIVE : true)
    ) {
      allActive = true;
    } else if (
      cardCapability?.status === InternalAccountCapability.StatusEnum.ACTIVE ||
      debitCapability?.status === InternalAccountCapability.StatusEnum.ACTIVE ||
      cpCapability?.status === InternalAccountCapability.StatusEnum.ACTIVE
    ) {
      partiallyActive = true;
    } else {
      noneActive = true;
    }

    const allCapabilities = [cardCapability, debitCapability, cpCapability];
    const hasFundingHold = allCapabilities.some((cap) => cap?.processing_details?.funding_hold);
    if (!cardCapability && !debitCapability && !cpCapability) {
      chipConfig.color = this.getColorClass('red', solid);
      chipConfig.icon = 'heroicons_outline:no-symbol';
      chipConfig.text = 'REQUIRES PRICING';
    } else if (allActive) {
      if (hasFundingHold) {
        chipConfig.capabilities = [];
        chipConfig.color = this.getColorClass('red', solid);
        chipConfig.icon = 'heroicons_outline:no-symbol';
        chipConfig.text = 'FUNDING HOLD';

        if (cardCapability) {
          if (cardCapability.processing_details?.funding_hold) {
            chipConfig.capabilities.push('Card - Funding Hold');
          } else {
            chipConfig.capabilities.push('Card - ' + this.enumToString(cardCapability.status));
          }
        }
        if (debitCapability) {
          if (debitCapability.processing_details?.funding_hold) {
            chipConfig.capabilities.push('Direct Debit - Funding Hold');
          } else {
            chipConfig.capabilities.push('Direct Debit - ' + this.enumToString(debitCapability.status));
          }
        }
        if (cpCapability) {
          if (cpCapability.processing_details?.funding_hold) {
            chipConfig.capabilities.push('Card Present - Funding Hold');
          } else {
            chipConfig.capabilities.push('Card Present - ' + this.enumToString(cpCapability.status));
          }
        }

        if (requestedDocs?.length > 0) {
          chipConfig.requestedDocuments = requestedDocs;
        } else if (submittedDocs?.length > 0) {
          chipConfig.color = this.getColorClass('purple', solid);
          chipConfig.icon = 'mat_outline:autorenew';
          chipConfig.submittedDocuments = submittedDocs;
          chipConfig.bottomNote = 'Status will automatically update when review is finished.';
        }
      } else {
        if (requestedDocs?.length > 0 || submittedDocs.length > 0) {
          chipConfig.capabilities = [];
          if (cardCapability) {
            chipConfig.capabilities.push('Card - ' + this.enumToString(cardCapability.status));
          }
          if (debitCapability) {
            chipConfig.capabilities.push('Direct Debit - ' + this.enumToString(debitCapability.status));
          }
          if (cpCapability) {
            chipConfig.capabilities.push('Card Present - ' + this.enumToString(cpCapability.status));
          }
        }
        if (requestedDocs?.length > 0) {
          chipConfig.color = this.getColorClass('red', solid);
          chipConfig.icon = 'heroicons_solid:clock';
          chipConfig.text = 'ACCOUNT REVIEW';
          chipConfig.requestedDocuments = requestedDocs;
          chipConfig.topNote = 'Periodic account review performed. Provide documents to avoid disruptions.';
        } else if (submittedDocs?.length > 0) {
          chipConfig.color = this.getColorClass('purple', solid);
          chipConfig.icon = 'mat_outline:autorenew';
          chipConfig.text = 'ACCOUNT REVIEW';
          chipConfig.submittedDocuments = submittedDocs;
          chipConfig.topNote =
            'Periodic account review in-progress. Status will automatically update when review is finished.';
        } else {
          chipConfig.color = this.getColorClass('green', solid);
          chipConfig.icon = 'heroicons_solid:check';
          chipConfig.text = 'ACTIVE';
        }
      }
    } else if (partiallyActive) {
      chipConfig.capabilities = [];
      chipConfig.text = 'PARTIALLY ACTIVE';
      if (cardCapability?.status) {
        chipConfig.capabilities.push('Card - ' + this.enumToString(cardCapability.status));
      }
      if (debitCapability?.status) {
        chipConfig.capabilities.push('Direct Debit - ' + this.enumToString(debitCapability.status));
      }
      if (cpCapability?.status) {
        chipConfig.capabilities.push('Card Present - ' + this.enumToString(cpCapability.status));
      }

      if (requestedDocs?.length > 0) {
        chipConfig.color = this.getColorClass('red', solid);
        chipConfig.icon = 'heroicons_solid:clock';
        chipConfig.requestedDocuments = requestedDocs;
        chipConfig.topNote = 'Account capabilities are partially active. Additional information is requested.';
      } else if (submittedDocs?.length > 0) {
        chipConfig.color = this.getColorClass('purple', solid);
        chipConfig.icon = 'mat_outline:autorenew';
        chipConfig.submittedDocuments = submittedDocs;
        chipConfig.topNote =
          'Account capabilities are partially active. Submitted information is in review. Status will automatically update when the review is finished.';
      } else {
        chipConfig.color = this.getColorClass('orange', solid);
        chipConfig.icon = 'heroicons_solid:check';
        chipConfig.topNote = 'Account capabilities are partially active.';
      }
    } else {
      const status = this.getUnifiedStatus(allCapabilities);
      if (
        status === InternalAccountCapability.StatusEnum.IN_REVIEW ||
        status === InternalAccountCapability.StatusEnum.SUBMITTED
      ) {
        chipConfig.color = this.getColorClass('purple', solid);
        chipConfig.icon = 'mat_outline:autorenew';
        chipConfig.text = 'IN REVIEW';
        chipConfig.capabilities = [];
        if (cardCapability?.status) {
          chipConfig.capabilities.push('Card - ' + this.enumToString(cardCapability.status));
        }
        if (debitCapability?.status) {
          chipConfig.capabilities.push('Direct Debit - ' + this.enumToString(debitCapability.status));
        }
        if (cpCapability?.status) {
          chipConfig.capabilities.push('Card Present - ' + this.enumToString(cpCapability.status));
        }
        if (requestedDocs?.length > 0) {
          chipConfig.color = this.getColorClass('red', solid);
          chipConfig.icon = 'heroicons_solid:clock';
          chipConfig.text = 'ACTION REQUIRED';
          chipConfig.requestedDocuments = requestedDocs;
        } else if (submittedDocs?.length > 0) {
          chipConfig.submittedDocuments = submittedDocs;
        } else {
          chipConfig.bottomNote =
            'This account is under review by the Underwriting team. No additional documentation is needed at this time.';
        }
      } else if (status === InternalAccountCapability.StatusEnum.CREATED) {
        chipConfig.color = this.getColorClass('blue', solid);
        chipConfig.icon = 'heroicons_solid:pencil';
        chipConfig.text = 'CREATED';
      } else if (status === InternalAccountCapability.StatusEnum.STARTED) {
        chipConfig.color = this.getColorClass('blue', solid);
        chipConfig.icon = 'heroicons_solid:pencil';
        chipConfig.text = 'STARTED';
      } else if (status === InternalAccountCapability.StatusEnum.DISABLED) {
        chipConfig.color = this.getColorClass('neutral', solid);
        chipConfig.icon = 'heroicons_outline:no-symbol';
        chipConfig.text = 'DISABLED';
      } else if (status === InternalAccountCapability.StatusEnum.REJECTED) {
        chipConfig.color = this.getColorClass('neutral', solid);
        chipConfig.icon = 'heroicons_outline:no-symbol';
        chipConfig.text = 'REJECTED';
      } else if (status === InternalAccountCapability.StatusEnum.WITHDRAWN) {
        chipConfig.color = this.getColorClass('neutral', solid);
        chipConfig.icon = 'heroicons_outline:no-symbol';
        chipConfig.text = 'WITHDRAWN';
      }
    }
    return chipConfig;
  }

  private getColorClass(color: string, solid: boolean): ChipColorClass {
    const prefix = solid ? 'SOLID_' : 'OPAQUE_';
    return ChipColorClass[`${prefix}${color.toUpperCase()}` as keyof typeof ChipColorClass];
  }

  private getDocumentsStringArray(docs: AccountDocument[]): string[] {
    const docString: string[] = [];
    for (const doc of docs) {
      docString.push(mapSubtypeToTitle(doc.subtype, doc.requestNotes, true));
    }
    return docString;
  }

  public getUnifiedStatus(capabilities: InternalAccountCapability[]): InternalAccountCapability.StatusEnum {
    const statusOrder: InternalAccountCapability.StatusEnum[] = [
      InternalAccountCapability.StatusEnum.ACTIVE,
      InternalAccountCapability.StatusEnum.IN_REVIEW,
      InternalAccountCapability.StatusEnum.SUBMITTED,
      InternalAccountCapability.StatusEnum.REJECTED,
      InternalAccountCapability.StatusEnum.STARTED,
      InternalAccountCapability.StatusEnum.WITHDRAWN,
      InternalAccountCapability.StatusEnum.DISABLED,
      InternalAccountCapability.StatusEnum.CREATED,
    ];
    if (capabilities?.length === 0) {
      return InternalAccountCapability.StatusEnum.DISABLED;
    } else if (capabilities.find((cap) => cap?.status === InternalAccountCapability.StatusEnum.ACTIVE)) {
      return InternalAccountCapability.StatusEnum.ACTIVE;
    } else if (capabilities.length > 0) {
      return capabilities
        .filter((cap) => cap !== null)
        .sort((a, b) => statusOrder.indexOf(a?.status) - statusOrder.indexOf(b?.status))[0]?.status;
    }
  }

  private enumToString(input: string): string {
    return input.replace(/_/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase());
  }
}
