/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { ListWebhookEndpoints200Response } from '../model/listWebhookEndpoints200Response';
// @ts-ignore
import { WebhookEndpoint } from '../model/webhookEndpoint';
// @ts-ignore
import { WebhookEndpointCreateParams } from '../model/webhookEndpointCreateParams';
// @ts-ignore
import { WebhookEndpointUpdateParams } from '../model/webhookEndpointUpdateParams';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface CreateWebhookEndpointRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    webhookEndpointCreateParams: WebhookEndpointCreateParams;
}

export interface DeleteWebhookEndpointRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    id: string;
}

export interface GetWebhookEndpointRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    id: string;
}

export interface ListWebhookEndpointsRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    /** The (zero-based) offset of the first item in the collection to return. */
    offset?: number;
    /** The maximum number of entries to return. If the value exceeds the maximum, then the maximum value will be used. */
    limit?: number;
}

export interface UpdateWebhookEndpointRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    id: string;
    webhookEndpointUpdateParams: WebhookEndpointUpdateParams;
}


@Injectable({
  providedIn: 'root'
})
export class WebhookEndpointsService {

    protected basePath = 'https://api.tilled.com';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Create a Webhook Endpoint
     * A webhook endpoint must have a url and a list of enabled_events. The specified url will be notified about events from all connected accounts along with your account.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createWebhookEndpoint(requestParameters: CreateWebhookEndpointRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<WebhookEndpoint>;
    public createWebhookEndpoint(requestParameters: CreateWebhookEndpointRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<WebhookEndpoint>>;
    public createWebhookEndpoint(requestParameters: CreateWebhookEndpointRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<WebhookEndpoint>>;
    public createWebhookEndpoint(requestParameters: CreateWebhookEndpointRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling createWebhookEndpoint.');
        }
        const webhookEndpointCreateParams = requestParameters.webhookEndpointCreateParams;
        if (webhookEndpointCreateParams === null || webhookEndpointCreateParams === undefined) {
            throw new Error('Required parameter webhookEndpointCreateParams was null or undefined when calling createWebhookEndpoint.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/webhook-endpoints`;
        return this.httpClient.request<WebhookEndpoint>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: webhookEndpointCreateParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a Webhook Endpoint
     * Delete a webhook endpoint.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteWebhookEndpoint(requestParameters: DeleteWebhookEndpointRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<object>;
    public deleteWebhookEndpoint(requestParameters: DeleteWebhookEndpointRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<object>>;
    public deleteWebhookEndpoint(requestParameters: DeleteWebhookEndpointRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<object>>;
    public deleteWebhookEndpoint(requestParameters: DeleteWebhookEndpointRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling deleteWebhookEndpoint.');
        }
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteWebhookEndpoint.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/webhook-endpoints/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<object>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a Webhook Endpoint
     * Retrieves the webhook endpoint with the given ID.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWebhookEndpoint(requestParameters: GetWebhookEndpointRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<WebhookEndpoint>;
    public getWebhookEndpoint(requestParameters: GetWebhookEndpointRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<WebhookEndpoint>>;
    public getWebhookEndpoint(requestParameters: GetWebhookEndpointRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<WebhookEndpoint>>;
    public getWebhookEndpoint(requestParameters: GetWebhookEndpointRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling getWebhookEndpoint.');
        }
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getWebhookEndpoint.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/webhook-endpoints/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<WebhookEndpoint>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List all Webhook Endpoints
     * Returns a list of your webhook endpoints.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listWebhookEndpoints(requestParameters: ListWebhookEndpointsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<ListWebhookEndpoints200Response>;
    public listWebhookEndpoints(requestParameters: ListWebhookEndpointsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<ListWebhookEndpoints200Response>>;
    public listWebhookEndpoints(requestParameters: ListWebhookEndpointsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<ListWebhookEndpoints200Response>>;
    public listWebhookEndpoints(requestParameters: ListWebhookEndpointsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling listWebhookEndpoints.');
        }
        const offset = requestParameters.offset;
        const limit = requestParameters.limit;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (offset !== undefined && offset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/webhook-endpoints`;
        return this.httpClient.request<ListWebhookEndpoints200Response>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a Webhook Endpoint
     * Updates the webhook endpoint. Any parameters not provided will be left unchanged.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateWebhookEndpoint(requestParameters: UpdateWebhookEndpointRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<WebhookEndpoint>;
    public updateWebhookEndpoint(requestParameters: UpdateWebhookEndpointRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<WebhookEndpoint>>;
    public updateWebhookEndpoint(requestParameters: UpdateWebhookEndpointRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<WebhookEndpoint>>;
    public updateWebhookEndpoint(requestParameters: UpdateWebhookEndpointRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling updateWebhookEndpoint.');
        }
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateWebhookEndpoint.');
        }
        const webhookEndpointUpdateParams = requestParameters.webhookEndpointUpdateParams;
        if (webhookEndpointUpdateParams === null || webhookEndpointUpdateParams === undefined) {
            throw new Error('Required parameter webhookEndpointUpdateParams was null or undefined when calling updateWebhookEndpoint.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/webhook-endpoints/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<WebhookEndpoint>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                body: webhookEndpointUpdateParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
