import { Component, ViewEncapsulation } from '@angular/core';
import { TilledHeadingGenericComponent } from './tilled-heading-generic.component';

@Component({
  selector: 'tilled-heading-h4',
  templateUrl: '../tilled-text-generic.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class TilledHeadingH4Component extends TilledHeadingGenericComponent {
  public styles: string;
  constructor() {
    super();
    this.internalStyles = 'font-semibold text-h4';
  }
}
