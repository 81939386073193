import { Pipe, PipeTransform } from '@angular/core';

/**
 * Takes string in snake case and splits it using underscores as the delimiter, returing words seperated by spaces
 */
@Pipe({
  name: 'capitalizeTitle',
  standalone: true,
})
export class CapitalizeTitlePipe implements PipeTransform {
  /**
   *
   * @param value string in snake case
   * @returns
   */
  transform(value: string): string {
    const dontCapitalize = [
      'of',
      'the',
      'and',
      'in',
      'on',
      'at',
      'to',
      'for',
      'with',
      'as',
      'by',
      'or',
      'but',
      'nor',
      'a',
      'an',
    ];
    const toUpperCase = ['API', 'URL', 'ID'];
    if (value) {
      const v = value
        .toLowerCase()
        .split(' ')
        .map((word) =>
          dontCapitalize.includes(word) || word === word.toUpperCase()
            ? word
            : word.charAt(0).toUpperCase() + word.slice(1),
        )
        .join(' ')
        // Uppercase the `toUpperCase` words
        .split(' ')
        .map((word) =>
          toUpperCase.includes(word.toUpperCase()) ? word.toUpperCase() : word.charAt(0).toUpperCase() + word.slice(1),
        )
        .join(' ');
      return v;
    }
    return value;
  }
}
