<span [matTooltip]="disabledTooltip" [matTooltipDisabled]="!disabled">
  <button
    class="bg-{{ backgroundColor }} bg-opacity-{{ bgOpacity }} text-{{ textDisplayColor }} min-w-0 {{ styles }} {{
      outlineColor ? 'outline outline-' + outlineColor : ''
    }}"
    [ngClass]="{ 'rounded-sm': !rounded, 'rounded-3xl': rounded }"
    mat-flat-button
    (click)="onButtonClick()"
    [disabled]="disabled"
  >
    <mat-icon *ngIf="prefixIcon" class="text-{{ textDisplayColor }} icon-size-5" [svgIcon]="prefixIcon"></mat-icon>
    <tilled-button-b2 [white]="displayWhite" [secondary]="secondary">{{ buttonText }}</tilled-button-b2>
    <mat-icon *ngIf="suffixIcon" class="text-{{ textDisplayColor }} icon-size-5" [svgIcon]="suffixIcon"></mat-icon>
  </button>
</span>
