import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { DocumentDto } from '@tilled-api-client';
import { AuthService } from 'app/core/services/auth.service';
import { BrandingService } from 'app/core/services/branding.service';
import { DocumentsAppService } from 'app/core/services/documents.app.service';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { DOCUMENT_NOTIFICATION_ID, Notification } from 'app/layout/common/notifications/notifications.types';
import { Subject, takeUntil } from 'rxjs';
import { LogoLoadedDirective } from '../../../shared/logo-loaded.directive';
import { TilledHeadingH4Component } from '../../../shared/tilled-text/tilled-heading/tilled-heading-h4.component';
import { TilledHeadingH6Component } from '../../../shared/tilled-text/tilled-heading/tilled-heading-h6.component';
import { TilledParagraphP4Component } from '../../../shared/tilled-text/tilled-paragraph/tilled-paragraph-p4.component';
import { NotificationsDirective } from './notifications.directive';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'notifications',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    TilledHeadingH4Component,
    RouterLink,
    NotificationsDirective,
    MatTooltipModule,
    LogoLoadedDirective,
    TilledHeadingH6Component,
    TilledParagraphP4Component,
    CommonModule,
  ],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
  @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;

  notifications: Notification[];
  unreadCount: number = 0;
  private _overlayRef: OverlayRef;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  public whiteText: boolean = true;

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _notificationsService: NotificationsService,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private _brandingService: BrandingService,
    private _documentsService: DocumentsAppService,
    private _authService: AuthService,
  ) {
    this._brandingService.primaryColor$.subscribe((color) => {
      if (color) {
        this.whiteText = this._brandingService.determineWhiteTextColorFromBgColor(color);
      }
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to notification changes
    this._notificationsService.notifications$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((notifications: Notification[]) => {
        // Load the notifications
        this.notifications = notifications;

        // Calculate the unread count
        this._calculateUnreadCount();

        // Mark for check
        this._changeDetectorRef.markForCheck();
      });

    this._authService.account$.pipe(takeUntil(this._unsubscribeAll)).subscribe((account) => {
      this._documentsService.getAllDocuments(account.id);
    });

    // Document Notifications
    // note: this will need to be a forkJoin when we add new/multiple notifications to ensure old ones are deleted when switching accounts
    this._documentsService.documentsAll$.subscribe((documents) => {
      if (!documents) {
        return;
      }
      this._notificationsService.deleteAll();
      if (this._authService.isMerchantUser()) {
        const docRequests = documents.filter((doc) => doc.status === DocumentDto.StatusEnum.REQUESTED);

        if (docRequests.length > 0) {
          this._notificationsService.create({
            id: DOCUMENT_NOTIFICATION_ID,
            title: 'Urgent: Additional Information Requested',
            description:
              'Your account has been reviewed and additional information is needed. Please upload requested documents promptly to avoid disruptions.',
            time: docRequests[0].updated_at, // latest document for time
            link: '/account',
            useRouter: true,
          });
        }
      }
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();

    // Dispose the overlay
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Open the notifications panel
   */
  openPanel(): void {
    // Return if the notifications panel or its origin is not defined
    if (!this._notificationsPanel || !this._notificationsOrigin) {
      return;
    }

    // Create the overlay if it doesn't exist
    if (!this._overlayRef) {
      this._createOverlay();
    }

    // Attach the portal to the overlay
    this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
  }

  /**
   * Close the notifications panel
   */
  closePanel(): void {
    this._overlayRef.detach();
  }

  /**
   * Mark all notifications as read
   */
  markAllAsRead(): void {
    // Mark all as read
    this._notificationsService.markAllAsRead();
  }

  /**
   * Toggle read status of the given notification
   */
  toggleRead(notification: Notification): void {
    // Toggle the read status
    notification.read = !notification.read;

    // Update the notification
    this._notificationsService.update(notification.id, notification);
  }

  /**
   * Delete the given notification
   */
  delete(notification: Notification): void {
    // Delete the notification
    this._notificationsService.delete(notification.id);
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create the overlay
   */
  private _createOverlay(): void {
    // Create the overlay
    this._overlayRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: 'fuse-backdrop-on-mobile',
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
        .withLockedPosition(true)
        .withPush(true)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
          },
        ]),
    });

    // Detach the overlay from the portal on backdrop click
    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.detach();
    });
  }

  /**
   * Calculate the unread count
   *
   * @private
   */
  private _calculateUnreadCount(): void {
    let count = 0;

    if (this.notifications && this.notifications.length) {
      count = this.notifications.filter((notification) => !notification.read).length;
    }

    this.unreadCount = count;
  }
}
