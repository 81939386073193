<fuse-card class="h-fit p-6" #fuseCard>
  <div class="w-full">
    <div class="w-full">
      <tilled-heading-h4>{{ headingText }}</tilled-heading-h4>
    </div>
    <mat-divider class="mt-3 mb-4 w-full"></mat-divider>
    <ng-container>
      <div class="w-full">
        <ng-container *ngFor="let row of rowsData">
          <tilled-card-row
            [labelText]="row.labelText"
            [valueText]="row.valueText"
            [valueInfoTooltip]="row.tooltipText"
            [urlValue]="row.urlValue"
          ></tilled-card-row>
        </ng-container>
      </div>
    </ng-container>
  </div>
</fuse-card>
