import { Injectable } from '@angular/core';
import { DEFAULT_PAGE_LIMIT } from 'app/core/constants';
import { BehaviorSubject, map, Observable, shareReplay, tap } from 'rxjs';
import {
  InternalGetTerminalReaderRequestParams,
  InternalListTerminalReadersRequestParams,
  InternalService,
  InternalTerminalReader,
  ListTerminalReadersRequestParams,
  TerminalReadersService,
  UpdateTerminalRequestParams,
} from '../../../../projects/tilled-api-client/src';
import { TilledAlert } from '../models/tilled-alert';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class TerminalReadersAppService {
  private _terminalReader$ = new BehaviorSubject<InternalTerminalReader>(null);
  private _terminalReaders$ = new BehaviorSubject<InternalTerminalReader[]>(null);
  private _terminalReadersCount$ = new BehaviorSubject<number>(null);

  public terminalReader$: Observable<InternalTerminalReader> = this._terminalReader$.asObservable();
  public terminalReaders$: Observable<InternalTerminalReader[]> = this._terminalReaders$.asObservable();
  public terminalReadersCount$: Observable<number> = this._terminalReadersCount$.asObservable();

  constructor(
    private _terminalReadersService: TerminalReadersService,
    private _internalService: InternalService,
    private _alertService: AlertService,
  ) {}
  public getAllTerminalReaders(params: InternalListTerminalReadersRequestParams): void {
    this._internalService
      .internalListTerminalReaders(params)
      .pipe(
        tap((result) => this._terminalReadersCount$.next(result.total)),
        map((result) => result.items),
        shareReplay(1),
      )
      .subscribe({
        next: (terminalReaders) => {
          this._terminalReaders$.next(terminalReaders);
        },
        error: (err) => {
          if (err?.error?.statusCode === 400) {
            const message: TilledAlert = {
              message: err?.error?.message,
              title: 'Failed',
              type: 'error',
            };
            this._alertService.showAlert(message);
          } else {
            // generic catch all for error responses
            const message: TilledAlert = {
              message: 'Could not load all terminal readers',
              title: 'Server error',
              type: 'error',
            };
            this._alertService.showAlert(message);
          }
        },
      });
  }

  public getTerminalReader(params: InternalGetTerminalReaderRequestParams): void {
    this._internalService.internalGetTerminalReader(params).subscribe({
      next: (terminal) => {
        this._terminalReader$.next(terminal);
      },
      error: (err) => {
        // generic catch all for error responses
        const message: TilledAlert = {
          message: "Could not load terminal '" + params.id + "'",
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
      },
    });
  }

  public updateTerminalReader(params: UpdateTerminalRequestParams, partnerAccountId?: string): void {
    this._terminalReadersService.updateTerminal(params).subscribe({
      next: (terminal) => {
        // Re fetch the internal terminal reader
        this.getTerminalReader({
          tilledAccount: params.tilledAccount,
          id: params.id,
        });

        const listParams: ListTerminalReadersRequestParams = {
          tilledAccount: partnerAccountId ?? params.tilledAccount,
          includeConnectedAccounts: true,
          offset: 0,
          limit: DEFAULT_PAGE_LIMIT,
        };

        // Refresh terminals list
        this.getAllTerminalReaders(listParams);
      },
      error: (err) => {
        // generic catch all for error responses
        const message: TilledAlert = {
          message: "Could not update terminal '" + params.id + "'",
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
      },
    });
  }
}
