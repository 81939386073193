<div class="flex min-w-120 flex-auto flex-col">
  <div class="flex justify-end">
    <!-- <mat-icon style="cursor: pointer" (click)="closeDialog()" svgIcon="mat_outline:close"></mat-icon> -->
  </div>
  <ng-container>
    <div class="grid w-full justify-items-center pb-8">
      <!-- Title -->
      <tilled-heading-h2 class="pb-2">Capture a payment</tilled-heading-h2>
      <tilled-paragraph-p3>Provide capture details below.</tilled-paragraph-p3>
    </div>
    <div>
      <form [formGroup]="captureForm" class="">
        <div class="px-8">
          <!-- Amount Start -->
          <tilled-input
            label="Capture Amount"
            placeholder="Enter the amount to capture"
            name="amount"
            tilledLabel="true"
            showCurrency="true"
            mask="separator.2"
            data-test-id="capture-amount"
            hint="Maximum capture amount is {{ maxCapture | minorUnitsToCurrency }}"
            [errors]="captureForm.get('amount').errors && captureForm.get('amount').touched"
            [errorMessage]="
              captureForm.get('amount').hasError('required') && captureForm.get('amount').touched
                ? 'A capture amount is required'
                : captureForm.get('amount').hasError('max') && captureForm.get('amount').touched
                  ? 'Cannot exceed the maximum capture amount'
                  : captureForm.get('amount').hasError('min') && captureForm.get('amount').touched
                    ? 'Capture amount must be greater than 0'
                    : ''
            "
          ></tilled-input>
          <!-- Amount End -->
        </div>
      </form>
    </div>

    <div *ngIf="displayAlert$ | async" class="flex w-full justify-start px-8 pt-2">
      <fuse-alert
        [type]="alertType"
        [appearance]="'outline'"
        [dismissible]="alertDismissible"
        [dismissed]="false"
        [name]="'captureDialogAlertBox'"
      >
        <span fuseAlertTitle>{{ alertTitle }}</span>
        {{ alertMessage }}
      </fuse-alert>
    </div>

    <div class="flex w-full justify-end px-8 pt-8">
      <!-- Cancel Button -->
      <tilled-button
        data-test-id="canel-capture-Btn"
        bgColor="white"
        styles="text-tilled-secondary"
        [whiteText]="false"
        [rounded]="true"
        buttonText="Cancel"
        (buttonClick)="closeDialog()"
      ></tilled-button>

      <!-- Submit Button -->
      <tilled-button
        data-test-id="submit-capture-Btn"
        bgColor="tilled-primary"
        [rounded]="true"
        buttonText="Submit Capture"
        (buttonClick)="submitCaptureClicked()"
        [whiteText]="true"
      ></tilled-button>
    </div>
  </ng-container>
</div>
