/**
 * Compares two strings to determine if they are equal
 * An empty string is considered equal to null
 * @param string1
 * @param string2
 */
export function _compareTwoStrings(string1: string, string2: string): boolean {
  if (!string1) {
    if (!string2) {
      return true;
    }
  }

  return string1 === string2;
}
