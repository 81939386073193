import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ConnectedAccountDialogComponent } from 'app/shared/connected-account/connected-account-dialog/connected-account-dialog.component';
import { TilledInputComponent } from 'app/shared/form-fields/tilled-input/tilled-input.component';
import { TilledButtonComponent } from '../../buttons/tilled-button.component';
import { TilledHeadingH2Component } from '../../tilled-text/tilled-heading/tilled-heading-h2.component';
import { TilledLabelL1Component } from '../../tilled-text/tilled-label/tilled-label-l1.component';
import { TilledParagraphP3Component } from '../../tilled-text/tilled-paragraph/tilled-paragraph-p3.component';
import { TilledParagraphP4Component } from '../../tilled-text/tilled-paragraph/tilled-paragraph-p4.component';

@Component({
  selector: 'app-customer-dialog',
  templateUrl: './customer-dialog.component.html',
  standalone: true,
  imports: [
    MatIconModule,
    TilledHeadingH2Component,
    TilledParagraphP3Component,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    TilledLabelL1Component,
    TilledInputComponent,
    MatInputModule,
    TilledParagraphP4Component,
    TilledButtonComponent,
  ],
})
export class CustomerDialogComponent implements OnInit {
  public isLoading: boolean;
  public customerForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ConnectedAccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;

    this.customerForm = this._formBuilder.group({
      first_name: new FormControl<string | null>(null, [Validators.required]),
      middle_name: new FormControl<string | null>(null),
      last_name: new FormControl<string | null>(null, [Validators.required]),
      email: new FormControl<string | null>(null),
      phone: new FormControl<string | null>(null),
    });
  }

  addCustomerClicked() {
    this.dialogRef.close(this.customerForm);
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
