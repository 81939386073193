export interface Notification {
  id: string;
  icon?: string;
  image?: string;
  title?: string;
  description?: string;
  time: string;
  link?: string;
  useRouter?: boolean;
  read?: boolean;
  dismissible?: boolean;
  removable?: boolean;
}

export const DOCUMENT_NOTIFICATION_ID = 'document_notification';
