/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BulkDocumentSubmissionResponse { 
    /**
     * The ID of a document submitted
     */
    document_id: string;
    /**
     * Message detailing the failure reason of a document submission
     */
    failure_message?: string;
    /**
     * The status response of a submission of a document
     */
    status: BulkDocumentSubmissionResponse.StatusEnum;
}
export namespace BulkDocumentSubmissionResponse {
    export type StatusEnum = 'succeeded' | 'failed';
    export const StatusEnum = {
        SUCCEEDED: 'succeeded' as StatusEnum,
        FAILED: 'failed' as StatusEnum
    };
}


