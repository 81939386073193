import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';



import { NotificationsModule } from 'app/layout/common/notifications/notifications.module';
import { UserModule } from 'app/layout/common/user/user.module';
import { FuturisticLayoutComponent } from 'app/layout/layouts/vertical/futuristic/futuristic.component';
import { SharedModule } from 'app/shared/shared.module';
import { HelpCenterModule } from '../../../common/help-center/help-center.module';

@NgModule({
    imports: [
    HttpClientModule,
    RouterModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    NotificationsModule,
    UserModule,
    SharedModule,
    HelpCenterModule,
    FuturisticLayoutComponent,
],
    exports: [FuturisticLayoutComponent],
})
export class FuturisticLayoutModule {}
