/* eslint-disable @typescript-eslint/naming-convention */
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertService } from '@fuse/components/alert';
import { CancelOutboundTransferRequestParams, InternalOutboundTransfer } from '@tilled-api-client';
import { ComponentBase } from 'app/core/componentBase';
import { MinorUnitsToCurrencyPipe } from 'app/core/pipes/minor-units-to-currency.pipe';
import { NumericToMinorUnitsPipe } from 'app/core/pipes/numeric-to-minor-units.pipe';
import { AuthService } from 'app/core/services/auth.service';
import { OutboundTransfersAppService } from 'app/core/services/outbound-transfers.app.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { FuseAlertComponent } from '../../../../@fuse/components/alert/alert.component';
import { TilledButtonComponent } from '../../../shared/buttons/tilled-button.component';
import { TilledHeadingH2Component } from '../../../shared/tilled-text/tilled-heading/tilled-heading-h2.component';
import { TilledParagraphP3Component } from '../../../shared/tilled-text/tilled-paragraph/tilled-paragraph-p3.component';

@Component({
  selector: 'app-cancel-outbound-transfer-dialog',
  templateUrl: './cancel-outbound-transfer-dialog.component.html',
  styleUrls: ['./cancel-outbound-transfer-dialog.component.scss'],
  animations: fuseAnimations,
  standalone: true,
  imports: [
    MatIconModule,
    TilledHeadingH2Component,
    TilledParagraphP3Component,
    FuseAlertComponent,
    TilledButtonComponent,
    MinorUnitsToCurrencyPipe,
    CommonModule,
  ],
})
export class CancelOutboundTransferDialogComponent extends ComponentBase implements OnInit {
  public outboundTransfer: InternalOutboundTransfer;
  public isMerchant: boolean;

  public alertType: string;
  public alertTitle: string;
  public alertMessage: string;
  public alertDismissible: boolean;

  public activelyCancelling = false;

  private accountId: string;

  private _displayAlert$ = new Subject<boolean>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public displayAlert$ = this._displayAlert$.asObservable();

  private cancelError$: Observable<string>;
  private outboundTransferCanceled$: Observable<boolean>;

  constructor(
    public matDialogRef: MatDialogRef<CancelOutboundTransferDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _authService: AuthService,
    private _numericToMinorPipe: NumericToMinorUnitsPipe,
    private _fuseAlertService: FuseAlertService,
    private _outboundTransfersService: OutboundTransfersAppService,
  ) {
    super();
    // Determine if this is a merchant or partner logged in
    this._authService.isMerchantAccount$.pipe(takeUntil(this._unsubscribeAll)).subscribe((isMerchant) => {
      this.isMerchant = isMerchant;
    });

    this.accountId = this._data.accountId;
    this.outboundTransfer = this._data.outboundTransfer;

    this.cancelError$ = this._outboundTransfersService.cancelError$;
    this.cancelError$.subscribe((message) => {
      this.alertType = 'warn';
      this.alertTitle = 'Cancellation Failed';
      this.alertMessage = message;
      this.alertDismissible = true;
      this._displayAlert$.next(true);
      this._fuseAlertService.show('cancelDialogAlertBox');
      this.activelyCancelling = false;
    });

    this.outboundTransferCanceled$ = this._outboundTransfersService.outboundTransferCanceled$;
    this.outboundTransferCanceled$.subscribe((success) => {
      if (success) {
        this.closeDialog(true);
      }
    });
  }

  async ngOnInit(): Promise<void> {}

  public confirmClicked(): void {
    const cancelRequestParams: CancelOutboundTransferRequestParams = {
      tilledAccount: this.accountId,
      id: this.outboundTransfer.id,
    };
    this.activelyCancelling = true;
    this._outboundTransfersService.cancelOutboundTransfer(cancelRequestParams);
  }

  public closeDialog(canceled?: boolean): void {
    this.matDialogRef.close(canceled);
  }
}
