import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CONFIG_TOKEN, DBConfig } from './indexed-db.meta';
import { IndexedDBService } from './indexed-db.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class IndexedDBModule {
  static forRoot(...dbConfigs: DBConfig[]): ModuleWithProviders<IndexedDBModule> {
    const value = {};
    for (const dbConfig of dbConfigs) {
      Object.assign(value, { [dbConfig.name]: dbConfig });
    }
    return {
      ngModule: IndexedDBModule,
      providers: [IndexedDBService, { provide: CONFIG_TOKEN, useValue: value }],
    };
  }
}
