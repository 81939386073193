/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  GetRefundRequestParams,
  InternalCreateRefundRequestParams,
  InternalService,
  Refund,
  RefundsService,
} from '../../../../projects/tilled-api-client/src';
import { TilledAlert } from '../models/tilled-alert';
import { MinorUnitsToCurrencyPipe } from '../pipes/minor-units-to-currency.pipe';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class RefundsAppService {
  constructor(
    private _refundsService: RefundsService,
    private _internalService: InternalService,
    private _alertService: AlertService,
  ) {}

  private _refund$ = new Subject<Refund>();
  private _refundCreated$ = new Subject<boolean>();
  private _refundCreatedError$ = new Subject<string>();

  public refund$ = this._refund$.asObservable();
  public refundCreated$ = this._refundCreated$.asObservable();
  public refundCreatedError$ = this._refundCreatedError$.asObservable();

  // TODO: this isn't use, we currently use the Client API request
  public getRefund(params: GetRefundRequestParams): void {
    this._refundsService.getRefund(params).subscribe({
      next: (refund) => {
        this._refund$.next(refund);
      },
      error: (err) => {
        // generic catch all for error responses
        const message: TilledAlert = {
          message: `Could not load refund '${params.id}'`,
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
      },
    });
  }

  public createRefund(params: InternalCreateRefundRequestParams): void {
    this._internalService.internalCreateRefund(params).subscribe({
      next: (refund) => {
        if (refund.status === Refund.StatusEnum.FAILED) {
          this._refundCreatedError$.next(refund.failure_message);
        } else {
          const currencyPipe = new MinorUnitsToCurrencyPipe();
          const message: TilledAlert = {
            message: `Refund '${refund.id}' for ${currencyPipe.transform(refund.amount)} was sent successfully`,
            title: 'Refund successful',
            type: 'success',
            timer: 8000,
          };
          this._alertService.showAlert(message);

          this._refundCreated$.next(true);
        }
      },
      error: (err) => {
        this._refundCreatedError$.next(err.error.message);
      },
    });
  }
}
