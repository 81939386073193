/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CheckoutMethods } from './checkoutMethods';


export interface BillingAndCheckout { 
    billing_model: Array<BillingAndCheckout.BillingModelEnum>;
    checkout_methods: Array<CheckoutMethods>;
}
export namespace BillingAndCheckout {
    export type BillingModelEnum = 'one_time' | 'recurring';
    export const BillingModelEnum = {
        ONE_TIME: 'one_time' as BillingModelEnum,
        RECURRING: 'recurring' as BillingModelEnum
    };
}


