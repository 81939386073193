import { Pipe, PipeTransform } from '@angular/core';

/**
 * Takes a numeric value (should be string or number) and outputs a number in minor units (will round for more than 2 decimals)
 * e.g. "10.00" returned as 1000, 5 returned as 500, "5" returned as 500, "10.01" returned as 1001, 10.01 returned as 1001
 */
@Pipe({
  name: 'numericToMinorUnits',
})
export class NumericToMinorUnitsPipe implements PipeTransform {
  /**
   *
   * @param value Numeric value (string or number)
   * @returns
   */
  transform(value: any): any {
    let result = parseInt((parseFloat(value.toString()) * 100).toFixed(0));
    return result;
  }
}
