import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ComponentBase } from 'app/core/componentBase';
import { SelectorTypes } from 'app/core/data/selector-types';
import { NumericToMinorUnitsPipe } from 'app/core/pipes/numeric-to-minor-units.pipe';
import { MerchantAppService } from 'app/core/services/merchant-app.service';
import { cloneDeep } from 'lodash';
import { Observable, Subscription, takeUntil } from 'rxjs';
import {
  BusinessLegalEntity,
  MerchantApplication,
  PricingTemplate,
} from '../../../../../projects/tilled-api-client/src';
import { MerchantAppCardComponent } from '../../cards/merchant-application/merchant-app-card/merchant-app-card.component';
import { TilledInputComponent } from '../../form-fields/tilled-input/tilled-input.component';
import { TilledHeadingH4Component } from '../../tilled-text/tilled-heading/tilled-heading-h4.component';
import { TilledParagraphP3Component } from '../../tilled-text/tilled-paragraph/tilled-paragraph-p3.component';

@Component({
  selector: 'processing-volumes-merchant-step',
  templateUrl: './processing-volumes-step.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MerchantAppCardComponent,
    FormsModule,
    ReactiveFormsModule,
    TilledHeadingH4Component,
    TilledParagraphP3Component,
    TilledInputComponent,
    CommonModule,
  ],
})
export class ProcessingVolumesMerchantStepComponent extends ComponentBase implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @Input() forConsole: boolean = false;
  @Input() disabled$: Observable<boolean> = null;
  @Input() saveApp$: Observable<string> = null;
  @Input() checkUnsavedApp$: Observable<string> = null;
  @Input() resetApp$: Observable<boolean> = null;
  @Input() stepNumber: number;
  @Output() markAppUnsaved: EventEmitter<boolean> = new EventEmitter<boolean>();
  private subscriptions: Subscription[] = [];
  public processingVolumesForm: FormGroup;
  public merchantApp: MerchantApplication;

  public volumeRangeTypes = SelectorTypes.YearlyVolumeRangeTypes;

  public hasCard: boolean = false;
  public hasDebit: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _merchantAppService: MerchantAppService,
    private _numericToMinorPipe: NumericToMinorUnitsPipe,
  ) {
    super();
  }

  ngOnInit(): void {
    this.processingVolumesForm = this._formBuilder.group({
      averageMonthlyTransactions: new FormControl<number | null>(
        this.merchantApp?.business_legal_entity?.average_transactions_per_month || null,
        [Validators.min(1)],
      ),
      averageMonthlyVolume: new FormControl<number | null>(
        this.merchantApp?.business_legal_entity?.monthly_volume_amount
          ? this.merchantApp?.business_legal_entity?.monthly_volume_amount / 100
          : null,
        [Validators.min(1)],
      ),
      averageTransactionAmountCard: new FormControl<number | null>(
        this.merchantApp?.business_legal_entity?.average_transaction_amount_card
          ? this.merchantApp?.business_legal_entity?.average_transaction_amount_card / 100
          : null,
        [Validators.min(1)],
      ),
      averageTransactionAmountDebit: new FormControl<number | null>(
        this.merchantApp?.business_legal_entity?.average_transaction_amount_debit
          ? this.merchantApp?.business_legal_entity?.average_transaction_amount_debit / 100
          : null,
        [Validators.min(1)],
      ),
    });

    this._merchantAppService.merchantApplicationResponse$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((application) => {
        this.merchantApp = cloneDeep(application);
        this.resetApplication();
      });

    if (this.disabled$) {
      this.subscriptions.push(
        this.disabled$.subscribe((isDisabled) => {
          if (isDisabled) {
            this.processingVolumesForm.disable();
          } else {
            this.processingVolumesForm.enable();
          }
        }),
      );
    }

    if (this.forConsole) {
      if (this.saveApp$) {
        this.subscriptions.push(
          this.saveApp$.subscribe((save) => {
            if (save) {
              this.onContinueClicked(save);
            }
          }),
        );
      }
      if (this.checkUnsavedApp$) {
        this.subscriptions.push(
          this.checkUnsavedApp$.subscribe((check) => {
            if (check) {
              this.markAppUnsaved.emit(this.isAppUnsaved());
            }
          }),
        );
      }
      if (this.resetApp$) {
        this.subscriptions.push(
          this.resetApp$.subscribe((reset) => {
            if (reset) {
              this.resetApplication();
            }
          }),
        );
      }
    }
  }

  ngAfterViewInit(): void {
    this.scrollToTop();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onBackClicked(event: string): void {
    this._merchantAppService.updateCurrentStep(this.stepNumber - 1);
  }

  onContinueClicked(accountId?: string) {
    if (this.processingVolumesForm.invalid) {
      return;
    }
    // ngx-mask sets certain empty values (phone numbers at least) to empty string, where api expects null
    for (const field in this.processingVolumesForm.controls) {
      const control = this.processingVolumesForm.get(field);
      if (control.value === '') {
        control.setValue(null);
      }
    }

    if (this.hasCard) {
      this.merchantApp.business_legal_entity.average_transaction_amount_card = this.processingVolumesForm.value
        .averageTransactionAmountCard
        ? this._numericToMinorPipe.transform(this.processingVolumesForm.value.averageTransactionAmountCard)
        : null;
      // set required average_transaction_amount to average_transaction_amount_card
      this.merchantApp.business_legal_entity.average_transaction_amount = this.processingVolumesForm.value
        .averageTransactionAmountCard
        ? this._numericToMinorPipe.transform(this.processingVolumesForm.value.averageTransactionAmountCard)
        : null;
    }
    if (this.hasDebit) {
      this.merchantApp.business_legal_entity.average_transaction_amount_debit = this.processingVolumesForm.value
        .averageTransactionAmountDebit
        ? this._numericToMinorPipe.transform(this.processingVolumesForm.value.averageTransactionAmountDebit)
        : null;
      if (!this.merchantApp.business_legal_entity.average_transaction_amount) {
        // set required average_transaction_amount to average_transaction_amount_debit
        this.merchantApp.business_legal_entity.average_transaction_amount = this.processingVolumesForm.value
          .averageTransactionAmountDebit
          ? this._numericToMinorPipe.transform(this.processingVolumesForm.value.averageTransactionAmountDebit)
          : null;
      }
    }

    this.merchantApp.business_legal_entity.average_transactions_per_month =
      this.processingVolumesForm.value.averageMonthlyTransactions;
    this.merchantApp.business_legal_entity.monthly_volume_amount = this.processingVolumesForm.value.averageMonthlyVolume
      ? this._numericToMinorPipe.transform(this.processingVolumesForm.value.averageMonthlyVolume)
      : null;

    if (this.merchantApp.business_legal_entity.monthly_volume_amount > 0) {
      // set required yearly_volume_range based on processing volume
      const yearlyProcessingVolume = this.merchantApp.business_legal_entity.monthly_volume_amount * 12;
      if (yearlyProcessingVolume <= 5000000) {
        this.merchantApp.business_legal_entity.yearly_volume_range = BusinessLegalEntity.YearlyVolumeRangeEnum.LOW;
      } else if (yearlyProcessingVolume > 5000000 && yearlyProcessingVolume <= 10000000) {
        this.merchantApp.business_legal_entity.yearly_volume_range = BusinessLegalEntity.YearlyVolumeRangeEnum.MEDIUM;
      } else if (yearlyProcessingVolume > 10000000 && yearlyProcessingVolume <= 25000000) {
        this.merchantApp.business_legal_entity.yearly_volume_range = BusinessLegalEntity.YearlyVolumeRangeEnum.HIGH;
      } else {
        this.merchantApp.business_legal_entity.yearly_volume_range =
          BusinessLegalEntity.YearlyVolumeRangeEnum.VERY_HIGH;
      }
    } else {
      this.merchantApp.business_legal_entity.yearly_volume_range = null;
    }

    this._merchantAppService.updateMerchantApplication(this.merchantApp, this.stepNumber + 1, accountId);
  }

  private isAppUnsaved(): boolean {
    for (const field in this.processingVolumesForm.controls) {
      const control = this.processingVolumesForm.get(field);
      if (control.value === '') {
        control.setValue(null);
      }
    }

    return !(this.merchantApp.business_legal_entity?.average_transaction_amount_card
      ? this.merchantApp.business_legal_entity?.average_transaction_amount_card / 100 ==
        this.processingVolumesForm.value.averageTransactionAmountCard
      : null == this.processingVolumesForm.value.averageTransactionAmountCard &&
          this.merchantApp.business_legal_entity?.average_transaction_amount_debit
        ? this.merchantApp.business_legal_entity?.average_transaction_amount_debit / 100 ==
          this.processingVolumesForm.value.averageTransactionAmountDebit
        : null == this.processingVolumesForm.value.averageTransactionAmountDebit &&
            this.merchantApp.business_legal_entity?.monthly_volume_amount
          ? this.merchantApp.business_legal_entity?.monthly_volume_amount / 100 ==
            this.processingVolumesForm.value.averageMonthlyVolume
          : null == this.processingVolumesForm.value.averageMonthlyVolume &&
            this.merchantApp.business_legal_entity?.average_transactions_per_month ==
              this.processingVolumesForm.value.averageMonthlyTransactions);
  }

  private resetApplication(): void {
    this.processingVolumesForm.controls['averageMonthlyTransactions'].setValue(
      this.merchantApp.business_legal_entity?.average_transactions_per_month,
    );

    this.processingVolumesForm.controls['averageMonthlyVolume'].setValue(
      this.merchantApp.business_legal_entity?.monthly_volume_amount
        ? this.merchantApp.business_legal_entity?.monthly_volume_amount / 100
        : null,
    );

    const cardPricing = this.merchantApp?.pricing_templates
      ? this.merchantApp.pricing_templates.find(
          (p) => p?.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.CARD,
        )
      : null;
    this.hasCard = cardPricing ? true : false;
    if (this.hasCard) {
      this.processingVolumesForm.controls['averageTransactionAmountCard'].setValue(
        this.merchantApp.business_legal_entity?.average_transaction_amount_card
          ? this.merchantApp.business_legal_entity?.average_transaction_amount_card / 100
          : null,
      );
    }

    const debitPricing = this.merchantApp?.pricing_templates
      ? this.merchantApp.pricing_templates.find(
          (p) =>
            p?.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.ACH_DEBIT ||
            p?.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.EFT_DEBIT,
        )
      : null;
    this.hasDebit = debitPricing ? true : false;
    if (this.hasDebit) {
      this.processingVolumesForm.controls['averageTransactionAmountDebit'].setValue(
        this.merchantApp.business_legal_entity?.average_transaction_amount_debit
          ? this.merchantApp.business_legal_entity?.average_transaction_amount_debit / 100
          : null,
      );
    }
  }

  scrollToTop(): void {
    const element = document.querySelector('.top-of-form');
    if (element) {
      element.scrollIntoView({ behavior: 'auto', block: 'end' });
    }
  }
}
