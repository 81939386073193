import { Injectable } from '@angular/core';

declare let Plaid: any;

@Injectable({
  providedIn: 'root',
})
export class PlaidLinkAppService {
  private loaded?: Promise<void>;
  public createPlaid(config: PlaidConfig): Promise<PlaidLinkHandler> {
    return this.loadPlaid().then(() => {
      return new PlaidLinkHandler(config);
    });
  }

  public loadPlaid(): Promise<void> {
    if (!this.loaded) {
      this.loaded = new Promise<void>((resolve, reject) => {
        const script: any = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';
        script.onerror = (e: any) => reject(e);
        if (script.readyState) {
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              resolve();
            }
          };
        } else {
          script.onload = () => {
            resolve();
          };
        }
        document.getElementsByTagName('body')[0].appendChild(script);
      });
    }

    return this.loaded;
  }
}

export interface PlaidConfig extends PlaidEventsConfig {
  token: string;
}

export interface PlaidEventsConfig {
  onEvent?: Function;
  onExit?: Function;
  onLoad?: Function;
  onSuccess: Function;
  receivedRedirectUri?: string;
}

export interface PlaidSuccessMetadata {
  link_session_id: string;
  institution?: PlaidInstitutionObject;
  account: PlaidAccountObject;
  accounts: Array<PlaidAccountObject>;
  account_id: string;
  public_token: string;
  transfer_status?: 'COMPLETE' | 'INCOMPLETE';
}

export interface PlaidOnSuccessArgs {
  token: string;
  metadata: PlaidSuccessMetadata;
}

export interface PlaidInstitutionObject {
  name: string;
  institution_id: string;
}

export interface PlaidAccountObject {
  id: string;
  name: string;
  mask?: string;
  type: string;
  subtype: string;
  verification_status?: string;
}

export interface PlaidErrorObject {
  display_message: string;
  error_code: string;
  error_message: string;
  error_type: string;
  request_id?: string;
}

export interface PlaidErrorMetadata {
  link_session_id: string;
  institution: PlaidInstitutionObject;
  status: string;
  request_id?: string;
}

export interface PlaidOnExitArgs {
  error: PlaidErrorObject;
  metadata: PlaidErrorMetadata;
}

export interface PlaidOnEventArgs {
  eventName: string;
  metadata: PlaidEventMetadata;
}

export interface PlaidEventMetadata {
  error_code: string | null;
  error_message: string | null;
  error_type: string | null;
  exit_status: string | null;
  institution_id: string | null;
  institution_name: string | null;
  institution_search_query: string | null;
  request_id: string;
  link_session_id: string;
  mfa_type: string | null;
  view_name: string;
  timestamp: string;
}

export class PlaidLinkHandler {
  /**
   * Holds the Plaid Link instance.
   */
  private plaidLink: any;

  /**
   * Constructor configures the Plaid Link handler with given config options.
   * @param PlaidCreateConfig config
   */
  constructor(config: PlaidConfig) {
    this.plaidLink = Plaid.create(config);
  }

  /**
   * Open the Plaid Link window for this handler.
   * @param string institution The name of the institution to open
   */
  public open(institution?: string): void {
    this.plaidLink.open(institution);
  }

  /**
   * Closes the currently open Plaid Link window if any.
   */
  public exit(options?: PlaidOnExitArgs): void {
    this.plaidLink.exit(options);
  }

  /**
   * Destroys Link Handler instance and removes all DOM artifacts.
   */
  public destroy(): void {
    this.plaidLink.destroy();
  }
}
