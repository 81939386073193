import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TilledParagraphGenericComponent } from './tilled-paragraph-generic.component';

@Component({
  selector: 'tilled-paragraph-p2',
  templateUrl: '../tilled-text-generic.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class TilledParagraphP2Component extends TilledParagraphGenericComponent {
  @Input() bold: boolean = false;
  @Input() italic: boolean = false;
  public styles: string;
  constructor() {
    super();
    this.styles = 'text-p2';
  }
}
