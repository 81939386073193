import { Injectable } from '@angular/core';
import { EMPTY, expand, Observable, Subject } from 'rxjs';
import {
  CreateFileRequestParams,
  DeleteFileRequestParams,
  FilesService,
  ListFilesRequestParams,
  ModelFile,
} from '../../../../projects/tilled-api-client/src';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class FilesAppService {
  constructor(private _filesService: FilesService, private _alertService: AlertService) {}

  private fileListBuilder: ModelFile[] = [];
  private _filesAll$ = new Subject<ModelFile[]>();
  public filesAll$: Observable<ModelFile[]> = this._filesAll$.asObservable();

  public createFile(params: CreateFileRequestParams): Observable<ModelFile> {
    return this._filesService.createFile(params);
  }

  public deleteFile(params: DeleteFileRequestParams): Observable<any> {
    return this._filesService.deleteFile(params);
  }

  // This will recursively call the list files endpoint until we have them all.
  public listAllFiles(accountId: string, purposes: any = null): void {
    this.fileListBuilder = [];
    const requestParams: ListFilesRequestParams = {
      tilledAccount: accountId,
      purposes: purposes,
      offset: 0,
      limit: 100,
    };

    const listFiles$ = this._filesService.listFiles(requestParams);
    listFiles$
      .pipe(
        expand((result) => {
          const hasMore = result.has_more;
          requestParams.offset = result.offset + result.limit;
          if (hasMore) {
            this.fileListBuilder.push(...result.items);
            return this._filesService.listFiles(requestParams);
          }
          this.fileListBuilder.push(...result.items);
          this._filesAll$.next(this.fileListBuilder);
          return EMPTY;
        }),
      )
      .subscribe();
  }
}
