<div class="flex flex-auto flex-col">
  <div class="flex justify-end pb-6" *ngIf="requestedDocsCount > 0">
    <tilled-button
      bgColor="tilled-primary"
      [rounded]="true"
      buttonText="Submit Requested Information"
      (buttonClick)="submitDocuments()"
      [disabled]="!('onboarding:write' | scopeAble)"
    ></tilled-button>
  </div>
  <div class="flex flex-col">
    <fuse-card class="tilled-card-styling h-fit w-full" #fuseCard>
      <div class="w-full">
        <div>
          <div class="pb-2">
            <tilled-heading-h4>Requested and Uploaded Documents</tilled-heading-h4>
          </div>
          <tilled-paragraph-p2 [secondary]="true"
            >All outstanding document requests and previously uploaded documents.</tilled-paragraph-p2
          >
          <mat-divider class="mb-6 mt-3 w-full"></mat-divider>
        </div>
        <app-account-documents-list [accountId]="accountId"></app-account-documents-list>
      </div>
    </fuse-card>
  </div>
</div>
