import { Pipe, PipeTransform } from '@angular/core';

/**
 * Takes string and capitalizes first letter. Adds a period at the end if there
 * is not one.
 */
@Pipe({
  name: 'snakeCaseSplit',
})
export class StringToSentencePipe implements PipeTransform {
  /**
   *
   * @param value string "this is great code"
   * @returns string "This is great code."
   * apiError[0].toUpperCase() + apiError.substring(1);
   */
  transform(value: string): any {
    // Trim off white space.
    let sanitized = value.trim();
    if (sanitized.length > 0) {
      // Capitalize first letter.
      sanitized = sanitized[0].toUpperCase() + sanitized.substring(1);
      // Add period to end of string if there isn't one.
      sanitized = sanitized[sanitized.length - 1] === '.' ? sanitized : sanitized.concat('.');
    }

    return sanitized;
  }
}
