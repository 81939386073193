import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Observable, map, startWith } from 'rxjs';
import { TilledLabelL1Component } from '../tilled-text/tilled-label/tilled-label-l1.component';
import { TilledParagraphP3Component } from '../tilled-text/tilled-paragraph/tilled-paragraph-p3.component';

// interface of the output of this component
interface MultiSelectedObject {
  name: string;
  desc: string;
}

@Component({
  selector: 'multi-select-search',
  templateUrl: './multi-select-search.component.html',
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: 'never' },
    },
  ],
  standalone: true,
  imports: [
    TilledLabelL1Component,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    TilledParagraphP3Component,
    CommonModule,
  ],
})
export class MultiSelectSearchComponent implements OnInit {
  @ViewChild('search') searchTextBox: ElementRef;

  @Input() selectFormControl: FormControl;
  @Input() labelText?: string;
  @Input() placeholderText?: string = 'Choose option(s)';
  @Input() initialValues$: Observable<Map<string, string>>;
  @Input() multiSelectOptions: Map<string, string>;
  @Input() emptySearchText: string = 'No results found';

  private selectedValues: MultiSelectedObject[] = [];
  private optionsArray: MultiSelectedObject[];
  public searchTextControl = new FormControl();
  public filteredOptions: Observable<MultiSelectedObject[]>;

  ngOnInit() {
    this.optionsArray = Array.from(this.multiSelectOptions, ([name, desc]) => ({ name, desc }));
    this.filteredOptions = this.searchTextControl.valueChanges.pipe(
      startWith<string>(''),
      map((name) => this._filter(name)),
    );

    this.initialValues$.subscribe({
      next: (values) => {
        if (values) {
          const mapValues = Array.from(values, ([name, desc]) => ({ name, desc }));
          this.selectFormControl.setValue(
            this.optionsArray.filter((item) => mapValues?.some((initialValue) => initialValue.name === item.name)),
          );
        }
      },
    });
  }

  private _filter(name: string): MultiSelectedObject[] {
    const filterValue = name ? name.toLowerCase() : '';
    // set selected values to retain the selected checkbox state
    this.setSelectedValues();
    this.selectFormControl.patchValue(this.selectedValues);
    let filteredList = this.optionsArray.filter((option) => option.desc.toLowerCase().includes(filterValue));
    return filteredList;
  }

  public selectionChange(event): void {
    if (event.isUserInput && event.source.selected == false) {
      let index = this.selectedValues.indexOf(event.source.value);
      this.selectedValues.splice(index, 1);
    }
  }

  public openedChange(e): void {
    //set search text value as empty while opening select
    this.searchTextControl.patchValue('');
    // focus to search text while clicking on select
    if (e === true) {
      this.searchTextBox.nativeElement.focus();
    }
  }

  public clearSearch(event): void {
    event.stopPropagation();
    this.searchTextControl.patchValue('');
  }

  public setSelectedValues(): void {
    if (this.selectFormControl.value && this.selectFormControl.value.length > 0) {
      this.selectFormControl.value.forEach((e) => {
        if (this.selectedValues.indexOf(e) === -1) {
          this.selectedValues.push(e);
        }
      });
    }
  }
}
