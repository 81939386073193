import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { TilledHeadingH5Component } from '../../tilled-text/tilled-heading/tilled-heading-h5.component';

@Component({
  selector: 'payment-card',
  templateUrl: './payment-cards.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatIconModule, TilledHeadingH5Component, MatRadioModule],
})
export class PaymentCardComponent implements OnInit, OnChanges {
  @Output() click: EventEmitter<string> = new EventEmitter<string>();

  @Input() text?: string;
  @Input() selected?: boolean = false;
  public styles: string = '';

  constructor() {}

  ngOnInit() {
    this.applyBorderStyle();
  }

  ngOnChanges() {
    this.applyBorderStyle();
  }

  applyBorderStyle() {
    this.styles = this.selected ? 'accent-border' : 'grey-border';
  }

  onSelected() {
    this.click.emit(this.text);
  }
}
