<div class="flex">
  <div class="{{ styles1 }}    hover:bg-tilled-neutral-200" *ngIf="stepNumber === 1" (click)="onClick(1)">
    <tilled-paragraph-p3>1</tilled-paragraph-p3>
  </div>
  <div *ngIf="stepNumber !== 1" class="scale-[1.7] px-[5px] pt-[8px] text-tilled-accent">
    <mat-icon
      class="icon-color rounded-3xl hover:border hover:border-tilled-secondary"
      svgIcon="mat_solid:check_circle"
      (click)="onClick(1)"
    ></mat-icon>
  </div>
  <div class="mt-1.5 mr-1 ml-2">
    <tilled-paragraph-p3>{{ text1 }}</tilled-paragraph-p3>
  </div>

  <div class="line-container"><hr class="{{ line1 }}" /></div>
  <div class="{{ styles2 }} hover:bg-tilled-neutral-200" *ngIf="stepNumber !== 3" (click)="onClick(2)">
    <tilled-paragraph-p3>2</tilled-paragraph-p3>
  </div>
  <div *ngIf="stepNumber === 3" class="scale-[1.7] px-[5px] pt-[8px] text-tilled-accent">
    <mat-icon
      class="icon-color rounded-3xl hover:border hover:border-tilled-secondary"
      svgIcon="mat_solid:check_circle"
      (click)="onClick(2)"
    ></mat-icon>
  </div>
  <div class="mt-1.5 mr-1 ml-2">
    <tilled-paragraph-p3>{{ text2 }}</tilled-paragraph-p3>
  </div>
  <div class="line-container"><hr class="{{ line2 }}" /></div>
  <div class="{{ styles3 }} hover:bg-tilled-neutral-200" (click)="onClick(3)">
    <tilled-paragraph-p3>3</tilled-paragraph-p3>
  </div>
  <div class="mt-1.5 mr-1 ml-2">
    <tilled-paragraph-p3>{{ text3 }}</tilled-paragraph-p3>
  </div>
</div>
