/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from './address';
import { BusinessRepresentative } from './businessRepresentative';


export interface BusinessProfile { 
    /**
     * The address for this business.
     */
    address: Address;
    /**
     * MCC category.
     */
    category: BusinessProfile.CategoryEnum;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    id?: string;
    /**
     * The legal name for the business.
     */
    legal_name: string;
    /**
     * The locale for the business.
     */
    locale: BusinessProfile.LocaleEnum;
    /**
     * The business\'s phone number.
     */
    phone: string;
    /**
     * The region where the business is located.
     */
    region: BusinessProfile.RegionEnum;
    /**
     * A list of Representatives included in the onboarding application.
     */
    representatives: Array<BusinessRepresentative>;
    /**
     * The structure of the business (LLC, Corp, etc.).
     */
    structure: BusinessProfile.StructureEnum;
    /**
     * Tax identification number.
     */
    tax_identification_number: string;
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
    /**
     * The website of the business.
     */
    website?: string;
}
export namespace BusinessProfile {
    export type CategoryEnum = 'ACCT' | 'ART' | 'BEAUTY' | 'CATERING' | 'CHARITY' | 'CLEANING' | 'CONSULTANT' | 'CONTRACTOR' | 'DENTIST' | 'EDU' | 'FOOD' | 'LANDSCAPING' | 'LEGAL' | 'MEDICAL_PRACT' | 'MEDICAL_SERV' | 'MEMBERSHIP' | 'MISC_FOOD_STORES' | 'MISC_MERCH' | 'MISC_SERV' | 'MUSIC' | 'PC' | 'PHOTO_FILM' | 'PROF_SERV' | 'REAL_ESTATE' | 'RECREATION' | 'REPAIR' | 'RESTO' | 'RETAIL' | 'TAXI' | 'UTILITY' | 'VET' | 'WEB_DEV' | 'WEB_HOSTING' | 'OTHER';
    export const CategoryEnum = {
        ACCT: 'ACCT' as CategoryEnum,
        ART: 'ART' as CategoryEnum,
        BEAUTY: 'BEAUTY' as CategoryEnum,
        CATERING: 'CATERING' as CategoryEnum,
        CHARITY: 'CHARITY' as CategoryEnum,
        CLEANING: 'CLEANING' as CategoryEnum,
        CONSULTANT: 'CONSULTANT' as CategoryEnum,
        CONTRACTOR: 'CONTRACTOR' as CategoryEnum,
        DENTIST: 'DENTIST' as CategoryEnum,
        EDU: 'EDU' as CategoryEnum,
        FOOD: 'FOOD' as CategoryEnum,
        LANDSCAPING: 'LANDSCAPING' as CategoryEnum,
        LEGAL: 'LEGAL' as CategoryEnum,
        MEDICAL_PRACT: 'MEDICAL_PRACT' as CategoryEnum,
        MEDICAL_SERV: 'MEDICAL_SERV' as CategoryEnum,
        MEMBERSHIP: 'MEMBERSHIP' as CategoryEnum,
        MISC_FOOD_STORES: 'MISC_FOOD_STORES' as CategoryEnum,
        MISC_MERCH: 'MISC_MERCH' as CategoryEnum,
        MISC_SERV: 'MISC_SERV' as CategoryEnum,
        MUSIC: 'MUSIC' as CategoryEnum,
        PC: 'PC' as CategoryEnum,
        PHOTO_FILM: 'PHOTO_FILM' as CategoryEnum,
        PROF_SERV: 'PROF_SERV' as CategoryEnum,
        REAL_ESTATE: 'REAL_ESTATE' as CategoryEnum,
        RECREATION: 'RECREATION' as CategoryEnum,
        REPAIR: 'REPAIR' as CategoryEnum,
        RESTO: 'RESTO' as CategoryEnum,
        RETAIL: 'RETAIL' as CategoryEnum,
        TAXI: 'TAXI' as CategoryEnum,
        UTILITY: 'UTILITY' as CategoryEnum,
        VET: 'VET' as CategoryEnum,
        WEB_DEV: 'WEB_DEV' as CategoryEnum,
        WEB_HOSTING: 'WEB_HOSTING' as CategoryEnum,
        OTHER: 'OTHER' as CategoryEnum
    };
    export type LocaleEnum = 'en_US' | 'en_CA' | 'fr_CA' | 'en_GB';
    export const LocaleEnum = {
        EN_US: 'en_US' as LocaleEnum,
        EN_CA: 'en_CA' as LocaleEnum,
        FR_CA: 'fr_CA' as LocaleEnum,
        EN_GB: 'en_GB' as LocaleEnum
    };
    export type RegionEnum = 'US' | 'CA';
    export const RegionEnum = {
        US: 'US' as RegionEnum,
        CA: 'CA' as RegionEnum
    };
    export type StructureEnum = 'CHARITY' | 'CIC' | 'CORP' | 'LTD' | 'LLC' | 'LLP' | 'NPCORP' | 'PARTNERSHIP' | 'PLC' | 'GOV' | 'SOLEPROP' | 'TRUST';
    export const StructureEnum = {
        CHARITY: 'CHARITY' as StructureEnum,
        CIC: 'CIC' as StructureEnum,
        CORP: 'CORP' as StructureEnum,
        LTD: 'LTD' as StructureEnum,
        LLC: 'LLC' as StructureEnum,
        LLP: 'LLP' as StructureEnum,
        NPCORP: 'NPCORP' as StructureEnum,
        PARTNERSHIP: 'PARTNERSHIP' as StructureEnum,
        PLC: 'PLC' as StructureEnum,
        GOV: 'GOV' as StructureEnum,
        SOLEPROP: 'SOLEPROP' as StructureEnum,
        TRUST: 'TRUST' as StructureEnum
    };
}


