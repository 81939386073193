import { NgModule } from '@angular/core';
import { DefaultValueAccessor } from '@angular/forms';
import { ExtraOptions, PreloadAllModules } from '@angular/router';
import { BrandingService } from './core/services/branding.service';
import { SplashScreenService } from './modules/splash-screen/splash-screen.service';

export const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
};

export function initTailwindThemeConfig(brandingService: BrandingService) {
  return () => {
    // This is a hack to ensure that branding is absolutely set before the app starts
    brandingService
      .setBranding()
      .then(() => setTimeout(() => SplashScreenService.hideSplash(), 100))
      .catch((e) => SplashScreenService.hideSplash()); // If branding fails, hide splash screen anyway
  };
}

@NgModule(/* TODO(standalone-migration): clean up removed NgModule class manually. 
{
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, routerConfig),
    IndexedDBModule.forRoot(dbConfig),

    // Fuse, FuseConfig & FuseMockAPI
    FuseModule,
    FuseConfigModule.forRoot(appConfig),

    // Core module of your application
    CoreModule,

    // Layout module of your application
    LayoutModule,

    // 3rd party modules that require global configuration via forRoot
    MarkdownModule.forRoot({}),

    // Tilled API Client
    ApiModule.forRoot(
      () =>
        new Configuration({
          basePath: environment.api,
          // Note: "JWT" is the securitySchemes name in falcon's spec3 file
          credentials: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            JWT: (): string => {
              const token = AuthService.getAccessToken();
              return token;
            },
          },
          //accessToken: (): string => AuthService.getAccessToken(),
        }),
    ),
  ],
  providers: [
    httpInterceptorProviders,
    // This calls the branding service faster than AppComponent ngOnInit
    BrandingService,
    {
      provide: APP_INITIALIZER,
      useFactory: initTailwindThemeConfig,
      deps: [BrandingService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
} */)
export class AppModule {
  constructor() {
    // This fixes all "empty" fields to return null instead of empty string.
    DefaultValueAccessor.prototype.registerOnChange = function (fn: (_: string | null) => void): void {
      this.onChange = (value: string | null): void => {
        fn(value === '' ? null : value);
      };
    };
  }
}
