import { CommonModule, NgClass, TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrandingService } from 'app/core/services/branding.service';
import { TilledButtonB2Component } from '../tilled-text/tilled-button/tilled-button-b2.component';

@Component({
  selector: 'tilled-button',
  templateUrl: './tilled-button.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatTooltipModule, MatButtonModule, NgClass, TilledButtonB2Component, MatIconModule, CommonModule],
})
export class TilledButtonComponent implements OnInit, OnChanges {
  @Input() buttonText?: string;
  @Input() rounded?: boolean = true;
  @Input() styles?: string;
  @Input() bgColor?: string;
  @Input() bgOpacity?: string = '100';
  @Input() textColor?: string = 'white';
  @Input() whiteText?: boolean = true;
  @Input() disabled?: boolean = false;
  @Input() secondary?: boolean = false;
  @Input() disabledTooltip?: string;
  @Input() outlineColor?: string;
  // prefix and suffix icon inputs expect a format of '[namespace]:[name]' (i.e. 'mat_outline:add')
  @Input() prefixIcon?: string;
  @Input() suffixIcon?: string;
  @Output() buttonClick: EventEmitter<string> = new EventEmitter<string>();

  public displayWhite: boolean;
  public backgroundColor: string;
  public textDisplayColor: string;
  private isWhiteLabel: boolean = false;

  constructor(private _brandingService: BrandingService) {
    this._brandingService.isWhiteLabel$.subscribe((res) => {
      this.isWhiteLabel = res;
    });
  }

  ngOnInit() {
    this.checkDisabled();
    this.buttonText = this.buttonTitlecase(this.buttonText);
  }

  ngOnChanges(): void {
    this.checkDisabled();
    if (!this.bgColor?.includes('transparent') || !this.bgColor?.includes('white')) {
      this.displayWhite = true;
    }
    // Swaps primary color for accent color if white label ENG-1737
    if (this.isWhiteLabel) {
      if (this.bgColor === 'tilled-primary') {
        this.bgColor = 'primary';
      }
      if (this.outlineColor === 'tilled-primary') {
        this.outlineColor = 'primary';
      }
    }
  }

  checkDisabled() {
    if (this.disabled) {
      this.displayWhite = false;
      if (this.bgColor?.includes('transparent') || this.bgColor?.includes('white')) {
        this.outlineColor = 'neutral-300';
        this.backgroundColor = 'transparent';
      } else {
        this.backgroundColor = 'neutral-300';
      }
    } else {
      this.displayWhite = this.whiteText;
      this.backgroundColor = this.bgColor;
      this.textDisplayColor = this.textColor;
    }
  }

  onButtonClick() {
    this.buttonClick.emit(this.buttonText);
  }

  // Handles buttonText that may contain `URL`, `API`, or `ID` (i.e. `Copy URL`, `Copy API Key To Clipboard`, etc.). Automatically applied to all buttonText via ngOnInit.

  buttonTitlecase(val: string): string {
    return val
      .split(' ')
      .map((word) => (/\b(URL|API|ID)\b/i.test(word) ? word.toUpperCase() : new TitleCasePipe().transform(word)))
      .join(' ');
  }
}
