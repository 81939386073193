import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[allowOnlyNumbers]',
    standalone: true,
})
export class AllowOnlyNumbersDirective {
  private navigationKeys = ['Delete', 'Backspace', 'Tab', 'Escape', 'Enter', 'Home', 'End', 'ArrowLeft', 'ArrowRight'];

  @Input() allowOnlyNumbers: boolean = true;
  @Input() allowDecimals: boolean = true;

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent): void {
    if (this.allowOnlyNumbers || this.navigationKeys.includes(e.key)) {
      if (
        this.navigationKeys.includes(e.key) ||
        (e.key === 'a' && e.ctrlKey === true) ||
        (e.key === 'c' && e.ctrlKey === true) ||
        (e.key === 'v' && e.ctrlKey === true) ||
        (e.key === 'x' && e.ctrlKey === true) ||
        (e.key === 'a' && e.metaKey === true) ||
        (e.key === 'c' && e.metaKey === true) ||
        (e.key === 'v' && e.metaKey === true) ||
        (e.key === 'x' && e.metaKey === true) ||
        (this.allowDecimals && e.key === '.' && this.el.nativeElement.value.indexOf('.') === -1)
      ) {
        return;
      }

      // Ensure that it is a number or period and stop the keypress
      if (e.key === ' ' || isNaN(Number(e.key)) || (!this.allowDecimals && e.key === '.')) {
        e.preventDefault();
      }
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    if (this.allowOnlyNumbers) {
      event.preventDefault();
      const pastedInput: string = event.clipboardData
        .getData('text/plain')
        .replace(this.allowDecimals ? /[^\d.]/g : /\D/g, ''); // Allow only numbers and periods if allowDecimals is true
      this.insertText(pastedInput);
    }
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent): void {
    if (this.allowOnlyNumbers) {
      event.preventDefault();
      const textData = event.dataTransfer
        .getData('text').replace(this.allowDecimals ? /[^\d.]/g : /\D/g, '');
    }
  }

  private insertText(text: string): void {
    const currentCursorPosition = this.el.nativeElement.selectionStart;
    const currentValue: string = this.el.nativeElement.value;
    const newValue =
      currentValue.substring(0, currentCursorPosition) +
      text +
      currentValue.substring(currentCursorPosition);
    this.el.nativeElement.value = newValue;
  }
}
