import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ComponentBase } from 'app/core/componentBase';
import { debounceTime, startWith } from 'rxjs';

@Component({
  selector: 'tilled-search',
  templateUrl: './tilled-search.component.html',
  styleUrls: ['./tilled-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatButtonModule,
    CommonModule,
  ],
})
export class TilledSearchComponent extends ComponentBase implements OnInit {
  @Input() controlName: FormControl;
  @Input() placeholder?: string;
  @Input() toolTip?: string;
  @Input() styles?: string = '';

  public showClear: boolean;
  constructor() {
    super();
  }

  ngOnInit() {
    this.controlName.valueChanges.pipe(startWith(this.controlName.value)).subscribe((q) => {
      if (q && q.length > 0) {
        this.showClear = true;
      }
    });
    this.controlName.valueChanges.pipe(debounceTime(600)).subscribe((q) => {
      this.controlName.patchValue(q?.trim());
    });
  }

  clearSearchText() {
    this.showClear = false;
    this.controlName.patchValue(null);
  }
}
