/* eslint-disable @typescript-eslint/naming-convention */
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertService } from '@fuse/components/alert';
import { Charge, InternalService, PaymentIntent } from '@tilled-api-client';
import { ComponentBase } from 'app/core/componentBase';
import { MinorUnitsToCurrencyPipe } from 'app/core/pipes/minor-units-to-currency.pipe';
import { NumericToMinorUnitsPipe } from 'app/core/pipes/numeric-to-minor-units.pipe';
import { AuthService } from 'app/core/services/auth.service';
import { PaymentAppService } from 'app/core/services/payments.app.service';
import { TilledInputComponent } from 'app/shared/form-fields/tilled-input/tilled-input.component';
import { Subject, takeUntil } from 'rxjs';
import { FuseAlertComponent } from '../../../../@fuse/components/alert/alert.component';
import { TilledButtonComponent } from '../../buttons/tilled-button.component';
import { OutlineInputComponent } from '../../outline-input/outline-input.component';
import { TilledHeadingH2Component } from '../../tilled-text/tilled-heading/tilled-heading-h2.component';
import { TilledParagraphP3Component } from '../../tilled-text/tilled-paragraph/tilled-paragraph-p3.component';

@Component({
  selector: 'app-capture-payment-dialog',
  templateUrl: './capture-payment-dialog.component.html',
  styleUrls: ['./capture-payment-dialog.component.scss'],
  animations: fuseAnimations,
  standalone: true,
  imports: [
    TilledHeadingH2Component,
    TilledParagraphP3Component,
    FormsModule,
    ReactiveFormsModule,
    OutlineInputComponent,
    FuseAlertComponent,
    TilledButtonComponent,
    MinorUnitsToCurrencyPipe,
    CommonModule,
    TilledInputComponent,
  ],
})
export class CapturePaymentDialogComponent extends ComponentBase implements OnInit {
  public captureForm: FormGroup;
  public payment: PaymentIntent;
  public maxCapture: number;
  public successfulCharge: Charge;
  public isMerchant: boolean;

  public alertType: string;
  public alertTitle: string;
  public alertMessage: string;
  public alertDismissible: boolean;

  private accountId: string;

  private _displayAlert$ = new Subject<boolean>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public displayAlert$ = this._displayAlert$.asObservable();

  constructor(
    public matDialogRef: MatDialogRef<CapturePaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
    private _authService: AuthService,
    private _paymentAppService: PaymentAppService,
    private _numericToMinorPipe: NumericToMinorUnitsPipe,
    private _fuseAlertService: FuseAlertService,
    private _internalService: InternalService,
  ) {
    super();
    // Determine if this is a merchant or partner logged in
    this._authService.isMerchantAccount$.pipe(takeUntil(this._unsubscribeAll)).subscribe((isMerchant) => {
      this.isMerchant = isMerchant;
    });
    this.accountId = this._data.accountId;
    this.payment = this._data.payment;
    this.maxCapture = this._data.payment.amount_capturable;
  }

  async ngOnInit(): Promise<void> {
    this.captureForm = this._formBuilder.group({
      amount: new FormControl<number | null>(null, [
        Validators.required,
        Validators.max(this.payment.amount_capturable / 100),
        Validators.min(0.01),
      ]),
    });
  }

  public async submitCaptureClicked(): Promise<void> {
    this._paymentAppService.payment$.pipe(takeUntil(this._unsubscribeAll)).subscribe((payment) => {
      this.closeDialog(payment.id);
    });

    await this._paymentAppService.capturePayment({
      id: this.payment.id,
      tilledAccount: this.accountId,
      paymentIntentCaptureParams: {
        amount_to_capture: this._numericToMinorPipe.transform(this.captureForm.get('amount').value),
      },
    });
  }

  public closeDialog(dialogResult?: any): void {
    this.matDialogRef.close(dialogResult);
  }
}
