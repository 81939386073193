import { APP_INITIALIZER, ApplicationConfig, enableProdMode, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { provideFuse } from '@fuse';
import { environment } from 'environments/environment';
import { MarkdownModule } from 'ngx-markdown';
import { ApiModule, Configuration } from '../../projects/tilled-api-client/src';
import { initTailwindThemeConfig, routerConfig } from './app.module';
import { appRoutes } from './app.routes';
import { CoreModule } from './core/core.module';
import { dbConfig } from './core/db/indexed-db/indexed-db.config';
import { IndexedDBModule } from './core/db/indexed-db/indexed-db.module';
import { httpInterceptorProviders } from './core/interceptors';
import { AuthService } from './core/services/auth.service';
import { BrandingService } from './core/services/branding.service';
import { LayoutModule } from './layout/layout.module';

if (environment.production) {
  enableProdMode();
}

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserModule,
      RouterModule.forRoot(appRoutes, routerConfig),
      IndexedDBModule.forRoot(dbConfig),
      // Core module of your application
      CoreModule,
      // Layout module of your application
      LayoutModule,
      // 3rd party modules that require global configuration via forRoot
      MarkdownModule.forRoot({}),
      // Tilled API Client
      ApiModule.forRoot(
        () =>
          new Configuration({
            basePath: environment.api,
            // Note: "JWT" is the securitySchemes name in falcon's spec3 file
            credentials: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              JWT: (): string => {
                const token = AuthService.getAccessToken();
                return token;
              },
            },
            //accessToken: (): string => AuthService.getAccessToken(),
          }),
      ),
    ),
    httpInterceptorProviders,
    // This calls the branding service faster than AppComponent ngOnInit
    BrandingService,
    {
      provide: APP_INITIALIZER,
      useFactory: initTailwindThemeConfig,
      deps: [BrandingService],
      multi: true,
    },
    provideAnimations(),
    // Fuse
    provideFuse({
      fuse: {
        layout: 'futuristic',
        scheme: 'light',
        screens: {
          sm: '600px',
          md: '960px',
          lg: '1280px',
          xl: '1440px',
        },
        theme: 'theme-default',
        themes: [
          {
            id: 'theme-default',
            name: 'Default',
          },
          {
            id: 'theme-brand',
            name: 'Brand',
          },
          {
            id: 'theme-teal',
            name: 'Teal',
          },
          {
            id: 'theme-rose',
            name: 'Rose',
          },
          {
            id: 'theme-purple',
            name: 'Purple',
          },
          {
            id: 'theme-amber',
            name: 'Amber',
          },
        ],
      },
    }),
  ],
};
