import { Pipe, PipeTransform } from '@angular/core';
import { ChipColorClass, TilledChipConfig } from 'app/shared/tilled-chip/tilled-chip.component';
import { InternalTerminalReader } from '../../../../projects/tilled-api-client/src';

/**
 * Takes a TerminalReader StatusEnum
 */
@Pipe({
    name: 'terminalReaderStatus',
    standalone: true,
})
export class TerminalReaderStatusPipe implements PipeTransform {
  /**
   *
   * @param terminalReader TerminalReader
   * @returns
   */
  transform(terminalReader: InternalTerminalReader): TilledChipConfig {
    const chipConfig: TilledChipConfig = {
      color: ChipColorClass.OPAQUE_ORANGE,
      text: terminalReader.status.toUpperCase(),
      toolTip: '',
    };
    if (terminalReader.status === InternalTerminalReader.StatusEnum.ACTIVE) {
      chipConfig.color = ChipColorClass.OPAQUE_GREEN;
    } else if (terminalReader.status === InternalTerminalReader.StatusEnum.DISABLED) {
      chipConfig.color = ChipColorClass.OPAQUE_RED;
    } else {
      chipConfig.color = ChipColorClass.OPAQUE_ORANGE;
    }

    return chipConfig;
  }
}
