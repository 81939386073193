import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormCardComponent } from 'app/shared/cards/form-cards/form-card.component';
import { TerminalReader } from '../../../../../projects/tilled-api-client/src/model/terminalReader';
import { TilledButtonComponent } from '../../../shared/buttons/tilled-button.component';
import { TilledInputComponent } from '../../../shared/form-fields/tilled-input/tilled-input.component';
import { TilledHeadingH2Component } from '../../../shared/tilled-text/tilled-heading/tilled-heading-h2.component';
import { TilledParagraphP3Component } from '../../../shared/tilled-text/tilled-paragraph/tilled-paragraph-p3.component';
import { HawkeyeTerminalUpdateParams } from '../terminal-list/terminal-reader-list.component';

@Component({
  selector: 'app-terminal-reader-update-dialog',
  templateUrl: './terminal-reader-update-dialog.component.html',
  standalone: true,
  imports: [
    MatIconModule,
    TilledHeadingH2Component,
    FormsModule,
    FormCardComponent,
    ReactiveFormsModule,
    TilledInputComponent,
    MatSlideToggleModule,
    TilledParagraphP3Component,
    TilledButtonComponent,
  ],
})
export class TerminalReaderUpdateDialogComponent implements OnInit {
  public description: string;
  public serialNumber: string;
  private currentReader: TerminalReader;
  public updateTerminalReaderForm: FormGroup;

  constructor(
    private _dialogRef: MatDialogRef<TerminalReaderUpdateDialogComponent>,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private _data: any,
  ) {
    this.currentReader = this._data.terminalReader;
  }

  ngOnInit(): void {
    this.createForm();
  }

  private createForm() {
    this.updateTerminalReaderForm = this._formBuilder.group({
      serialNumber: new FormControl<string | null>(this.currentReader.serial_number || null),
      description: new FormControl<string | null>(this.currentReader.description || null),
      tipEnabledSetting: new FormControl<boolean>(false),
      printReceiptSetting: new FormControl<boolean>(false),
    });
    this.updateTerminalReaderForm.patchValue({ tipEnabledSetting: this.currentReader?.settings?.tipping_enabled });
    this.updateTerminalReaderForm.patchValue({ printReceiptSetting: this.currentReader?.settings?.print_receipt });
  }

  public updateTerminalClicked(): void {
    let response: HawkeyeTerminalUpdateParams = {
      accountId: this.currentReader.account_id,
      params: {
        serial_number: this.updateTerminalReaderForm.value.serialNumber,
        description: this.updateTerminalReaderForm.value.description,
        settings: {
          tipping_enabled: this.updateTerminalReaderForm.value.tipEnabledSetting,
          print_receipt: this.updateTerminalReaderForm.value.printReceiptSetting,
        },
      },
    };

    this._dialogRef.close(response);
  }

  public closeDialog(): void {
    this._dialogRef.close();
  }
}
