/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CardChargeFeeTemplateCreateParams } from './cardChargeFeeTemplateCreateParams';
import { DebitFeeTemplateCreateParams } from './debitFeeTemplateCreateParams';


export interface PricingTemplateCreateParams { 
    /**
     * The account monthly fee configured for this pricing template, in currency minor units.
     */
    account_monthly_fee: number;
    /**
     * The account monthly minimum fee configured for this pricing template, in currency minor units.
     */
    account_monthly_minimum_fee: number;
    /**
     * The configuration of the card specific fee schedules. Required when the payment method type is `card`.
     */
    card?: CardChargeFeeTemplateCreateParams;
    /**
     * The currency this pricing template applies to.
     */
    currency: PricingTemplateCreateParams.CurrencyEnum;
    /**
     * The configuration of this debit specific fee schedule. Required when the payment method type is `ach_debit` or `eft_debit`.
     */
    debit?: DebitFeeTemplateCreateParams;
    /**
     * The name of this pricing template.
     */
    name: string;
    /**
     * The payment method type this pricing template applies to.
     */
    payment_method_type: PricingTemplateCreateParams.PaymentMethodTypeEnum;
}
export namespace PricingTemplateCreateParams {
    export type CurrencyEnum = 'aud' | 'cad' | 'dkk' | 'eur' | 'hkd' | 'jpy' | 'nzd' | 'nok' | 'gbp' | 'zar' | 'sek' | 'chf' | 'usd';
    export const CurrencyEnum = {
        AUD: 'aud' as CurrencyEnum,
        CAD: 'cad' as CurrencyEnum,
        DKK: 'dkk' as CurrencyEnum,
        EUR: 'eur' as CurrencyEnum,
        HKD: 'hkd' as CurrencyEnum,
        JPY: 'jpy' as CurrencyEnum,
        NZD: 'nzd' as CurrencyEnum,
        NOK: 'nok' as CurrencyEnum,
        GBP: 'gbp' as CurrencyEnum,
        ZAR: 'zar' as CurrencyEnum,
        SEK: 'sek' as CurrencyEnum,
        CHF: 'chf' as CurrencyEnum,
        USD: 'usd' as CurrencyEnum
    };
    export type PaymentMethodTypeEnum = 'card' | 'ach_debit' | 'card_present' | 'eft_debit';
    export const PaymentMethodTypeEnum = {
        CARD: 'card' as PaymentMethodTypeEnum,
        ACH_DEBIT: 'ach_debit' as PaymentMethodTypeEnum,
        CARD_PRESENT: 'card_present' as PaymentMethodTypeEnum,
        EFT_DEBIT: 'eft_debit' as PaymentMethodTypeEnum
    };
}


