<div class="top-of-form"></div>
<merchant-app-card
  [stepNumber]="!forConsole ? this.stepNumber + 1 : null"
  [title]="!forConsole ? 'Business Details' : null"
  [description]="!forConsole ? 'Tell us about your business' : null"
  [backButton]="false"
  (continueClick)="onContinueClicked($event)"
  [continueButton]="!forConsole && !(disabled$ | async)"
>
  <form data-test-id="details-step" class="w-full" [formGroup]="businessDetailsForm">
    <tilled-input
      data-test-id="details-legal-name"
      class="w-full"
      name="legalName"
      placeholder="Full Legal Business Name"
      label="LEGAL BUSINESS NAME"
      tilledLabel="true"
    ></tilled-input>
    <tilled-input
      data-test-id="details-dba"
      class="w-full"
      name="dba"
      placeholder="Business “DBA” or Trading Name"
      label="DOING BUSINESS AS"
      tilledLabel="true"
      toolTip="The business name you present to customers"
    ></tilled-input>
    <div class="col-span-1 flex w-full flex-col">
      <div class="w-full pb-2.5 pt-4">
        <tilled-label-l1 class="w-full" secondary="true">Business entity type</tilled-label-l1>
      </div>
      <tilled-select
        classOverride="w-full"
        data-test-id="details-entity-type"
        [multiple]="false"
        [options]="entityTypesOptions"
        [controlName]="businessDetailsForm.get('type')"
        [placeholder]="'Choose One'"
        [errors]="businessDetailsForm.get('type').hasError('required') && businessDetailsForm.get('type').touched"
        errorMessage="A business entity type is required"
        toolTip="Your legal business structure as registered for tax filing"
      ></tilled-select>
    </div>
    <tilled-input
      data-test-id="details-business-identifier"
      class="w-full"
      name="businessIdentifier"
      placeholder="Tax ID #, Canadian Business #, Social Security # or EIN"
      label="BUSINESS IDENTIFIER"
      tilledLabel="true"
      mask="000000000"
      toolTip="ie: Tax ID#, Canadian Business #, SSN # or EIN"
      [errors]="
        businessDetailsForm.get('businessIdentifier').invalid && businessDetailsForm.get('businessIdentifier').touched
      "
      errorMessage="Business Identifier must be 9 digits."
    ></tilled-input>
    <div class="grid w-full grid-cols-1 gap-x-6 sm:grid-cols-2">
      <div class="col-span-1 flex flex-col">
        <div class="w-full pb-2.5 pt-4">
          <tilled-label-l1 secondary="true">Country of incorporation</tilled-label-l1>
        </div>
        <tilled-select
          classOverride="w-full"
          data-test-id="details-country-inc"
          [multiple]="false"
          [options]="validCountriesOfInc"
          [controlName]="businessDetailsForm.get('countryOfInc')"
          [placeholder]="'Choose One'"
          [errors]="
            businessDetailsForm.get('countryOfInc').hasError('required') &&
            businessDetailsForm.get('countryOfInc').touched
          "
          errorMessage="A country of incorporation is required"
        ></tilled-select>
      </div>
      <div class="col-span-1 flex flex-col">
        <tilled-input
          data-test-id="details-years-inc"
          class="w-full"
          name="yearOfInc"
          placeholder="Year of incorporation"
          label="Year of incorporation"
          tilledLabel="true"
          mask="0000"
          [errors]="
            businessDetailsForm.get('yearOfInc').hasError('min') || businessDetailsForm.get('yearOfInc').hasError('max')
          "
          errorMessage="Must be a valid year."
        ></tilled-input>
      </div>
    </div>
    <div class="col-span-1 flex flex-col">
      <div class="w-full pb-2.5 pt-4">
        <tilled-label-l1 secondary="true">Industry</tilled-label-l1>
      </div>
      <tilled-select
        classOverride="w-full"
        data-test-id="details-business-category"
        [multiple]="false"
        [options]="codesOptions"
        [controlName]="businessDetailsForm.get('category')"
        [placeholder]="'Choose One'"
        [errors]="
          businessDetailsForm.get('category').hasError('required') && businessDetailsForm.get('category').touched
        "
        errorMessage="A business category is required"
      ></tilled-select>
    </div>
    <tilled-input
      data-test-id="details-statement-descriptor"
      class="w-full pb-2.5"
      name="statementDescriptor"
      placeholder="Statement Descriptor"
      label="What name do you want to appear on customer credit card statements?"
      maxLength="20"
      tilledLabel="true"
    ></tilled-input>
    <div>
      <div class="w-full pb-2.5 pt-4">
        <tilled-label-l1 secondary="true">Describe the product and services you offer</tilled-label-l1>
      </div>
      <mat-form-field appearance="fill" class="w-full">
        <textarea
          class="text-field"
          data-test-id="details-description"
          [formControlName]="'description'"
          matInput
          maxLength="240"
          cdkTextareaAutosize
          cdkAutosizeMinRows="3"
          cdkAutosizeMaxRows="5"
          placeholder="Describe product and services you offer in 240 characters or less"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
</merchant-app-card>
