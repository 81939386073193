/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DisputeStatusHistory } from './disputeStatusHistory';
import { DisputeEvidence } from './disputeEvidence';
import { BalanceTransaction } from './balanceTransaction';


export interface Dispute { 
    /**
     * The ID of the Account associated with this dispute.
     */
    account_id: string;
    /**
     * Amount of the charge that is in dispute in the smallest currency unit (e.g., 100 cents to charge $1.00 or 100 to charge ¥100, a zero-decimal currency). If the dispute is type `chargeback` the amount has already been deducted from your account balance and can only be restored by submitting evidence proving the validity of the charge.
     */
    amount: number;
    /**
     * The balance transactions that describes the impact of this dispute on your account balance.
     */
    balance_transactions: Array<BalanceTransaction>;
    /**
     * The ID of the Charge associated with this dispute.
     */
    charge_id: string;
    /**
     * Time at which the supporting evidence is due and the dispute will be closed with no response.
     */
    closing_at?: string;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * Three-letter [ISO currency code](https://www.iso.org/iso-4217-currency-codes.html), in lowercase.
     */
    currency: Dispute.CurrencyEnum;
    /**
     * Evidence submitted to challenge the dispute.
     */
    evidence?: DisputeEvidence;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * Description explaining the reason for a dispute
     */
    reason_description?: string;
    /**
     * Time at which the dispute was reversed.
     */
    reversed_at?: string;
    /**
     * Current status of the dispute.
     */
    status: Dispute.StatusEnum;
    /**
     * Status history of the dispute.
     */
    status_history: Array<DisputeStatusHistory>;
    /**
     * Time at which the object was updated.
     */
    updated_at: string;
}
export namespace Dispute {
    export type CurrencyEnum = 'aud' | 'cad' | 'dkk' | 'eur' | 'hkd' | 'jpy' | 'nzd' | 'nok' | 'gbp' | 'zar' | 'sek' | 'chf' | 'usd';
    export const CurrencyEnum = {
        AUD: 'aud' as CurrencyEnum,
        CAD: 'cad' as CurrencyEnum,
        DKK: 'dkk' as CurrencyEnum,
        EUR: 'eur' as CurrencyEnum,
        HKD: 'hkd' as CurrencyEnum,
        JPY: 'jpy' as CurrencyEnum,
        NZD: 'nzd' as CurrencyEnum,
        NOK: 'nok' as CurrencyEnum,
        GBP: 'gbp' as CurrencyEnum,
        ZAR: 'zar' as CurrencyEnum,
        SEK: 'sek' as CurrencyEnum,
        CHF: 'chf' as CurrencyEnum,
        USD: 'usd' as CurrencyEnum
    };
    export type StatusEnum = 'warning_needs_response' | 'warning_under_review' | 'warning_closed' | 'needs_response' | 'under_review' | 'closed' | 'won' | 'lost';
    export const StatusEnum = {
        WARNING_NEEDS_RESPONSE: 'warning_needs_response' as StatusEnum,
        WARNING_UNDER_REVIEW: 'warning_under_review' as StatusEnum,
        WARNING_CLOSED: 'warning_closed' as StatusEnum,
        NEEDS_RESPONSE: 'needs_response' as StatusEnum,
        UNDER_REVIEW: 'under_review' as StatusEnum,
        CLOSED: 'closed' as StatusEnum,
        WON: 'won' as StatusEnum,
        LOST: 'lost' as StatusEnum
    };
}


