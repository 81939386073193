<form-card
  [submitButton]="true"
  [submitDisabled]="userInviteForm.invalid"
  [submitText]="buttonText"
  [styles]="'flex flex-auto flex-col'"
  [titleText]="titleText"
  [secondaryText]="secondaryText"
  [minWidth]="'min-w-100'"
  [testIds]="{ submitButton: 'saveBtn', titleText: 'userInvite-header' }"
  (exitClick)="closeDialog()"
  (submitClick)="inviteOrEditUser()"
>
  <ng-container>
    <div data-test-id="userInvite-form" class="min-w-100">
      <form [formGroup]="userInviteForm">
        <tilled-input
          data-test-id="userInvite-email"
          label="EMAIL"
          tilledLabel="true"
          name="email"
          [placeholder]="'Enter the user\'s email'"
          [hint]="isMerchantUser ? 'The merchant user will have full view and edit access.' : ''"
          [errors]="
            (userInviteForm.get('email').hasError('invalidEmail') ||
              userInviteForm.get('email').hasError('required')) &&
            userInviteForm.get('email').touched
          "
          errorMessage="A valid email is required"
        ></tilled-input>
        <ng-container *ngIf="!isMerchantUser">
          <div class="w-full pb-2.5 pt-4">
            <tilled-label-l1 secondary="true">User role</tilled-label-l1>
          </div>
          <tilled-select
            classOverride="w-full"
            data-test-id="userInvite-role"
            [multiple]="false"
            [options]="availableUserRoleOptions"
            [controlName]="userInviteForm.get('role')"
            [placeholder]="'Select a role'"
            [errors]="userInviteForm.get('role').hasError('required') && userInviteForm.get('role').touched"
            errorMessage="A role is required"
          ></tilled-select>
        </ng-container>
      </form>

      <ng-container *ngIf="!isMerchantUser">
        <div class="pb-6 pt-4">
          <tilled-paragraph-p3 data-test-id="description-header" class="font-semibold text-black"
            >Role Descriptions</tilled-paragraph-p3
          >
        </div>
        <ng-container *ngFor="let userRole of availableUserRoles">
          <div class="pb-2">
            <tilled-paragraph-p3 data-test-id="description-roleName">{{ userRole.name }}</tilled-paragraph-p3>
          </div>
          <div class="pb-4">
            <tilled-paragraph-p4 data-test-id="description" [secondary]="true">{{
              userRole.description
            }}</tilled-paragraph-p4>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</form-card>
