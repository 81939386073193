import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TimelineActivity } from 'app/shared/payments/payments.types';
import { FuseCardComponent } from '../../../../@fuse/components/card/card.component';
import { DateTooltipComponent } from '../../date-tooltip/date-tooltip.component';
import { TilledHeadingH4Component } from '../../tilled-text/tilled-heading/tilled-heading-h4.component';
import { TilledParagraphP4Component } from '../../tilled-text/tilled-paragraph/tilled-paragraph-p4.component';

@Component({
  selector: 'timeline-card',
  templateUrl: './timeline-card.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FuseCardComponent,
    TilledHeadingH4Component,
    MatDividerModule,
    MatIconModule,
    MatTooltipModule,
    TilledParagraphP4Component,
    DateTooltipComponent,
    CommonModule,
  ],
})
export class TimelineCardComponent implements OnInit, OnChanges {
  @Input() timelineActivities: TimelineActivity[];
  @Input() hideTransactionIds: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  trackByFn(index: number, item: TimelineActivity): string {
    return item.id || index.toString();
  }
}
