<div class="flex items-center">
  <mat-form-field class="fuse-mat-dense remove-bottom-border w-72">
    <mat-select
      [panelClass]="'w-72 min-w-72 max-w-72 h-auto max-h-screen'"
      [value]="account?.id"
      disableOptionCentering
      (selectionChange)="updateAccount($event)"
    >
      <mat-select-trigger class="text-md">
        <div class="h-auto py-2 leading-none">
          <div class="flex flex-row items-center">
            <span class="material-icons account-icon">{{ account.icon }}</span>
            <div class="flex w-52 flex-col">
              <div class="account-title">{{ account.title }}</div>
              <div class="account-subtitle">{{ account.subtitle }}</div>
            </div>
          </div>
        </div>
      </mat-select-trigger>
      <ng-container *ngFor="let group of accountGroups">
        <mat-optgroup class="account-group-heading">
          <div class="flex flex-row items-center">
            <span [className]="group.status + '-text'">{{ group.title | uppercase }}</span>
            <div [className]="group.status + '-divider'"></div>
          </div>

          <mat-option class="h-auto py-2 pl-4 leading-none" [value]="acct.id" *ngFor="let acct of group.accounts">
            <div class="flex flex-row items-center">
              <span class="material-icons account-icon">{{ acct.icon }}</span>
              <div class="flex flex-col">
                <div class="account-title">{{ acct.title }}</div>
                <div class="account-subtitle">{{ acct.subtitle }}</div>
              </div>
            </div>
          </mat-option>
        </mat-optgroup>
      </ng-container>
    </mat-select>
  </mat-form-field>
</div>
