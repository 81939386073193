import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlContainer, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {} from 'google.maps';
import { PartialAddressV2 } from '../../../../projects/tilled-api-client/src';
import { TilledLabelL1Component } from '../tilled-text/tilled-label/tilled-label-l1.component';

declare const google: any;

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: 'always' },
    },
  ],
  standalone: true,
  imports: [MatFormFieldModule, TilledLabelL1Component, MatInputModule, FormsModule, ReactiveFormsModule, CommonModule],
})
export class AutocompleteComponent implements OnInit, AfterViewInit {
  @Input() disabled: boolean;
  @Input() autocompleteAddress: PartialAddressV2;
  @Input() autocompletePreviousAddress: PartialAddressV2;
  @Input() autocompleteRegion;
  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() groupName?: string = '';
  @Input() name?: string;
  @Input() fieldType?: string = 'text';
  @Input() styles?: string = '';
  @Input() tilledLabel: boolean = false;
  @Input() hint?: string = null;
  @Output() setAddress: EventEmitter<PartialAddressV2> = new EventEmitter();
  @Output() setPreviousAddress: EventEmitter<PartialAddressV2> = new EventEmitter();
  @ViewChild('addresstext') addresstext: any;

  queryWait: boolean;
  outputAddress: PartialAddressV2;
  autocompleteInput: string;
  streetWithNumber: string;

  phone: string;

  constructor(public zone: NgZone) {}

  ngOnInit(): void {
    if (this.autocompletePreviousAddress) {
      this.streetWithNumber = this.convertAddressToString(this.autocompletePreviousAddress);
    } else {
      this.streetWithNumber = this.convertAddressToString(this.autocompleteAddress);
    }
  }

  ngAfterViewInit(): void {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete(): void {
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement, {
      componentRestrictions: {
        country: this.autocompleteRegion || ['US', 'CA'],
      },
      types: ['address'], // 'establishment' / 'address' / 'geocode' Note: establishment is pretty flaky
    });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.invokeEvent(place);
    });
  }

  convertAddressToString(address: PartialAddressV2): string {
    if (address && address.street) {
      const street2 = address.street2 ? ' ' + address.street2 : ''; // don't add space if street2 isn't set
      return `${address.street}${street2}`;
    } else {
      return '';
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  invokeEvent(place: Object): void {
    if (this.autocompletePreviousAddress) {
      this.outputAddress = {
        street: this.getStreetWithNumber(place),
        city: this.getCity(place),
        postal_code: this.getPostCode(place),
        state: this.getState(place),
        country: this.getCountryShort(place),
      };
      this.streetWithNumber = this.getStreetWithNumber(place);
      this.setPreviousAddress.emit(this.outputAddress);
    } else if (this.autocompleteAddress) {
      this.outputAddress = {
        street: this.getStreetWithNumber(place),
        city: this.getCity(place),
        postal_code: this.getPostCode(place),
        state: this.getState(place),
        country: this.getCountryShort(place),
      };
      this.streetWithNumber = this.getStreetWithNumber(place);
      this.setAddress.emit(this.outputAddress);
    }
  }

  getAddrComponent(place: any, componentTemplate: any): any {
    let result;

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < place.address_components.length; i++) {
      const addressType = place.address_components[i].types[0];
      if (componentTemplate[addressType]) {
        result = place.address_components[i][componentTemplate[addressType]];
        return result;
      }
    }
    return;
  }

  getStreetNumber(place): string {
    const COMPONENT_TEMPLATE = { street_number: 'short_name' };
    const streetNumber = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return streetNumber || '';
  }

  getStreet(place): string {
    const COMPONENT_TEMPLATE = { route: 'long_name' };
    const street = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return street;
  }

  getStreetWithNumber(place): string {
    const streetWithNumber = `${this.getStreetNumber(place)} ${this.getStreet(place)}`;
    this.streetWithNumber = streetWithNumber;
    return streetWithNumber;
  }

  getCity(place): string {
    const COMPONENT_TEMPLATE = {
      locality: 'long_name',
      sublocality_level_1: 'long_name',
    };
    const city = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return city;
  }

  getState(place): string {
    const COMPONENT_TEMPLATE = { administrative_area_level_1: 'short_name' };
    const state = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return state;
  }

  getDistrict(place): string {
    const COMPONENT_TEMPLATE = { administrative_area_level_2: 'short_name' };
    const state = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return state;
  }

  getCountryShort(place): string {
    const COMPONENT_TEMPLATE = { country: 'short_name' };
    const countryShort = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return countryShort;
  }

  getCountry(place): string {
    const COMPONENT_TEMPLATE = { country: 'long_name' };
    const country = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return country;
  }

  getPostCode(place): string {
    const COMPONENT_TEMPLATE = { postal_code: 'long_name' };
    const postCode = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return postCode;
  }

  getPhone(place): string {
    const COMPONENT_TEMPLATE = {
      formatted_phone_number: 'formatted_phone_number',
    };
    const phone = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return phone;
  }
}
