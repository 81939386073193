import { Pipe, PipeTransform } from '@angular/core';
import { ChipColorClass, TilledChipConfig } from 'app/shared/tilled-chip/tilled-chip.component';
import { PricingTemplate } from '../../../../projects/tilled-api-client/src';

/**
 * Takes a pricing template status
 */
@Pipe({
    name: 'template_status_chip_config',
    standalone: true,
})
export class TemplateStatusChipConfigPipe implements PipeTransform {
  /**
   *
   * @param status: Pricing Template status
   * @returns
   */
  transform(status: PricingTemplate.StatusEnum): TilledChipConfig {
    if (status === PricingTemplate.StatusEnum.ACTIVE) {
      return {
        text: 'ACTIVE',
        toolTip: 'This Pricing Template is Active',
        color: ChipColorClass.SOLID_GREEN,
      };
    } else if (status === PricingTemplate.StatusEnum.PENDING) {
      return {
        text: 'PENDING',
        toolTip: 'This Pricing Template is Pending',
        color: ChipColorClass.SOLID_YELLOW,
      };
    } else if (status === PricingTemplate.StatusEnum.ARCHIVED) {
      return {
        text: 'ARCHIVED',
        toolTip: 'This Pricing Template is Archived',
        color: ChipColorClass.SOLID_GRAY,
      };
    }
  }
}
