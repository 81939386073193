<div class="flex cursor-pointer flex-col items-center" (click)="onButtonClick()">
  <div
    class="z-20 flex h-9 w-9 flex-0 items-center justify-center rounded-full ring-2 ring-inset ring-transparent"
    [ngClass]="{
      'bg-primary': appStep.complete,
      'bg-gray-600': !appStep.complete
    }"
  >
    <ng-container>
      <mat-icon
        class="m-0"
        [ngClass]="{
          'text-white': appStep.complete,
          'text-zinc-400': !appStep.complete
        }"
        svgIcon="mat_outline:{{ appStep.icon }}"
      ></mat-icon>
    </ng-container>
  </div>
  <fuse-card
    class="z-10 -mt-4 h-30 w-36 flex-shrink items-center rounded-md bg-white bg-opacity-10 px-5 text-center text-white outline outline-1 outline-white hover:bg-opacity-0 hover:outline-primary"
  >
    <span>{{ appStep.title }}</span>
  </fuse-card>
</div>
