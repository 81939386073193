/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReportRunRequestParameters } from './reportRunRequestParameters';


export interface InternalReportRun { 
    /**
     * The id of the associated account.
     */
    account_id: string;
    /**
     * The name of the connected account associated with this Report Run.
     */
    connected_account_name?: string;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * The failure message of the report run, if status is \'failed\'.
     */
    failure_message?: string;
    /**
     * Unique identifier for the object.
     */
    id: string;
    parameters: ReportRunRequestParameters;
    result?: any;
    /**
     * The status of a report run.
     */
    status: InternalReportRun.StatusEnum;
    /**
     * The type of a report run.
     */
    type: InternalReportRun.TypeEnum;
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
}
export namespace InternalReportRun {
    export type StatusEnum = 'queued' | 'finished' | 'failed';
    export const StatusEnum = {
        QUEUED: 'queued' as StatusEnum,
        FINISHED: 'finished' as StatusEnum,
        FAILED: 'failed' as StatusEnum
    };
    export type TypeEnum = 'payments_summary_1' | 'payouts_summary_1' | 'payouts_summary_2' | 'fees_summary_1' | 'processing_summary_1' | 'disputes_summary_1' | 'interchange_detail_1';
    export const TypeEnum = {
        PAYMENTS_SUMMARY_1: 'payments_summary_1' as TypeEnum,
        PAYOUTS_SUMMARY_1: 'payouts_summary_1' as TypeEnum,
        PAYOUTS_SUMMARY_2: 'payouts_summary_2' as TypeEnum,
        FEES_SUMMARY_1: 'fees_summary_1' as TypeEnum,
        PROCESSING_SUMMARY_1: 'processing_summary_1' as TypeEnum,
        DISPUTES_SUMMARY_1: 'disputes_summary_1' as TypeEnum,
        INTERCHANGE_DETAIL_1: 'interchange_detail_1' as TypeEnum
    };
}


