<tilled-label-l1 *ngIf="labelText" secondary="true">{{ labelText }}</tilled-label-l1>
<mat-form-field appearance="standard" class="-mt-4 w-full">
  <mat-select
    (openedChange)="openedChange($event)"
    class="italic"
    [placeholder]="placeholderText"
    [formControl]="selectFormControl"
    multiple
  >
    <mat-select-trigger>
      <span class="... overflow-hidden text-ellipsis">
        <ng-container *ngFor="let item of selectFormControl.value; let i = index"
          >{{ i > 0 ? ', ' : '' }}{{ item.desc }}</ng-container
        >
      </span>
    </mat-select-trigger>
    <div class="">
      <mat-optgroup>
        <mat-form-field class="w-full">
          <input #search autocomplete="off" placeholder="Search" matInput [formControl]="searchTextControl" />
          <button
            [disableRipple]="true"
            *ngIf="search.value"
            matSuffix
            mat-icon-button
            class="focus:shadow-outline ml-2 w-10 rounded-full pb-1"
            (click)="clearSearch($event)"
          >
            <mat-icon class="ml-4 mt-1 icon-size-5" [svgIcon]="'mat_outline:close'"></mat-icon>
          </button>
        </mat-form-field>
      </mat-optgroup>
      <mat-optgroup *ngIf="(filteredOptions | async)?.length == 0">
        <div class="pt-1">
          <tilled-paragraph-p3>{{ emptySearchText }}</tilled-paragraph-p3>
        </div>
      </mat-optgroup>
      <mat-option
        (onSelectionChange)="selectionChange($event)"
        *ngFor="let option of filteredOptions | async"
        [value]="option"
      >
        {{ option.desc }}
      </mat-option>
    </div>
  </mat-select>
</mat-form-field>
