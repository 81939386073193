import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ControlContainer, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskModule } from 'ngx-mask';
import { AllowOnlyNumbersDirective } from '../allow-only-numbers.directive';
import { TilledInputComponent } from '../form-fields/tilled-input/tilled-input.component';
import { TilledParagraphP3Component } from '../tilled-text/tilled-paragraph/tilled-paragraph-p3.component';

import { CommonModule } from '@angular/common';
import { TilledLabelL1Component } from '../tilled-text/tilled-label/tilled-label-l1.component';

@Component({
  selector: 'outline-input',
  templateUrl: './outline-input.component.html',
  styleUrls: ['./outline-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: 'always' },
    },
  ],
  standalone: true,
  imports: [
    TilledLabelL1Component,
    TilledParagraphP3Component,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    NgxMaskModule,
    ReactiveFormsModule,
    AllowOnlyNumbersDirective,
    CommonModule,
  ],
})
export class OutlineInputComponent extends TilledInputComponent {
  @Output() onBlur: EventEmitter<string> = new EventEmitter<string>();
  @Input() inputTextStyle?: string = 'ml-2 text-tilled-primary py-1';
  @Input() value: any;
  @Input() mask?: string;
  @Input() thousandSeparator?: string = ',';
  @Input() width: string = 'w-24';
  @Input() paragraphText?: string;
  @Input() onlyNumbers: boolean;

  constructor() {
    super();
  }

  ngOnInit() {}

  onInputBlur() {
    this.onBlur.emit(this.label);
  }
}
