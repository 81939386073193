import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TilledHeadingH2Component } from '../../tilled-text/tilled-heading/tilled-heading-h2.component';
import { TilledLabelL1Component } from '../../tilled-text/tilled-label/tilled-label-l1.component';

@Component({
  selector: 'color-text-card',
  templateUrl: './color-text-card.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [TilledLabelL1Component, TilledHeadingH2Component, MatProgressSpinnerModule, CommonModule],
})
export class ColorTextCardComponent implements OnInit, OnChanges {
  @Input() title: string = '';
  @Input() input: string = '';
  @Input() green: boolean = false;
  @Input() rectangle: boolean = false;
  @Input() currency: boolean = false;
  @Input() loading: boolean = false;
  public value: string = '';
  public bg_color: string = 'bg-tilled-indigo';
  public size: string = ' min-w-48 tilled-2xl:min-w-48 tilled-xl:min-w-44 xl:min-w-30 tilled-lg:min-w-52 h-44 ';
  constructor() {}

  ngOnInit() {
    this.checkInputs();
  }

  ngOnChanges() {
    this.checkInputs();
  }

  checkInputs() {
    if (this.green) {
      this.bg_color = ' bg-tilled-green ';
    }
    if (this.rectangle) {
      this.size =
        ' min-w-[350px] tilled-2xl:min-w-[350px] xl:min-w-[200px] tilled-xl:min-w-[500px] tilled-lg:min-w-[444px]  h-44 ';
    }
    if (this.currency) {
      this.value = `$${(Number(this.input) / 100).toLocaleString('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })}`;
    } else {
      this.value = this.input;
    }
  }
}
