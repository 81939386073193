<div>
  <tilled-table
    [data]="(outboundTransfersViewModel$ | async)!"
    [getPageCallback]="getOutboundTransfers"
    [dataLength]="outboundTransfersCount$ | async"
    data-test-id="outbound-transfers-table"
    [noDataMainText]="'No outbound transfers found'"
    [noDataSecondaryText]="secondaryReasonText"
    [getClickCallback]="rowClickedCallback"
    [hideColumnKeys]="hideColumnKeys"
    [scrollable]="true"
    [showDisplayedColumnsDropdown]="false"
    [startingPageIndex]="pageIndex"
    [startingPageLimit]="pageSize"
  ></tilled-table>
</div>
