<div class="flex min-w-100 max-w-120 flex-auto flex-col">
  <div class="relative left-1 top-[-5px] flex justify-end">
    <mat-icon style="cursor: pointer" (click)="closeDialog()" svgIcon="mat_outline:close"></mat-icon>
  </div>
  <ng-container>
    <div class="grid w-full justify-items-center px-4">
      <!-- Title -->
      <tilled-heading-h2 class="pb-2">Add New Customer</tilled-heading-h2>
      <tilled-paragraph-p3>Enter customer details below.</tilled-paragraph-p3>

      <!-- Form -->
      <ng-container>
        <div class="min-w-100">
          <form [formGroup]="customerForm">
            <tilled-input
              name="first_name"
              data-test-id="customer-first-name"
              label="First name"
              [tilledLabel]="true"
              placeholder="Enter first name"
              [errors]="customerForm.get('first_name').errors && customerForm.get('first_name').touched"
              errorMessage="First name is required"
            ></tilled-input>
            <tilled-input
              name="middle_name"
              data-test-id="customer-middle-name"
              label="Middle name"
              [tilledLabel]="true"
              placeholder="Enter middle name"
              hint="Optional"
            ></tilled-input>
            <tilled-input
              name="last_name"
              data-test-id="customer-last-name"
              label="Last name"
              [tilledLabel]="true"
              placeholder="Enter last name"
              [errors]="customerForm.get('last_name').errors && customerForm.get('last_name').touched"
              errorMessage="Last name is required"
            ></tilled-input>
            <tilled-input
              name="email"
              data-test-id="customer-email"
              label="Email"
              [tilledLabel]="true"
              placeholder="Enter email"
              [errors]="customerForm.get('email').errors && customerForm.get('email').touched"
              errorMessage="Email invalid"
              hint="Optional"
            ></tilled-input>
            <tilled-input
              name="phone"
              data-test-id="customer-phone-number"
              label="Phone"
              [tilledLabel]="true"
              placeholder="Enter phone number"
              [errors]="customerForm.get('phone').errors && customerForm.get('phone').touched"
              errorMessage="Phone number invalid"
              hint="Optional"
            ></tilled-input>
          </form>
        </div>
      </ng-container>
    </div>

    <!-- Add Actions -->
    <div class="grid w-full justify-items-end">
      <!-- Save -->
      <tilled-button
        [disabled]="!customerForm.valid"
        data-test-id="add-customer-save-Btn"
        bgColor="tilled-primary"
        [rounded]="true"
        buttonText="Add Customer"
        (buttonClick)="addCustomerClicked()"
        [whiteText]="true"
      ></tilled-button>
    </div>
  </ng-container>
</div>
