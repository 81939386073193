/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ReportAdditionalFilter { 
    key: ReportAdditionalFilter.KeyEnum;
    value: string;
}
export namespace ReportAdditionalFilter {
    export type KeyEnum = 'search_filter';
    export const KeyEnum = {
        SEARCH_FILTER: 'search_filter' as KeyEnum
    };
}


