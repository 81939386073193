import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, ReplaySubject, shareReplay, tap } from 'rxjs';
import {
  GetPayoutRequestParams,
  InternalPayout,
  InternalService,
  ListPayoutsRequestParams,
} from '../../../../projects/tilled-api-client/src';
import { TilledAlert } from '../models/tilled-alert';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class PayoutsAppService {
  private _payouts$ = new ReplaySubject<InternalPayout[]>();
  private _payoutSubject = new BehaviorSubject<InternalPayout>(null);
  private _payoutsCount$ = new ReplaySubject<number>();

  public payout$: Observable<InternalPayout> = this._payoutSubject.asObservable();
  public payouts$: Observable<InternalPayout[]> = this._payouts$.asObservable();
  public payoutsCount$: Observable<number> = this._payoutsCount$.asObservable();

  constructor(private _internalService: InternalService, private _alertService: AlertService) {}

  // TODO: not used anywhere at the moment, we use _internalService.internalGetPayout
  public getPayout(params: GetPayoutRequestParams): Observable<InternalPayout> {
    return this._internalService.internalGetPayout(params).pipe(
      tap((payout) => {
        this._payoutSubject.next(payout);
      }),
      catchError((err) => {
        throw new Error('Error loading payout: ' + err.message);
      }),
    );
  }

  public getAllPayouts(params: ListPayoutsRequestParams): void {
    this._internalService
      .internalListPayouts(params)
      .pipe(
        tap((result) => this._payoutsCount$.next(result.total)),
        map((result) => result.items),
        shareReplay(1),
      )
      .subscribe({
        next: (payouts) => {
          this._payouts$.next(payouts);
        },
        error: (err) => {
          // generic catch all for error responses
          const message: TilledAlert = {
            message: 'Could not load all payouts',
            title: 'Server error',
            type: 'error',
          };
          this._alertService.showAlert(message);
        },
      });
  }
}
