<form-card
  [titleText]="title"
  [secondaryText]="inviteText"
  [submitButton]="true"
  [submitText]="submitButtonText"
  [exitButton]="true"
  [submitDisabled]="userInviteForm.invalid || disabled"
  [displayAlert]="displayAlert$ | async"
  [alertTitle]="'Error inviting collaborator'"
  [alertMessage]="alertMessage"
  [testIds]="{ submitButton: 'share-via-email-button' }"
  data-test-id="invite-form"
  (exitClick)="closeDialog()"
  (submitClick)="inviteUser('email')"
>
  <div class="grid w-full grid-cols-1 gap-x-6">
    <div class="col-span-1 flex flex-col">
      <tilled-input
        data-test-id="invite-email"
        label="EMAIL ADDRESS"
        tilledLabel="true"
        name="email"
        [formGroup]="userInviteForm"
        placeholder="Enter email address"
        [errors]="userInviteForm.get('email').errors && userInviteForm.get('email').touched"
        errorMessage="Email Address must be valid."
        [readonly]="userInviteForm.invalid || disabled"
        [copyToClipboard]="true"
        (copyClick)="inviteUser('copy')"
        [copyClickText]="'Generate'"
      >
      </tilled-input>
      <div
        *ngIf="showLinkToUsers && accountUserCount > 0"
        class="flex cursor-pointer flex-row justify-center pt-6 underline"
        (click)="navigateToUsers()"
      >
        <tilled-paragraph-p3 secondary="true">view previously invited users</tilled-paragraph-p3>
      </div>
    </div>
  </div>
</form-card>
