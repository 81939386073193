<mat-form-field
  *ngIf="!matSuffix && !matPrefix"
  [class]="classOverride ? classOverride : 'fuse-mat-dense fuse-mat-rounded'"
  [appearance]="appearance"
>
  <mat-select
    class="min-h-12"
    *ngIf="multiple"
    multiple
    #tilledSelect
    [placeholder]="sentenceCasePlaceholder ? (placeholder | sentenceCase) : placeholder"
    [formControl]="controlName"
    (closed)="closed.emit()"
    [disabled]="readonly"
  >
    <mat-option *ngFor="let option of options" [value]="option.value">{{ option.label }}</mat-option>
    <mat-option *ngFor="let disabledOption of disabledOptions" [value]="disabledOption.value" disabled>
      {{ disabledOption.label }}
    </mat-option>
    <div class="grid w-full grid-cols-2 justify-items-center py-2">
      <tilled-button
        buttonText="Clear"
        bgColor="white"
        [whiteText]="false"
        [secondary]="true"
        (buttonClick)="clearSelection()"
      ></tilled-button>
      <tilled-button buttonText="Apply" (buttonClick)="closeDropdown()"></tilled-button>
    </div>
  </mat-select>
  <mat-select
    class="min-h-12"
    *ngIf="!multiple"
    #tilledSelect
    [placeholder]="sentenceCasePlaceholder ? (placeholder | sentenceCase) : placeholder"
    [formControl]="controlName"
    (selectionChange)="selectionChange.emit($event)"
    [disabled]="readonly"
  >
    <ng-container *ngIf="groupName !== 'MerchantAppBusinessContactForm'">
      <mat-option *ngFor="let option of options" [value]="option.value">{{ option.label }}</mat-option>
      <mat-option *ngFor="let disabledOption of disabledOptions" [value]="disabledOption.value" disabled>
        {{ disabledOption.label }}
      </mat-option>
    </ng-container>
    <ng-container *ngIf="groupName === 'MerchantAppBusinessContactForm'">
      <mat-option *ngFor="let option of options" [value]="option.value">{{ option.value }}</mat-option>
      <mat-option *ngFor="let disabledOption of disabledOptions" [value]="disabledOption.value" disabled>
        {{ disabledOption.value }}
      </mat-option>
    </ng-container>
  </mat-select>
  <mat-icon
    *ngIf="errors"
    color="warn"
    class="icon-size-4.5"
    matTooltipClass="tooltip-list"
    [matTooltip]="errorMessage"
    [svgIcon]="'heroicons_outline:information-circle'"
  >
  </mat-icon>
  <mat-icon
    *ngIf="toolTip && !errors"
    class="text-hint icon-size-4.5"
    matTooltipClass="tooltip-list"
    [matTooltip]="toolTip"
    [svgIcon]="'mat_outline:help_outline'"
  >
  </mat-icon>
  <mat-icon
    [color]="errors ? 'warn' : 'hint'"
    matSuffix
    class="ml-1 icon-size-5"
    id="select-arrow"
    [svgIcon]="'heroicons_outline:chevron-down'"
  ></mat-icon>
  <mat-error color="warn" *ngIf="errors" class="mb-0.5 pt-2">{{ errorMessage }}</mat-error>
</mat-form-field>

<ng-container *ngIf="matSuffix && !matPrefix">
  <button
    mat-button
    (click)="openDropdown()"
    class="flex pr-0"
    [disabled]="options?.length > 1 ? false : true"
    [ngClass]="templateRef === 'currencySelect' ? 'flex-row-reverse' : 'flex-row'"
  >
    <mat-select
      #tilledSelect
      [formControl]="controlName"
      [placeholder]="placeholder"
      (selectionChange)="selectionChange.emit($event)"
      (panelOpen)="panelOpen.emit()"
      class="min-h-12 pr-2"
      [disabled]="readonly"
    >
      <mat-option *ngFor="let option of options" [value]="option.value">{{ option.label }}</mat-option>
      <mat-option *ngFor="let disabledOption of disabledOptions" [value]="disabledOption.value" disabled>
        {{ disabledOption.label }}
      </mat-option>
    </mat-select>
    <mat-icon
      [color]="errors ? 'warn' : 'hint'"
      *ngIf="placeholder !== ''"
      mat-button
      (click)="openDropdown()"
      class="ml-1 icon-size-5"
      [svgIcon]="'heroicons_outline:chevron-down'"
    ></mat-icon>
  </button>
</ng-container>

<ng-container *ngIf="matPrefix && !matSuffix">
  <button mat-button (click)="openDropdown()" class="pr-0" [disabled]="options?.length > 1 ? false : true">
    <mat-select
      #tilledSelect
      [formControl]="controlName"
      [placeholder]="placeholder"
      (selectionChange)="selectionChange.emit($event)"
      (panelOpen)="panelOpen.emit()"
      class="min-h-12 pr-2"
      [disabled]="readonly"
    >
      <mat-option *ngFor="let option of options" [value]="option.value">{{ option.label }}</mat-option>
      <mat-option *ngFor="let disabledOption of disabledOptions" [value]="disabledOption.value" disabled>
        {{ disabledOption.label }}
      </mat-option>
    </mat-select>
    <mat-icon
      [color]="errors ? 'warn' : 'hint'"
      *ngIf="placeholder !== ''"
      mat-button
      (click)="openDropdown()"
      class="-ml-1 mr-1 icon-size-5"
      [svgIcon]="'heroicons_outline:chevron-down'"
    ></mat-icon>
  </button>
</ng-container>
