export class ActionListItem {
  name: string;
  callback: (param: any) => void;
  disabled?: boolean;

  constructor(options: Partial<ActionListItem> = {}) {
    this.name = options.name;
    this.callback = options.callback;
    this.disabled = options.disabled || false;
  }
}
