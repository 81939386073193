<button data-test-id="open-user-menu" mat-icon-button [matMenuTriggerFor]="userActions">
  <mat-icon
    *ngIf="!showAvatar || !user.avatar"
    class="text-primary"
    [svgIcon]="'heroicons_outline:user-circle'"
  ></mat-icon>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <button mat-menu-item data-test-id="user-menu">
    <span class="flex flex-col leading-none">
      <span>Signed in as</span>
      <span class="mt-1.5 text-md font-medium">{{ user.email }}</span>
    </span>
  </button>
  <mat-divider class="my-2"></mat-divider>
  <button data-test-id="reset-password-button" mat-menu-item (click)="resetPassword()">
    <mat-icon style="transform: scaleX(-1)" [svgIcon]="'heroicons_outline:arrow-path'"></mat-icon>
    <span>Reset password</span>
  </button>
  <button data-test-id="sign-out-button" mat-menu-item (click)="signOut()">
    <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"> </mat-icon>
    <span>Sign out</span>
  </button>
</mat-menu>

<mat-menu class="user-status-menu" #userStatus="matMenu">
  <button mat-menu-item (click)="updateUserStatus('online')">
    <span class="mr-3 h-4 w-4 rounded-full bg-green-500"></span>
    <span>Online</span>
  </button>
  <button mat-menu-item (click)="updateUserStatus('away')">
    <span class="mr-3 h-4 w-4 rounded-full bg-amber-500"></span>
    <span>Away</span>
  </button>
  <button mat-menu-item (click)="updateUserStatus('busy')">
    <span class="mr-3 h-4 w-4 rounded-full bg-red-500"></span>
    <span>Busy</span>
  </button>
  <button mat-menu-item (click)="updateUserStatus('not-visible')">
    <span class="mr-3 h-4 w-4 rounded-full bg-gray-400"></span>
    <span>Invisible</span>
  </button>
</mat-menu>
