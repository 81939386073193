import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { SentenceCasePipe } from '../../core/pipes/sentence-case.pipe';
import { TilledLabelL1Component } from '../tilled-text/tilled-label/tilled-label-l1.component';

@Component({
  selector: 'tilled-chip-list',
  templateUrl: './tilled-chip-list.component.html',
  styleUrls: ['./tilled-chip-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  standalone: true,
  imports: [
    TilledLabelL1Component,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatIconModule,
    MatOptionModule,
    SentenceCasePipe,
    CommonModule,
  ],
})
export class TilledChipListComponent {
  @Input() options?: any[] = [];
  @Input() controlName?: FormControl;
  @Input() label?: string = '';
  @Input() selectOptions?: any[] = [];
  @Input() placeholder?: string = '';
  @Input() sentenceCasePlaceholder?: boolean = true;

  @Output() removed = new EventEmitter<any>();
  @Output() selectionChange = new EventEmitter<any[]>();

  removeItem(item: any) {
    this.removed.emit(item);
  }

  constructor() {}
}
