<fuse-card data-test-id="metadata-card" *ngIf="metadata" class="tilled-card-styling col-span-1" #fuseCard>
  <div class="w-full">
    <div class="grid w-full grid-cols-2">
      <!-- Title-->
      <tilled-heading-h4 class="text-lg font-semibold">Metadata</tilled-heading-h4>
      <!-- Edit Metadata -->
      <div class="justify-self-end" *ngIf="displayView">
        <button data-test-id="edit-metadata" [disabled]="disableEdit" (click)="editMetadata()">
          <mat-icon [matTooltip]="editToolTip" class="icon-size-5" [svgIcon]="'heroicons_solid:pencil'"></mat-icon>
        </button>
      </div>
    </div>
    <mat-divider class="mb-4 mt-3 w-full"></mat-divider>
    <!-- Metadata Items -->
    <ng-container *ngIf="displayView; else editView">
      <div data-test-id="metadata-list" class="w-full">
        <div *ngFor="let metadata of metadata | keyvalue" class="grid w-full grid-cols-1 sm:grid-cols-2">
          <tilled-paragraph-p3 class="justify-self-center sm:justify-self-start" [secondary]="true">
            {{ metadata.key }}</tilled-paragraph-p3
          >

          <tilled-paragraph-p3 class="justify-self-center sm:justify-self-end">
            {{ metadata.value }}
          </tilled-paragraph-p3>
        </div>
      </div>
    </ng-container>

    <!-- built in edit -->
    <ng-template #editView>
      <!-- Tasks -->
      <ng-container>
        <div class="mx-2 mt-4 space-y-1.5">
          <form [formGroup]="metadataForm">
            <div formArrayName="items" *ngFor="let metadata of metadataForm.get('items')['controls']; let i = index">
              <div [formGroupName]="i" class="flex flex-row">
                <div class="group mr-6 flex w-full flex-row items-center">
                  <tilled-input
                    data-test-id="metadata-key"
                    label="Key"
                    tilledLabel="true"
                    name="key"
                    [placeholder]="'Key'"
                    [control]="metadataForm.get('items').at(i).get('key')"
                    [errors]="
                      metadataForm.get('items').at(i).get('key').hasError('required') &&
                      metadataForm.get('items').at(i).get('key').touched
                    "
                    errorMessage="Key is required"
                  ></tilled-input>
                  <tilled-input
                    data-test-id="metadata-value"
                    label="Value"
                    tilledLabel="true"
                    name="value"
                    [placeholder]="'Value'"
                    [control]="metadataForm.get('items').at(i).get('value')"
                    [errors]="
                      metadataForm.get('items').at(i).get('value').hasError('required') &&
                      metadataForm.get('items').at(i).get('value').touched
                    "
                    errorMessage="Value is required"
                  ></tilled-input>
                  <mat-icon (click)="removeMetadataRow(i)" data-test-id="metadata-delete">delete</mat-icon>
                </div>
              </div>
            </div>
          </form>
        </div>
      </ng-container>

      <!-- Add Actions -->
      <ng-container>
        <div class="mt-4 flex flex-row items-center justify-between">
          <!-- Add Item -->
          <tilled-button
            data-test-id="metadata-addBtn"
            bgColor="accent"
            [rounded]="true"
            buttonText="Add Item"
            (buttonClick)="addMetadataItem()"
          ></tilled-button>
          <div clas="flex flex-col justify-end">
            <!-- Cancel -->
            <tilled-button
              class="mr-3"
              data-test-id="metadata-cancelBtn"
              bgColor="white"
              textColor="grey-300"
              [rounded]="true"
              buttonText="Cancel"
              (buttonClick)="closeMetadata()"
            ></tilled-button>

            <!-- Save -->
            <tilled-button
              data-test-id="metadata-saveBtn"
              bgColor="tilled-primary"
              [rounded]="true"
              buttonText="Save"
              (buttonClick)="closeMetadata(metadataForm)"
            ></tilled-button>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </div>
</fuse-card>
<fuse-card *ngIf="!metadata" class="flex w-full flex-auto rounded-3xl border border-dashed border-tilled-secondary-600">
  <!-- No templates -->
  <div class="w-full">
    <!-- Admin no templates-->
    <div>
      <div data-test-id="metadata-placeholder-card" class="my-[9%] text-center tracking-tight">
        <div class="mx-4 mb-4 mt-4 text-2xl font-bold">Metadata has not been created.</div>

        <tilled-button
          data-test-id="add-metadata"
          bgColor="tilled-primary"
          (buttonClick)="editMetadata()"
          buttonText="Add Metadata"
          [disabled]="disableAdd"
        >
        </tilled-button>
      </div>
    </div>
  </div>
</fuse-card>
