<div class="container w-full px-1">
  <div
    app-drag-and-drop
    (fileDropped)="addFiles($event)"
    (showOverlay)="changeOverlay($event)"
    [ngClass]="{
      'bg-transparent': isPartnerApp || isMerchantApp,
      'bg-white': !isPartnerApp && !isMerchantApp
    }"
    class="dropArea h-full w-full bg-transparent"
  >
    <div class="flex flex-col">
      <div class="flex flex-row">
        <tilled-paragraph-p4 [white]="isPartnerApp" class="pb-1">Supported file formats:</tilled-paragraph-p4>
        <ng-container *ngFor="let type of allowedFileTypes; index as i">
          <tilled-paragraph-p4 *ngIf="i !== allowedFileTypes.length - 1" [white]="isPartnerApp" class="pb-1 pl-1"
            >.{{ type }},
          </tilled-paragraph-p4>
          <tilled-paragraph-p4 *ngIf="i === allowedFileTypes.length - 1" [white]="isPartnerApp" class="pb-1 pl-1"
            >.{{ type }}</tilled-paragraph-p4
          >
        </ng-container>
      </div>
      <tilled-paragraph-p4 [white]="isPartnerApp" class="pb-6">File size limit: 10MB</tilled-paragraph-p4>

      <!-- Drag&Drop/Browse Input -->
      <div
        *ngIf="windowWidth >= 640 && !overFileCountLimit"
        class="relative mb-6 h-40 cursor-pointer rounded-3xl border border-solid border-tilled-neutral-400 px-6"
      >
        <!-- this input and div above must have the same width and height (input is hidden behind) -->
        <input
          type="file"
          #fileInput
          class="absolute left-0 top-0 z-0 h-40 w-full cursor-pointer rounded-3xl opacity-0"
          [multiple]="allowMultipleFiles ? true : null"
          (change)="addFiles($event.target.files)"
        />

        <div class="flex h-full flex-col items-center justify-center">
          <mat-icon
            [ngClass]="{
              'text-white': isPartnerApp,
              'text-tilled-primary': !isPartnerApp
            }"
            class="icon-size-5"
            svgIcon="heroicons_solid:arrow-up-tray"
          ></mat-icon>
          <tilled-paragraph-p3 [primaryHighlight]="true" class="pt-2 text-primary underline"
            >Drag and drop or browse to select a file
          </tilled-paragraph-p3>
        </div>
      </div>
      <div
        *ngIf="windowWidth < 640 && !overFileCountLimit"
        class="relative mb-6 h-20 min-w-120 cursor-pointer rounded-3xl border border-solid border-tilled-neutral-400 px-6"
      >
        <!-- this input and div above must have the same width and height (input is hidden behind) -->
        <input
          type="file"
          #fileInput
          class="absolute left-0 top-0 z-0 h-20 w-120 cursor-pointer rounded-3xl opacity-0"
          [multiple]="allowMultipleFiles ? true : null"
          (change)="addFiles($event.target.files)"
        />

        <div class="flex h-full flex-row items-center">
          <mat-icon
            [ngClass]="{
              'text-white': isPartnerApp,
              'text-tilled-primary': !isPartnerApp
            }"
            class="icon-size-5"
            svgIcon="heroicons_solid:arrow-up-tray"
          ></mat-icon>
          <tilled-paragraph-p3 [primaryHighlight]="true" class="pl-2">Browse to select a file</tilled-paragraph-p3>
        </div>
      </div>
      <!-- Files List -->
      <form [formGroup]="descriptionsFormArray">
        <div
          *ngFor="let fileView of fileViews$ | async; index as i"
          class="grid"
          [ngClass]="{ 'grid-cols-1': slim, 'grid-cols-2 space-x-5': !slim }"
          [formGroup]="fileView.descriptionForm"
        >
          <div class="">
            <div
              class="mb-4 flex cursor-pointer flex-col rounded-3xl border border-solid border-tilled-neutral-400 sm:pl-6 sm:pr-2"
            >
              <div class="flex h-[70px] flex-row items-center">
                <mat-icon
                  *ngIf="fileView.state === 'pre_upload' || fileView.state === 'processing'"
                  [ngClass]="{
                    'text-white': isPartnerApp,
                    'text-tilled-primary': !isPartnerApp
                  }"
                  class="icon-size-5"
                  svgIcon="heroicons_solid:paper-clip"
                ></mat-icon>
                <mat-icon
                  *ngIf="fileView.state === 'successful'"
                  class="text-green-500 icon-size-5"
                  svgIcon="heroicons_solid:check"
                ></mat-icon>
                <mat-icon
                  *ngIf="fileView.state === 'failed_validation' || fileView.state === 'failed_server'"
                  class="text-warn icon-size-5"
                  svgIcon="heroicons_solid:exclamation"
                ></mat-icon>
                <div *ngIf="fileView.state === 'failed_validation'" class="flex w-full flex-col overflow-hidden pl-2">
                  <div class="flex flex-row">
                    <tilled-paragraph-p3
                      class="w-3/4 whitespace-nowrap"
                      [bold]="true"
                      [warn]="true"
                      [truncate]="true"
                      >{{ fileView.name }}</tilled-paragraph-p3
                    >
                    <tilled-paragraph-p3 [bold]="true" [warn]="true" class="w-fit pl-2">{{
                      fileView.sizeString
                    }}</tilled-paragraph-p3>
                  </div>
                  <p class="... truncate text-sm text-warn">{{ fileView.errorMessage }}</p>
                </div>
                <tilled-paragraph-p3
                  *ngIf="fileView.state === 'failed_server'"
                  [bold]="true"
                  [warn]="true"
                  [truncate]="true"
                  class="overflow-hidden whitespace-nowrap pl-2"
                  >{{ fileView.name }}</tilled-paragraph-p3
                >
                <tilled-paragraph-p3
                  *ngIf="!(fileView.state === 'failed_validation' || fileView.state === 'failed_server')"
                  [bold]="true"
                  [truncate]="true"
                  [white]="isPartnerApp"
                  class="overflow-hidden whitespace-nowrap pl-2"
                  >{{ fileView.name }}</tilled-paragraph-p3
                >
                <tilled-paragraph-p3
                  *ngIf="fileView.state === 'failed_server'"
                  [bold]="true"
                  [warn]="true"
                  class="w-fit pl-2"
                  >{{ fileView.sizeString }}</tilled-paragraph-p3
                >
                <tilled-paragraph-p3
                  *ngIf="!(fileView.state === 'failed_validation' || fileView.state === 'failed_server')"
                  [bold]="true"
                  [white]="isPartnerApp"
                  class="w-fit pl-2"
                  >{{ fileView.sizeString }}</tilled-paragraph-p3
                >
                <div class="ml-auto flex flex-row">
                  <button
                    *ngIf="fileView.state === 'failed_server'"
                    mat-icon-button
                    (click)="uploadDocuments(fileView)"
                    class="-mr-2"
                    matTooltip="Retry upload"
                  >
                    <mat-icon
                      [ngClass]="{
                        'text-white': isPartnerApp,
                        'text-tilled-primary': !isPartnerApp
                      }"
                      class="icon-size-5"
                      [svgIcon]="'heroicons_outline:refresh'"
                    ></mat-icon>
                  </button>

                  <button
                    *ngIf="fileView.state !== 'successful'"
                    mat-icon-button
                    (click)="removeFile(i)"
                    matTooltip="Remove file from list"
                  >
                    <mat-icon
                      [ngClass]="{
                        'text-white': isPartnerApp,
                        'text-tilled-primary': !isPartnerApp
                      }"
                      class="icon-size-5"
                      svgIcon="mat_outline:close"
                    ></mat-icon>
                  </button>

                  <button
                    *ngIf="fileView.state === 'successful'"
                    mat-icon-button
                    (click)="beforeDeleteDocument(i)"
                    [disabled]="!('files:write' | scopeAble) || (deletingFile$ | async)"
                    matTooltip="Delete uploaded file"
                  >
                    <mat-icon
                      [ngClass]="{
                        'text-white': isPartnerApp,
                        'text-tilled-primary': !isPartnerApp
                      }"
                      class="icon-size-5"
                      svgIcon="heroicons_solid:trash"
                    ></mat-icon>
                  </button>
                </div>
              </div>
              <mat-progress-bar
                *ngIf="fileView.state === 'processing'"
                [mode]="'indeterminate'"
                class="-ml-2 mt-auto w-full rounded-3xl"
              ></mat-progress-bar>
            </div>
          </div>
          <!-- Descriptions List -->
          <div class="col-span-2 flex flex-col sm:col-span-1">
            <div class="file-description-div mb-4 flex h-20 flex-col">
              <div class="w-full pb-2.5">
                <tilled-label-l1 [white]="isPartnerApp" [secondary]="!isPartnerApp" class=""
                  >File description
                </tilled-label-l1>
              </div>
              <tilled-select
                *ngIf="fileDescriptions"
                classOverride="w-full"
                [multiple]="false"
                [options]="fileDescriptionOptions"
                [controlName]="fileView.descriptionForm.get('description')"
                [errors]="
                  descriptionsFormArray.controls[i].get('description').hasError('required') &&
                  descriptionsFormArray.controls[i].get('description').touched
                "
                errorMessage="A file description is required"
                [readonly]="
                  !(fileView.state === 'pre_upload' || fileView.state === 'failed_server') ||
                  fileDescriptions.length === 1
                "
                data-test-id="file-description"
                placeholder="Select file description"
              ></tilled-select>
              <mat-form-field *ngIf="!fileDescriptions" floatLabel="always" appearance="standard" class="-mt-4 w-full">
                <tilled-label-l1 [white]="isPartnerApp" [secondary]="!isPartnerApp" class=""
                  >File description
                </tilled-label-l1>
                <input
                  data-test-id="file-description-text"
                  class="text-secondary pt-4 text-p3"
                  matInput
                  formControlName="description"
                  [disabled]="!(fileView.state === 'pre_upload' || fileView.state === 'failed_server')"
                  placeholder="Enter file description"
                />
                <mat-error
                  class="text-sm"
                  color="warn"
                  *ngIf="descriptionsFormArray.controls[i].get('description').errors?.['required']"
                  >A file description is required
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
      <tilled-button
        *ngIf="allowMultipleFiles"
        class="w-fit pt-2"
        bgColor="tilled-primary"
        [rounded]="true"
        [matTooltip]="disabledTooltip"
        [buttonText]="uploadableFileCount === 0 ? 'Upload Files' : 'Upload ' + uploadableFileCount + ' Files'"
        (click)="uploadDocuments()"
        [whiteText]="true"
        [disabled]="
          uploadableFileCount === 0 || descriptionsFormArray.invalid || !('files:write' | scopeAble) || uploadingFiles
        "
      ></tilled-button>
    </div>
  </div>
  <div
    class="dropAreaOverlay dropAreaOverlayOver flex h-full w-full min-w-80 flex-row items-center justify-center border-4 border-dashed border-tilled-neutral-400"
    (fileDropped)="addFiles($event)"
    [ngClass]="{
      'opacity-100': isPartnerApp && showOverlay,
      'opacity-0': (isPartnerApp && !showOverlay) || (isMerchantApp && !showOverlay),
      '': !isPartnerApp
    }"
  >
    <div class="flex h-full flex-col items-center justify-center">
      <mat-icon class="icon-display text-primary" svgIcon="heroicons_solid:cloud"></mat-icon>
      <mat-icon class="pt-10 text-primary icon-size-5" svgIcon="heroicons_solid:arrow-up"></mat-icon>
      <tilled-paragraph-p3 [primaryHighlight]="true" class="pt-4">Drop files here</tilled-paragraph-p3>
    </div>
  </div>
</div>
