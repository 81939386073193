import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { DocumentDto } from '@tilled-api-client';
import { ScopeAblePipe } from 'app/core/pipes/scope-able.pipe';
import { DocumentsAppService } from 'app/core/services/documents.app.service';
import { Subscription, takeUntil } from 'rxjs';
import { FuseCardComponent } from '../../../@fuse/components/card/card.component';
import { ComponentBase } from '../../core/componentBase';
import { AuthService } from '../../core/services/auth.service';
import { TilledButtonComponent } from '../buttons/tilled-button.component';
import { TilledHeadingH4Component } from '../tilled-text/tilled-heading/tilled-heading-h4.component';
import { TilledParagraphP2Component } from '../tilled-text/tilled-paragraph/tilled-paragraph-p2.component';
import { AccountDocumentsListComponent } from './list/account-documents-list.component';

@Component({
  selector: 'account-documents',
  templateUrl: './account-documents.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TilledButtonComponent,
    FuseCardComponent,
    TilledHeadingH4Component,
    TilledParagraphP2Component,
    MatDividerModule,
    AccountDocumentsListComponent,
    ScopeAblePipe,
    CommonModule,
  ],
})
export class AccountDocumentsComponent extends ComponentBase implements OnInit, OnDestroy {
  @Input() merchantAccountId: string;
  @Output() documentsCountChanged: EventEmitter<number> = new EventEmitter<number>();

  public accountId: string;
  public requestedDocsCount: number = 0;
  private documentsAllSub: Subscription;

  constructor(
    private _authService: AuthService,
    private _documentsService: DocumentsAppService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.accountId = this.merchantAccountId ?? AuthService.getCurrentAccountId();

    this.documentsAllSub = this._documentsService.documentsAllTotal$.subscribe((total) => {
      this.requestedDocsCount = total;
      this.onDocumentsChanged(total);
    });

    this._authService.account$.pipe(takeUntil(this._unsubscribeAll)).subscribe((account) => {
      if (this.merchantAccountId) {
        this._documentsService.getAllDocuments(this.accountId, DocumentDto.StatusEnum.REQUESTED);
      } else {
        this.accountId = account?.id;
      }
    });
  }

  ngOnDestroy(): void {
    this.documentsAllSub.unsubscribe();
  }

  onDocumentsChanged(count: number): void {
    this.documentsCountChanged.emit(count);
  }

  submitDocuments(): void {
    if (this.merchantAccountId) {
      const url = `/documents/${this.accountId}`;
      window.open(url, '_blank');
    } else {
      const url = `/documents`;
      window.open(url, '_blank');
    }
  }
}
