import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceNullWithString',
  standalone: true,
})
export class ReplaceNullWithStringPipe implements PipeTransform {
  transform(value: any, repleceText: string = '-'): any {
    // Handles undefined, nulls and whitespace/empty value.
    if (
      typeof value === 'undefined' ||
      value === null ||
      value === 'null' ||
      String(value).trim().length === 0 ||
      String(value).toLowerCase().includes('undefined')
    ) {
      return repleceText;
    }

    return value;
  }
}
