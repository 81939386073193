import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'dashed-line-container',
  templateUrl: './dashed-line-container.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class DashedLineContainerComponent {
  constructor() {}
}
