import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TilledParagraphP3Component } from '../tilled-text/tilled-paragraph/tilled-paragraph-p3.component';
import { ActionListItem } from './action-list.model';

import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'action-list-component',
  templateUrl: './action-list.component.html',
  styleUrls: ['./action-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [MatButtonModule, MatMenuModule, MatIconModule, TilledParagraphP3Component, CommonModule],
})
export class ActionListComponent implements OnInit {
  @Input() actionList: ActionListItem[];

  constructor() {}

  ngOnInit(): void {}
}
