import { Pipe, PipeTransform } from '@angular/core';

/**
 * Takes string in snake case and splits it using underscores as the delimiter, returing words seperated by spaces
 */
@Pipe({
    name: 'snakeCaseSplit',
    standalone: true,
})
export class SnakeCaseSplitPipe implements PipeTransform {
  /**
   *
   * @param value string in snake case
   * @returns
   */
  transform(value: string): any {
    if (value) {
      const v = value.split('_').join(' ');
      return v;
    }
    return value;
  }
}
