<form-card
  [titleText]="'Add Metadata'"
  [secondaryText]="'Add or update metadata key-value pairs to the item.'"
  [submitButton]="true"
  [backButton]="true"
  [backButtonText]="'Add Item'"
  [submitDisabled]="false"
  [submitText]="'Save Metadata'"
  [minWidth]=""
  [maxWidth]=""
  (exitClick)="dialogRef.close()"
  (submitClick)="dialogRef.close(metadataForm)"
  (backClick)="addMetadataItem()"
  [testIds]="{ exitButton: 'metadata-cancelBtn', submitButton: 'metadata-saveBtn', backButton: 'metadata-addBtn' }"
>
  <ng-container>
    <div class="mx-2 mt-4 space-y-1.5">
      <form [formGroup]="metadataForm">
        <div formArrayName="items" *ngFor="let metadata of metadataForm.get('items')['controls']; let i = index">
          <div [formGroupName]="i" class="flex flex-row">
            <div class="group flex w-full flex-row items-center">
              <div class="w-full flex-col">
                <div class="w-full pb-2.5 pt-4">
                  <tilled-label-l1 class="w-full" secondary="true">Key</tilled-label-l1>
                </div>
                <mat-form-field floatLabel="always" appearance="fill" class="mr-6 flex w-full flex-col py-0.5 pr-1">
                  <input
                    class="text-secondary text-md"
                    matInput
                    formControlName="key"
                    placeholder="Key"
                    data-test-id="metadata-key"
                  />
                  <mat-error class="text-sm" color="warn" *ngIf="metadata.get('key').errors?.['required']"
                    >Key is required.</mat-error
                  >
                  <mat-error class="text-sm" color="warn" *ngIf="metadata.get('key').hasError('pattern')"
                    >&lt; and &gt; symbols are not allowed.</mat-error
                  >
                </mat-form-field>
              </div>
              <div class="w-full flex-col px-2">
                <div class="w-full pb-2.5 pt-4">
                  <tilled-label-l1 class="w-full" secondary="true">Value</tilled-label-l1>
                </div>
                <mat-form-field floatLabel="always" appearance="fill" class="mr-6 flex w-full flex-col py-0.5">
                  <input
                    class="text-secondary text-md"
                    matInput
                    formControlName="value"
                    placeholder="Value"
                    data-test-id="metadata-value"
                  />
                  <mat-error class="text-sm" color="warn" *ngIf="metadata.get('value').hasError('pattern')"
                    >&lt; and &gt; symbols are not allowed.</mat-error
                  >
                </mat-form-field>
              </div>
              <mat-icon (click)="removeMetadataRow(i)" data-test-id="metadata-delete" class="mt-9 text-primary"
                >delete</mat-icon
              >
            </div>
          </div>
        </div>
      </form>
    </div>
  </ng-container>
</form-card>
