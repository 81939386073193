<mat-form-field appearance="fill" class="w-full overflow-visible">
  <mat-label *ngIf="!tilledLabel" class="text-xl {{ styles }}">{{ label }}</mat-label>
  <div *ngIf="tilledLabel" class="mb-4">
    <tilled-label-l1 [secondary]="true">{{ label }}</tilled-label-l1>
  </div>
  <input
    type="{{ fieldType }}"
    [formControlName]="name"
    matInput
    placeholder="{{ placeholder }}"
    [value]="streetWithNumber"
    #addresstext
  />
  <mat-hint id="field-hint" class="mb-0.5 pt-1" *ngIf="hint">{{ hint }}</mat-hint>
</mat-form-field>
