import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DEFAULT_PAGE_LIMIT } from 'app/core/constants';
import { TilledAlert } from 'app/core/models/tilled-alert';
import { MinorUnitsToCurrencyPipe } from 'app/core/pipes/minor-units-to-currency.pipe';
import { AlertService } from 'app/core/services/alert.service';
import { FileDownloadService } from 'app/core/services/file-download.service';
import { cloneDeep, sortBy } from 'lodash';
import { MarkdownModule } from 'ngx-markdown';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { Subscription } from 'rxjs';
import { FilesService } from '../../../../projects/tilled-api-client/src';
import { ActionListComponent } from '../action-list/action-list.component';
import { DashedEmptyCellDirective } from '../dashed-empty-cell.directive';
import { DateTooltipComponent } from '../date-tooltip/date-tooltip.component';
import { IsOverflowingDirective } from '../is-overflowing.directive';
import { LogoLoadedDirective } from '../logo-loaded.directive';
import { MerchantAppInvitationDialogComponent } from '../merchant-app-invitation/merchant-app-invitation-dialog.component';
import { TilledChipComponent } from '../tilled-chip/tilled-chip.component';
import { TilledProgressBarComponent } from '../tilled-progress-bar/tilled-progress-bar.component';
import { TilledHeadingH2Component } from '../tilled-text/tilled-heading/tilled-heading-h2.component';
import { TilledParagraphP1Component } from '../tilled-text/tilled-paragraph/tilled-paragraph-p1.component';
import { TilledParagraphP3Component } from '../tilled-text/tilled-paragraph/tilled-paragraph-p3.component';
import { TilledParagraphP4Component } from '../tilled-text/tilled-paragraph/tilled-paragraph-p4.component';
import { tableSymbol } from './decorators/column';
import { ColumnModel } from './decorators/column.model';
import { TableModel } from './decorators/table.model';

import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';

@Component({
  selector: 'tilled-table',
  templateUrl: './tilled-table.component.html',
  styleUrls: ['./tilled-table.component.scss'],
  standalone: true,
  imports: [
    MatTableModule,
    MatSortModule,
    NgxTippyModule,
    MatIconModule,
    TilledParagraphP4Component,
    MarkdownModule,
    TilledProgressBarComponent,
    MatButtonModule,
    TilledChipComponent,
    TilledParagraphP3Component,
    ActionListComponent,
    LogoLoadedDirective,
    DateTooltipComponent,
    MatTooltipModule,
    DashedEmptyCellDirective,
    IsOverflowingDirective,
    TilledHeadingH2Component,
    TilledParagraphP1Component,
    MatPaginatorModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MinorUnitsToCurrencyPipe,
    CommonModule,
  ],
})
export class TilledTableComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  private _data = [];
  private _originalData: any[] = [];
  private _tableModel: TableModel;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  private paginatorSub: Subscription;
  private sortSub: Subscription;
  public pageIndex = 0;
  public pageSize = DEFAULT_PAGE_LIMIT;
  public sortInfo = null;
  public isLoading = true;
  public unnamedColumns = [];
  public viewportMaxSize: boolean = false;
  public screenHeight: number;
  public screenWidth: number;
  public actionHover: boolean = false;
  public dialogRef: any;

  @Input() accountId: string;
  @Input() hidePaginator: boolean = false;
  @Input() hideZeroState: boolean = false;
  @Input() queryString: string;
  @Input() queryMerchantId: string;
  @Input() dataLength: number;
  @Input() noDataMainText?: string = 'No data found';
  @Input() noDataSecondaryText?: string = 'Do things to get data.';
  @Input() startingPageLimit: number = DEFAULT_PAGE_LIMIT;
  @Input() startingPageIndex: number = 0;
  @Input() testId: string;
  @Input() hideColumns: number[] = [];
  @Input() hideColumnKeys: string[] = [];
  @Input() scrollable: boolean = false;
  @Input() showDisplayedColumnsDropdown: boolean = false;
  @Input() filterArray: any[];
  @Input() hasPermissionToView: boolean = true;

  // it may be unnecessary to pass query data here
  @Input() getPageCallback: (limit?: number, index?: number, sort?: string, q?: string, filter_array?: any[]) => void;
  @Input() getClickCallback: (data: any, event?: MouseEvent) => void;

  @Input() set data(values: any[]) {
    if (values && values.length > 0) {
      this._data = cloneDeep(values);
      this._tableModel = this._data[0][tableSymbol];
      this.buildColumns();
      if (!this._originalData.length) {
        // Keep original order of data
        this._originalData = cloneDeep(this._data);
      }

      //no data, columns are defined, don't want to show 'empty' row
      if (Object.keys(this._data[0]).length === 0) {
        this._data = [];
      }
    }
  }

  get data(): any[] {
    return this._data;
  }

  @Input() instance: any;

  columns: ColumnModel[];
  displayedColumns: string[];

  constructor(
    private _filesService: FilesService,
    private _alertService: AlertService,
    private _matDialog: MatDialog,
    private _fileDownloadService: FileDownloadService,
  ) {}

  ngOnInit(): void {
    this.pageSize = this.startingPageLimit;
    this.pageIndex = this.startingPageIndex;
    this.onResize();
    if (this.hasPermissionToView === false) {
      this.isLoading = false;
    }
  }

  ngOnDestroy(): void {
    this.paginatorSub.unsubscribe();
    this.sortSub.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.paginatorSub = this.paginator.page.subscribe(() => {
      this.pageIndex = this.paginator.pageIndex;
      this.pageSize = this.paginator.pageSize;
      this.getPageCallback(this.pageSize, this.pageIndex, this.sortInfo, this.queryString, this.filterArray);
    });
    this.sortSub = this.sort.sortChange.subscribe(() => {
      if (!this.sort.active || this.sort.direction === '') {
        this.sortInfo = null;
        return;
      }
      this.sortInfo = this.sort.active + ':' + this.sort.direction;
      this.getPageCallback(this.pageSize, this.pageIndex, this.sortInfo, this.queryString, this.filterArray);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.queryString || (changes.filterArray && !changes.filterArray.firstChange)) {
      this.pageIndex = this.startingPageIndex;
      this.getPageCallback(this.pageSize, 0, this.sortInfo, this.queryString, this.filterArray);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth > 1520) {
      this.viewportMaxSize = true;
    } else {
      this.viewportMaxSize = false;
    }
  }

  sortData(params: Sort) {}

  openAlert(message: string, title: string, error?: boolean): void {
    const alert: TilledAlert = {
      message: message,
      type: error ? 'error' : 'info',
      timer: error ? null : 5000,
      title: title,
    };
    this._alertService.showAlert(alert);
  }

  rowClicked(data: any, event: MouseEvent): void {
    const clickedElement = event.target as HTMLElement;
    if ((clickedElement.closest('.mat-mdc-cell') && !clickedElement.closest('button')) || this.actionHover) {
      this.getClickCallback(data, event);
    }
  }

  actionClicked(data: any): void {
    if (this.getClickCallback) {
      this.getClickCallback(data);
    }
  }

  async downloadFileClicked(fileId: string, fileName: string): Promise<void> {
    this._fileDownloadService.download(fileId, fileName, this.accountId);
  }

  private buildColumns() {
    this.columns = this._tableModel.columns;
    this.sortColumns();
    this.displayedColumns = this.columns.map((col) => col.key);
    if (this.hideColumns) {
      for (const column of this.hideColumns) {
        this.hideColumnKeys.push(this.displayedColumns[column]);
      }
    }
    if (this.hideColumnKeys) {
      for (const key of this.hideColumnKeys) {
        this.displayedColumns = this.displayedColumns.filter((k) => k !== key);
      }
    }
    this.columns.forEach((col) => {
      if (!col.name && this.displayedColumns.includes(col.key)) {
        this.unnamedColumns.push(col.key);
      }
    });

    this.isLoading = false;
  }

  public onColumnSelectionChange(keys: MatSelectChange) {
    this.displayedColumns = keys.value.concat(this.unnamedColumns);
  }

  private sortColumns() {
    this.columns = sortBy(this.columns, ['order']);
  }

  public inviteNewMerchantUser(accountId: string, name: string): void {
    this.dialogRef = this._matDialog.open(MerchantAppInvitationDialogComponent, {
      autoFocus: false,
      data: {
        accountId: accountId,
        title: 'Share merchant application',
        legalName: name,
        showCopyUrlButton: true,
        submitButtonText: 'Send Invite via Email',
        showLinkToUsers: true,
      },
    });
  }
}
