<div>
  <mat-chip-listbox class="relative z-0">
    <span
      *ngIf="!hasDocuments && !icon"
      [matTooltip]="tooltipText"
      matTooltipPosition="above"
      [matTooltipDisabled]="false"
    >
      <mat-chip-option
        id="tilled-chip"
        class="min-w-10 truncate text-sm"
        disabled
        [matTooltip]="tooltipText"
        matTooltipPosition="above"
        [matTooltipDisabled]="false"
        [color]="colorClass"
        >{{ displayStatus }}
      </mat-chip-option>
    </span>
    <span
      *ngIf="!hasDocuments && icon && !hasMatMenu"
      [matTooltip]="tooltipText"
      matTooltipPosition="above"
      [matTooltipDisabled]="false"
    >
      <mat-chip-option
        id="tilled-chip"
        class="min-w-10 truncate text-sm"
        disabled
        [matTooltip]="tooltipText"
        matTooltipPosition="above"
        [matTooltipDisabled]="false"
        [color]="colorClass"
        >{{ displayStatus }}
        <mat-icon class="icon-size-4"></mat-icon>
      </mat-chip-option>
    </span>
    <span
      *ngIf="((hasDocuments && !icon) || (!hasDocuments && icon)) && hasMatMenu"
      [matMenuTriggerFor]="documentsMenu"
      #menuTrigger="matMenuTrigger"
      (mouseenter)="openDocumentsMenu(menuTrigger)"
      (mouseleave)="closeDocumentsMenu(menuTrigger)"
    >
      <mat-chip-option
        id="tilled-chip"
        disabled
        class="min-w-10 cursor-pointer truncate text-sm"
        [color]="colorClass"
        [matMenuTriggerFor]="documentsMenu"
        #menuTrigger="matMenuTrigger"
        (mouseenter)="openDocumentsMenu(menuTrigger)"
        (mouseleave)="closeDocumentsMenu(menuTrigger)"
        >{{ displayStatus }}
        <mat-icon class="icon-size-4"></mat-icon>
      </mat-chip-option>
    </span>
    <span
      *ngIf="hasDocuments && icon"
      [matMenuTriggerFor]="documentsMenu"
      #menuTrigger="matMenuTrigger"
      (mouseenter)="openDocumentsMenu(menuTrigger)"
      (mouseleave)="closeDocumentsMenu(menuTrigger)"
    >
      <mat-chip-option
        id="tilled-chip"
        disabled
        class="min-w-10 cursor-pointer truncate text-sm"
        [color]="colorClass"
        [matMenuTriggerFor]="documentsMenu"
        #menuTrigger="matMenuTrigger"
        (mouseenter)="openDocumentsMenu(menuTrigger)"
        (mouseleave)="closeDocumentsMenu(menuTrigger)"
        >{{ displayStatus }}
        <mat-icon class="icon-size-4"></mat-icon>
        <mat-icon class="icon-size-4"></mat-icon>
      </mat-chip-option>
    </span>

    <mat-icon
      *ngIf="icon"
      [color]="colorClass"
      [ngClass]="{
        'text-white': solidColor,
        'text-[#59c288]': !solidColor && colorClass == 'opaque_green',
        'text-[#f8d562]': !solidColor && colorClass == 'opaque_yellow',
        'text-[#ea4628]': !solidColor && colorClass == 'opaque_red',
        'text-[#304166]': !solidColor && colorClass == 'opaque_gray',
        'text-[#03a9f4]': !solidColor && colorClass == 'opaque_blue',
        'text-[#1b253b]': !solidColor && colorClass == 'opaque_accent',
        'text-[#ffa000]': !solidColor && colorClass == 'opaque_orange',
        'text-[#6c6dde]': !solidColor && colorClass == 'opaque_purple',
        'text-[#677489]': !solidColor && colorClass == 'opaque_neutral',
        '-ml-7': !hasDocuments,
        '-ml-11': hasDocuments
      }"
      class="pointer-events-none z-10 icon-size-4"
      [svgIcon]="icon"
    ></mat-icon>
    <mat-icon
      *ngIf="requestedDocs"
      [ngClass]="{ 'text-white': solidColor, 'text-warn-500': !solidColor, '-ml-7': !icon }"
      class="pointer-events-none z-10 icon-size-4"
      [svgIcon]="'heroicons_solid:chevron-down'"
    ></mat-icon>
    <mat-icon
      *ngIf="submittedDocs"
      [ngClass]="{ 'text-white': solidColor, 'text-[#6c6dde]': !solidColor, '-ml-7': !icon }"
      class="pointer-events-none z-10 icon-size-4"
      [svgIcon]="'heroicons_solid:chevron-down'"
    ></mat-icon>

    <div class="z-5 relative pl-6 sm:right-6 sm:pl-0">
      <mat-menu
        #documentsMenu="matMenu"
        [hasBackdrop]="false"
        class="-ml-8 overflow-hidden rounded-[10px] bg-tilled-primary text-white"
      >
        <div mat-menu-item class="h-fit py-1" [disabled]="true" *ngIf="topNote">
          <span class="flex items-start text-left text-white">
            <span style="white-space: pre-line">
              <tilled-paragraph-p4 [white]="true"> {{ topNote }} </tilled-paragraph-p4>
            </span>
          </span>
        </div>
        <div mat-menu-item class="h-fit py-1" [disabled]="true" *ngIf="capabilities">
          <span class="flex items-start text-left text-white">
            <span style="white-space: pre-line">
              <tilled-paragraph-p4 [bold]="true" [white]="true"> Capabilities: </tilled-paragraph-p4>
              <tilled-paragraph-p4 [white]="true" *ngFor="let cap of capabilities">{{ cap }}</tilled-paragraph-p4>
            </span>
          </span>
        </div>
        <div mat-menu-item class="h-fit py-1" [disabled]="true" *ngIf="requestedDocs">
          <span class="flex items-start text-left text-white">
            <span style="white-space: pre-line">
              <tilled-paragraph-p4 [bold]="true" [white]="true"> Requested Documents: </tilled-paragraph-p4>
              <tilled-paragraph-p4 [white]="true" *ngFor="let doc of requestedDocs.slice(0, 10)"
                >&nbsp;&nbsp;&bull; {{ doc }}</tilled-paragraph-p4
              >
            </span>
          </span>
        </div>
        <div mat-menu-item class="h-fit py-1" [disabled]="true" *ngIf="submittedDocs">
          <span class="flex items-start text-left text-white">
            <span style="white-space: pre-line">
              <tilled-paragraph-p4 [bold]="true" [white]="true"> Documents In Review: </tilled-paragraph-p4>
              <tilled-paragraph-p4 [white]="true" *ngFor="let doc of submittedDocs.slice(0, 10)"
                >&nbsp;&nbsp;&bull; {{ doc }}</tilled-paragraph-p4
              >
            </span>
          </span>
        </div>
        <div mat-menu-item class="h-fit py-1" [disabled]="true" *ngIf="bottomNote">
          <span class="flex items-start text-left text-white">
            <span style="white-space: pre-line">
              <tilled-paragraph-p4 [white]="true"> {{ bottomNote }} </tilled-paragraph-p4>
            </span>
          </span>
        </div>
      </mat-menu>
    </div>
  </mat-chip-listbox>
</div>
