<div class="my-[5px] grid w-full grid-cols-2">
  <tilled-paragraph-p3 class="justify-self-start" [secondary]="true">
    {{ !useTitlecase ? labelText : (labelText | titlecase) }}
    <mat-icon
      *ngIf="labelInfoTooltip"
      class="align-center ml-[2px] fill-accent-500 text-accent-500 icon-size-3"
      [svgIcon]="'mat_outline:help_outline'"
      [matTooltip]="labelInfoTooltip"
    ></mat-icon>
  </tilled-paragraph-p3>

  <ng-container *ngIf="valueInfoTooltip">
    <tilled-paragraph-p3
      breakWords="true"
      class="relative justify-self-end text-right"
      matTooltipClass="multiline-tooltip"
      [matTooltip]="valueInfoTooltip"
      [matTooltipPosition]="'right'"
    >
      <a *ngIf="urlValue; else noUrl" class="text-primary" [href]="urlValue">{{ valueText }} </a>
      <ng-template #noUrl>{{ nullOrEmptyText(valueText) }}</ng-template>
      <mat-icon
        class="absolute top-[50%] -right-[14px] translate-y-[-50%] text-accent-500 icon-size-3"
        [svgIcon]="'mat_outline:help_outline'"
        [matTooltip]="valueInfoTooltip"
      ></mat-icon>
    </tilled-paragraph-p3>
  </ng-container>
  <!-- We wrap a div around tilled-paragraph-p3 so break-works works properly -->
  <div *ngIf="!valueInfoTooltip">
    <tilled-paragraph-p3 breakWords="true" class="justify-self-end text-right">
      <a *ngIf="urlValue; else noUrl" class="text-primary" [href]="urlValue">{{ valueText }} </a>
      <ng-template #noUrl>{{ nullOrEmptyText(valueText) }}</ng-template>
    </tilled-paragraph-p3>
  </div>
</div>
