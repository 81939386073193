import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    selector: '[dashedEmptyCell]',
    standalone: true
})
export class DashedEmptyCellDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    // Check if the element has no children
    if (this.el.nativeElement.children.length === 0 && this.el.nativeElement.textContent.trim() === "") {
      // Apply the specified class if there are no children
      this.renderer.addClass(this.el.nativeElement, "after:content-['-']");
    }
  }
}
