import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ComponentBase } from 'app/core/componentBase';
import { FuseAlertComponent } from '../../../../../@fuse/components/alert/alert.component';
import { TilledButtonComponent } from '../../../buttons/tilled-button.component';
import { TilledHeadingH6Component } from '../../../tilled-text/tilled-heading/tilled-heading-h6.component';
import { TilledParagraphP3Component } from '../../../tilled-text/tilled-paragraph/tilled-paragraph-p3.component';

@Component({
  selector: 'merchant-app-alert',
  templateUrl: './merchant-app-alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FuseAlertComponent,
    MatIconModule,
    TilledHeadingH6Component,
    TilledParagraphP3Component,
    TilledButtonComponent,
    CommonModule,
  ],
})
export class MerchantAppAlertComponent extends ComponentBase implements OnInit {
  @Output() editClick: EventEmitter<string> = new EventEmitter<string>();

  @Input() alertName: string;
  @Input() title: string;
  @Input() description: string;
  @Input() subDescriptionArray: string[];

  constructor() {
    super();
  }

  ngOnInit() {}

  onButtonClick(text) {
    this.editClick.emit();
  }
}
