import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sentenceCase',
    standalone: true,
})
export class SentenceCasePipe implements PipeTransform {
  private specialCharsRegex = /[^a-z ' A-Z]|[^\/][\W]( ?= )[^a-zA-Z]/g;
  private exactMatchRegex = /\b(URL|API|ID|IC|EFT|ACH|ABA|DBA|SSN)\b/g;
  private splitRegex = new RegExp(`(${this.specialCharsRegex.source})`);

  transform(val: string): string {
    if (!val) return val;

    const segments = val.split(this.splitRegex);
    const result = segments
      .map((segment) => (segment && !this.specialCharsRegex.test(segment) ? this.processSegment(segment) : segment))
      .join('');

    return result;
  }

  private processSegment(segment: string): string {
    const words = segment.split(' ');

    const processedWords = words.map((word, index) => {
      if (this.exactMatchRegex.test(word)) {
        return word.toUpperCase();
      } else if (index === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      } else {
        return word.toLowerCase();
      }
    });

    return processedWords.join(' ');
  }
}
