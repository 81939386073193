import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { TilledCardRowComponent } from '../../tilled-card-row/tilled-card-row.component';

import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { FuseCardComponent } from '../../../../@fuse/components/card/card.component';
import { TilledHeadingH4Component } from '../../tilled-text/tilled-heading/tilled-heading-h4.component';

@Component({
  selector: 'tilled-card-underline',
  templateUrl: './tilled-card-underline.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [FuseCardComponent, TilledHeadingH4Component, MatDividerModule, TilledCardRowComponent, CommonModule],
})
export class TilledCardUnderlineComponent implements OnInit, OnChanges {
  @Input() headingText: string;
  @Input() rowsData: TilledCardRowData[];

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}
}

export interface TilledCardRowData {
  labelText: string;
  valueText: string;
  tooltipText?: string;
  urlValue?: string;
}
