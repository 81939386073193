import { Pipe, PipeTransform } from '@angular/core';
import {
  InternalAccount,
  InternalAccountCapability,
  PricingTemplate,
  ProductCode,
} from '../../../../projects/tilled-api-client/src';

@Pipe({
    name: 'capabilityStatus',
    standalone: true,
})
export class CapabilityStatusPipe implements PipeTransform {
  constructor() {}

  /**
   *
   * @param account InternalAccount object
   * @param type The type of capability whose status you are looking for
   * @returns
   */
  transform(account: InternalAccount, type: string): InternalAccountCapability.StatusEnum | string {
    const capability = this.getCapability(account, type);

    if (!capability) {
      return 'REQUIRES PRICING';
    } else {
      return capability.status;
    }
  }

  public getCapability(account: InternalAccount, type: string): InternalAccountCapability {
    switch (type) {
      case 'card':
        const cardCapabilities = account.capabilities?.filter((cap) => {
          if (cap.pricing_template) {
            if (cap.pricing_template.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.CARD) {
              return cap;
            }
          } else if (cap.product_code) {
            if (cap.product_code.payment_method_type === ProductCode.PaymentMethodTypeEnum.CARD) {
              return cap;
            }
          }
        });
        return this.getCurrentCapability(cardCapabilities);
      case 'card_present':
        const cpCapabilities = account.capabilities?.filter((cap) => {
          if (cap.pricing_template) {
            if (cap.pricing_template.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.CARD_PRESENT) {
              return cap;
            }
          } else if (cap.product_code) {
            if (cap.product_code.payment_method_type === ProductCode.PaymentMethodTypeEnum.CARD_PRESENT) {
              return cap;
            }
          }
        });
        return this.getCurrentCapability(cpCapabilities);
      case 'debit':
        const debitCapabilities = account.capabilities?.filter((cap) => {
          if (cap.pricing_template) {
            if (
              cap.pricing_template.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.ACH_DEBIT ||
              cap.pricing_template.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.EFT_DEBIT
            ) {
              return cap;
            }
          } else if (cap.product_code) {
            if (
              cap.product_code.payment_method_type === ProductCode.PaymentMethodTypeEnum.ACH_DEBIT ||
              cap.product_code.payment_method_type === ProductCode.PaymentMethodTypeEnum.EFT_DEBIT
            ) {
              return cap;
            }
          }
        });
        return this.getCurrentCapability(debitCapabilities);
    }
    return null;
  }

  private getCurrentCapability(capabilities: InternalAccountCapability[]): InternalAccountCapability {
    if (!capabilities || capabilities.length === 0) {
      return null;
    }

    const sortedCapabilities = capabilities.sort((a, b) => {
      if (
        a.status === InternalAccountCapability.StatusEnum.ACTIVE &&
        b.status !== InternalAccountCapability.StatusEnum.ACTIVE
      ) {
        return -1;
      } else if (
        a.status !== InternalAccountCapability.StatusEnum.DISABLED &&
        b.status === InternalAccountCapability.StatusEnum.DISABLED
      ) {
        return -1;
      } else {
        return 0;
      }
    });

    return sortedCapabilities[0];
  }
}
