import { Router } from '@angular/router';
import { CardPricingTemplateDto, DebitPricingTemplateDto } from 'app/core/models/pricing-templates';
import { ChipColorClass, TilledChipConfig } from 'app/shared/tilled-chip/tilled-chip.component';
import { PricingTemplate } from '../../../../projects/tilled-api-client/src';

export class GenericPricingTemplateListItem {
  public template: CardPricingTemplateDto | DebitPricingTemplateDto;
  public canClick: boolean = true;

  constructor(private _router: Router) {}

  generateChipConfig(): TilledChipConfig {
    if (this.template.status === PricingTemplate.StatusEnum.ACTIVE) {
      return {
        text: 'ACTIVE',
        toolTip: 'This Pricing Template is Active',
        color: ChipColorClass.SOLID_GREEN,
      };
    } else if (this.template.status === PricingTemplate.StatusEnum.PENDING) {
      return {
        text: 'PENDING',
        toolTip: 'This Pricing Template is Pending',
        color: ChipColorClass.SOLID_YELLOW,
      };
    }
  }
  onTemplateClick() {
    if (this.canClick) {
      this._router.navigate([`/pricing-templates/${this.template.id}`]);
    }
  }
}
