<div class="no-scrollbar max-h-[95svh] w-fit sm:min-w-120">
  <mat-stepper #stepper labelPosition="bottom" [animationDuration]="700">
    <mat-step label="New Payment" color="primary">
      <div class="flex">
        <form-card
          (exitClick)="closeDialog()"
          (nextClick)="nextPage()"
          [backButton]="false"
          [submitButton]="newPaymentForm.get('paymentMethod').value === 'card_present'"
          (submitClick)="collectCardPresentPayment()"
          [nextButton]="newPaymentForm.get('paymentMethod').value !== 'card_present'"
          nextButtonText="Enter Payment Method Details"
          [nextDisabled]="newPaymentForm.invalid"
          [submitDisabled]="
            newPaymentForm.invalid ||
            (newPaymentForm.get('paymentMethod').value === 'card_present' && !newPaymentForm.get('terminalIds').value)
          "
          [titleText]="'Collect Payment Manually'"
          [secondaryText]="'Enter payment details below to collect a payment manually.'"
          [testIds]="{ headerTestId: 'modal-header' }"
        >
          <form [formGroup]="newPaymentForm" class="-mt-2">
            <div>
              <ng-container *ngIf="!isMerchant">
                <app-merchant-selector
                  labelName="Merchant"
                  inputAppearance="fill"
                  additionalClasses="w-full"
                  (setMerchantAccount)="merchantAccountChanged($event)"
                >
                </app-merchant-selector>
              </ng-container>
              <div *ngIf="merchantAccount && availablePaymentMethods.size !== 1" class="pt-4">
                <tilled-label-l1 secondary="true">Payment method</tilled-label-l1>
                <mat-radio-group
                  [formControlName]="'paymentMethod'"
                  class="space-x-4 space-y-3"
                  data-test-id="payment-method"
                >
                  <mat-radio-button
                    *ngFor="let paymentMethod of availablePaymentMethods | keyvalue"
                    [value]="paymentMethod.key"
                    >{{ paymentMethod.value | sentenceCase }}</mat-radio-button
                  >
                </mat-radio-group>
              </div>
              <tilled-input
                label="Amount"
                placeholder="Enter the amount"
                name="amount"
                [errors]="newPaymentForm.get('amount').hasError('required') && newPaymentForm.get('amount').touched"
                errorMessage="A valid payment amount is required"
                tilledLabel="true"
                showCurrency="true"
                mask="separator.2"
                data-test-id="payment-amount"
                [suffixMultiple]="false"
                [suffixOptions]="availableCurrencies"
                [suffixControlName]="newPaymentForm.get('currency')"
                [suffixPlaceholder]="selectedCurrency ? availableCurrencies[0]?.label : ''"
              >
              </tilled-input>
              <div *ngIf="newPaymentForm.get('paymentMethod').value === 'card_present'" class="mt-5 flex flex-col">
                <tilled-label-l1 secondary="true">Select Capture Method </tilled-label-l1>
                <mat-radio-group [formControlName]="'captureMethod'" class="space-x-4 pt-3">
                  <mat-radio-button [value]="'automatic'">Sale </mat-radio-button>
                  <mat-radio-button [value]="'manual'">Auth </mat-radio-button>
                </mat-radio-group>
              </div>
              <div
                *ngIf="newPaymentForm.get('paymentMethod').value === 'card_present'"
                class="-mb-2 flex min-w-80 flex-col"
              >
                <div class="w-full pb-2.5 pt-4">
                  <tilled-label-l1 secondary="true">Select Terminal</tilled-label-l1>
                </div>
                <tilled-select
                  [options]="availableTerminals"
                  [multiple]="false"
                  [controlName]="newPaymentForm.get('terminalIds')"
                  placeholder="Select a terminal"
                  classOverride="fuse-mat-dense fuse-mat-rounded w-full"
                  data-test-id="terminal-ids"
                ></tilled-select>
              </div>
            </div>
          </form>
        </form-card>
      </div>
    </mat-step>

    <mat-step label="Collect Payment Details">
      <!-- Credit Card -->
      <div [ngClass]="selectedPaymentType == 'card' ? 'visible grid min-w-100 sm:min-w-[700px]' : 'hidden'">
        <form-card
          (exitClick)="closeDialog()"
          (backClick)="prevPage()"
          [backButton]="true"
          [submitButton]="true"
          (submitClick)="collectCardPayment()"
          [submitDisabled]="cardPaymentForm.invalid || tilledCardForm?.invalid || (submittingPayment$ | async)"
          [submitText]="'Collect Payment'"
          [displayAlert]="alertDialogError$ | async"
          [showAlert$]="alertDialogError$"
          [alertTitle]="errorTitle"
          [alertMessage]="errorMessage"
          [maxWidth]="'max-w-160'"
          [titleText]="'Enter Payment Details'"
          [secondaryText]="'Enter payment details below.'"
          [testIds]="{ headerTestId: 'credit-card-header' }"
        >
          <form [formGroup]="cardPaymentForm">
            <div class="flex flex-col">
              <div class="-mt-4 grid w-[full] grid-cols-2 gap-x-6 gap-y-1">
                <div class="col-span-4 flex flex-col items-center">
                  <div class="mb-5 w-32">
                    <div id="native-payment-element"></div>
                  </div>
                </div>
                <div class="col-span-2 flex flex-col">
                  <div class="flex w-full flex-col">
                    <div class="mt-2 w-full pb-2.5">
                      <tilled-label-l1 secondary="true" class="pb-1.5">Credit card number</tilled-label-l1>
                    </div>
                    <div class="inputField inline-flex h-9 flex-row-reverse items-center justify-end overflow-hidden">
                      <div id="card-number-element"></div>
                      <div
                        class="relative -ml-2 flex h-6 w-10 items-center justify-center rounded-md border-opacity-20"
                        id="card-suffix"
                      >
                        <mat-icon>credit_card</mat-icon>
                      </div>
                    </div>
                    <div class="pt-1 text-sm text-warn" *ngIf="showCardNumberError; else noCardNumberError" @fadeIn>
                      A valid credit card number is required
                    </div>
                    <ng-template #noCardNumberError>
                      <div class="pt-1 text-sm">&nbsp;</div>
                    </ng-template>
                  </div>
                </div>
                <div class="col-span-1 flex w-[170px] flex-col">
                  <div class="flex w-full flex-col">
                    <div class="mt-2 w-full pb-2.5">
                      <tilled-label-l1 secondary="true" class="pb-1.5">Expiration</tilled-label-l1>
                    </div>
                    <div class="inputField" id="card-expiration-element"></div>
                    <div class="pt-1 text-sm text-warn" *ngIf="showExpirationError; else noExpirationError" @fadeIn>
                      A valid expiration date is required
                    </div>
                    <ng-template #noExpirationError>
                      <div class="pt-1 text-sm">&nbsp;</div>
                    </ng-template>
                  </div>
                </div>
                <div class="col-span-1 flex w-[170px] flex-col">
                  <div class="flex w-full flex-col">
                    <div class="mt-2 w-full pb-2.5">
                      <tilled-label-l1 secondary="true" class="pb-1.5">CVV</tilled-label-l1>
                    </div>
                    <div class="inputField" id="card-cvv-element"></div>
                    <div class="pt-1 text-sm text-warn" *ngIf="showCvvError; else noCvvError" @fadeIn>
                      A valid CVV number is required
                    </div>
                    <ng-template #noCvvError>
                      <div class="pt-1 text-sm">&nbsp;</div>
                    </ng-template>
                  </div>
                </div>
                <!-- new row -->
                <div class="col-span-2 -mt-2 flex flex-col">
                  <tilled-input
                    label="Name on Card"
                    placeholder="Enter the name on the card"
                    name="cardholderName"
                    [errors]="
                      cardPaymentForm.get('cardholderName').hasError('required') &&
                      cardPaymentForm.get('cardholderName').touched
                    "
                    errorMessage="A name on card is required"
                    tilledLabel="true"
                    data-test-id="cardholder-name"
                  ></tilled-input>
                </div>
                <div class="col-span-2 -mt-2 flex flex-col">
                  <tilled-input
                    label="Billing Zip"
                    placeholder="12345"
                    maxLength="10"
                    name="postalCode"
                    [errors]="
                      cardPaymentForm.get('postalCode').hasError('required') &&
                      cardPaymentForm.get('postalCode').touched
                    "
                    errorMessage="A billing ZIP is required"
                    tilledLabel="true"
                    data-test-id="billing-zip"
                  ></tilled-input>
                </div>
              </div>
              <!-- end of Card Info 4 col grid-->

              <!-- start of Billing Address toggle -->
              <div class="pt-6">
                <mat-slide-toggle
                  class="pb-1.5"
                  color="primary"
                  (change)="cardAddressToggled($event)"
                  [(ngModel)]="cardAddressIsChecked"
                  [ngModelOptions]="{ standalone: true }"
                  data-test-id="billing-address-toggle"
                >
                  <tilled-paragraph-p3>Billing Address</tilled-paragraph-p3>
                </mat-slide-toggle>
                <tilled-paragraph-p4>Optional</tilled-paragraph-p4>
                <div *ngIf="cardAddressIsChecked" @expandCollapse>
                  <div class="grid w-full grid-cols-1 gap-x-6 sm:grid-cols-4">
                    <div class="col-span-2 flex flex-col">
                      <tilled-input
                        label="Address line 1"
                        name="street1"
                        [errors]="
                          cardPaymentForm.get('street1').hasError('required') && cardPaymentForm.get('street1').touched
                        "
                        errorMessage="A valid address is required"
                        tilledLabel="true"
                        placeholder="Enter street address"
                        data-test-id="billing-address-1"
                      ></tilled-input>
                    </div>
                    <div class="col-span-2 flex flex-col">
                      <tilled-input
                        label="Address line 2"
                        name="street2"
                        tilledLabel="true"
                        placeholder="Suite / Apt / Unit (Optional)"
                        data-test-id="billing-address-2"
                      ></tilled-input>
                    </div>
                    <div class="col-span-1 flex flex-col">
                      <div class="w-full pb-2.5 pt-2">
                        <tilled-label-l1 secondary="true">Country</tilled-label-l1>
                      </div>
                      <tilled-select
                        [multiple]="false"
                        [options]="countryCodeMap"
                        [controlName]="cardPaymentForm.get('country')"
                        placeholder="Select a country"
                        classOverride="fuse-mat-dense fuse-mat-rounded w-full"
                        data-test-id="billing-country"
                      ></tilled-select>
                    </div>
                    <div class="col-span-1 flex flex-col">
                      <div class="w-full pb-2.5 pt-2">
                        <tilled-label-l1 secondary="true">State</tilled-label-l1>
                      </div>
                      <tilled-select
                        [multiple]="false"
                        [options]="stateCodeMap"
                        [controlName]="cardPaymentForm.get('state')"
                        placeholder="Select a state"
                        classOverride="fuse-mat-dense fuse-mat-rounded w-full"
                        data-test-id="billing-state"
                      ></tilled-select>
                    </div>
                    <div class="col-span-2 -mt-2 flex flex-col">
                      <tilled-input
                        label="City"
                        placeholder="Enter the city"
                        name="city"
                        [errors]="
                          cardPaymentForm.get('city').hasError('required') && cardPaymentForm.get('city').touched
                        "
                        errorMessage="A valid city is required"
                        tilledLabel="true"
                        data-test-id="billing-city"
                      ></tilled-input>
                    </div>
                  </div>
                  <!-- end of Billing Address 4 column grid -->
                </div>
              </div>
              <!-- end of Billing Address toggle -->

              <!-- start of Level 3 toggle -->
              <div class="pt-6" formGroupName="level3Form">
                <mat-slide-toggle
                  class="pb-1.5"
                  data-test-id="level-3-toggle"
                  color="primary"
                  (change)="cardLevel3Toggled($event)"
                  [(ngModel)]="cardLevel3IsChecked"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <tilled-paragraph-p3>Level II/III Data</tilled-paragraph-p3>
                </mat-slide-toggle>
                <tilled-paragraph-p4>Optional</tilled-paragraph-p4>
                <div *ngIf="cardLevel3IsChecked" @expandCollapse>
                  <div class="pb-2 pt-6">
                    <tilled-heading-h5>Shipping Details</tilled-heading-h5>
                  </div>
                  <div class="grid w-full grid-cols-3 gap-x-6">
                    <div class="col-span-1 flex flex-col">
                      <div class="w-full pb-2.5 pt-4">
                        <tilled-label-l1 secondary="true">Ship to country</tilled-label-l1>
                      </div>
                      <mat-form-field floatLabel="always" appearance="fill" class="flex w-full flex-col">
                        <mat-select
                          data-test-id="level-3-country"
                          class="text-secondary text-p3"
                          formControlName="shipToCountry"
                          placeholder="Select a country"
                        >
                          <mat-option *ngFor="let country of countryCodeMap" [value]="country.value">
                            {{ country.label }}
                          </mat-option>
                        </mat-select>
                        <mat-icon
                          matSuffix
                          class="text-secondary icon-size-5"
                          [svgIcon]="'heroicons_outline:chevron-down'"
                        ></mat-icon>
                      </mat-form-field>
                    </div>
                    <div class="col-span-1 flex flex-col">
                      <tilled-input
                        label="Ship to Zip"
                        placeholder="12345"
                        maxlength="10"
                        groupName="level3Form"
                        name="shipToZip"
                        tilledLabel="true"
                        data-test-id="level-3-ship-to-zip"
                      ></tilled-input>
                    </div>
                    <div class="col-span-1 flex flex-col">
                      <tilled-input
                        label="Ship from Zip"
                        placeholder="12345"
                        maxlength="10"
                        groupName="level3Form"
                        name="shipFromZip"
                        tilledLabel="true"
                        data-test-id="level-3-ship-from-zip"
                      ></tilled-input>
                    </div>
                    <!-- new row -->
                    <div class="col-span-1 flex flex-col">
                      <tilled-input
                        label="Shipping Amount"
                        name="shipAmount"
                        groupName="level3Form"
                        tilledLabel="true"
                        showCurrency="true"
                        mask="separator.2"
                        placeholder="Enter shipping amount"
                        data-test-id="level-3-shipping-amount"
                      ></tilled-input>
                    </div>
                    <div class="col-span-1 flex flex-col">
                      <tilled-input
                        label="Duty Amount"
                        name="dutyAmount"
                        groupName="level3Form"
                        tilledLabel="true"
                        showCurrency="true"
                        mask="separator.2"
                        placeholder="Enter duty amount"
                        data-test-id="level-3-duty-amount"
                      ></tilled-input>
                    </div>
                  </div>
                  <!-- end of Level 3 - 3 column grid -->

                  <div class="flex w-full flex-col pt-8">
                    <app-line-items
                      [lineItems]="lineItems"
                      [updateLineItemsCallback]="updateLineItems"
                    ></app-line-items>
                  </div>
                </div>
              </div>
              <!-- end of Level 3 toggle -->
            </div>
          </form>
        </form-card>
      </div>

      <!-- ACH Debit -->
      <div
        [ngClass]="
          selectedPaymentType == 'ach_debit' || selectedPaymentType == 'eft_debit'
            ? 'visible grid min-w-100 sm:min-w-[700px]'
            : 'hidden'
        "
      >
        <form-card
          (exitClick)="closeDialog()"
          (backClick)="prevPage()"
          [backButton]="true"
          [submitButton]="true"
          (submitClick)="collectBankPayment()"
          [submitDisabled]="bankPaymentForm.invalid || tilledBankForm?.invalid || (submittingPayment$ | async)"
          [submitText]="'Collect Payment'"
          [displayAlert]="alertDialogError$ | async"
          [showAlert$]="alertDialogError$"
          [alertTitle]="errorTitle"
          [alertMessage]="errorMessage"
          [maxWidth]="'max-w-180'"
          [titleText]="'Enter Payment Details'"
          [secondaryText]="'Enter payment details below.'"
          [testIds]="{ headerTestId: 'ach-header' }"
        >
          <form [formGroup]="bankPaymentForm">
            <div class="flex flex-col">
              <div class="-mt-4 grid w-[full] grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-4">
                <div class="col-span-2 flex flex-col">
                  <div class="flex w-full flex-col">
                    <tilled-label-l1 secondary="true" class="pb-2.5 pt-4">Account Number</tilled-label-l1>
                    <div class="inputField" id="bank-account-number-element"></div>
                    <div
                      class="pt-1 text-sm text-warn"
                      *ngIf="showAccountNumberError; else noAchAccountNumberError"
                      @fadeIn
                    >
                      A valid account number is required
                    </div>
                    <ng-template #noAchAccountNumberError>
                      <div class="pt-1 text-sm">&nbsp;</div>
                    </ng-template>
                  </div>
                </div>
                <div
                  [ngClass]="
                    selectedPaymentType == 'ach_debit'
                      ? 'col-span-1 flex w-[170px] flex-col'
                      : 'col-span-2 flex flex-col'
                  "
                >
                  <div class="flex w-full flex-col">
                    <ng-container *ngIf="selectedPaymentType == 'ach_debit'; else eftLabel">
                      <tilled-label-l1 secondary="true" class="w-full pb-2.5 pt-4">Routing Number</tilled-label-l1>
                    </ng-container>
                    <ng-template #eftLabel>
                      <tilled-label-l1 secondary="true" class="w-full pb-2.5 pt-4"
                        >Transit Number and Institution Id</tilled-label-l1
                      >
                    </ng-template>
                    <div class="inputField" id="bank-routing-number-element"></div>
                    <div
                      class="pt-1 text-sm text-warn"
                      *ngIf="showRoutingNumberError; else noRoutingNumberError"
                      @fadeIn
                    >
                      A valid
                      {{ selectedPaymentType == 'ach_debit' ? 'routing number' : 'transit number and institution ID' }}
                      is required
                    </div>

                    <ng-template #noRoutingNumberError>
                      <div class="pt-1 text-sm">&nbsp;</div>
                    </ng-template>
                  </div>
                </div>
                <div *ngIf="selectedPaymentType == 'ach_debit'" class="col-span-1 flex flex-col">
                  <div class="w-full pb-2.5 pt-4">
                    <tilled-label-l1 secondary="true">Account type</tilled-label-l1>
                  </div>
                  <tilled-select
                    [multiple]="false"
                    [options]="[
                      { value: 'checking', label: 'Checking' },
                      { value: 'savings', label: 'Savings' }
                    ]"
                    [controlName]="bankPaymentForm.get('accountType')"
                    placeholder="Select an account type"
                    classOverride="fuse-mat-dense fuse-mat-rounded flex w-full flex-col"
                    [errors]="
                      bankPaymentForm.get('accountType').hasError('required') &&
                      bankPaymentForm.get('accountType').touched
                    "
                    errorMessage="An account type is required"
                    data-test-id="ach-account-type"
                  ></tilled-select>
                </div>
                <!-- new row -->
                <div class="col-span-4 -mt-4 flex flex-col">
                  <tilled-input
                    label="Account Holder Name"
                    name="accountholderName"
                    [errors]="
                      bankPaymentForm.get('accountholderName').hasError('required') &&
                      bankPaymentForm.get('accountholderName').touched
                    "
                    errorMessage="An account holder name is required"
                    tilledLabel="true"
                    placeholder="Enter account holder name"
                    data-test-id="ach-account-holder"
                  ></tilled-input>
                </div>

                <!-- billing details -->
                <div class="col-span-2 flex flex-col">
                  <tilled-input
                    label="Address line 1"
                    name="street1"
                    [errors]="
                      bankPaymentForm.get('street1').hasError('required') && bankPaymentForm.get('street1').touched
                    "
                    errorMessage="An address line 1 is required"
                    tilledLabel="true"
                    placeholder="Enter street address"
                    data-test-id="ach-address-1"
                  ></tilled-input>
                </div>
                <div class="col-span-2 flex flex-col">
                  <tilled-input
                    label="Address line 2"
                    name="street2"
                    tilledLabel="true"
                    placeholder="Suite / Apt / Unit (Optional)"
                    data-test-id="ach-address-2"
                  >
                  </tilled-input>
                </div>
                <!-- new row -->
                <div class="col-span-1 flex flex-col">
                  <div class="w-full pb-2.5 pt-2">
                    <tilled-label-l1 secondary="true">Country</tilled-label-l1>
                  </div>
                  <tilled-select
                    [multiple]="false"
                    [options]="countryCodeMap"
                    [controlName]="bankPaymentForm.get('country')"
                    placeholder="Select a country"
                    classOverride="fuse-mat-dense fuse-mat-rounded w-full"
                    [errors]="
                      bankPaymentForm.get('country').hasError('required') && bankPaymentForm.get('country').touched
                    "
                    errorMessage="A country is required"
                    data-test-id="ach-country"
                  ></tilled-select>
                </div>
                <div class="col-span-1 flex flex-col">
                  <div class="w-full pb-2.5 pt-2">
                    <tilled-label-l1 secondary="true">State</tilled-label-l1>
                  </div>
                  <tilled-select
                    [multiple]="false"
                    [options]="stateCodeMap"
                    [controlName]="bankPaymentForm.get('state')"
                    placeholder="Select a state"
                    classOverride="fuse-mat-dense fuse-mat-rounded w-full"
                    [errors]="bankPaymentForm.get('state').hasError('required') && bankPaymentForm.get('state').touched"
                    errorMessage="A state is required"
                    data-test-id="ach-state"
                  ></tilled-select>
                </div>
                <div class="col-span-1 -mt-2 flex flex-col">
                  <tilled-input
                    label="City"
                    name="city"
                    [errors]="bankPaymentForm.get('city').hasError('required') && bankPaymentForm.get('city').touched"
                    errorMessage="A city is required"
                    tilledLabel="true"
                    placeholder="Enter the city"
                    data-test-id="ach-city"
                  ></tilled-input>
                </div>
                <div class="col-span-1 flex flex-col">
                  <tilled-input
                    label="Zip"
                    placeholder="12345"
                    maxLength="10"
                    name="postalCode"
                    [errors]="
                      bankPaymentForm.get('postalCode').hasError('required') &&
                      bankPaymentForm.get('postalCode').touched
                    "
                    errorMessage="A zip is required"
                    tilledLabel="true"
                    data-test-id="ach-zip"
                  ></tilled-input>
                </div>
              </div>
              <!-- end of ACH Info 4 col grid-->
            </div>
          </form>
        </form-card>
      </div>
    </mat-step>
  </mat-stepper>
</div>
