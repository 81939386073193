import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ProgressBarMode, MatProgressBarModule } from '@angular/material/progress-bar';

export enum ProgressBarClass {
  GREEN = 'green',
  GREEN_VERTICAL = 'green_vertical',
  RED = 'red',
  RED_VERTICAL = 'red_vertical',
  YELLOW = 'yellow',
  YELLOW_VERTICAL = 'yellow_vertical',
}

@Component({
  selector: 'tilled-progress-bar',
  templateUrl: './tilled-progress-bar.component.html',
  styleUrls: ['./tilled-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatProgressBarModule],
})
export class TilledProgressBarComponent implements OnInit {
  @Input() color: ProgressBarClass;
  @Input() value: number;
  @Input() mode?: ProgressBarMode = 'determinate';
  public colorClass: string;

  constructor() {}

  ngOnInit(): void {
    this.colorClass = this.color as string;
  }
}
