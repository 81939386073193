<div class="flex w-full flex-col p-6 pb-0 sm:mx-5">
  <div class="grid grid-cols-1 justify-items-start">
    <tilled-heading-h3 *ngIf="stepNumber" class="">STEP # {{ stepNumber }}</tilled-heading-h3>
    <tilled-heading-h1 *ngIf="title" [primaryHighlight]="true" class="py-2">{{ title }}</tilled-heading-h1>
    <tilled-paragraph-p1 *ngIf="description" [secondary]="true">{{ description }}</tilled-paragraph-p1>
    <div
      class="my-4 flex w-full flex-col items-start overflow-scroll rounded-xl bg-neutral-50 px-8 py-6 outline outline-1"
    >
      <ng-content></ng-content>
    </div>
    <div class="mt-4 flex w-full items-end justify-end space-x-4" [ngClass]="{ 'py-5': backButton || continueButton }">
      <div *ngIf="backButton">
        <tilled-button
          data-test-id="merchant-app-backBtn"
          bgColor="tilled-neutral-200"
          [whiteText]="false"
          [secondary]="true"
          [rounded]="true"
          buttonText="Back"
          (buttonClick)="onButtonClick('back')"
        ></tilled-button>
      </div>
      <div *ngIf="continueButton">
        <tilled-button
          data-test-id="merchant-app-saveBtn"
          bgColor="tilled-primary"
          [rounded]="true"
          [disabled]="disableContinueButton"
          [buttonText]="continueButtonText"
          (buttonClick)="onButtonClick('continue')"
        ></tilled-button>
      </div>
    </div>
  </div>
</div>
