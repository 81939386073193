/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Customer } from './customer';
import { PaymentIntentError } from './paymentIntentError';
import { Level3 } from './level3';
import { PaymentMethod } from './paymentMethod';
import { InternalCharge } from './internalCharge';


export interface InternalPaymentIntent { 
    /**
     * ID of the Tilled account for which the funds of this PaymentIntent are intended.
     */
    account_id: string;
    /**
     * The name of the account associated with this Payment Intent.
     */
    account_name?: string;
    /**
     * Amount intended to be collected by this PaymentIntent. A positive integer representing how much to charge in the smallest currency unit (e.g., 100 cents to charge $1.00 or 100 to charge ¥100, a zero-decimal currency).
     */
    amount: number;
    /**
     * Amount that can be captured from this PaymentIntent.
     */
    amount_capturable: number;
    /**
     * Amount that was collected by this PaymentIntent.
     */
    amount_received: number;
    /**
     * Populated when `status` is `canceled`, this is the time at which the PaymentIntent was canceled.
     */
    canceled_at?: string;
    /**
     * Reason for cancellation of this PaymentIntent, either user-provided (`duplicate`, `fraudulent`, `requested_by_customer`, or `abandoned`) or generated by Tilled internally (`automatic`).
     */
    cancellation_reason?: InternalPaymentIntent.CancellationReasonEnum;
    /**
     * Controls when the funds will be captured from the customer’s account.  `automatic` (Default) Tilled automatically captures funds when the customer authorizes the payment.  `manual` Place a hold on funds when the customer authorizes the payment, but don\'t capture the funds until later.
     */
    capture_method: InternalPaymentIntent.CaptureMethodEnum;
    /**
     * Charges that were created by this PaymentIntent, if any. At most, this list will only contain one successful charge.
     */
    charges: Array<InternalCharge>;
    /**
     * The client secret of this PaymentIntent. The client secret, along with a publishable key, can be used to complete a payment from your frontend. It should not be stored, logged, embedded in URLs, or exposed to anyone other than the customer. Make sure that you have TLS enabled on any page that includes the client secret.
     */
    client_secret: string;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * Three-letter [ISO currency code](https://www.iso.org/iso-4217-currency-codes.html), in lowercase.
     */
    currency: InternalPaymentIntent.CurrencyEnum;
    /**
     * The Customer this PaymentIntent belongs to, if one exists. Payment methods attached to other Customers cannot be used with this PaymentIntent.
     */
    customer?: Customer;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * The payment error encountered in the previous PaymentIntent confirmation. It will be cleared if the PaymentIntent is later updated for any reason.
     */
    last_payment_error?: PaymentIntentError;
    /**
     * Level 2 and Level 3 (L2/L3) credit card processing refers to certain B2B card transactions for which the merchant might be eligible for lower credit card interchange rates. The lower rates may be available for merchants who provide more detailed information when processing card-not-present transactions.
     */
    level3?: Level3;
    /**
     * Set of [key-value pairs](#section/Metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format.
     */
    metadata?: { [key: string]: string; };
    /**
     * Used to identify authorization requests that use stored credentials to improve authorization rates and reduce fraud.  `consumer_ad_hoc` Ad hoc consumer-initiated request  `merchant_ad_hoc` Unscheduled merchant-initiated request  `merchant_recurring` Scheduled, merchant-initiated recurring request
     */
    occurrence_type?: InternalPaymentIntent.OccurrenceTypeEnum;
    /**
     * The PaymentMethod used in this PaymentIntent.
     */
    payment_method?: PaymentMethod;
    /**
     * The list of payment method types (e.g. card) that this PaymentIntent is allowed to use.
     */
    payment_method_types: Array<InternalPaymentIntent.PaymentMethodTypesEnum>;
    /**
     * The amount of the fee (if any) that will be requested to be applied to the payment and transferred to the `partner` account. The amount of the fee collected will be capped a the total payment amount.
     */
    platform_fee_amount?: number;
    /**
     * Provides information about a card payment that customers see on their statements. Concatenated with the prefix (shortened descriptor) or statement descriptor that’s set on the account to form the complete statement descriptor. Maximum 22 characters for the concatenated descriptor
     */
    statement_descriptor_suffix?: string;
    /**
     * Status of this PaymentIntent, one of `requires_payment_method`, `requires_confirmation`, `requires_action`, `processing`, `requires_capture`, `canceled`, or `succeeded`.
     */
    status: InternalPaymentIntent.StatusEnum;
    /**
     * ID of the subscription related to this PaymentIntent (if any).
     */
    subscription_id?: string;
    /**
     * Time at which the object was updated.
     */
    updated_at: string;
}
export namespace InternalPaymentIntent {
    export type CancellationReasonEnum = 'duplicate' | 'fraudulent' | 'requested_by_customer' | 'abandoned' | 'automatic';
    export const CancellationReasonEnum = {
        DUPLICATE: 'duplicate' as CancellationReasonEnum,
        FRAUDULENT: 'fraudulent' as CancellationReasonEnum,
        REQUESTED_BY_CUSTOMER: 'requested_by_customer' as CancellationReasonEnum,
        ABANDONED: 'abandoned' as CancellationReasonEnum,
        AUTOMATIC: 'automatic' as CancellationReasonEnum
    };
    export type CaptureMethodEnum = 'automatic' | 'manual';
    export const CaptureMethodEnum = {
        AUTOMATIC: 'automatic' as CaptureMethodEnum,
        MANUAL: 'manual' as CaptureMethodEnum
    };
    export type CurrencyEnum = 'aud' | 'cad' | 'dkk' | 'eur' | 'hkd' | 'jpy' | 'nzd' | 'nok' | 'gbp' | 'zar' | 'sek' | 'chf' | 'usd';
    export const CurrencyEnum = {
        AUD: 'aud' as CurrencyEnum,
        CAD: 'cad' as CurrencyEnum,
        DKK: 'dkk' as CurrencyEnum,
        EUR: 'eur' as CurrencyEnum,
        HKD: 'hkd' as CurrencyEnum,
        JPY: 'jpy' as CurrencyEnum,
        NZD: 'nzd' as CurrencyEnum,
        NOK: 'nok' as CurrencyEnum,
        GBP: 'gbp' as CurrencyEnum,
        ZAR: 'zar' as CurrencyEnum,
        SEK: 'sek' as CurrencyEnum,
        CHF: 'chf' as CurrencyEnum,
        USD: 'usd' as CurrencyEnum
    };
    export type OccurrenceTypeEnum = 'consumer_ad_hoc' | 'merchant_ad_hoc' | 'merchant_recurring';
    export const OccurrenceTypeEnum = {
        CONSUMER_AD_HOC: 'consumer_ad_hoc' as OccurrenceTypeEnum,
        MERCHANT_AD_HOC: 'merchant_ad_hoc' as OccurrenceTypeEnum,
        MERCHANT_RECURRING: 'merchant_recurring' as OccurrenceTypeEnum
    };
    export type PaymentMethodTypesEnum = 'card' | 'ach_debit' | 'eft_debit' | 'card_present';
    export const PaymentMethodTypesEnum = {
        CARD: 'card' as PaymentMethodTypesEnum,
        ACH_DEBIT: 'ach_debit' as PaymentMethodTypesEnum,
        EFT_DEBIT: 'eft_debit' as PaymentMethodTypesEnum,
        CARD_PRESENT: 'card_present' as PaymentMethodTypesEnum
    };
    export type StatusEnum = 'canceled' | 'processing' | 'requires_action' | 'requires_capture' | 'requires_confirmation' | 'requires_payment_method' | 'succeeded';
    export const StatusEnum = {
        CANCELED: 'canceled' as StatusEnum,
        PROCESSING: 'processing' as StatusEnum,
        REQUIRES_ACTION: 'requires_action' as StatusEnum,
        REQUIRES_CAPTURE: 'requires_capture' as StatusEnum,
        REQUIRES_CONFIRMATION: 'requires_confirmation' as StatusEnum,
        REQUIRES_PAYMENT_METHOD: 'requires_payment_method' as StatusEnum,
        SUCCEEDED: 'succeeded' as StatusEnum
    };
}


