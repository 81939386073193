<ng-container *ngIf="showCard">
  <div class="w-full pb-2">
    <tilled-paragraph-p3>{{ label }}</tilled-paragraph-p3>
  </div>
  <!-- Card Template Details -->
  <ng-container>
    <div class="w-full">
      <div class="my-[5px] grid w-full grid-cols-1 sm:grid-cols-2">
        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-start" [secondary]="true">
          Currency</tilled-paragraph-p3
        >
        <tilled-paragraph-p3 data-test-id="currency" class="justify-self-center sm:justify-self-end">
          {{ currency | uppercase }}
        </tilled-paragraph-p3>
      </div>
      <div class="my-[5px] grid w-full grid-cols-1 sm:grid-cols-2">
        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-start" [secondary]="true">
          Fee Type</tilled-paragraph-p3
        >
        <tilled-paragraph-p3 data-test-id="fee-type" class="justify-self-center sm:justify-self-end">
          {{ feeType | snakeCaseSplit | titlecase }}
        </tilled-paragraph-p3>
      </div>
      <mat-divider class="mt-3 mb-4 w-full"></mat-divider>
      <div class="my-[5px] grid w-full grid-cols-1 sm:grid-cols-2">
        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-start" [secondary]="true">
          Visa Discount Rate</tilled-paragraph-p3
        >
        <tilled-paragraph-p3 data-test-id="visa-rate" class="justify-self-center sm:justify-self-end">
          {{ visaRate | percent : '0.2-2' }}
        </tilled-paragraph-p3>
      </div>
      <div class="my-[5px] grid w-full grid-cols-1 sm:grid-cols-2">
        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-start" [secondary]="true">
          Amex Discount Rate</tilled-paragraph-p3
        >
        <tilled-paragraph-p3 data-test-id="amex-rate" class="justify-self-center sm:justify-self-end">
          {{ amexRate | percent : '0.2-2' }}
        </tilled-paragraph-p3>
      </div>
      <div class="my-[5px] grid w-full grid-cols-1 sm:grid-cols-2">
        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-start" [secondary]="true"
          >Transaction Fee</tilled-paragraph-p3
        >
        <tilled-paragraph-p3 data-test-id="transaction-fee" class="justify-self-center sm:justify-self-end">
          {{ transactionFee | minorUnitsToCurrency : currency }}
        </tilled-paragraph-p3>
      </div>
      <div class="my-[5px] grid w-full grid-cols-1 sm:grid-cols-2">
        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-start" [secondary]="true"
          >Chargeback Fee</tilled-paragraph-p3
        >
        <tilled-paragraph-p3 data-test-id="chargeback-fee" class="justify-self-center sm:justify-self-end">
          {{ chargebackFee | minorUnitsToCurrency : currency }}
        </tilled-paragraph-p3>
      </div>
      <div class="my-[5px] grid w-full grid-cols-1 sm:grid-cols-2">
        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-start" [secondary]="true"
          >Retrieval Fee</tilled-paragraph-p3
        >
        <tilled-paragraph-p3 data-test-id="retrieval-fee" class="justify-self-center sm:justify-self-end">
          {{ retrievalFee | minorUnitsToCurrency : currency }}
        </tilled-paragraph-p3>
      </div>
      <div class="my-[5px] grid w-full grid-cols-1 sm:grid-cols-2">
        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-start" [secondary]="true"
          >Reversal Fee</tilled-paragraph-p3
        >
        <tilled-paragraph-p3 data-test-id="reversal-fee" class="justify-self-center sm:justify-self-end">
          {{ reversalFee | minorUnitsToCurrency : currency }}
        </tilled-paragraph-p3>
      </div>
      <mat-divider class="mt-3 mb-4 w-full"></mat-divider>
      <div class="my-[5px] grid w-full grid-cols-1 sm:grid-cols-2">
        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-start" [secondary]="true"
          >Monthly Account Fee</tilled-paragraph-p3
        >
        <tilled-paragraph-p3 data-test-id="account-monthly-fee" class="justify-self-center sm:justify-self-end">
          {{ accountMonthlyFee | minorUnitsToCurrency : currency }}
        </tilled-paragraph-p3>
      </div>
      <div class="my-[5px] grid w-full grid-cols-1 sm:grid-cols-2">
        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-start" [secondary]="true"
          >Monthly Minimum Fee</tilled-paragraph-p3
        >
        <tilled-paragraph-p3 data-test-id="account-monthly-minimum-fee" class="justify-self-center sm:justify-self-end">
          {{ accountMonthlyMinimumFee | minorUnitsToCurrency : currency }}
        </tilled-paragraph-p3>
      </div>
    </div>
  </ng-container>
</ng-container>
