import { RECENT_MERCHANTS, USER_LOCAL_DATA } from 'app/core/constants';
import { DBConfig } from './indexed-db.meta';

export const dbConfig: DBConfig = {
  name: 'tilled',
  version: 1,
  objectStoresMeta: [
    {
      store: USER_LOCAL_DATA,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [{ name: RECENT_MERCHANTS, keypath: RECENT_MERCHANTS, options: { unique: false } }],
    },
  ],
};
