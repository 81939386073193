<tilled-table
  [data]="(userInvitesViewModel$ | async)!"
  [getPageCallback]="getUserInvitations"
  [dataLength]="userInvitationsCount$ | async"
  [noDataMainText]="noDataMainText"
  [noDataSecondaryText]="''"
  [hidePaginator]="true"
  [hasPermissionToView]="hasPermissionToView"
  data-test-id="user-invitable-table"
></tilled-table>
