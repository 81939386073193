import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FuseCardComponent } from '@fuse/components/card';
import { CardPricingTemplateDto } from 'app/core/models/pricing-templates';
import { MinorUnitsToCurrencyPipe } from 'app/core/pipes/minor-units-to-currency.pipe';
import { SnakeCaseSplitPipe } from 'app/core/pipes/snake-case-split.pipe';
import { TemplateStatusChipConfigPipe } from 'app/core/pipes/template-status-chip-config.pipe';
import { GenericPricingTemplateListItem } from '../../../shared/pricing-templates/generic-pricing-template-list-item';
import { TilledChipComponent } from '../../tilled-chip/tilled-chip.component';
import { TilledHeadingH4Component } from '../../tilled-text/tilled-heading/tilled-heading-h4.component';
import { TilledLabelL1Component } from '../../tilled-text/tilled-label/tilled-label-l1.component';
import { TilledParagraphP3Component } from '../../tilled-text/tilled-paragraph/tilled-paragraph-p3.component';
import { TilledParagraphP4Component } from '../../tilled-text/tilled-paragraph/tilled-paragraph-p4.component';
import { EditablePricingTemplateNicknameComponent } from '../editable-pricing-template-nickname/editable-pricing-template-nickname.component';
@Component({
  selector: 'card-pricing-template-list-item',
  templateUrl: './card-pricing-template-list-item.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TilledHeadingH4Component,
    EditablePricingTemplateNicknameComponent,
    TilledChipComponent,
    TilledParagraphP4Component,
    TilledParagraphP3Component,
    TilledLabelL1Component,
    MinorUnitsToCurrencyPipe,
    SnakeCaseSplitPipe,
    TemplateStatusChipConfigPipe,
    FuseCardComponent,
    CommonModule,
  ],
})
export class CardPricingTemplateListItemComponent extends GenericPricingTemplateListItem implements OnInit {
  @Input() detailView: boolean = false;
  @Input() canClick: boolean = true;
  @Input() canEdit: boolean = false;
  @Input() template: CardPricingTemplateDto;
  @Output() cardClick: EventEmitter<number> = new EventEmitter<number>();

  constructor(private router: Router) {
    super(router);
  }

  ngOnInit() {}

  onButtonClick() {}
}
