<div
  class="
        shadow-light-shadow
        {{ bg_color }}
        rounded-3xl
        {{ size }}
        flex flex-col
        mx-3
        tilled-lg:my-10
        tilled-md:my-10
        tilled-sm:my-10
    "
>
  <div class="h-10" *ngIf="!loading"></div>
  <div class="mx-6 mt-10" *ngIf="!loading">
    <div class="">
      <tilled-label-l1 white="true">{{ title }}</tilled-label-l1>
    </div>
    <div class="mt-3 mb-8">
      <tilled-heading-h2 white="true">{{ value }}</tilled-heading-h2>
    </div>
  </div>

  <div *ngIf="loading" class="my-10 mx-4">
    <mat-spinner></mat-spinner>
  </div>
</div>
