import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ComponentBase } from '../../core/componentBase';
import { TilledButtonComponent } from '../buttons/tilled-button.component';
import { TilledParagraphP3Component } from '../tilled-text/tilled-paragraph/tilled-paragraph-p3.component';
import { TilledHeadingH2Component } from '../tilled-text/tilled-heading/tilled-heading-h2.component';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'merchant-app-unsaved-dialog',
  templateUrl: './merchant-app-unsaved-dialog.component.html',
  standalone: true,
  imports: [MatIconModule, TilledHeadingH2Component, TilledParagraphP3Component, TilledButtonComponent],
})
export class MerchantAppUnsavedDialogComponent extends ComponentBase implements OnInit {
  public title: string;
  public text: string;
  public stayButtonText: string;
  public leaveButtonText: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public dialogRef: MatDialogRef<MerchantAppUnsavedDialogComponent>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.title = this._data.title;
    this.text = this._data.text;
    this.stayButtonText = this._data.stayButtonText;
    this.leaveButtonText = this._data.leaveButtonText;
  }

  public closeDialog(stayOnPage: boolean): void {
    this.dialogRef.close(stayOnPage);
  }
}
