import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AUTH_LOGIN_ROUTE, HOMEPAGE_ROUTE } from 'app/core/constants';
import { StatusCodes } from 'http-status-codes';
import { Observable } from 'rxjs';
import { AuthLinkLoginDto, AuthLinksService } from '../../../../../projects/tilled-api-client/src';
import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizeLinkGuard {
  //, CanActivateChild {
  private authLink$: Observable<AuthLinkLoginDto>;

  constructor(private authService: AuthService, private authLinkService: AuthLinksService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!route.queryParams['key']) {
      return this.router.navigate(['/' + AUTH_LOGIN_ROUTE]);
    }

    this.authLink$ = this.authService.redeemAuthLink(route.queryParams['key']);

    this.authLink$.subscribe({
      next: (result) => {
        // if user is 'logged in' through magic link, redirect to url
        if (this.authService.user) {
          if (result.redirect_url) {
            return this.router.navigate([result.redirect_url]);
          } else {
            return this.router.navigate(['/' + HOMEPAGE_ROUTE]);
          }
          // if user is not 'logged in' but we have a redirect url,
          // their tokens probably got cleared, need new ones
        } else {
          return this.router.navigate(['/enroll/expired']);
        }
      },
      error: (err) => {
        if (err.status === StatusCodes.UNAUTHORIZED) {
          return this.router.navigate(['/enroll/expired']);
        } else {
          return this.router.navigate(['/' + AUTH_LOGIN_ROUTE]);
        }
      },
    });
  }

  // only executed if child route is called. right now there are no child routes for auth/magic-link
  // if we were to add them, then we would want to implement this very similar/same as canActivate
  /*canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {

    }*/
}
