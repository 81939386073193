import { NgModule, Optional, SkipSelf } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { provideIcons } from 'app/core/icons/icons.provider';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { environment } from 'environments/environment';
import { LIB_VERSION } from '../../../version';
import { AuthService } from './services/auth.service';

if (environment.sentryDSN.length > 0) {
  const release = LIB_VERSION;
  const replay = Sentry.replayIntegration({
    unmask: ['.fuse-alert'],
  });

  Sentry.init({
    dsn: environment.sentryDSN,
    environment: environment.env,
    release: 'hawkeye@v' + release,
    replaysSessionSampleRate: environment.replaysSamplingRate,
    replaysOnErrorSampleRate: environment.replaysOnErrorSamplingRate,
    integrations: [
      Sentry.browserTracingIntegration({
        instrumentNavigation: true,
        instrumentPageLoad: true,
      }),
      replay,
    ],
    tracePropagationTargets: [environment.api],
    tracesSampleRate: environment.tracesSampleRate,
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  });

  // This will enable session replay on the onboarding pages in Sandbox and Production
  if (environment.env !== 'staging') {
    const url = window.location.href;
    if (url.includes('/onboarding/')) {
      replay.start();
    }
  }
}
@NgModule({
  imports: [TranslocoCoreModule],
  providers: [AuthService, provideIcons()],
})
export class CoreModule {
  /**
   * Constructor
   */
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    // Do not allow multiple injections
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
    }
  }
}
