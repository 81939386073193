// set-data.directive.ts
import { Directive, HostListener, Input } from '@angular/core';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';

@Directive({
    selector: '[notificationRoute]',
    standalone: true,
})
export class NotificationsDirective {
  @Input() notificationRoute: boolean;

  constructor(private notificationsService: NotificationsService) {}

  @HostListener('click')
  onClick(): void {
    this.notificationsService.setRoutedFromNotification(this.notificationRoute);
  }
}
