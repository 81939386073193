import { Component, ViewEncapsulation } from '@angular/core';
import { TilledHeadingGenericComponent } from './tilled-heading-generic.component';

@Component({
  selector: 'tilled-heading-h2',
  templateUrl: '../tilled-text-generic.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class TilledHeadingH2Component extends TilledHeadingGenericComponent {
  constructor() {
    super();
    this.internalStyles = 'font-bold text-h2';
  }
}
