<div class="flex flex-auto flex-col">
  <form-card
    [testIds]="{ submitButton: 'submit-refund-Btn' }"
    [submitButton]="true"
    [submitDisabled]="refundForm.invalid"
    (submitClick)="submitRefundClicked()"
    [submitText]="'Issue Refund'"
    (exitClick)="closeDialog()"
    [styles]="'pr-0'"
    [titleText]="'Issue Refund'"
    [secondaryText]="'Enter refund details below to issue a refund.'"
  >
    <!-- Form -->
    <form [formGroup]="refundForm" class="">
      <!-- Amount Start -->
      <div>
        <tilled-input
          name="amount"
          showCurrency="true"
          label="Refund Amount"
          tilledLabel="true"
          mask="separator.2"
          toolTip="Maximum refund amount is {{ maxRefund | minorUnitsToCurrency }}"
          placeholder="Enter refund amount"
          data-test-id="refund-amount"
          [errors]="
            (refundForm.get('amount').hasError('required') && refundForm.get('amount').touched) ||
            (refundForm.get('amount').hasError('max') && refundForm.get('amount').touched) ||
            (refundForm.get('amount').hasError('min') && refundForm.get('amount').touched)
          "
          [errorMessage]="
            refundForm.get('amount').hasError('required') && refundForm.get('amount').touched
              ? 'A refund amount is required'
              : refundForm.get('amount').hasError('max') && refundForm.get('amount').touched
                ? 'Cannot exceed the maximum refund amount'
                : refundForm.get('amount').hasError('min') && refundForm.get('amount').touched
                  ? 'Refund amount must be greater than 0'
                  : ''
          "
        ></tilled-input>
        <!-- Amount End -->
        <!-- Reason Start-->
        <div class="w-full pb-2.5 pt-4">
          <tilled-label-l1 secondary="true">Refund reason</tilled-label-l1>
        </div>
        <tilled-select
          classOverride="fuse-mat-dense fuse-mat-no-subscript w-full"
          data-test-id="reason"
          [multiple]="false"
          [options]="[
            { label: 'Duplicate', value: 'duplicate' },
            { label: 'Fraudulent', value: 'fraudulent' },
            { label: 'Requested by customer', value: 'requested_by_customer' }
          ]"
          [controlName]="refundForm.get('reason')"
          [placeholder]="'Select a refund reason'"
          [errors]="refundForm.get('reason').hasError('required') && refundForm.get('reason').touched"
          errorMessage="A refund reason is required"
        ></tilled-select>
        <!-- Reason End -->

        <!-- Platform Fee Start -->
        <div *ngIf="!isMerchant && payment.platform_fee_amount" class="pt-4">
          <mat-slide-toggle color="primary" (change)="togglePlatformFeeRefund()" data-test-id="platform-fee-refund">
            <tilled-paragraph-p3>Refund platform fee</tilled-paragraph-p3>
          </mat-slide-toggle>
          <div class="flex flex-row pt-1">
            <mat-icon
              class="-ml-0.5 -mt-1 text-tilled-secondary"
              style="font-size: 4px; transform: scale(0.7)"
              svgIcon="heroicons_solid:exclamation-circle"
            >
            </mat-icon>
            <tilled-paragraph-p4 secondary="'true'"
              >Platform fee will be refunded in proportion to the refund amount</tilled-paragraph-p4
            >
          </div>
        </div>
        <!-- Platform Fee End -->

        <!-- Payment Method -->
        <div *ngIf="requireNewPaymentMethod" class="-mt-4 grid">
          <div data-test-id="credit-card-header" class="grid min-w-fit justify-items-center pb-10">
            <fuse-alert [type]="'info'" [appearance]="'soft'" [dismissible]="false" [dismissed]="false">
              A refund of this payment cannot be automatically applied to the original card. Enter the customer's
              payment method information.
            </fuse-alert>
          </div>
          <div class="flex flex-col">
            <div class="-mt-4 grid w-[full] grid-cols-2">
              <div class="col-span-1 flex w-[210px] flex-col">
                <div class="flex w-full flex-col">
                  <tilled-label-l1 secondary="true" class="pb-1.5">Credit Card Number</tilled-label-l1>
                  <div class="flex flex-row">
                    <div class="inputField" id="card-number-element"></div>
                    <div id="card-suffix" class="-ml-10 flex h-[40px] w-[40px] items-center">
                      <mat-icon>credit_card</mat-icon>
                    </div>
                  </div>
                  <div class="pt-1 text-sm text-warn" *ngIf="showCardNumberError; else noCardNumberError" @fadeIn>
                    A valid card number is required
                  </div>
                  <ng-template #noCardNumberError>
                    <div class="pt-1 text-sm">&nbsp;</div>
                  </ng-template>
                </div>
              </div>
              <div class="col-span-1 flex w-[150px] flex-col justify-self-end">
                <div class="flex w-full flex-col">
                  <tilled-label-l1 secondary="true" class="pb-1.5">Expiration</tilled-label-l1>
                  <div class="inputField" id="card-expiration-element"></div>
                  <div class="pt-1 text-sm text-warn" *ngIf="showExpirationError; else noExpirationError" @fadeIn>
                    A valid date is required
                  </div>
                  <ng-template #noExpirationError>
                    <div class="pt-1 text-sm">&nbsp;</div>
                  </ng-template>
                </div>
              </div>
              <div class="col-span-1 flex w-[170px] flex-col">
                <div class="flex w-full flex-col">
                  <tilled-label-l1 secondary="true" class="pb-1.5">CVV</tilled-label-l1>
                  <div class="inputField" id="card-cvv-element"></div>
                  <div class="pt-1 text-sm text-warn" *ngIf="showCvvError; else noCvvError" @fadeIn>Required</div>
                  <ng-template #noCvvError>
                    <div class="pt-1 text-sm">&nbsp;</div>
                  </ng-template>
                </div>
              </div>
              <div class="col-span-1 -mt-8 flex w-[150px] flex-col justify-self-end">
                <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                  <tilled-label-l1 secondary="true">Billing Zip</tilled-label-l1>
                  <input
                    data-test-id="billing-zip"
                    class="pt-4 text-p3 text-tilled-primary"
                    matInput
                    formControlName="postalCode"
                    placeholder="12345"
                    maxlength="10"
                  />
                  <mat-error class="text-sm" color="warn" *ngIf="refundForm.get('postalCode').hasError('required')"
                    >Required</mat-error
                  >
                </mat-form-field>
              </div>
              <div class="col-span-2 -mt-8 flex w-[full] flex-col">
                <mat-form-field floatLabel="always" appearance="standard" class="flex w-full flex-col">
                  <tilled-label-l1 secondary="true">Name on Card</tilled-label-l1>
                  <input
                    data-test-id="cardholder-name"
                    class="pt-4 text-p3 text-tilled-primary"
                    matInput
                    formControlName="cardholderName"
                    placeholder=""
                  />
                  <mat-error class="text-sm" color="warn" *ngIf="refundForm.get('cardholderName').hasError('required')"
                    >A name on card is required</mat-error
                  >
                </mat-form-field>
              </div>
            </div>
            <!-- end of Card Info 4 col grid-->
          </div>
        </div>
        <!-- Payment Method -->
      </div>
    </form>

    <div *ngIf="displayAlert$ | async" class="flex w-full justify-start px-8 pt-2">
      <fuse-alert
        [type]="alertType"
        [appearance]="'outline'"
        [dismissible]="alertDismissible"
        [dismissed]="false"
        [name]="'refundDialogAlertBox'"
      >
        <span fuseAlertTitle>{{ alertTitle }}</span>
        {{ alertMessage }}
      </fuse-alert>
    </div>
  </form-card>
</div>
