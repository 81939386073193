<div>
  <tilled-table
    [data]="(terminalReadersViewModel$ | async)!"
    [getPageCallback]="getTerminalReaders"
    [dataLength]="terminalReadersCount$ | async"
    data-test-id="terminal-readers-table"
    [noDataMainText]="'No terminal readers found'"
    [noDataSecondaryText]="secondaryReasonText"
    [getClickCallback]="rowClickedCallback"
    [scrollable]="true"
    [showDisplayedColumnsDropdown]="false"
    [startingPageIndex]="pageIndex"
    [startingPageLimit]="pageSize"
  ></tilled-table>
</div>
