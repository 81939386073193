import { Injectable, OnDestroy } from '@angular/core';
import {
  MerchantAppMissingFields,
  MerchantApplicationStepType,
  PaysafeApplicationSteps,
  TsysApplicationSteps,
} from 'app/core/data/onboarding-types';
import { FilesAppService } from 'app/core/services/files.app.service';
import { BehaviorSubject, Observable, Subject, catchError, takeUntil, tap } from 'rxjs';
import {
  AccountInternalMetadata,
  BankVerification,
  BusinessLegalEntity,
  InternalGetMerchantApplicationRequestParams,
  InternalService,
  MerchantApplication,
  MerchantApplicationCreateParams,
  ModelFile,
  OnboardingService,
  PricingTemplate,
  ProductCode,
  UpdateMerchantApplicationRequestParams,
} from '../../../../projects/tilled-api-client/src';
import {
  GetPlaidLinkTokenRequestParams,
  PlaidService,
  PlaidUpdateMerchantApplicationRequestParams,
} from '../../../../projects/tilled-api-client/src/api/plaid.service';
import { ApplicationStep, IsStepComplete } from '../../core/models/application-step';
import { AuthService } from '../../core/services/auth.service';
import { TilledAlert } from '../models/tilled-alert';
import { AlertService } from './alert.service';
import { PlaidConfig, PlaidEventsConfig, PlaidLinkAppService } from './plaid-link.app.service';
import RegionEnum = BusinessLegalEntity.RegionEnum;

@Injectable({
  providedIn: 'root',
})
export class MerchantAppService implements OnDestroy {
  private stepsSubject = new BehaviorSubject<ApplicationStep[]>(null);
  private currentStepSubject = new BehaviorSubject<ApplicationStep>(null);
  private applicationResponse = new BehaviorSubject<MerchantApplication>(null);
  private submittedApplicationResponse = new Subject();
  public merchantAppSteps$: Observable<ApplicationStep[]> = this.stepsSubject.asObservable();
  public merchantApplicationResponse$: Observable<MerchantApplication> = this.applicationResponse.asObservable();
  //.pipe(skipWhile((value, index) => !value));
  public currentStep$: Observable<ApplicationStep> = this.currentStepSubject.asObservable();
  public submittedApplicationResponse$: Observable<any> = this.submittedApplicationResponse.asObservable();

  private submittedApplicationErrors = new BehaviorSubject<any>(null);
  public submittedApplicationErrors$: Observable<any> = this.submittedApplicationErrors.asObservable();

  private _applicationUpdatedAt$ = new BehaviorSubject<string>(null);
  public applicationUpdatedAt$: Observable<any> = this._applicationUpdatedAt$.asObservable();
  private _applicationSuccessfullyUpdatedByPlaid = new Subject<boolean>();
  public applicationSuccessfullyUpdatedByPlaid$: Observable<boolean> =
    this._applicationSuccessfullyUpdatedByPlaid.asObservable();

  private hasBankVerificationEnabled: boolean = false;
  private bankVerificationMethods: BankVerification.VerificationMethodEnum[] = [];
  private areBankDocsRequired: boolean = false;
  private requiredBankDocs: string[] = ['Voided Check', 'Bank Letter'];
  private currentBankDocs: string[] = [];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private _merchantApplicationService: OnboardingService,
    private _internalService: InternalService,
    private _plaidService: PlaidService,
    private _alertService: AlertService,
    private _plaidLinkAppService: PlaidLinkAppService,
    private _filesAppService: FilesAppService,
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public updateCurrentStep(step: number): void {
    const allSteps = this.stepsSubject.getValue();

    let currentStep = allSteps.filter((s) => s.order === step)[0];
    if (currentStep.subSteps?.length > 0) {
      currentStep = allSteps.filter((step) => step.order === currentStep.subSteps[0])[0];
    }
    this.currentStepSubject.next(currentStep);
  }

  public getLinkToken(eventListeners: PlaidEventsConfig, redirectUri: string): void {
    const params: GetPlaidLinkTokenRequestParams = {
      tilledAccount: AuthService.getCurrentAccountId(),
      redirect: redirectUri,
    };
    this._plaidService.getPlaidLinkToken(params).subscribe({
      next: (response) => {
        const linkScript: any = document.createElement('script');
        linkScript.type = 'text/javascript';
        linkScript.src = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';
        linkScript.onerror = (e: any) => console.log(e);

        const plaidConfig: PlaidConfig = {
          token: response.token,
          ...eventListeners,
        };
        this._plaidLinkAppService.createPlaid(plaidConfig).then((linkHandler) => {
          linkHandler?.open();
        });
        return;
      },
      error: (err) => {
        // generic catch all for error responses
        const message: TilledAlert = {
          message: 'Could not load Plaid interface.',
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
        this._applicationSuccessfullyUpdatedByPlaid.next(false);
      },
    });
  }

  public async updatePlaidAccessToken(token: string): Promise<void> {
    const params: PlaidUpdateMerchantApplicationRequestParams = {
      token: token,
      tilledAccount: AuthService.getCurrentAccountId(),
    };
    this._plaidService.plaidUpdateMerchantApplication(params).subscribe({
      next: (response) => {
        this.applicationResponse.next(response);
        this._applicationSuccessfullyUpdatedByPlaid.next(true);
      },
      error: (err) => {
        // generic catch all for error responses
        const message: TilledAlert = {
          message: 'Could not update application.',
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
        this._applicationSuccessfullyUpdatedByPlaid.next(false);
      },
    });
  }

  public getApplication(
    internalMetadata: AccountInternalMetadata,
    accountId?: string,
  ): Observable<MerchantApplication> {
    const accountIdToUse = accountId ?? AuthService.getCurrentAccountId();
    const params: InternalGetMerchantApplicationRequestParams = {
      tilledAccount: accountIdToUse,
    };

    this.getUploadedBankDocs(accountIdToUse);

    return this._internalService.internalGetMerchantApplication(params).pipe(
      tap((application) => {
        if (!application.business_legal_entity) {
          application.business_legal_entity = {} as BusinessLegalEntity;
        }
        this.loadSteps(application);
        this.updateBankDocsRequired(application, internalMetadata);
        this.applicationResponse.next(application);
        this._applicationUpdatedAt$.next(application.updated_at);
      }),
      catchError((err) => {
        throw JSON.stringify(err);
      }),
    );
  }

  public updateMerchantApplication(updatedApp: MerchantApplication, nextStep: number, accountId?: string): void {
    const params: UpdateMerchantApplicationRequestParams = {
      accountId: accountId ?? AuthService.getCurrentAccountId(),
      merchantApplicationCreateParams: this.mapResponseToParams(updatedApp),
    };
    this.checkAndUpdateFromPricingTemplate(updatedApp);
    this._merchantApplicationService
      .updateMerchantApplication(params)
      .pipe(
        tap((application) => {
          this.loadSteps(application);
          this.currentStepSubject.next(this.stepsSubject.getValue().filter((s) => s.order === nextStep)[0]);
          this._applicationUpdatedAt$.next(application.updated_at);
        }),
        catchError((err) => {
          if (err.error) {
            if (err.error.statusCode === 400) {
              const message: TilledAlert = {
                message: err.error.message,
                title: 'Server error',
                type: 'error',
              };
              this._alertService.showAlert(message);
            } else {
              // generic catch all for error responses
              const message: TilledAlert = {
                message: 'Could not update application, please try again or contact support for assistance',
                title: 'Server error',
                type: 'error',
              };
              this._alertService.showAlert(message);
            }
          }
          throw new Error('Error loading application: ' + JSON.stringify(err));
        }),
      )
      .subscribe((response) => this.applicationResponse.next(response));
  }

  public updateAndSubmitMerchantApplication(updatedApp: MerchantApplication, accountId?: string): void {
    const params: UpdateMerchantApplicationRequestParams = {
      accountId: accountId ?? AuthService.getCurrentAccountId(),
      merchantApplicationCreateParams: this.mapResponseToParams(updatedApp),
    };
    this._merchantApplicationService.updateMerchantApplication(params).subscribe({
      next: (response) => {
        this.applicationResponse.next(response);
        this.submitMerchantApplication(accountId);
      },
      error: (err) => {
        // generic catch all for error responses
        const message: TilledAlert = {
          message: 'Could not update application, please try again or contact support for assistance',
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
      },
    });
  }

  public isBusinessDetailsStepComplete(application: MerchantApplication): IsStepComplete {
    const stepComplete: IsStepComplete = {
      complete: true,
      missingFields: [],
    };

    if (!application.business_legal_entity.legal_name) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.BusinessDetailsStep.LEGAL_NAME);
    }
    if (!application.business_legal_entity.name) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.BusinessDetailsStep.DBA);
    }
    if (!application.business_legal_entity.type) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.BusinessDetailsStep.ENTITY_TYPE);
    }
    if (!application.business_legal_entity.tax_identification_number) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.BusinessDetailsStep.BUSINESS_ID);
    }
    if (!application.business_legal_entity.category) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.BusinessDetailsStep.INDUSTRY);
    }
    if (!application.business_legal_entity.statement_descriptor) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.BusinessDetailsStep.STATEMENT_DESCRIPTOR);
    }
    if (!application.business_legal_entity.description) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.BusinessDetailsStep.DESCRIPTION);
    }
    if (!application.business_legal_entity.country_of_incorporation) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.BusinessDetailsStep.COUNTRY_OF_INC);
    }
    if (!application.business_legal_entity.incorporation_year) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.BusinessDetailsStep.YEAR_OF_INC);
    }
    return stepComplete;
  }

  public isBusinessContactInformationComplete(application: MerchantApplication): IsStepComplete {
    const stepComplete: IsStepComplete = {
      complete: true,
      missingFields: [],
    };

    if (!application.business_legal_entity.phone) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.ContactInfoStep.PHONE);
    }
    if (!application.business_legal_entity.company_email) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.ContactInfoStep.EMAIL);
    }
    // TBD
    /*if (!application.business_legal_entity.company_email) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.ContactInfoStep.WEBSITE);
    }*/
    if (!application.business_legal_entity.address?.street) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.ContactInfoStep.STREET);
    }
    if (!application.business_legal_entity.address?.city) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.ContactInfoStep.CITY);
    }
    if (!application.business_legal_entity.address?.state) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.ContactInfoStep.STATE);
    }
    if (!application.business_legal_entity.address?.zip) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.ContactInfoStep.ZIP);
    }

    return stepComplete;
  }

  public isPaymentProcessingVolumeComplete(application: MerchantApplication): IsStepComplete {
    const stepComplete: IsStepComplete = {
      complete: true,
      missingFields: [],
    };
    if (!application.business_legal_entity?.average_transactions_per_month) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.VolumesStep.AVERAGE_TRX_PER_MONTH);
    }
    if (!application.business_legal_entity?.monthly_volume_amount) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.VolumesStep.MONTHLY_VOLUME);
    }

    const cardPricing = application?.pricing_templates
      ? application.pricing_templates.find((p) => p?.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.CARD)
      : null;
    const debitPricing = application?.pricing_templates
      ? application.pricing_templates.find(
          (p) =>
            p?.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.ACH_DEBIT ||
            p?.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.EFT_DEBIT,
        )
      : null;
    if (!application.business_legal_entity?.average_transaction_amount_card && cardPricing) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.VolumesStep.AVERAGE_AMOUNT_CARD);
    }
    if (!application.business_legal_entity?.average_transaction_amount_debit && debitPricing) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.VolumesStep.AVERAGE_AMOUNT_DEBIT);
    }
    return stepComplete;
  }

  public isBusinessRepresentativesComplete(application: MerchantApplication): IsStepComplete {
    const stepComplete: IsStepComplete = {
      complete: true,
      missingFields: [],
    };

    if (!application.business_legal_entity?.principals) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.RepresentativesStep.NO_PRINCIPALS);
      return stepComplete;
    }

    for (const [index, principal] of application.business_legal_entity?.principals?.entries()) {
      if (
        !(
          application.product_codes[0].region === RegionEnum.CA ||
          application.business_legal_entity?.type === BusinessLegalEntity.TypeEnum.CHARITY ||
          application.business_legal_entity?.type === BusinessLegalEntity.TypeEnum.GOV ||
          application.business_legal_entity?.type === BusinessLegalEntity.TypeEnum.NPCORP ||
          principal.ssn
        )
      ) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(
          'Business Representative ' + (index + 1) + ' ' + MerchantAppMissingFields.RepresentativesStep.SSN,
        );
      }
      if (!principal.first_name) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(
          'Business Representative ' + (index + 1) + ' ' + MerchantAppMissingFields.RepresentativesStep.FIRST_NAME,
        );
      }
      if (!principal.last_name) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(
          'Business Representative ' + (index + 1) + ' ' + MerchantAppMissingFields.RepresentativesStep.LAST_NAME,
        );
      }
      if (!principal.phone) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(
          'Business Representative ' + (index + 1) + ' ' + MerchantAppMissingFields.RepresentativesStep.PHONE,
        );
      }
      if (!principal.date_of_birth) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(
          'Business Representative ' + (index + 1) + ' ' + MerchantAppMissingFields.RepresentativesStep.DOB,
        );
      }
      if (!principal.job_title) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(
          'Business Representative ' + (index + 1) + ' ' + MerchantAppMissingFields.RepresentativesStep.JOB_TITLE,
        );
      }
      if (principal.percentage_shareholding == null) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(
          'Business Representative ' + (index + 1) + ' ' + MerchantAppMissingFields.RepresentativesStep.OWNERSHIP,
        );
      }
      if (!principal.address?.street) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(
          'Business Representative ' + (index + 1) + ' ' + MerchantAppMissingFields.RepresentativesStep.STREET,
        );
      }
      if (!principal.address?.city) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(
          'Business Representative ' + (index + 1) + ' ' + MerchantAppMissingFields.RepresentativesStep.CITY,
        );
      }
      if (!principal.address?.state) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(
          'Business Representative ' + (index + 1) + ' ' + MerchantAppMissingFields.RepresentativesStep.STATE,
        );
      }
      if (!principal.address?.zip) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(
          'Business Representative ' + (index + 1) + ' ' + MerchantAppMissingFields.RepresentativesStep.ZIP,
        );
      }

      const needPreviousAddress =
        application.product_codes[0].region === RegionEnum.CA && principal.address.years_at_address <= 3;
      if (needPreviousAddress && !principal.previous_address?.street) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(
          'Business Representative ' + (index + 1) + ' ' + MerchantAppMissingFields.RepresentativesStep.PREV_STREET,
        );
      }
      if (needPreviousAddress && !principal.previous_address?.city) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(
          'Business Representative ' + (index + 1) + ' ' + MerchantAppMissingFields.RepresentativesStep.PREV_CITY,
        );
      }
      if (needPreviousAddress && !principal.previous_address?.state) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(
          'Business Representative ' + (index + 1) + ' ' + MerchantAppMissingFields.RepresentativesStep.PREV_STATE,
        );
      }
      if (needPreviousAddress && !principal.previous_address?.zip) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(
          'Business Representative ' + (index + 1) + ' ' + MerchantAppMissingFields.RepresentativesStep.PREV_ZIP,
        );
      }
    }

    const applicants = application.business_legal_entity.principals.filter(
      (principal) => principal.is_applicant === true,
    );
    if (applicants.length !== 1) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.RepresentativesStep.APPLICANT);
    }
    const controlProngs = application.business_legal_entity.principals.filter(
      (principal) => principal.is_control_prong === true,
    );
    if (controlProngs.length !== 1) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.RepresentativesStep.CONTROL_PRONG);
    }
    return stepComplete;
  }

  public isBankingInformationComplete(application: MerchantApplication): IsStepComplete {
    const stepComplete: IsStepComplete = {
      complete: true,
      missingFields: [],
    };

    this.updateBankDocsRequired(application);
    let docRequirementsMet = !this.areBankDocsRequired;
    if (!docRequirementsMet) {
      for (const currentBankDoc of this.currentBankDocs) {
        if (this.requiredBankDocs.includes(currentBankDoc)) {
          docRequirementsMet = true;
          break;
        }
      }
    }

    if (!application.business_legal_entity?.bank_account?.account_number) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.BankAccountStep.ACCOUNT_NUMBER);
    }
    if (!application.business_legal_entity?.bank_account?.routing_number) {
      stepComplete.complete = false;
      if (application.product_codes[0]?.region === ProductCode.RegionEnum.CA) {
        stepComplete.missingFields.push(MerchantAppMissingFields.BankAccountStep.ROUTING_NUMBER_CA);
      } else {
        stepComplete.missingFields.push(MerchantAppMissingFields.BankAccountStep.ROUTING_NUMBER);
      }
    }
    if (!application.business_legal_entity?.bank_account?.type) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.BankAccountStep.TYPE);
    }
    if (!application.business_legal_entity?.bank_account?.account_holder_name) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.BankAccountStep.ACCOUNT_HOLDER_NAME);
    }
    if (!application.business_legal_entity?.bank_account?.bank_name) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.BankAccountStep.BANK_NAME);
    }
    if (!docRequirementsMet) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.BankAccountStep.DOC_UPLOAD);
    }

    return stepComplete;
  }

  public isReviewPricingAndTermsComplete(application: MerchantApplication): IsStepComplete {
    const stepComplete: IsStepComplete = {
      complete: application.accept_terms_and_conditions,
      missingFields: [],
    };
    return stepComplete;
  }

  public updateBankDocsRequiredFromComponent(required: boolean, current?: string[]): void {
    this.areBankDocsRequired = required;
    if (current && current.length > 0) {
      this.currentBankDocs = current;
    }
  }

  private getUploadedBankDocs(accountId: string): void {
    this._filesAppService.filesAll$.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (files) => {
        if (files) {
          this.currentBankDocs = [];
          for (const file of files) {
            this.currentBankDocs.push(file.title?.split(':')[0]);
          }
        }
        return;
      },
      error: (err) => {
        if (this.areBankDocsRequired) {
          // generic catch all for error responses
          const message: TilledAlert = {
            message: 'Could not load Files.',
            title: 'Server error',
            type: 'error',
          };
          this._alertService.showAlert(message);
        }
      },
    });
    this._filesAppService.listAllFiles(accountId, [ModelFile.PurposeEnum.ONBOARDING_DOCUMENTATION]);
  }

  private submitMerchantApplication(accountId?: string): void {
    this._merchantApplicationService
      .submitMerchantApplication({ accountId: accountId ?? AuthService.getCurrentAccountId() })
      .subscribe({
        next: (response) => {
          this.submittedApplicationResponse.next(response);
        },
        error: (err) => {
          this.submittedApplicationErrors.next(err);
        },
      });
  }

  private checkAndUpdateFromPricingTemplate(application: MerchantApplication): void {
    const cardPricing = application.pricing_templates.find(
      (p) => p?.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.CARD,
    );
    const debitPricing = application.pricing_templates.find(
      (p) =>
        p?.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.ACH_DEBIT ||
        p?.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.EFT_DEBIT,
    );

    if (application.business_legal_entity) {
      const validPricingTemplate = cardPricing || debitPricing;
      if (validPricingTemplate?.currency === PricingTemplate.CurrencyEnum.USD) {
        application.business_legal_entity.currency = BusinessLegalEntity.CurrencyEnum.USD;
        application.business_legal_entity.locale = BusinessLegalEntity.LocaleEnum.EN_US;
        application.business_legal_entity.region = BusinessLegalEntity.RegionEnum.US;
      } else if (validPricingTemplate?.currency === PricingTemplate.CurrencyEnum.CAD) {
        application.business_legal_entity.currency = BusinessLegalEntity.CurrencyEnum.CAD;
        application.business_legal_entity.locale = BusinessLegalEntity.LocaleEnum.EN_CA;
        application.business_legal_entity.region = BusinessLegalEntity.RegionEnum.CA;
      }
    }
  }

  private loadSteps(application: MerchantApplication): void {
    const steps = new Array<ApplicationStep>();

    // TODO: we will need 'application type' from falcon to determine which app steps to load
    if (true) {
      steps.push(
        {
          order: PaysafeApplicationSteps.BUSINESS_DETAILS_STEP,
          type: MerchantApplicationStepType.BUSINESS_DETAILS,
          title: 'Business Details',
          icon: 'format_align_left',
          complete: this.isBusinessDetailsStepComplete(application),
        },
        {
          order: PaysafeApplicationSteps.BUSINESS_CONTACT_STEP,
          type: MerchantApplicationStepType.BUSINESS_CONTACT,
          title: 'Contact Info',
          icon: 'phone',
          complete: this.isBusinessContactInformationComplete(application),
        },
        {
          order: PaysafeApplicationSteps.PROCESSING_VOLUMES_STEP,
          type: MerchantApplicationStepType.PROCESSING_VOLUMES,
          title: 'Processing Volumes',
          icon: 'credit_card',
          complete: this.isPaymentProcessingVolumeComplete(application),
        },
        {
          order: PaysafeApplicationSteps.REPRESENTATIVES_STEP,
          type: MerchantApplicationStepType.REPRESENTATIVES,
          title: 'Representatives',
          icon: 'badge',
          complete: this.isBusinessRepresentativesComplete(application),
        },
        {
          order: PaysafeApplicationSteps.BANK_ACCOUNT_STEP,
          type: MerchantApplicationStepType.BANK_ACCOUNT,
          title: 'Bank Account',
          icon: 'account_balance',
          complete: this.isBankingInformationComplete(application),
        },
        {
          order: PaysafeApplicationSteps.SUBMIT_STEP,
          type: MerchantApplicationStepType.SUBMIT,
          title: 'Submit Application',
          icon: 'check',
          complete: this.isReviewPricingAndTermsComplete(application),
        },
      );
      // else if (application?.type === MerchantApplication.TypeEnum.TSYS) {
    } else {
      steps.push(
        {
          order: TsysApplicationSteps.WELCOME_STEP,
          type: MerchantApplicationStepType.OTHER,
          title: 'Welcome',
          icon: 'sentiment_satisfied',
          complete: true,
        },
        {
          order: TsysApplicationSteps.VERIFY_BUSINESS_STEP,
          type: MerchantApplicationStepType.BUSINESS_DETAILS,
          title: 'Verify your business',
          icon: 'format_align_left',
          complete: this.isBusinessDetailsStepComplete(application),
          subSteps: [
            TsysApplicationSteps.SUB_BUSINESS_DETAILS_STEP,
            TsysApplicationSteps.SUB_BUSINESS_CONTACT_STEP,
            TsysApplicationSteps.SUB_TAX_REGISTRATION_STEP,
            TsysApplicationSteps.SUB_PAYMENT_STEP,
            TsysApplicationSteps.SUB_PROCESSING_VOLUMES_STEP,
            TsysApplicationSteps.SUB_REPRESENTATIVES_STEP,
          ],
        },
        {
          order: TsysApplicationSteps.SUB_BUSINESS_DETAILS_STEP,
          type: MerchantApplicationStepType.BUSINESS_DETAILS,
          title: 'Business Details',
          complete: this.isBusinessDetailsStepComplete(application),
          isSubStep: true,
          parentStep: TsysApplicationSteps.VERIFY_BUSINESS_STEP,
        },
        {
          order: TsysApplicationSteps.SUB_BUSINESS_CONTACT_STEP,
          type: MerchantApplicationStepType.BUSINESS_CONTACT,
          title: 'Business Contact',
          complete: this.isBusinessContactInformationComplete(application),
          isSubStep: true,
          parentStep: TsysApplicationSteps.VERIFY_BUSINESS_STEP,
        },
        {
          order: TsysApplicationSteps.SUB_TAX_REGISTRATION_STEP,
          type: MerchantApplicationStepType.OTHER,
          title: 'Tax and Registration',
          complete: true,
          isSubStep: true,
          parentStep: TsysApplicationSteps.VERIFY_BUSINESS_STEP,
        },
        {
          order: TsysApplicationSteps.SUB_PAYMENT_STEP,
          type: MerchantApplicationStepType.OTHER,
          title: 'Payment Acceptance',
          complete: true,
          isSubStep: true,
          parentStep: TsysApplicationSteps.VERIFY_BUSINESS_STEP,
        },
        {
          order: TsysApplicationSteps.SUB_PROCESSING_VOLUMES_STEP,
          type: MerchantApplicationStepType.PROCESSING_VOLUMES,
          title: 'Processing Volumes',
          complete: this.isPaymentProcessingVolumeComplete(application),
          isSubStep: true,
          parentStep: TsysApplicationSteps.VERIFY_BUSINESS_STEP,
        },
        {
          order: TsysApplicationSteps.SUB_REPRESENTATIVES_STEP,
          type: MerchantApplicationStepType.REPRESENTATIVES,
          title: 'Business Representatives',
          complete: this.isBusinessRepresentativesComplete(application),
          isSubStep: true,
          parentStep: TsysApplicationSteps.VERIFY_BUSINESS_STEP,
          isLastSubStep: true,
        },
        {
          order: TsysApplicationSteps.BANK_ACCOUNT_STEP,
          type: MerchantApplicationStepType.BANK_ACCOUNT,
          title: 'Add bank account',
          icon: 'account_balance',
          complete: this.isBankingInformationComplete(application),
        },
        {
          order: TsysApplicationSteps.DOCUMENTS_STEP,
          type: MerchantApplicationStepType.OTHER,
          title: 'Upload documents',
          icon: 'upload',
          complete: false,
        },
        {
          order: TsysApplicationSteps.SUBMIT_STEP,
          type: MerchantApplicationStepType.SUBMIT,
          title: 'Review and submit',
          icon: 'check',
          complete: this.isReviewPricingAndTermsComplete(application),
        },
      );
    }

    this.stepsSubject.next(steps);
    let currentStep = steps[0];
    if (currentStep.subSteps?.length > 0) {
      currentStep = steps.filter((step) => step.order === currentStep.subSteps[0])[0];
    }
    this.currentStepSubject.next(currentStep);
  }

  private mapResponseToParams(response: MerchantApplication): MerchantApplicationCreateParams {
    const params: MerchantApplicationCreateParams = {
      accept_terms_and_conditions: response.accept_terms_and_conditions,
      business_legal_entity: response.business_legal_entity,
    };

    return params;
  }

  private updateBankDocsRequired(application: MerchantApplication, internalMetadata?: AccountInternalMetadata): void {
    if (internalMetadata) {
      this.bankVerificationMethods = internalMetadata?.bank_verification_methods;
      this.hasBankVerificationEnabled =
        this.bankVerificationMethods?.includes(BankVerification.VerificationMethodEnum.PLAID) ||
        this.bankVerificationMethods?.includes(BankVerification.VerificationMethodEnum.TILLED_MANUAL)
          ? true
          : false;
    }
    if (this.hasBankVerificationEnabled) {
      const accNum = application.business_legal_entity?.bank_account?.account_number;
      if (
        (accNum == null || accNum.trim().length === 0) &&
        this.bankVerificationMethods?.includes(BankVerification.VerificationMethodEnum.PLAID)
      ) {
        this.areBankDocsRequired = false;
      } else {
        if (application.bank_verification?.verification_status === BankVerification.VerificationStatusEnum.VERIFIED) {
          this.areBankDocsRequired = false;
        } else {
          this.areBankDocsRequired = true;
        }
      }
    } else {
      this.areBankDocsRequired = false;
    }
  }
}
