<mat-form-field
  class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-fit min-w-64"
  appearance="fill"
  floatLabel="auto"
>
  <mat-date-range-input
    [rangePicker]="rangePicker"
    [formGroup]="filterForm"
    [separator]="filterForm.get('minDate').value ? '-' : ''"
    [attr.class]="hasDate ? 'ml-2.5 text-secondary w-fit text-p3' : 'text-secondary w-full text-p3 ml-2.5'"
    (keyup.enter)="endDateChanged($event)"
  >
    <input
      matStartDate
      placeholder="Select dates"
      formControlName="minDate"
      (dateChange)="startDateChanged($event)"
      class="text-secondary w-fit text-p3"
    />
    -
    <input
      matEndDate
      formControlName="maxDate"
      (dateChange)="endDateChanged($event)"
      class="text-secondary w-fit text-p3"
    />
    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
  </mat-date-range-input>
  <mat-datepicker-toggle matPrefix [for]="rangePicker">
    <mat-icon matDatepickerToggleIcon [svgIcon]="'heroicons_outline:calendar'"></mat-icon>
  </mat-datepicker-toggle>

  <mat-date-range-picker #rangePicker [calendarHeaderComponent]="DateRangeHeaderComponent">
    <mat-date-range-picker-actions>
      <button mat-button matDateRangePickerCancel>Cancel</button>
      <button mat-raised-button class="bg-primary" matDateRangePickerApply>
        <div class="text-tilled-neutral-100">Apply</div>
      </button>
    </mat-date-range-picker-actions>
  </mat-date-range-picker>
  <button *ngIf="hasDate" matSuffix mat-icon-button aria-label="Clear" (click)="clearDateRange()">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
