<div class="top-of-form"></div>
<merchant-app-card
  [stepNumber]="!forConsole ? this.stepNumber + 1 : null"
  [title]="!forConsole ? 'Business Representatives' : null"
  [description]="
    !forConsole ? 'At least one owner, director, partner, or trustee must be listed as an applicant.' : null
  "
  [backButton]="!forConsole"
  (backClick)="onBackClicked($event)"
  (continueClick)="onContinueClicked($event)"
  [continueButton]="!forConsole && !(disabled$ | async)"
>
  <form data-test-id="representatives-step" class="w-full" [formGroup]="representativesFormArray">
    <div *ngIf="!businessEntityType" class="pb-6">
      <merchant-app-alert
        data-test-id="businessTypeAlert"
        alertName="businessTypeWarnAlertBox"
        title="Business type not set"
        description="A business type must be set on the Business Details page before adding
                representatives"
        (editClick)="onEditClicked($event)"
      ></merchant-app-alert>
    </div>
    <div *ngIf="businessEntityType" class="pb-6">
      <fuse-alert
        [type]="'basic'"
        [appearance]="'outline'"
        [dismissible]="false"
        [dismissed]="false"
        [name]="'representativeAlertBox'"
      >
        <mat-icon class="icon-size-5" fuseAlertIcon [svgIcon]="'mat_solid:badge'"> </mat-icon>
        <tilled-heading-h6 fuseAlertTitle>Your business type: {{ businessEntityType.desc }}</tilled-heading-h6>
        <div *ngFor="let req of requirements" class="flex flex-row">
          &bull;&nbsp;<tilled-paragraph-p3>{{ req }}</tilled-paragraph-p3>
        </div>
      </fuse-alert>
    </div>
    <div *ngFor="let group of representativesFormArray.controls; let i = index" [formGroup]="group">
      <!--Add An Owner-->
      <div
        [ngClass]="{
          'border-t-2 border-neutral-400 pt-6': i > 0,
          'border-neutral-400': i > 0,
          'pt-6': i > 0
        }"
      >
        <tilled-heading-h4>Add a Business Owner</tilled-heading-h4>
        <div class="my-4 grid grid-cols-4 items-center">
          <div class="col-span-2 flex flex-col">
            <div class="col-span-2 flex flex-row items-center">
              <tilled-paragraph-p3>Is this individual the applicant?</tilled-paragraph-p3>
              <mat-icon
                class="mx-2 icon-size-5"
                [svgIcon]="'mat_outline:help_outline'"
                matTooltip="It is required to designate one Business Owner as the applicant. Only one owner may be the designated applicant."
              ></mat-icon>
            </div>
            <mat-radio-group
              data-test-id="reps-applicant"
              class="flex space-x-4 pt-3"
              color="primary"
              [formControlName]="'isApplicant'"
            >
              <mat-radio-button [value]="true" (change)="onIsApplicantYes(i)">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col-span-2 flex flex-col pl-3">
            <div class="col-span-2 flex flex-row items-center">
              <tilled-paragraph-p3 class="col-span-2">Is this individual a control prong?</tilled-paragraph-p3>
              <mat-icon
                class="mx-2 icon-size-5"
                [svgIcon]="'mat_outline:help_outline'"
                matTooltip="The control prong, of which there must be one, is the person with the most control and responsibility over the account (ie: (e.g., a Chief Executive Officer, Chief Financial Officer, Chief Operating Officer, Managing Member, General Partner, President, Vice-President, or Treasurer)."
              ></mat-icon>
            </div>
            <mat-radio-group
              data-test-id="reps-control-prong"
              class="flex space-x-4 pt-3"
              color="primary"
              [formControlName]="'isControlProng'"
            >
              <mat-radio-button [value]="true" (change)="onIsControlProngYes(i)">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
      <!--Owner Details-->
      <div class="pt-4">
        <tilled-heading-h5>Owner Details</tilled-heading-h5>
        <div class="grid w-full grid-cols-1 gap-x-6 sm:grid-cols-2">
          <div class="col-span-1 flex flex-col">
            <tilled-input
              data-test-id="reps-first-name"
              name="firstName"
              label="FIRST NAME"
              tilledLabel="true"
              placeholder="Enter first name"
              [errors]="group.get('firstName').errors?.['required'] && group.get('firstName').touched"
              errorMessage="First Name is Required."
            ></tilled-input>
          </div>
          <div class="col-span-1 flex flex-col">
            <tilled-input
              data-test-id="reps-middle-name"
              name="middleName"
              label="MIDDLE NAME (OPTIONAL)"
              placeholder="Enter middle name (optional)"
              tilledLabel="true"
            ></tilled-input>
          </div>
        </div>
        <div class="grid w-full grid-cols-1 gap-x-6 sm:grid-cols-2">
          <div class="col-span-1 flex flex-col">
            <tilled-input
              data-test-id="reps-last-name"
              class="w-full"
              name="lastName"
              label="LAST NAME"
              placeholder="Enter last name"
              tilledLabel="true"
              [errors]="group.get('lastName').errors?.['required'] && group.get('lastName').touched"
              errorMessage="Last Name is Required."
            ></tilled-input>
          </div>
          <div class="col-span-1 flex flex-col">
            <tilled-input
              data-test-id="reps-phone-number"
              class="w-full"
              name="phone"
              [prefix]="group.get('phone')?.value?.includes('+') ? '' : selectedPhoneCode"
              placeholder="(555) 000-0000"
              [mask]="group.get('phone')?.value?.includes('+') ? '+0 (000) 000-0000' : '(000) 000-0000'"
              label="PHONE NUMBER"
              tilledLabel="true"
              [errors]="group.get('phone').hasError('invalidPhone') && group.get('phone').touched"
              errorMessage="Must be a valid phone number."
              [prefixMultiple]="false"
              [prefixOptions]="phoneCodeMap"
              [prefixControlName]="group.get('phoneCode')"
              [prefixPlaceholder]="'US'"
            ></tilled-input>
          </div>
        </div>
        <div class="grid w-full grid-cols-1 gap-x-6 sm:grid-cols-2">
          <div class="col-span-1 flex flex-col">
            <tilled-input
              data-test-id="reps-dob"
              name="dob"
              label="DATE OF BIRTH"
              placeholder="MM/DD/YYYY"
              [sentenceCasePlaceholder]="false"
              tilledLabel="true"
              mask="M0/d0/0000"
              [dropMaskCharacters]="false"
              [leadZeroDateTime]="true"
              [errors]="group.get('dob').errors?.['younger'] || group.get('dob').errors?.['mask']"
              errorMessage="Business owner must be older than 18 years and have a valid birth year."
            ></tilled-input>
          </div>
          <div class="col-span-1 flex flex-col">
            <tilled-input
              data-test-id="reps-ssn"
              name="ssn"
              mask="000-00-0000"
              placeholder="{{
                representativesFormArray.value[i].ssn
                  ? 'Already submitted. Click if you need to update SSN'
                  : 'Enter SSN'
              }}"
              label="SOCIAL SECURITY NUMBER"
              tilledLabel="true"
              [errors]="group.get('ssn').hasError('isEqualToList') && group.get('ssn').touched"
              [errorMessage]="'A valid SSN is required'"
            ></tilled-input>
          </div>
        </div>
        <div class="grid w-full grid-cols-1 gap-x-6 sm:grid-cols-2">
          <div class="col-span-1 flex flex-col">
            <tilled-input
              data-test-id="reps-job-title"
              name="jobTitle"
              label="JOB TITLE"
              tilledLabel="true"
              placeholder="Enter job title"
            ></tilled-input>
          </div>
          <tilled-input
            data-test-id="reps-ownership-percentage"
            name="percentageShareholding"
            label="OWNERSHIP %"
            tilledLabel="true"
            placeholder="Enter %"
            [showPercent]="true"
            minValue="0"
            maxValue="100"
            mask="percent"
            [errors]="
              group.get('percentageShareholding').errors?.['min'] ||
              (group.get('percentageShareholding').errors?.['max'] && group.get('percentageShareholding').touched)
            "
            [errorMessage]="'Must not be greater than 100%'"
            hint="Enter the % amount of the business owned by this individual"
          ></tilled-input>
        </div>
      </div>
      <!--Owner Current Residential Address-->
      <div class="pt-10">
        <tilled-heading-h4>Owner Residential Address</tilled-heading-h4>
        <div class="w-full pb-2.5 pt-4">
          <tilled-label-l1> Street address </tilled-label-l1>
        </div>
        <app-autocomplete
          data-test-id="contact-current-street"
          class="w-full"
          name="currentStreet"
          [autocompleteAddress]="representativesFormArray.value[i].currentStreet"
          (setAddress)="setPrincipalAddress($event, i, 'current')"
          hint="Enter the address where this business owner lives. P.O. boxes are not accepted."
        ></app-autocomplete>
        <!-- </div> -->
        <tilled-input
          data-test-id="contact-current-street2"
          class="w-full"
          name="currentStreet2"
          label="STREET ADDRESS 2"
          placeholder="Suite / Apt / Unit (Optional)"
          tilledLabel="true"
        ></tilled-input>
        <div class="grid w-full grid-cols-1 gap-x-6 sm:grid-cols-2">
          <div class="col-span-1 flex flex-col">
            <tilled-input
              data-test-id="contact-current-city"
              name="currentCity"
              label="CITY"
              placeholder="Louisville"
              tilledLabel="true"
            ></tilled-input>
          </div>
          <div class="col-span-1 flex flex-col">
            <div class="w-full pb-2.5 pt-4">
              <tilled-label-l1 secondary="true" class="pt-8">State</tilled-label-l1>
            </div>
            <tilled-select
              [multiple]="false"
              [options]="stateCodeMap"
              [placeholder]="'Choose One'"
              [controlName]="group.get('currentState')"
              classOverride="w-full"
              data-test-id="contact-current-state"
              groupName="MerchantAppBusinessContactForm"
            ></tilled-select>
          </div>
        </div>
        <div class="grid w-full grid-cols-1 gap-x-6 sm:grid-cols-2">
          <div class="col-span-1 flex flex-col">
            <tilled-input
              data-test-id="contact-current-zip"
              name="currentZip"
              label="ZIP"
              tilledLabel="true"
              placeholder="80205"
              maxLength="10"
            ></tilled-input>
          </div>
          <div *ngIf="isCanadian" class="col-span-1 flex flex-col">
            <tilled-input
              data-test-id="reps-years-at-current-address"
              name="yearsAtCurrentAddress"
              label="# OF YEARS AT THIS ADDRESS"
              tilledLabel="true"
              placeholder="Enter #"
              minValue="1"
            ></tilled-input>
          </div>
        </div>
      </div>
      <!--Owner Current Residential Address-->
      <div class="pt-4">
        <div *ngIf="representativesFormArray.value[i].yearsAtCurrentAddress <= 3 && isCanadian">
          <div class="w-full pb-2.5 pt-4">
            <tilled-label-l1>Owner Previous Residential Address</tilled-label-l1>
          </div>
          <div>
            <app-autocomplete
              data-test-id="contact-previous-street"
              class="w-full"
              name="previousStreet"
              [autocompleteAddress]="representativesFormArray.value[i].previousStreet"
              (setAddress)="setPrincipalAddress($event, i, 'previous')"
            ></app-autocomplete>
          </div>
          <tilled-input
            data-test-id="contact-previous-street2"
            class="w-full"
            name="previousStreet2"
            label="STREET ADDRESS 2"
            placeholder="Suite / Apt / Unit (Optional)"
            tilledLabel="true"
          ></tilled-input>
          <div class="grid w-full grid-cols-1 gap-x-6 sm:grid-cols-2">
            <div class="col-span-1 flex flex-col">
              <tilled-input
                data-test-id="contact-previous-city"
                name="previousCity"
                label="CITY"
                placeholder="Louisville"
                tilledLabel="true"
              ></tilled-input>
            </div>
            <div class="col-span-1 flex flex-col">
              <div class="w-full pb-2.5 pt-4">
                <tilled-label-l1 secondary="true" class="pt-8">State</tilled-label-l1>
              </div>
              <tilled-select
                [multiple]="false"
                [options]="stateCodeMap"
                [placeholder]="'Choose One'"
                [controlName]="group.get('previousState')"
                classOverride="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full"
                data-test-id="contact-previous-state"
                groupName="MerchantAppBusinessContactForm"
              ></tilled-select>
            </div>
          </div>
          <div class="grid w-full grid-cols-1 gap-x-6 sm:grid-cols-2">
            <div class="col-span-1 flex flex-col">
              <tilled-input
                data-test-id="contact-previous-zip"
                name="previousZip"
                label="ZIP"
                placeholder="80205"
                tilledLabel="true"
                maxLength="10"
              ></tilled-input>
            </div>
            <div class="col-span-1 flex flex-col">
              <tilled-input
                data-test-id="reps-years-at-previous-address"
                name="yearsAtPreviousAddress"
                label="# OF YEARS AT THIS ADDRESS"
                tilledLabel="true"
                placeholder="Enter #"
                minValue="1"
              ></tilled-input>
            </div>
          </div>
        </div>
      </div>
      <div class="ng-star-inserted flex w-full flex-row-reverse items-end justify-between pb-6">
        <tilled-button
          *ngIf="representativesFormArray.length > 1"
          [whiteText]="false"
          [rounded]="true"
          [secondary]="true"
          (buttonClick)="removePrincipalFormGroup(i)"
          bgColor="tilled-neutral-300"
          [disabled]="disabled$ | async"
          buttonText="Delete"
        ></tilled-button>
        <tilled-button
          data-test-id="businessRep-addRepBtn"
          *ngIf="representativesFormArray.length < 4"
          class="pt-4"
          bgColor="tilled-primary"
          [rounded]="true"
          [disabled]="disabled$ | async"
          buttonText="Add Another"
          (buttonClick)="representativesFormArray.push(getPrincipalFormGroup(null))"
        ></tilled-button>
      </div>
    </div>
  </form>
</merchant-app-card>
