import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ApplicationStep } from 'app/core/models/application-step';
import { FuseCardComponent } from '../../../../@fuse/components/card/card.component';
import { MatIconModule } from '@angular/material/icon';
import { NgClass } from '@angular/common';

@Component({
  selector: 'isv-app-summary-card',
  templateUrl: './app-summary-card.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgClass, MatIconModule, FuseCardComponent],
})
export class PartnerApplicationSummaryCardComponent implements OnInit {
  @Input() appStep?: ApplicationStep;
  @Output() cardClick: EventEmitter<number> = new EventEmitter<number>();
  constructor() {}

  ngOnInit() {}

  onButtonClick() {
    this.cardClick.emit(this.appStep.order);
  }
}
