<div class="fixed bottom-0 top-0 h-full font-sans shadow">
  <div
    class="flex h-full w-96 flex-col bg-tilled-neutral-200 transition-transform duration-400 ease-drawer"
    [ngClass]="{
      '-translate-x-full shadow': opened,
      'translate-x-0': !opened
    }"
  >
    <!-- Header -->
    <div class="flex h-16 flex-0 items-center bg-tilled-neutral-200 drop-shadow" (click)="toggle()">
      <!-- Toggle -->
      <div *ngIf="opened" class="flex flex-auto items-center justify-center">
        <div class="flex w-16 flex-0 items-center justify-center">
          <mat-icon class="text-primary icon-size-6" [svgIcon]="'heroicons_outline:question-mark-circle'"></mat-icon>
        </div>
        <button class="ml-auto mr-4" mat-icon-button>
          <mat-icon [svgIcon]="'mat_outline:close'"></mat-icon>
        </button>
      </div>
    </div>

    <!-- Content -->
    <div
      class="m-auto flex h-full max-h-screen w-full flex-col items-center overflow-auto border-t bg-tilled-neutral-200 p-4"
    >
      <div class="mb-16 mt-4 h-[131px] w-[280px] justify-center p-4 text-center text-[30px] font-extrabold leading-10">
        Need Support? We have you covered.
      </div>

      <div class="bg-card mb-20 flex h-[210px] w-[280px] flex-none flex-col items-center rounded-2xl p-6 shadow">
        <!-- <div
        class="relative -top-14 flex h-[60px] w-[60px] items-center justify-center rounded-full bg-tilled-neutral-100"
        > -->
        <div class="relative -top-14 flex h-[75px] w-[75px] items-center justify-center rounded-full bg-tilled-orange">
          <mat-icon class="white-icon icon-size-12" color="white">
            <!-- svgIcon="../../../../assets/icons/question_mark.svg" -->
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
              <path
                d="M424-320q0-81 14.5-116.5T500-514q41-36 62.5-62.5T584-637q0-41-27.5-68T480-732q-51 0-77.5 31T365-638l-103-44q21-64 77-111t141-47q105 0 161.5 58.5T698-641q0 50-21.5 85.5T609-475q-49 47-59.5 71.5T539-320H424Zm56 240q-33 0-56.5-23.5T400-160q0-33 23.5-56.5T480-240q33 0 56.5 23.5T560-160q0 33-23.5 56.5T480-80Z"
              />
            </svg>
          </mat-icon>
        </div>
        <div class="relative -top-7 text-[24px] font-bold leading-[30px]">FAQs</div>
        <div class="text-[15px] font-semibold leading-[20px]">
          <div *ngIf="showPartnerHelpCenterLink" class="text-center">
            <a href="https://tilledpartners.zendesk.com/hc/en-us" target="_blank">Partner Help Center</a>
          </div>
          <div class="mt-2 text-center">
            <a href="{{ merchantSupportCenterUrl }}" target="_blank">{{ merchantSupportCenterName }}</a>
          </div>
        </div>
      </div>

      <div
        *ngIf="showEmail"
        class="bg-card mb-20 flex h-[210px] w-[280px] flex-none flex-col items-center rounded-2xl p-6 shadow"
      >
        <div class="relative -top-14 flex h-[75px] w-[75px] items-center justify-center rounded-full bg-[#6C6DD7]">
          <mat-icon class="white-icon icon-size-12" svgIcon="heroicons_solid:envelope" color="white"></mat-icon>
        </div>
        <div class="relative -top-7 text-[24px] font-bold leading-[30px]">Email</div>
        <div class="text-[15px] font-semibold leading-[20px]">
          <a href="mailto:{{ supportEmailLink }}">{{ supportEmail }}</a>
        </div>
      </div>
      <div
        *ngIf="showPhone"
        class="bg-card mb-20 flex h-[210px] w-[280px] flex-none flex-col items-center rounded-2xl p-6 shadow"
      >
        <div class="relative -top-14 flex h-[75px] w-[75px] items-center justify-center rounded-full bg-[#EB5778]">
          <mat-icon class="white-icon icon-size-12" svgIcon="heroicons_solid:phone"></mat-icon>
        </div>
        <div class="relative -top-7 text-[24px] font-bold leading-[30px]">Call us</div>
        <div class="text-[15px] font-semibold leading-[20px]">
          <a href="tel:{{ supportPhoneLink }}">{{ supportPhone }}</a>
        </div>
        <div *ngIf="showTilledHours" class="mt-2 text-[15px] font-semibold leading-[20px]">
          9am-6pm ET, Monday-Friday
        </div>
      </div>
    </div>
  </div>
</div>
