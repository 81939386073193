/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { parsePhoneNumberFromString, CountryCode } from 'libphonenumber-js';

export function isPhoneNumber(countryString: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }
    const countryCode = <CountryCode>countryString;

    const phoneNum = parsePhoneNumberFromString(value, countryCode);
    const result = phoneNum?.isValid();

    return result ? null : { invalidPhone: true };
  };
}
