/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Markup } from './markup';


export interface CardChargeFeeTemplate { 
    /**
     * The amount of the fee applied when the merchant bank account information is updated, in currency minor units.
     */
    bank_account_change_fee?: number;
    /**
     * The amount of the fee applied per card updated, in currency minor units.
     */
    card_update_fee?: number;
    /**
     * The amount of the fee applied per chargeback, in currency minor units.
     */
    chargeback_fee: number;
    /**
     * The list of additional markups applied to a given card type.
     */
    markups: Array<Markup>;
    /**
     * The amount of the fee applied per retrieval, in currency minor units.
     */
    retrieval_fee: number;
    /**
     * The amount of the fee applied per reversal, in currency minor units.
     */
    reversal_fee: number;
    /**
     * The amount of the transaction fee applied to each transaction, in currency minor units.
     */
    transaction_fee: number;
    /**
     * The type of transaction fee this pricing template applies.
     */
    transaction_fee_type: CardChargeFeeTemplate.TransactionFeeTypeEnum;
}
export namespace CardChargeFeeTemplate {
    export type TransactionFeeTypeEnum = 'flat_rate' | 'interchange';
    export const TransactionFeeTypeEnum = {
        FLAT_RATE: 'flat_rate' as TransactionFeeTypeEnum,
        INTERCHANGE: 'interchange' as TransactionFeeTypeEnum
    };
}


