import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TilledParagraphGenericComponent } from './tilled-paragraph-generic.component';

@Component({
  selector: 'tilled-paragraph-p3',
  templateUrl: '../tilled-text-generic.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class TilledParagraphP3Component extends TilledParagraphGenericComponent {
  @Input() bold: boolean = false;
  @Input() italic: boolean = false;
  public styles: string;
  constructor() {
    super();
    this.styles = 'text-p3';
  }
}
