import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { PartnerAppService } from 'app/core/services/partner-app.service';
import { environment } from 'environments/environment';
import { Observable, map, of, switchMap } from 'rxjs';
import {
  AccountCapability,
  AccountDocument,
  AccountInternalMetadata,
  InternalAccount,
  PartnerApplicationResponse,
} from '../../../../../projects/tilled-api-client/src';
import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationStatusGuard {
  private partnerApp: Observable<PartnerApplicationResponse>;
  constructor(private authService: AuthService, private router: Router, private partnerAppService: PartnerAppService) {}

  public canActivatePartner(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // We currently only want to restrict partner access in production and staging for testing.
    // Sandbox should remain unrestricted.
    if (!environment.production && environment.env !== 'staging') {
      return true;
    }

    return this.authService.account$.pipe(
      switchMap((result: InternalAccount) => {
        // If an existing partner does not have internal metadata set, we want proceed as before.
        if (!result.internal_metadata?.status) {
          return of(true);
        }

        if (
          result.internal_metadata.status === AccountInternalMetadata.StatusEnum.CERTIFIED ||
          result.internal_metadata.status === AccountInternalMetadata.StatusEnum.LIVE ||
          result.internal_metadata.status === AccountInternalMetadata.StatusEnum.ACTIVE
        ) {
          return of(true);
        } else {
          return this.partnerAppService.loadApplication().pipe(
            map((application) => {
              if (
                application.status === PartnerApplicationResponse.StatusEnum.NOT_STARTED ||
                application.status === PartnerApplicationResponse.StatusEnum.STARTED ||
                application.status === PartnerApplicationResponse.StatusEnum.SENT_TO_PARTNER
              ) {
                this.router.navigate(['/enroll']);
                return false;
              } else {
                this.router.navigate(['/enroll/success']);
                return false;
              }
            }),
          );
        }
      }),
    );
  }

  public canActivateMerchant(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.account$.pipe(
      map((result) => {
        if (
          result.capabilities.find((cap) =>
            [AccountCapability.StatusEnum.ACTIVE, AccountCapability.StatusEnum.DISABLED].includes(cap.status),
          )
        ) {
          return true;
        } else if (
          result.capabilities.find((cap) =>
            [AccountCapability.StatusEnum.SUBMITTED, AccountCapability.StatusEnum.IN_REVIEW].includes(cap.status),
          ) &&
          result.document_requests?.length > 0 &&
          result.document_requests.find((doc) => doc.status === AccountDocument.StatusEnum.REQUESTED)
        ) {
          this.router.navigate(['/onboarding/documents']);
        } else if (
          result.capabilities.find((cap) =>
            [
              AccountCapability.StatusEnum.SUBMITTED,
              AccountCapability.StatusEnum.REJECTED,
              AccountCapability.StatusEnum.WITHDRAWN,
              AccountCapability.StatusEnum.IN_REVIEW,
            ].includes(cap.status),
          )
        ) {
          // redirect to submitted page.
          this.router.navigate(['/onboarding/submitted']);
        } else if (result.capabilities.find((cap) => cap.status === AccountCapability.StatusEnum.CREATED)) {
          this.router.navigate(['/onboarding/get-started']);
        } else {
          // Authorized user that has not yet completed a merchant application
          // Redirect to merch app.
          this.router.navigate(['/onboarding/application']);
        }

        return false;
      }),
    );
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.user && !this.authService.isMerchantUser()) {
      return this.canActivatePartner();
    } else {
      return this.canActivateMerchant();
    }
  }

  canActivateChild(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.user && !this.authService.isMerchantUser()) {
      return this.canActivatePartner();
    } else {
      return this.canActivateMerchant();
    }
  }
}
