import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ComponentBase } from 'app/core/componentBase';
import { TilledButtonComponent } from '../../../buttons/tilled-button.component';
import { TilledHeadingH1Component } from '../../../tilled-text/tilled-heading/tilled-heading-h1.component';
import { TilledHeadingH3Component } from '../../../tilled-text/tilled-heading/tilled-heading-h3.component';
import { TilledParagraphP1Component } from '../../../tilled-text/tilled-paragraph/tilled-paragraph-p1.component';

@Component({
  selector: 'merchant-app-card',
  templateUrl: './merchant-app-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TilledHeadingH3Component,
    TilledHeadingH1Component,
    TilledParagraphP1Component,
    TilledButtonComponent,
    CommonModule,
  ],
})
export class MerchantAppCardComponent extends ComponentBase implements OnInit {
  @Output() backClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() continueClick: EventEmitter<string> = new EventEmitter<string>();

  @Input() backButton?: boolean = true;
  @Input() continueButton?: boolean = true;
  @Input() continueButtonText?: string = 'Save and Continue';
  @Input() disableContinueButton?: boolean = false;

  @Input() stepNumber?: string;
  @Input() title?: string;
  @Input() description?: string;

  constructor() {
    super();
  }

  ngOnInit() {}

  onButtonClick(text) {
    switch (text) {
      case 'back':
        this.backClick.emit();
        break;
      case 'continue':
        this.continueClick.emit();
        break;
    }
  }
}
