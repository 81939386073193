import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TilledParagraphP3Component } from 'app/shared/tilled-text';
import { LineItem } from '../../../../projects/tilled-api-client/src';
import { TilledButtonComponent } from '../buttons/tilled-button.component';
import { DashedLineContainerComponent } from '../containers/dashed-line-container/dashed-line-container.component';
import { TilledInputComponent } from '../form-fields/tilled-input/tilled-input.component';
import { MetadataDialogComponent } from '../metadata-dialog/metadata-dialog.component';
import { TilledHeadingH2Component } from '../tilled-text/tilled-heading/tilled-heading-h2.component';
import { TilledLabelL1Component } from '../tilled-text/tilled-label/tilled-label-l1.component';
import { TilledParagraphP1Component } from '../tilled-text/tilled-paragraph/tilled-paragraph-p1.component';

@Component({
  selector: 'app-line-items',
  templateUrl: './line-items.component.html',
  styleUrls: ['./line-items.component.scss'],
  standalone: true,
  imports: [
    TilledLabelL1Component,
    MatDividerModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    TilledInputComponent,
    DashedLineContainerComponent,
    TilledHeadingH2Component,
    TilledParagraphP1Component,
    TilledParagraphP3Component,
    TilledButtonComponent,
    CommonModule,
  ],
})
export class LineItemsComponent implements OnInit {
  @Input() lineItems: LineItem[];
  @Input() updateLineItemsCallback: (lineItems: LineItem[]) => void;
  @Output() lineItemsChanged: EventEmitter<any> = new EventEmitter<any>();

  public currentEditIndex: number = -1;
  public editDisabled: boolean = false;
  public lineItemForm: FormGroup;

  public openDialogMode: boolean = true;
  public displayView = true;
  public metadataForm: FormGroup;
  items: FormArray;
  public dialogRef: any;

  constructor(
    private _matDialog: MatDialog,
    private _formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.lineItemForm = this._formBuilder.group({
      productCode: new FormControl<string | null>(null, [Validators.required]),
      productDescription: new FormControl<string | null>(null, [Validators.required]),
      quantity: new FormControl<number | null>(null, [Validators.required]),
      unitAmount: new FormControl<number | null>(null, [Validators.required]),
      taxAmount: new FormControl<number | null>(null, [Validators.required]),
    });

    if (this.lineItems) {
      let i = 0;
      for (const lineItem of this.lineItems) {
        if (
          !lineItem.product_code ||
          !lineItem.product_description ||
          !lineItem.quantity ||
          !lineItem.tax_amount ||
          !lineItem.unit_amount
        ) {
          this.lineItems.splice(i, 1);
        }
        i++;
      }
    }
  }

  public addFirstLineItem(): void {
    this.lineItems = [];
    let tempLineItem: LineItem = {
      product_code: null,
      product_description: null,
      quantity: null,
      unit_amount: null,
      tax_amount: null,
    };
    this.lineItems.push(tempLineItem);
    this.currentEditIndex = 0;
    this.editDisabled = true;
  }

  public saveLineItemRow(index): void {
    if (this.lineItemForm.invalid) {
      this.lineItemForm.markAllAsTouched();
      return;
    }
    if (index !== -1 && index < this.lineItems.length) {
      let tempLineItem: LineItem = {
        product_code: this.lineItemForm.get('productCode').value,
        product_description: this.lineItemForm.get('productDescription').value,
        quantity: this.lineItemForm.get('quantity').value,
        unit_amount: this.lineItemForm.get('unitAmount').value,
        tax_amount: this.lineItemForm.get('taxAmount').value,
      };
      this.lineItems[index] = tempLineItem;
    }
    this.currentEditIndex = -1;
    this.editDisabled = false;
    this.lineItemForm.reset();
    this.updateLineItemsCallback(this.lineItems);
  }

  public editLineItemRow(index): void {
    if (index !== -1 && index < this.lineItems.length) {
      this.lineItemForm.get('productCode').setValue(this.lineItems[index].product_code);
      this.lineItemForm.get('productDescription').setValue(this.lineItems[index].product_description);
      this.lineItemForm.get('quantity').setValue(this.lineItems[index].quantity);
      this.lineItemForm.get('unitAmount').setValue(Number(this.lineItems[index].unit_amount).toFixed(2));
      this.lineItemForm.get('taxAmount').setValue(Number(this.lineItems[index].tax_amount).toFixed(2));
    }
    this.currentEditIndex = index;
    this.editDisabled = true;
  }

  public deleteLineItemRow(index): void {
    if (index !== -1 && index < this.lineItems.length) {
      this.lineItems.splice(index, 1);
    }
    if (this.lineItems.length === 0) {
      this.lineItems = null;
    }
    this.updateLineItemsCallback(this.lineItems);
    this.lineItemsChanged.emit(this.lineItems);
  }

  public addLineItemRow(): void {
    let tempLineItem: LineItem = {
      product_code: null,
      product_description: null,
      quantity: null,
      unit_amount: null,
      tax_amount: null,
    };
    this.lineItems.push(tempLineItem);
    this.currentEditIndex = this.lineItems.length - 1;
    this.editDisabled = true;
  }

  public editMetadata(): void {
    if (this.openDialogMode) {
      this.openEditDialog();
    } else {
      this.setValuesFromInput();
      this.displayView = false;
    }
  }

  public closeMetadata(response: FormGroup) {
    if (response) {
      //this.updateLineItemsCallback(this.lineItems);
    }

    this.displayView = true;
  }

  setValuesFromInput(): void {
    this.items = null;
    if (this.metadataForm) {
      this.metadataForm.reset();
    }
    this.metadataForm = this._formBuilder.group({
      items: this._formBuilder.array([]),
    });

    if (this.lineItems) {
      for (const lineItem of this.lineItems) {
        let tempLineItem: LineItem = {
          product_code: lineItem.product_code,
          product_description: lineItem.product_description,
          quantity: lineItem.quantity,
          unit_amount: lineItem.unit_amount,
          tax_amount: lineItem.tax_amount,
        };
      }
    }
  }

  addMetadataItem(key = '', value = ''): void {
    this.items = this.metadataForm.get('items') as FormArray;
    this.items.push(this.createMetadataItem(key, value));
  }

  createMetadataItem(k, v): FormGroup {
    return this._formBuilder.group({
      lineItem: [
        {
          value: k,
          disabled: k, // disable input if k is passed in
        },
      ],
    });
  }

  removeMetadataRow(index): void {
    this.items.removeAt(index);
    this._formBuilder.group({ items: this.items });
  }

  private openEditDialog(): void {
    this.dialogRef = this._matDialog.open(MetadataDialogComponent, {
      panelClass: 'meta-data-dialog',
      data: {
        action: 'edit',
        metadata: this.lineItems,
      },
    });

    this.dialogRef.afterClosed().subscribe((response: FormGroup) => {
      if (!response) {
        return;
      }

      //this.updateLineItemsCallback(this.lineItems);
    });
  }
}
