import { NgModule } from '@angular/core';

import { SharedModule } from 'app/shared/shared.module';
import { HelpCenterComponent } from './help-center.component';

@NgModule({
    imports: [SharedModule, HelpCenterComponent],
    exports: [HelpCenterComponent],
})
export class HelpCenterModule {}
