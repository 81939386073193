import { Pipe, PipeTransform } from '@angular/core';
import { ChipColorClass, TilledChipConfig } from 'app/shared/tilled-chip/tilled-chip.component';
import { DocumentDto } from '../../../../projects/tilled-api-client/src';

/**
 * Takes a DocumentDto
 */
@Pipe({
  name: 'documentStatus',
})
export class DocumentsStatusPipe implements PipeTransform {
  /**
   *
   * @param document DocumentDto
   * @returns
   */
  transform(document: DocumentDto): TilledChipConfig {
    const chipConfig: TilledChipConfig = {
      color: ChipColorClass.OPAQUE_ORANGE,
      text: document.status.toUpperCase(),
      toolTip: '',
    };
    if (document.status === DocumentDto.StatusEnum.SUBMITTED) {
      chipConfig.text = 'IN REVIEW';
      chipConfig.color = ChipColorClass.OPAQUE_PURPLE;
    } else if (document.status === DocumentDto.StatusEnum.REJECTED) {
      chipConfig.text += ' - ' + document.rejected_reason?.toUpperCase();
      chipConfig.color = ChipColorClass.OPAQUE_ACCENT;
    } else if (document.status === DocumentDto.StatusEnum.VERIFIED) {
      chipConfig.color = ChipColorClass.OPAQUE_GREEN;
    } else if ((document.status = DocumentDto.StatusEnum.REQUESTED)) {
      chipConfig.color = ChipColorClass.OPAQUE_RED;
    }

    return chipConfig;
  }
}
