import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SentenceCasePipe } from '../../core/pipes/sentence-case.pipe';
import { TilledButtonComponent } from '../buttons/tilled-button.component';

@Component({
  selector: 'tilled-select',
  templateUrl: './tilled-select.component.html',
  styleUrls: ['./tilled-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatOptionModule,
    TilledButtonComponent,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    SentenceCasePipe,
    CommonModule,
  ],
})
export class TilledSelectComponent implements OnInit {
  @ViewChild('tilledSelect') select!: MatSelect;
  @Input() multiple? = true;
  @Input() options: { value: string; label: string }[] = [];
  @Input() controlName?: FormControl;
  @Input() groupName?: string;
  @Input() appearance?: 'fill' | 'outline' = 'fill';
  @Input() toolTip: string = null;
  @Input() placeholder?: string = '';
  @Input() sentenceCasePlaceholder?: boolean = true;
  @Input() templateRef?: TemplateRef<any>;
  @Input() classOverride?: string;
  @Input() matSuffix?: boolean;
  @Input() matPrefix?: boolean;
  @Input() errors?: boolean = false;
  @Input() errorMessage?: string;
  @Input() readonly?: boolean;
  @Input() suffixReadonly?: boolean;
  @Input() disabledOptions?: { value: string; label: string }[] = [];
  @Output() closed? = new EventEmitter<void>();
  @Output() selectionChange? = new EventEmitter<void>();
  @Output() opened? = new EventEmitter<void>();
  @Output() panelOpen = new EventEmitter<void>();

  constructor() {}
  ngOnInit(): void {
    // for testing console logging
  }

  clearSelection(): void {
    this.controlName.reset([]);
  }

  closeDropdown(): void {
    this.select.close();
  }

  openDropdown(): void {
    this.select.open();
  }
}
