import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AUTH_LOGIN_ROUTE } from 'app/core/constants';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizeGuard {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // TODO: figure out how to handle this in routing
    if (state.url.includes('enroll/expired')) {
      return true;
    }

    if (this.authService.user) {
      return true;
    } else {
      // No one logged in or token is expired
      // Redirect to login
      // this.router.createUrlTree???
      return this.router.navigate(['/' + AUTH_LOGIN_ROUTE], {
        queryParams: {
          redirectTo: state.url,
        },
      });
    }
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // TODO: figure out how to handle this in routing
    if (state.url.includes('enroll/expired')) {
      return true;
    }
    if (this.authService.user) {
      return true;
    } else {
      // No one logged in or token is expired
      // Redirect to login
      // this.router.createUrlTree???
      return this.router.navigate(['/' + AUTH_LOGIN_ROUTE], {
        queryParams: {
          redirectTo: state.url,
        },
      });
    }
  }
}
