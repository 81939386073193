import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CapabilityStatusPipe } from 'app/core/pipes/capability-status.pipe';
import { PhoneNumberPipe } from 'app/core/pipes/phone-number.pipe';
import { BusinessRepresentative, InternalAccount } from '../../../../projects/tilled-api-client/src';

import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { FuseCardComponent } from '../../../@fuse/components/card/card.component';
import { TilledCardRowComponent } from '../tilled-card-row/tilled-card-row.component';
import { TilledHeadingH4Component } from '../tilled-text/tilled-heading/tilled-heading-h4.component';

@Component({
  selector: 'company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FuseCardComponent,
    TilledHeadingH4Component,
    MatDividerModule,
    TilledCardRowComponent,
    PhoneNumberPipe,
    CapabilityStatusPipe,
    CommonModule,
  ],
})
export class CompanyProfileComponent implements OnInit {
  @Input() account: InternalAccount;

  constructor() {}

  ngOnInit(): void {}

  getRepName(businessRep: BusinessRepresentative): string {
    let nameString = '';
    nameString = businessRep.first_name + ' ';
    if (businessRep.middle_name) {
      nameString += businessRep.middle_name + ' ';
    }
    nameString += businessRep.last_name;

    return nameString;
  }
}
