<div class="w-full pb-2.5 pt-4">
  <tilled-label-l1 secondary="true">{{ label }}</tilled-label-l1>
</div>
<mat-form-field
  appearance="fill"
  class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded tilled-chip-field w-full"
>
  <mat-select
    [formControl]="controlName"
    [placeholder]="sentenceCasePlaceholder ? (placeholder | sentenceCase) : placeholder"
    multiple
    (selectionChange)="selectionChange.emit($event)"
  >
    <mat-select-trigger>
      <mat-chip-listbox>
        <mat-chip-option
          *ngFor="let item of controlName.value"
          (removed)="removeItem(item)"
          class="tilled-chip-list rounded-md bg-white font-normal text-tilled-primary"
        >
          {{ item }}
          <mat-icon [svgIcon]="'mat_outline:close'" class="icon-size-4.5" color="hint" matChipRemove></mat-icon>
        </mat-chip-option>
      </mat-chip-listbox>
    </mat-select-trigger>
    <mat-option *ngFor="let option of selectOptions" [value]="option">{{ option }}</mat-option>
  </mat-select>
  <mat-icon
    color="hint"
    matSuffix
    class="ml-1 icon-size-5"
    id="select-arrow"
    [svgIcon]="'heroicons_outline:chevron-down'"
  ></mat-icon>
</mat-form-field>
