/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccountCapabilityProcessingDetail } from './accountCapabilityProcessingDetail';
import { ProductCode } from './productCode';
import { AccountRequirementsError } from './accountRequirementsError';
import { PricingTemplate } from './pricingTemplate';


export interface InternalAccountCapability { 
    billing_owner: InternalAccountCapability.BillingOwnerEnum;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * The percentage of required fields completed in the onboarding application.
     */
    onboarding_application_progress: number;
    /**
     * The URL for the merchant\'s onboarding application.
     */
    onboarding_application_url?: string;
    /**
     * The pricing applied to/associated with this account.
     */
    pricing_template: PricingTemplate;
    processing_details?: AccountCapabilityProcessingDetail;
    /**
     * *Deprecated: See `pricing_template` for account details.*  The product code applied to/associated with this account.
     */
    product_code: ProductCode;
    /**
     * The fields that need to be collected again because validation failed for some reason.
     */
    requirements_errors: Array<AccountRequirementsError>;
    /**
     * Status of the associated product code as part of the onboarding process. Only the `active` status represents that payments can be handled by this account for this given product code.  `created` The capability has been created but no further action taken. Generally, this is the state of all capabilities when a merchant has been created but the onboarding process has not yet started.  `started` The onboarding process has been started but not yet submitted.  `submitted` The onboarding process for this product code has been submitted.  `active` This associated product code is active for payments on this account.  `disabled` This associated product code is not valid for payments on this account.
     */
    status: InternalAccountCapability.StatusEnum;
}
export namespace InternalAccountCapability {
    export type BillingOwnerEnum = 'provider' | 'tilled';
    export const BillingOwnerEnum = {
        PROVIDER: 'provider' as BillingOwnerEnum,
        TILLED: 'tilled' as BillingOwnerEnum
    };
    export type StatusEnum = 'created' | 'started' | 'submitted' | 'active' | 'disabled' | 'in_review' | 'rejected' | 'withdrawn';
    export const StatusEnum = {
        CREATED: 'created' as StatusEnum,
        STARTED: 'started' as StatusEnum,
        SUBMITTED: 'submitted' as StatusEnum,
        ACTIVE: 'active' as StatusEnum,
        DISABLED: 'disabled' as StatusEnum,
        IN_REVIEW: 'in_review' as StatusEnum,
        REJECTED: 'rejected' as StatusEnum,
        WITHDRAWN: 'withdrawn' as StatusEnum
    };
}


