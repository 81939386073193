<div
  class="
      rounded-xl
      p-4
      my-4
      cursor-pointer
      {{ styles }}
    "
>
  <mat-radio-group (onClick)="onSelected()" color="primary" value="null">
    <mat-radio-button (change)="onSelected()" [checked]="selected === true">{{ text }}</mat-radio-button>
  </mat-radio-group>
</div>
