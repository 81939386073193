<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<div class="fixed left-0 right-0 top-20 z-[99999] m-auto w-full max-w-full px-6 sm:w-fit sm:max-w-[50%] sm:px-0">
  <tilled-alert></tilled-alert>
</div>

<!-- Wrapper -->
<div class="flex w-full flex-auto flex-col">
  <!-- Header -->
  <div class="relative z-49 flex h-16 w-full flex-0 flex-row-reverse items-center bg-white px-4">
    <!-- User Profile/Logout -->
    <user></user>

    <app-multi-account-selector
      *ngIf="this.isMultiAccount"
      class="border-r border-tilled-neutral-300 pr-4"
    ></app-multi-account-selector>
  </div>

  <!-- Content -->
  <div class="flex flex-auto flex-col">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
    Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>
</div>
