import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReplaceNullWithStringPipe } from 'app/core/pipes/replace-null-with-string.pipe';
import { TilledParagraphP3Component } from '../tilled-text/tilled-paragraph/tilled-paragraph-p3.component';

@Component({
  selector: 'tilled-card-row',
  templateUrl: './tilled-card-row.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [TilledParagraphP3Component, MatIconModule, MatTooltipModule, CommonModule],
})
export class TilledCardRowComponent implements OnInit, OnChanges {
  @Input() labelText: string;
  @Input() valueText: string;
  @Input() labelInfoTooltip: string;
  @Input() valueInfoTooltip: string;
  @Input() hideValueInfoIcon: boolean = false;
  @Input() urlValue: string;
  @Input() useTitlecase: boolean = true; // Defaults to true, but normally overridden for things like strings with `ID` or `URL` in them) Only applies to the label, not the value
  @Input() infoIconPosition: 'left' | 'right' = 'left';

  constructor() {}

  ngOnInit(): void {}
  nullOrEmptyText(val: any): string {
    return new ReplaceNullWithStringPipe().transform(val);
  }

  ngOnChanges(changes: SimpleChanges): void {}
}
export interface TilledCardRowData {
  labelText: string;
  valueText: string;
  labelInfoTooltip?: string;
  valueInfoTooltip?: string;
  urlValue?: string;
  useTitlecase?: boolean;
}
// Pushing blank commit
