/* eslint-disable prefer-arrow/prefer-arrow-functions, @typescript-eslint/naming-convention */
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import validator from 'validator';

interface UrlValidatorOptions {
  require_host?: boolean;
  require_protocol?: boolean;
  require_tld?: boolean;
}

export function isValidUrl(
  options: UrlValidatorOptions = { require_host: true, require_protocol: true, require_tld: false },
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }

    // Merge default options with provided options
    const validationOptions = {
      require_host: true,
      require_protocol: true,
      require_tld: false,
      ...options,
    };

    return validator.isURL(value, validationOptions) ? null : { invalidUrl: true };
  };
}
