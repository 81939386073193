import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseCardComponent } from '../../../@fuse/components/card/card.component';
import { TilledButtonComponent } from '../buttons/tilled-button.component';
import { DashedLineContainerComponent } from '../containers/dashed-line-container/dashed-line-container.component';
import { TilledInputComponent } from '../form-fields/tilled-input/tilled-input.component';
import { MetadataDialogComponent } from '../metadata-dialog/metadata-dialog.component';
import { TilledHeadingH4Component } from '../tilled-text/tilled-heading/tilled-heading-h4.component';
import { TilledParagraphP3Component } from '../tilled-text/tilled-paragraph/tilled-paragraph-p3.component';

@Component({
  selector: 'app-metadata-card',
  templateUrl: './metadata-card.component.html',
  styleUrls: ['./metadata-card.component.scss'],
  standalone: true,
  imports: [
    FuseCardComponent,
    TilledHeadingH4Component,
    MatIconModule,
    MatTooltipModule,
    MatDividerModule,
    TilledParagraphP3Component,
    FormsModule,
    ReactiveFormsModule,
    TilledInputComponent,
    TilledButtonComponent,
    DashedLineContainerComponent,
    CommonModule,
  ],
})
export class MetadataCardComponent implements OnInit {
  @Input() metadata: { [key: string]: string };
  @Input() saveMetadataCallback: (metadataForm: FormGroup) => void;
  @Input() disableEdit: boolean = false;
  @Input() disableAdd: boolean = false;

  public openDialogMode: boolean = true;
  public displayView = true;
  public metadataForm: FormGroup;
  items: FormArray;
  public dialogRef: any;
  public editToolTip: string;

  constructor(
    private _matDialog: MatDialog,
    private _formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.editToolTip = this.disableEdit ? 'You do not have sufficient privileges to edit metadata.' : '';
  }

  public editMetadata(): void {
    if (this.openDialogMode) {
      this.openEditDialog();
    } else {
      this.setValuesFromInput();
      this.displayView = false;
    }
  }

  public closeMetadata(response: FormGroup): void {
    if (response) {
      this.saveMetadataCallback(response);
    }

    this.displayView = true;
  }

  setValuesFromInput(): void {
    this.items = null;
    if (this.metadataForm) {
      this.metadataForm.reset();
    }
    this.metadataForm = this._formBuilder.group({
      items: new FormArray<any | null>([]),
    });
    if (this.metadata) {
      for (const [key, value] of Object.entries(this.metadata)) {
        this.addMetadataItem(key, value);
      }
    }
  }

  addMetadataItem(key = '', value = ''): void {
    this.items = this.metadataForm.get('items') as FormArray;
    this.items.push(this.createMetadataItem(key, value));
  }

  createMetadataItem(k, v): FormGroup {
    return this._formBuilder.group({
      key: [
        {
          value: k,
          disabled: k, // disable input if k is passed in
        },
      ],
      value: v,
    });
  }

  removeMetadataRow(index): void {
    this.items.removeAt(index);
    this._formBuilder.group({ items: this.items });
  }

  private openEditDialog(): void {
    this.dialogRef = this._matDialog.open(MetadataDialogComponent, {
      panelClass: 'meta-data-dialog',
      data: {
        action: 'edit',
        metadata: this.metadata,
      },
    });

    this.dialogRef.afterClosed().subscribe((response: FormGroup) => {
      if (!response) {
        return;
      }

      this.saveMetadataCallback(response);
    });
  }
}
