import { CommonModule } from '@angular/common';
import { Component, Inject, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormCardComponent } from 'app/shared/cards/form-cards/form-card.component';
import { TilledButtonComponent } from '../buttons/tilled-button.component';
import { TilledLabelL1Component } from '../tilled-text/tilled-label/tilled-label-l1.component';

@Component({
  selector: 'app-metadata-dialog',
  templateUrl: './metadata-dialog.component.html',
  styleUrls: ['./metadata-dialog.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    FormCardComponent,
    ReactiveFormsModule,
    TilledLabelL1Component,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    TilledButtonComponent,
    CommonModule,
  ],
})
export class MetadataDialogComponent implements OnInit {
  @Input() metadata: string;
  @Output() updatedMetadata;
  public metadataForm: FormGroup;
  items: FormArray;
  editingMetadata = false;

  constructor(
    public dialogRef: MatDialogRef<MetadataDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
  ) {
    this.metadata = this._data.metadata;
  }

  ngOnInit(): void {
    this.setValuesFromInput();
  }

  setValuesFromInput(): void {
    this.items = null;
    if (this.metadataForm) {
      this.metadataForm.reset();
    }
    this.metadataForm = this._formBuilder.group({
      items: new FormArray([]),
    });
    if (this.metadata) {
      for (const [key, value] of Object.entries(this.metadata)) {
        this.addMetadataItem(key, value);
      }
    }
  }

  addMetadataItem(key = '', value = ''): void {
    this.items = this.metadataForm.get('items') as FormArray;
    this.items.push(this.createMetadataItem(key, value));
  }

  removeMetadataRow(index): void {
    this.items.removeAt(index);
    this._formBuilder.group({ items: this.items });
  }

  createMetadataItem(k, v): FormGroup {
    const group = this._formBuilder.group({
      key: [
        {
          value: k,
          disabled: k, // disable input if k is passed in
        },
      ],
      value: v,
    });
    group.get('key').addValidators([Validators.required, Validators.pattern(/^[^<>]+$/)]);
    group.get('value').addValidators([Validators.pattern(/^[^<>]+$/)]);
    return group;
  }
}
