import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SplashScreenService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  static hideSplash(): void {
    document.body.classList.add('fuse-splash-screen-hidden')
  }
}
