import { Directive, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
    selector: 'img',
    standalone: true,
})
export class LogoLoadedDirective {
  @Output('LogoLoaded') loaded = new EventEmitter();

  @HostListener('load')
  logoLoaded(): void {
    this.loaded.emit('load');
    this.loaded.complete();
  }

  @HostListener('error')
  logoError(): void {
    this.loaded.emit('error');
    this.loaded.complete();
  }
}
