import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
    selector: '[isOverflowing]',
    exportAs: 'isOverflowing',
    standalone: true,
})
export class IsOverflowingDirective implements AfterViewInit {
  constructor(private el: ElementRef, private renderer: Renderer2, private cdr: ChangeDetectorRef) {}
  @Input('isOverflowing') isOverflowing: boolean = false;
  @HostListener('window:resize') onResize() {
    this.setIsOverFlowing();
  }

  ngAfterViewInit(): void {
    this.setIsOverFlowing();
  }

  private setIsOverFlowing() {
    const scrollWidth = this.el.nativeElement.scrollWidth;
    const offsetWidth = this.el.nativeElement.offsetWidth;

    this.isOverflowing = scrollWidth > offsetWidth; // This will likely be used to update the view
    this.cdr.detectChanges(); // So, we need to detect changes
  }
}
