import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import moment from 'moment-timezone';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'date-tooltip',
  templateUrl: './date-tooltip.component.html',
  styleUrls: ['./date-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatTooltipModule],
})
export class DateTooltipComponent implements OnChanges {
  @Input() date: Date | string;
  @Input() includeTime = true;
  @Input() preferredTimeZone?: string;
  @Input() tableCell: boolean = true;
  public tooltipText: string;
  public displayText: string = '';

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.date) {
      return;
    }
    for (const propName in changes) {
      if (propName === 'date') {
        if (typeof this.date === 'string') {
          // Used to suppress the deprecation warning for `moment` when using `isValid`
          // This avoids `Invalid date` when a string other than timestamp is passed in a Date field (i.e. 'i.e. 'Invitation Sent`, `Awaiting document`, etc.)
          moment.suppressDeprecationWarnings = true;
          if (!moment(this.date).isValid()) {
            this.displayText = this.date;
            this.tooltipText = null;
          }
        }
        this.computeTooltipText();
      }
    }
  }

  private computeTooltipText(): void {
    if (this.displayText === this.date) {
      return;
    } else {
      const momentDate = moment(this.date);
      const localTimezone = moment.tz.guess();
      const tz = this.preferredTimeZone || localTimezone;
      const tooltipFormat = 'ddd, MMM D, YYYY, h:mm' + (this.tableCell ? ' A' : ':ss A');
      const displayFormat = this.tableCell ? 'MMM D, YYYY, hh:mm A' : tooltipFormat.replace('ddd, ', '');
      const localTimestamp = momentDate.tz(localTimezone).format(tooltipFormat);
      const estTimestamp = momentDate.tz('America/New_York').format(tooltipFormat);

      this.tooltipText = `Local Time: ${localTimestamp}\nEastern Time: ${estTimestamp}`;
      this.displayText = momentDate.tz(tz).format(displayFormat);
    }
  }
}
