import { Component, ViewEncapsulation } from '@angular/core';
import { TilledLabelGenericComponent } from './tilled-label-generic.component';

@Component({
  selector: 'tilled-label-l1',
  templateUrl: '../tilled-text-generic.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class TilledLabelL1Component extends TilledLabelGenericComponent {
  public styles: string;
  constructor() {
    super();
    this.styles = 'text-label1';
  }
}
