import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TilledAlert } from '../models/tilled-alert';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private _newAlert$ = new BehaviorSubject<TilledAlert>(null);
  public newAlert$: Observable<TilledAlert> = this._newAlert$.asObservable();

  showAlert(alert: TilledAlert) {
    if (alert) {
      this._newAlert$.next(alert);
    }
  }
}
