<div class="flex min-w-120 flex-auto flex-col">
  <div class="flex justify-end">
    <!-- <mat-icon style="cursor: pointer" (click)="closeDialog()" svgIcon="mat_outline:close"></mat-icon> -->
  </div>
  <ng-container>
    <div class="grid w-full justify-items-center pb-8">
      <!-- Title -->
      <tilled-heading-h2 class="pb-2">Cancel a payment before it has completed</tilled-heading-h2>
      <tilled-paragraph-p3>Provide cancel details below.</tilled-paragraph-p3>
    </div>
    <div>
      <form [formGroup]="cancelForm" class="">
        <!-- Reason Start-->
        <div>
          <mat-form-field floatLabel="always" appearance="fill" class="-mt-4 w-full pb-4">
            <tilled-label-l1 secondary="true" class="">Cancellation Reason</tilled-label-l1>
            <mat-select
              class="text-secondary pt-4 text-p3"
              formControlName="reason"
              placeholder="Select a cancellation reason"
              data-test-id="reason"
            >
              <mat-option value="duplicate">Duplicate</mat-option>
              <mat-option value="fraudulent">Fraudulent</mat-option>
              <mat-option value="requested_by_customer">Requested by customer</mat-option>
              <mat-option value="abandoned">Abandoned</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- Reason End -->
      </form>
    </div>

    <div *ngIf="displayAlert$ | async" class="flex w-full justify-start px-8 pt-2">
      <fuse-alert
        [type]="alertType"
        [appearance]="'outline'"
        [dismissible]="alertDismissible"
        [dismissed]="false"
        [name]="'cancelPaymentDialogAlertBox'"
      >
        <span fuseAlertTitle>{{ alertTitle }}</span>
        {{ alertMessage }}
      </fuse-alert>
    </div>

    <div class="flex w-full justify-end px-8 pt-8">
      <!-- Cancel Button -->
      <tilled-button
        bgColor="white"
        styles="text-tilled-secondary"
        [whiteText]="false"
        [rounded]="true"
        buttonText="Cancel"
        (buttonClick)="closeDialog()"
      ></tilled-button>

      <!-- Submit Button -->
      <tilled-button
        bgColor="tilled-primary"
        [rounded]="true"
        buttonText="Confirm"
        (buttonClick)="submitCancelClicked()"
        [whiteText]="true"
      ></tilled-button>
    </div>
  </ng-container>
</div>
