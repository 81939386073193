<form-card
  [submitButton]="true"
  [submitDisabled]="!updateTerminalReaderForm.get('serialNumber').value"
  [submitText]="'Update'"
  [titleText]="'Edit Terminal Reader'"
  [maxWidth]="'max-w-120'"
  (exitClick)="closeDialog()"
  (submitClick)="updateTerminalClicked()"
  [testIds]="{ exitButton: 'update-terminal-reader-close-Btn', submitButton: 'update-terminal-reader-save-Btn' }"
>
  <form
    class="flex w-full flex-col items-start space-y-2 rounded-md bg-white bg-opacity-10 outline outline-1 outline-white"
    [formGroup]="updateTerminalReaderForm"
  >
    <tilled-input
      class="w-full"
      name="description"
      tilledLabel="true"
      placeholder="Description"
      label="Description"
    ></tilled-input>
    <tilled-input
      class="w-full"
      name="serialNumber"
      tilledLabel="true"
      placeholder="125xxxxxx"
      label="Serial Number"
    ></tilled-input>

    <div class="w-full text-tilled-secondary">
      <mat-slide-toggle formControlName="tipEnabledSetting" color="primary">
        <tilled-paragraph-p3 [secondary]="true">Tips Enabled</tilled-paragraph-p3>
      </mat-slide-toggle>
      <tilled-paragraph-p3 [secondary]="true">
        Enabling this setting will show 4 tip options during checkout on the terminal. These amounts are 10%, 15%, 20%
        and 25%. These pre-set amounts cannot be changed. Terminal User may also enter a custom amount.
      </tilled-paragraph-p3>
    </div>
    <div class="mt-4 w-full text-tilled-secondary">
      <mat-slide-toggle formControlName="printReceiptSetting" color="primary">
        <tilled-paragraph-p3 [secondary]="true">Receipts</tilled-paragraph-p3>
      </mat-slide-toggle>
      <tilled-paragraph-p3 [secondary]="true">
        Enabling this setting will print a customer and merchant receipt copy for each transaction.
      </tilled-paragraph-p3>
    </div>
  </form>
</form-card>
