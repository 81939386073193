import { Injectable } from '@angular/core';
import { ULD_TABLE_NAME } from 'app/core/constants';
import { RecentMerchantData } from 'app/shared/merchant-selector/merchant-selector.component';
import { Observable } from 'rxjs';
import { IndexedDBService } from '../indexed-db/indexed-db.service';

@Injectable({
  providedIn: 'root',
})
export class UserLocalDataService {
  constructor(private _indexedDbService: IndexedDBService) {}

  public getRecentMerchants(userId: string): Observable<UserLocalDataObject> {
    return this._indexedDbService.getByKey(ULD_TABLE_NAME, userId);
  }

  public updateRecentMerchants(value: RecentMerchantData[], key: string): Observable<UserLocalDataObject> {
    let params: UserLocalDataObject = {
      id: key,
      recent_merchants: value,
    };
    return this._indexedDbService.update(ULD_TABLE_NAME, params);
  }
}

export class UserLocalDataObject {
  id: string;
  recent_merchants: RecentMerchantData[];
}
