import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { BehaviorSubject, Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private _notifications: BehaviorSubject<Notification[]> = new BehaviorSubject<Notification[]>([]);
  private _routedFromNotification$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public routedFromNotification$ = this._routedFromNotification$.asObservable();

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for notifications
   */
  get notifications$(): Observable<Notification[]> {
    return this._notifications.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all notifications
   */
  getAll(): Observable<Notification[]> {
    return this.notifications$;
  }

  /**
   * Create a notification
   *
   * @param notification
   */
  create(notification: Notification): void {
    this.notifications$.pipe(take(1)).subscribe((notifications) => {
      // Update the notifications with the new notification
      this._notifications.next([...notifications, notification]);
    });
  }

  /**
   * Update the notification
   *
   * @param id
   * @param notification
   */
  update(id: string, notification: Notification): void {
    this.notifications$.pipe(take(1)).subscribe((notifications) => {
      // Find the index of the updated notification
      const index = notifications.findIndex((item) => item.id === id);

      // Update the notification, if found
      if (index !== -1) {
        notifications[index] = { ...notifications[index], ...notification };

        // Update the notifications
        this._notifications.next([...notifications]);
      }
    });
  }

  /**
   * Delete the notification
   *
   * @param id
   */
  delete(id: string): void {
    this.notifications$.pipe(take(1)).subscribe((notifications) => {
      // Find the index of the deleted notification
      const index = notifications.findIndex((item) => item.id === id);

      // Delete the notification, if found
      if (index !== -1) {
        notifications.splice(index, 1);

        // Update the notifications
        this._notifications.next([...notifications]);
      }
    });
  }

  /**
   * Mark all notifications as read
   */
  markAllAsRead(): void {
    this.notifications$.pipe(take(1)).subscribe((notifications) => {
      // Go through all notifications and set them as read
      const updatedNotifications = notifications.map((notification) => ({
        ...notification,
        read: true,
      }));

      // Update the notifications
      this._notifications.next(updatedNotifications);
    });
  }

  deleteAll(): void {
    this._notifications.next([]);
  }

  setRoutedFromNotification(value: boolean): void {
    this._routedFromNotification$.next(value);
  }
}
