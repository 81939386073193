export class PaymentsTypes {
  static PaymentMethodDisplayText = new Map<string, string>([
    ['card', 'Card'],
    ['card_present', 'Card Present'],
    ['ach_debit', 'ACH Debit'],
    ['eft_debit', 'EFT Debit'],
  ]);

  static CurrencyDisplayText = new Map<string, string>([
    ['cad', 'CAD'],
    ['usd', 'USD'],
  ]);
}
