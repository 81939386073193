/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { FormArray, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function allOrNone(): ValidatorFn {
  return (group: FormGroup): ValidationErrors | null => {
    const value = group.value;
    if (!value) {
      return null;
    }

    let nullCount = 0;
    let controlsCount = 0;
    for (let control in group.controls) {
      if (!group.get([control]).value) {
        nullCount++;
      } else {
        // nested validate FormArrays
        if (group.get([control]) instanceof FormArray) {
          let formArray = group.get([control]) as FormArray;
          if (formArray.controls?.length === 0) {
            nullCount++;
          } else {
            for (let formControl of (group.get([control]) as FormArray).controls) {
              if (!formControl.value) {
                nullCount++;
              }
              controlsCount++;
            }
          }
        }
      }
      controlsCount++;
    }

    if (nullCount > 0) {
      if (nullCount != controlsCount) {
        return { nullField: true };
      }
    }

    return null;
  };
}
