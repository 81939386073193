<ng-container *ngIf="showCard">
  <div class="w-full pb-2">
    <tilled-paragraph-p3>{{ label }}</tilled-paragraph-p3>
  </div>
  <!-- Debit Template Details -->
  <ng-container>
    <div class="w-full">
      <div class="my-[5px] grid w-full grid-cols-1 sm:grid-cols-2">
        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-start" [secondary]="true">
          Currency</tilled-paragraph-p3
        >

        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-end">
          {{ currency | uppercase }}
        </tilled-paragraph-p3>
      </div>
      <div class="my-[5px] grid w-full grid-cols-1 sm:grid-cols-2">
        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-start" [secondary]="true">
          Fee Type</tilled-paragraph-p3
        >

        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-end">Flat Fee</tilled-paragraph-p3>
      </div>
      <mat-divider class="mt-3 mb-4 w-full"></mat-divider>
      <div class="my-[5px] grid w-full grid-cols-1 sm:grid-cols-2">
        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-start" [secondary]="true"
          >Transaction Fee</tilled-paragraph-p3
        >
        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-end">
          {{ transactionFee | minorUnitsToCurrency : currency }}
        </tilled-paragraph-p3>
      </div>
      <div class="my-[5px] grid w-full grid-cols-1 sm:grid-cols-2">
        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-start" [secondary]="true"
          >Return Fee</tilled-paragraph-p3
        >
        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-end">
          {{ returnFee | minorUnitsToCurrency : currency }}
        </tilled-paragraph-p3>
      </div>
      <mat-divider class="mt-3 mb-4 w-full"></mat-divider>
      <div class="my-[5px] grid w-full grid-cols-1 sm:grid-cols-2">
        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-start" [secondary]="true"
          >Monthly Account Fee</tilled-paragraph-p3
        >
        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-end">
          {{ accountMonthlyFee | minorUnitsToCurrency : currency }}
        </tilled-paragraph-p3>
      </div>
      <div class="my-[5px] grid w-full grid-cols-1 sm:grid-cols-2">
        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-start" [secondary]="true"
          >Monthly Account Minimum Fee</tilled-paragraph-p3
        >
        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-end">
          {{ accountMonthlyMinimumFee | minorUnitsToCurrency : currency }}
        </tilled-paragraph-p3>
      </div>
    </div>
  </ng-container>
</ng-container>
