export const environment = {
  env: 'staging',
  api: 'https://staging-api.tilled.com',
  appUrl: 'staging-app.tilled.com',
  sentryDSN: 'https://41ee08ccbfb64c0386f90376b569ab93@o377738.ingest.sentry.io/6582075',
  production: false,
  hmr: false,
  whiteLabelDomain: '.staging-paymentsonline.io',
  tracesSampleRate: 1.0,
  replaysSamplingRate: 0,
  replaysOnErrorSamplingRate: 0,
};
