<tilled-table
  [data]="(viewModels$ | async)!"
  [getPageCallback]="type === paymentsSummary1 ? getPaymentsReportRuns : getPayoutsReportRuns"
  [dataLength]="reportRunsCount$ | async"
  data-test-id="downloads-table"
  [startingPageLimit]="5"
  [accountId]="accountId"
  [hideColumnKeys]="hideColumnKeys"
  [hideZeroState]="true"
></tilled-table>
